import { cuit as argCuitValidator } from 'arg.js';

import { DOCUMENT_TYPES, DOCUMENT_TYPES_CUIT } from '../constants/solicitudesF2';
import { DOCUMENT_NUMBER_PROPS_F2, PROMOTER_ID_LENGTH } from '../constants/validationsF2';

import { minMaxLength, numericalDigits, minLength } from './inputValidations';

function isNotAPrefix(documentNumberType: string, valueToCkeck: string) {
  return DOCUMENT_NUMBER_PROPS_F2[documentNumberType].PREFIXES?.every(
    type => !valueToCkeck.startsWith(String(type))
  );
}

function cuitCuilValidation(documentNumberType: string, cuitCuil: any, errorMessage: string) {
  const cuitCuilStr = String(cuitCuil);
  return (
    (isNotAPrefix(documentNumberType, cuitCuilStr) || !argCuitValidator.isValid(cuitCuilStr)) && errorMessage
  );
}

const cuilValidation = (errorMessage: string) => (cuil: any) =>
  cuitCuilValidation(DOCUMENT_TYPES.CUIL.COD, cuil, errorMessage);

const cuitValidation = (errorMessage: string) => (cuit: any) =>
  cuitCuilValidation(DOCUMENT_TYPES_CUIT.COD, cuit, errorMessage);

export const getDocumentNumberValidationF2 = ({ t, type }: { t: any; type: string }) => [
  type === DOCUMENT_TYPES.DNI.COD &&
    numericalDigits(
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS_F2[type].MIN,
        max: DOCUMENT_NUMBER_PROPS_F2[type].MAX
      })
    ),
  (type === DOCUMENT_TYPES.PASSPORT.COD || type === DOCUMENT_TYPES.DNI.COD) &&
    minMaxLength(
      DOCUMENT_NUMBER_PROPS_F2[type].MIN,
      DOCUMENT_NUMBER_PROPS_F2[type].MAX,
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS_F2[type].MIN,
        max: DOCUMENT_NUMBER_PROPS_F2[type].MAX
      })
    ),
  type === DOCUMENT_TYPES.CUIL.COD &&
    minLength(
      DOCUMENT_NUMBER_PROPS_F2[DOCUMENT_TYPES.CUIL.COD].MIN,
      t('validation.exactDigits', { length: DOCUMENT_NUMBER_PROPS_F2[DOCUMENT_TYPES.CUIL.COD].MIN })
    ),
  type === DOCUMENT_TYPES_CUIT.COD &&
    minLength(
      DOCUMENT_NUMBER_PROPS_F2[DOCUMENT_TYPES_CUIT.COD].MIN,
      t('validation.exactDigits', { length: DOCUMENT_NUMBER_PROPS_F2[DOCUMENT_TYPES_CUIT.COD].MIN })
    ),
  type === DOCUMENT_TYPES.CUIL.COD && cuilValidation(t('validation.cuilNumber')),
  type === DOCUMENT_TYPES_CUIT.COD && cuitValidation(t('validation.cuitNumber'))
];

export const validateDNI = (t: any) => [
  numericalDigits(
    t('Los datos ingresados son inválidos', {
      min: DOCUMENT_NUMBER_PROPS_F2.DNI.MIN,
      max: DOCUMENT_NUMBER_PROPS_F2.DNI.MAX
    })
  ),
  minMaxLength(
    DOCUMENT_NUMBER_PROPS_F2.DNI.MIN,
    DOCUMENT_NUMBER_PROPS_F2.DNI.MAX,
    t('Los datos ingresados son inválidos', {
      min: DOCUMENT_NUMBER_PROPS_F2.DNI.MIN,
      max: DOCUMENT_NUMBER_PROPS_F2.DNI.MAX
    })
  )
];

export const promoterId = (t: any) =>
  minMaxLength(
    PROMOTER_ID_LENGTH.MIN,
    PROMOTER_ID_LENGTH.MAX,
    t('validation.documentNumber', {
      min: PROMOTER_ID_LENGTH.MIN,
      max: PROMOTER_ID_LENGTH.MAX
    })
  );
