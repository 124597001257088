import { RejectedReason, FamilyMembers, FamilyMember } from '~utils/types';

export const orderRiskData = (data: RejectedReason[], familyGroupDetails: FamilyMember[], t: Function) => {
  let childCount = 0;
  return familyGroupDetails.map<RejectedReason>((familyMember, index) => {
    const isChild = familyMember.member === FamilyMembers.CHILD;
    let gender = null;
    if (isChild) {
      childCount++;
      gender = familyMember.sex;
    }
    const findReason = data.find(reason => reason.index === index);
    return {
      type: `${t(`resources.affiliations.familyGroup.${gender && gender === 'Femenino' ? 'daughter' : familyMember.member}`)}${
        isChild ? ` ${childCount}` : ''
      }`,
      age: familyMember.age,
      ...(findReason ? { index: findReason.index, reasons: findReason?.reasons } : { index: -1, reasons: [] })
    };
  });
};
