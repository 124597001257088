import { makeStyles } from '@material-ui/core';
import { WHITE, DARK_GRAY, PRIMARY_BLUE, TRANSPARENCY } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: 10
    }
  },
  commonModal: {
    backgroundColor: WHITE,
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    '&:focus': {
      outline: 'none'
    }
  },
  rotategif: {
    transform: ' rotate(-90deg)'
  },
  modal: {
    maxWidth: 450,
    padding: '32px 60px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 300,
      padding: '30px 20px'
    }
  },
  modalInfo: {
    minWidth: '640px',
    padding: '16px 40px',
    [theme.breakpoints.only('xs')]: {
      minWidth: '300px',
      maxWidth: '90%',
      padding: '16px 10px',
      width: '100%'
    }
  },
  closeIconContainer: {
    position: 'absolute',
    right: 12,
    top: 18,
    padding: 0,
    '&:hover': {
      backgroundColor: TRANSPARENCY
    },
    [theme.breakpoints.down('xs')]: {
      top: 12
    }
  },
  closeIcon: {
    color: DARK_GRAY,
    height: 24,
    width: 24
  },
  cancelButton: {
    backgroundColor: `${WHITE}!important`,
    padding: '14px 20px',
    '&:hover': {
      backgroundColor: WHITE,
      color: `${PRIMARY_BLUE}!important`,
      opacity: '0.9',
      transform: 'scale(1.03, 1.03)'
    },
    '& span': {
      color: PRIMARY_BLUE,
      fontWeight: 600,
      padding: 0
    }
  },
  actionsContainer: {
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  loadingContainer: {
    display: 'grid',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  },
  commonTitle: {
    color: DARK_GRAY,
    textAlign: 'center'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '21px',
    marginBottom: 25
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: -25,
    marginBottom: 10
  },
  titleInfo: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '60px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '24px',
      marginBottom: '25px'
    }
  },
  modalInfoInnerContainer: {
    maxHeight: '80vh',
    minHeight: '140px',
    overflowY: 'auto',
    width: '100%'
  }
}));
