import { sortAlphabetically } from '~utils/general';
import pathParse from 'path-parse';
import mime from 'mime-types';

const FileBaseAndContextGCloudRegExp = /\/([^/?#]*)(?:[?#].*)?$/;
export const getCleanFileBaseFromUrl = (url: string) => {
  const fileBaseMatching: RegExpMatchArray | null = url.match(FileBaseAndContextGCloudRegExp);
  return fileBaseMatching ? decodeURI(fileBaseMatching[1]) : '';
};

const FileBasePartsConsts = {
  fullRegExpExec: (fileName: string) => /^(?:([a-zA-Z])_)?([^-]*)(-.*)?$/.exec(fileName),
  fullPrefixGroup: 1,
  fullRootNameGroup: 2,
  fullCenterGroup: 3,
  centerRegExpExec: (centerPart: string) =>
    /^-()?(\d+)$/.exec(centerPart) || /^-(.*)-(\d+)$/.exec(centerPart) || /^-(.*)()?$/.exec(centerPart)!,
  centerAfterNameGroup: 1,
  centerIndexGroup: 2
};

export type FileBaseParts = {
  prefix: string | undefined;
  rootName: string | undefined;
  afterDash: string | undefined;
  index: string | undefined;
  extension: string | undefined;
};

export function getFileBaseParts(fileBase: string): FileBaseParts {
  const fileBaseParsed = pathParse(fileBase);
  const fnPartsMatch: RegExpMatchArray = FileBasePartsConsts.fullRegExpExec(fileBaseParsed.name)!;
  const result: FileBaseParts = {
    prefix: fnPartsMatch[FileBasePartsConsts.fullPrefixGroup],
    rootName: fnPartsMatch[FileBasePartsConsts.fullRootNameGroup],
    afterDash: undefined,
    index: undefined,
    extension: fileBaseParsed.ext
  };

  const center: string = fnPartsMatch[FileBasePartsConsts.fullCenterGroup];
  if (center !== undefined) {
    const fnCenterMatch: RegExpMatchArray = FileBasePartsConsts.centerRegExpExec(center);

    result.afterDash = fnCenterMatch[FileBasePartsConsts.centerAfterNameGroup];
    result.index = fnCenterMatch[FileBasePartsConsts.centerIndexGroup];
  }

  return result;
}

export const getFileBase = ({ prefix, rootName, afterDash, index, extension }: FileBaseParts) =>
  (prefix ? `${prefix}_` : '') +
  (rootName ? rootName : '') +
  (afterDash === '' || afterDash ? `-${afterDash}` : '') +
  (index ? `-${index}` : '') +
  (extension ? extension : '');

export type AttachmentElementType = { url: string; name: string };
export type AttachmentsType = Array<AttachmentElementType>;

export function countByName(attachments: AttachmentsType, nameRegExp: RegExp) {
  return attachments && attachments.filter(({ name }) => nameRegExp.test(name)).length;
}

export const getOrderedAttachments = (
  attachments: string[],
  t: Function
): { fromQuestions: AttachmentsType; fromProspect: AttachmentsType; fromPromoter: AttachmentsType } => {
  const holderAttachs: AttachmentsType = [];
  const coupleAttachs: AttachmentsType = [];
  const sonsAttachs: AttachmentsType = [];
  const promoterAttachs: AttachmentsType = [];
  const otherAttachs: AttachmentsType = [];
  const questionAttach: AttachmentsType = [];

  const holderText = t('general.holder').toLowerCase();
  const coupleText = t('general.couple').toLowerCase();
  const sonText = t('general.son').toLowerCase();
  const sonRegExp = new RegExp(`^${sonText}\\d+$`);
  const generalDocumentText = t('general.document').toLowerCase();

  const cleanAttachments = attachments
    .reduce((prevClenaed: AttachmentsType, url: string) => {
      const cleanFileBase = getCleanFileBaseFromUrl(url);
      if (!cleanFileBase) {
        // eslint-disable-next-line no-console
        console.error(`URL [${url}] Not matching expected format.`);
        return prevClenaed;
      }
      return [...prevClenaed, { url, name: cleanFileBase }];
    }, [] as AttachmentsType)
    .sort(sortAlphabetically('name'));

  // eslint-disable-next-line complexity
  cleanAttachments.forEach(cleanAttach => {
    const { name: cleanName, url } = cleanAttach;
    const { prefix, rootName, afterDash, index, extension } = getFileBaseParts(cleanName);
    const pushWithName = (target: AttachmentsType, name: string) => target.push({ url, name });
    const screenNameForFamily = (currentAttachs: AttachmentsType) => {
      if (prefix || afterDash || !index?.match(/^\d+$/)) {
        return cleanName;
      }

      const defaultNameHoledReplace = (paramValue: string) => {
        const indexExtesionHole = '{indexExtesionHole}';
        const defaultNameHoled = `${rootName}-${generalDocumentText}-${indexExtesionHole}`;
        return defaultNameHoled.replace(indexExtesionHole, paramValue);
      };
      // una expresion regular donde aparece un ìndice y una extensiòn
      const defaultNameRegExp = new RegExp(`^${defaultNameHoledReplace('\\d+([.][^.]*)?')}$`);

      const countWithDefaultName = countByName(currentAttachs, defaultNameRegExp);

      return defaultNameHoledReplace(String(countWithDefaultName + 1) + extension);
    };
    switch (prefix || rootName) {
      case 'P':
        if (rootName?.startsWith('questions')) {
          pushWithName(questionAttach, cleanName);
        } else {
          pushWithName(promoterAttachs, cleanName);
        }
        break;
      case '':
      case undefined:
      case null:
        switch (rootName) {
          case holderText:
            pushWithName(holderAttachs, screenNameForFamily(holderAttachs));
            break;
          case coupleText:
            pushWithName(coupleAttachs, screenNameForFamily(coupleAttachs));
            break;
          default:
            if (sonRegExp.test(rootName || '')) {
              pushWithName(sonsAttachs, screenNameForFamily(sonsAttachs));
            } else if (rootName?.startsWith('PROSP') || rootName?.startsWith('prosp')) {
              pushWithName(questionAttach, cleanName);
            } else if (
              rootName?.startsWith('form') ||
              rootName?.startsWith('FORM') ||
              rootName?.startsWith('others')
            ) {
              pushWithName(otherAttachs, cleanName);
            }
        }
        break;
      default:
        pushWithName(otherAttachs, cleanName);
        break;
    }
  });

  return {
    fromQuestions: questionAttach.sort(sortAlphabetically('name')),
    fromProspect: [
      ...holderAttachs.sort(sortAlphabetically('name')),
      ...coupleAttachs.sort(sortAlphabetically('name')),
      ...sonsAttachs.sort(sortAlphabetically('name')),
      ...otherAttachs.sort(sortAlphabetically('name'))
    ],
    fromPromoter: promoterAttachs.sort(sortAlphabetically('name'))
  };
};

export const isPromoterAttachment = (obj: AttachmentElementType) => obj.name.startsWith('P_');
export const isProspectAttachment = (obj: AttachmentElementType) => obj.name.startsWith('PROSP_');

/**
 * Determina la extensiòn indicada para un archivo contemplando el tipo mime y su propia extensión.
 *
 * @param {string} fileMimeType el tipo mime del archivo
 * @param {string} fileExtension la extension actual del archivo
 * @returns {string | undefined} retorna la extension elegida
 */
export function getCorrectFileExtenssion(
  fileMimeType: string,
  fileExtension: string | undefined
): string | undefined {
  const fileExtensionNotDotted = fileExtension?.slice(0, 1) === '.' ? fileExtension.slice(1) : undefined;
  const posibleExtensionsFromMime = mime.extensions[fileMimeType];
  if (fileExtensionNotDotted && posibleExtensionsFromMime?.includes(fileExtensionNotDotted)) {
    return fileExtension;
  }

  const extensionFromMime = fileMimeType && mime.extension(fileMimeType);

  return extensionFromMime ? `.${extensionFromMime}` : fileExtension || '.';
}
