import React, { useCallback } from 'react';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import CustomButton from '~components/CustomButton';
import { useDispatch, useSelector } from '~contexts/ModalContext';
import { IVBSteps } from '~utils/interfaces';

import { useStyles } from './styles';
import VBModal from './VBModal';
import Point from './VBModal/Point';

interface Props {
  vbSteps: IVBSteps | undefined;
}

function VBField({ vbSteps }: Props) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const modal = useSelector(state => state);
  const isOpen = modal[MODAL_NAMES.VB];

  const handleOpen = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.VB));
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.VB));
  }, [dispatch]);

  const vbTerminado = vbSteps?.procesoTerminado;
  const vbSinError = vbSteps?.procesoTerminado && !vbSteps?.procesoTerminadoconError;

  return (
    <>
      <div className={styles.container}>
        {vbTerminado ? (
          <>
            <Point condition={vbSinError} />
            <p className={styles.label}>{vbSinError ? 'Si' : 'No'}</p>
          </>
        ) : (
          'En proceso'
        )}
        <CustomButton onClick={handleOpen} label="Ver detalle" isLinkButton />
      </div>
      <CustomModal
        modalName={MODAL_NAMES.VB}
        onClose={handleClose}
        title="Validaci&oacute;n biom&eacute;trica"
        isInfo
      >
        {isOpen && <VBModal vbSteps={vbSteps} onClick={handleClose} />}
      </CustomModal>
    </>
  );
}

export default VBField;
