import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources, useTranslate } from 'react-admin';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppState, GenericObjectInterface } from '~utils/types';

import { useStyles } from './styles';

interface Props extends RouteComponentProps<any> {
  onToggleSidebar?: () => void;
}

function Menu({ onToggleSidebar }: Props) {
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const resources: [] = useSelector(getResources);
  const styles = useStyles();
  const t = useTranslate();
  const notCapitalizeItemIndex = 5; 

  return (
    <div className={styles.container}>
      {resources.map((resource: GenericObjectInterface<any>, index: number) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && t(resource.options.label)) || resource.name}
          leftIcon={createElement(resource.icon)}
          onClick={onToggleSidebar}
          sidebarIsOpen={open}
          classes={{ 
            active: styles.activeLink, 
            ...(index === notCapitalizeItemIndex && { root: styles.notCapitalize })
          }}
          className={styles.link}
        />
      ))}
    </div>
  );
}

export default withRouter(Menu);
