import { makeStyles } from '@material-ui/core/styles';
import { DARK_GRAY, LIGHT_GRAY, TRANSPARENCY } from '~constants/colors';

export const useClasses = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
    [theme.breakpoints.only('xs')]: {
      backgroundColor: LIGHT_GRAY,
      boxShadow: `0 10px 8px ${TRANSPARENCY}`,
      padding: 0
    }
  },
  title: {
    flex: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& > span': {
      color: DARK_GRAY,
      fontSize: 24,
      fontWeight: 600,
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      }
    }
  },
  icon: {
    flex: 1,
    padding: 0
  }
}));

export const useStyles = makeStyles({
  logo: {
    alignItems: 'center',
    flex: 2,
    height: 40,
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    '& > img': {
      margin: 0
    }
  }
});
