import {
  AFFILIATION_TYPES,
  SOLICITUDES_ON_REQUEST_STATUS,
  DOCUMENT_TYPES,
  APPLICATION_TYPES,
  HIRING_MODE_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  HIRING_MODE_TYPES_AUTOGESTION,
  AFFILIATION_TYPES_AUTOGESTION
} from '~constants/solicitudesON';

const solicitudesONSpanish = {
  name: 'Solicitudes ON',
  history: 'Historial',
  listName: 'Listado de solicitudes',
  detailTitle: 'Detalle de solicitud',
  initialEmail: {
    send: {
      complete: 'Enviar e-mail de inicio',
      short: 'Enviar',
      title: 'Click para enviar e-mail de inicio'
    },
    resend: {
      complete: 'Reenviar e-mail de inicio',
      short: 'Reenviar',
      title: 'Click para reenviar e-mail de inicio'
    }
  },
  resendUserValidationEmail: {
    complete: 'Reenviar e-mail de validación de datos',
    short: 'Reenviar',
    title: 'Click para reenviar e-mail de validación de datos'
  },
  validateAffiliation: 'Evaluar',
  validateAffiliationTitle: 'Click para ir al detalle y evaluar la solicitud de afiliación',
  createTitle: 'Nueva solicitud',
  modals: {
    title: '¿Estás seguro?',
    initialEmail: {
      send: 'Estás por enviar el e-mail para iniciar el proceso de afiliación.',
      resend: 'Estás por reenviar el e-mail para iniciar el proceso de afiliación.',
      success: 'Se ha enviado el e-mail correctamente'
    },
    userValidationEmail: {
      resend: 'Estás por reenviar el e-mail para que el afiliado valide sus datos.',
      success: 'Se ha enviado el e-mail correctamente'
    },
    form: {
      createSuccess: 'Se creó con éxito la nueva solicitud de afiliación.',
      editSuccess: 'Se editó con éxito los datos de la afiliación.'
    },
    titleHistorial: 'Histórico de pasos',
    titlePotentialRisk: 'Potencial de riesgo'
  },
  fields: {
    action: 'Acción',
    id: 'ID',
    promoterId: 'Promotor',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    emailConfirmation: 'Confirmación de e-mail',
    phoneNumber: 'Teléfono',
    document: 'Documento',
    type: 'Tipo de socio',
    companyCuit: 'Cuit ',
    companyName: 'Nombre de la compañia',

    affiliationTypes: {
      [AFFILIATION_TYPES.MANDATORY]: 'Obligatorio',
      [AFFILIATION_TYPES.AGREEMENT]: 'Convenio'
      // [AFFILIATION_TYPES.COMPLEMENT]: 'Complementación'
    },
    affiliationTypesAutogestion: {
      [AFFILIATION_TYPES_AUTOGESTION.MANDATORY]: 'Obligatorio',
      [AFFILIATION_TYPES_AUTOGESTION.AGREEMENT]: 'Convenio',
      [AFFILIATION_TYPES_AUTOGESTION.DIRECT]: 'Directo'
    },
    hiringMode: 'Modalidad de contratación',
    hiringModeTypes: {
      // [HIRING_MODE_TYPES.APO]: 'APO',
      [HIRING_MODE_TYPES.PLA]: 'PLA'
    },
    hiringModeTypesAutogestion: {
      [HIRING_MODE_TYPES_AUTOGESTION.APO]: 'APO',
      [HIRING_MODE_TYPES_AUTOGESTION.PLA]: 'PLA'
    },

    //   companyPlan: 'Seleccione el plan',
    //   companyPlanTypes: {
    //     [COMPANY_PLAN_TYPES.P210]: '210',
    //     [COMPANY_PLAN_TYPES.P310]: '310',
    //     [COMPANY_PLAN_TYPES.P410]: '410',
    //     [COMPANY_PLAN_TYPES.P450]: '450',
    //     [COMPANY_PLAN_TYPES.P510]: '510'
    //   },
    //   increasePlanAllowed: 'Permite mejorar plan',
    //     ]    increasePlanAllowedTypes: {
    //     [INCREASE_PLAN_ALLOWED_TYPES.true]: 'Si',
    //     [INCREASE_PLAN_ALLOWED_TYPES.false]: 'No'
    //   },
    isPaymentResponsible: 'Socio Abona Diferencia',
    isPaymentResponsibleTypes: {
      // [IS_PAYMENT_RESPONSIBLE_TYPES.true]: 'Si',
      [IS_PAYMENT_RESPONSIBLE_TYPES.false]: 'No'
    },
    applicationType: 'Tipo de formulario',
    applicationTypes: {
      [APPLICATION_TYPES.FULL]: 'F1 + DDJJ'
      // [APPLICATION_TYPES.F1]: 'F1',
      // [APPLICATION_TYPES.BENEFICIARY]: 'Beneficiario'
    },
    planValidityDate: 'Vigencia (Fecha de inicio del plan)',
    documentType: 'Tipo de Documento',
    documentNumber: 'Número de Documento',
    status: 'Estado',
    statusTypes: {
      [SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING]: 'Inicio pendiente',
      [SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_SENT]: 'Email enviado',
      [SOLICITUDES_ON_REQUEST_STATUS.FORM_LINK_OPENED]: 'Link abierto',
      [SOLICITUDES_ON_REQUEST_STATUS.PENDING_USER_VALIDATION]: 'Confirmación PDF pendiente',
      [SOLICITUDES_ON_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION]: 'Revisar PDF',
      [SOLICITUDES_ON_REQUEST_STATUS.FINISHED]: 'En proceso alta',
      [SOLICITUDES_ON_REQUEST_STATUS.REVISION]: 'En revisión',
      [SOLICITUDES_ON_REQUEST_STATUS.USER_REJECTED]: 'Datos Incorrectos',
      [SOLICITUDES_ON_REQUEST_STATUS.EXPIRED]: 'Vencido',
      [SOLICITUDES_ON_REQUEST_STATUS.CANCELED]: 'Cancelado'
    },
    documentTypes: {
      [DOCUMENT_TYPES.DNI]: 'DNI',
      [DOCUMENT_TYPES.PASSPORT]: 'Pasaporte',
      [DOCUMENT_TYPES.CUIL]: 'CUIL'
    },
    potentialRisk: 'Potencial de riesgo'
  },
  documents: {
    f1: { title: 'Formulario F1' },
    sworn: { title: 'Declaración jurada' },
    attachments: { title: 'Documentación' }
  },
  evaluate: {
    [SOLICITUDES_ON_REQUEST_STATUS.FINISHED]: 'Dar alta proceso',
    [SOLICITUDES_ON_REQUEST_STATUS.REVISION]: 'Llevar a revisión',
    [SOLICITUDES_ON_REQUEST_STATUS.CANCELED]: 'Cancelar solicitud',
    modal: {
      [SOLICITUDES_ON_REQUEST_STATUS.FINISHED]: {
        message: 'Estás por dar de alta el proceso de afiliación.',
        success: 'Se ha dado de alta el proceso de afiliación correctamente'
      },
      [SOLICITUDES_ON_REQUEST_STATUS.REVISION]: {
        message: 'Estás por llevar a revisión el proceso de afiliación.',
        success: 'Se ha llevado a revisión el proceso de afiliación correctamente'
      },
      [SOLICITUDES_ON_REQUEST_STATUS.CANCELED]: {
        message: 'Estás por llevar a cancelado el proceso de afiliación.',
        success: 'Se ha llevado a cancelado el proceso de afiliación correctamente'
      }
    }
  },
  tooltip: 'Modificado el ',
  familyGroup: {
    partner: 'Cónyuge',
    conyuge: 'Cónyuge',
    child: 'Hijo',
    hijo: 'Hijo',
    daughter: 'Hija',
    childNB: 'Hijo no binario',
    prospect: 'Titular',
    familyGroup: 'Grupo Familiar',
    age: '%{age} años'
  },
  potentialRisk: {
    detail: 'Ver detalle',
    riskText: 'Presenta riesgos en %{reasons}'
  }
};

export default solicitudesONSpanish;
