import { makeStyles } from '@material-ui/core/styles';
import { WIDTH_ROW } from '~constants/sizes';
import { WHITE, PRIMARY_BLUE, DARK_GRAY } from '~constants/colors';

export const useF2FormStyles = makeStyles(theme => ({
  form: {
    [theme.breakpoints.up('sm')]: {
      width: WIDTH_ROW
    }
  },
  marginBottom15: {
    marginBottom: '15px'
  },
  textCheckAllDiscounts: {
    fontSize: '16px',
    marginLeft: '5px'
  },
  percentageDiscounts: {
    fontSize: '20px',
    padding: '12px 5px',
    fontWeight: 'bold'
  },
  percentageOneDiscountsPadding: {
    padding: '12px 5px'
  },
  percentageAllDiscountsPadding: {
    padding: '37px 5px'
  },
  cancelButton: {
    backgroundColor: `${WHITE}!important`,
    padding: '14px 20px',
    '&:hover': {
      backgroundColor: WHITE,
      color: `${PRIMARY_BLUE}!important`,
      opacity: '0.9',
      transform: 'scale(1.03, 1.03)'
    },
    '& span': {
      color: PRIMARY_BLUE,
      fontWeight: 600,
      padding: 0
    }
  },
  label: {
    color: `${DARK_GRAY}!important`
  },
  actionsContainer: {
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  }
}));
