import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE } from '~constants/colors';

export const useSapLogoStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '15px',
    marginTop: '-20px',
    width: '100%'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  label: {
    color: PRIMARY_BLUE,
    fontWeight: 'bold'
    // marginBottom: '8px'
  },
  value: {
    color: PRIMARY_BLUE,
    fontSize: '16px'
  }
});
