import { SolicitudF2Request } from '~utils/types';
import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';

export const getSendEmailRequestPayload = (solicitud?: SolicitudF2Request) => ({ id: solicitud?.id });

export const getShowSendInitialEmail = (status: string) =>
  [
    SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD,
    SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_SENT.COD,
    SOLICITUDES_REQUEST_STATUS.FORM_LINK_OPENED.COD
  ].includes(status);

export const getEvaluatePayload = (data?: { id?: number; newState?: string }) => ({
  id: data?.id,
  newState: data?.newState
});

export const isSolicitudFilled = (status: string) =>
  [
    SOLICITUDES_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION.COD,
    SOLICITUDES_REQUEST_STATUS.PENDING_USER_VALIDATION.COD,
    SOLICITUDES_REQUEST_STATUS.FINISHED.COD,
    SOLICITUDES_REQUEST_STATUS.REVISION.COD,
    SOLICITUDES_REQUEST_STATUS.USER_REJECTED.COD,
    SOLICITUDES_REQUEST_STATUS.EXPIRED.COD
  ].includes(status);

export const isAttorneyValidationPending = (status: string) =>
  status === SOLICITUDES_REQUEST_STATUS.PENDING_USER_VALIDATION.COD;

export const isInitialMailPending = (status: string | undefined) =>
  status === SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD;

export const isInitialMailSent = (status: string | undefined) =>
  status === SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_SENT.COD;

export const isEditable = (status: string | undefined) =>
  status === SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD ||
  status === SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_SENT.COD;

export const isEvaluationPending = (status: string) =>
  [
    SOLICITUDES_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION.COD,
    SOLICITUDES_REQUEST_STATUS.REVISION.COD
  ].includes(status);

export const getSendEmailTexts = (status: string) => {
  let base = '';
  const modalBase = 'resources.solicitudF2.modals';
  if (isAttorneyValidationPending(status)) {
    base = 'resources.solicitudF2.resendUserValidationEmail';
    return {
      label: `${base}.complete`,
      labelShort: `${base}.short`,
      title: `${base}.title`,
      modalMessage: `${modalBase}.userValidationEmail.resend`
    };
  }

  base = 'resources.solicitudF2.initialEmail';
  const type = status === SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD ? 'send' : 'resend';
  return {
    label: `${base}.${type}.complete`,
    labelShort: `${base}.${type}.short`,
    title: `${base}.${type}.title`,
    modalMessage: `${modalBase}.initialEmail.${type}`
  };
};

export const hasVB = (steps: any) => {
  return steps?.correspondeProceso && steps?.procesoTerminado &&  !steps?.procesoTerminadoconError;
}
