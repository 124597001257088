/* eslint-disable max-lines */
/* eslint-disable complexity */

import React, { useCallback, useEffect, useState } from 'react';
import { SimpleForm, useTranslate, useMutation, regex, FormDataConsumer, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import InputLabel from '~components/InputLabel';
import { useFormStyles, useCommonStyles } from '~app/styles';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import FeedbackModalContent from '~components/FeedbackModalContent';
import {
  required as requiredValidator,
  email,
  phoneDigits,
  numericalDigits,
  promoterId
} from '~utils/inputValidations';
import { CREATE_PROMOTER, EDIT_PROMOTER } from '~config/providers/constants';
import InputSelect from '~components/InputSelect';
import { useDispatch } from '~contexts/ModalContext';
import useListProviders from '~config/hooks/useListProviders';
import { PROMOTER_FIELDS } from '~screens/Promoters/constants';
import { ROLE_TYPES, PERMISO_OSDE_CHOICES, PERMISO_BINARIA_CHOICES } from '~constants/promoters';
import Toolbar from '~components/Toolbar';
import { FILIALES_LIST } from '~constants/filiales';
import { Roles, PromoterForm as PromoterFormType } from '~utils/types';
import { getEmailValidation } from '~services/OsdeService';
import authProvider from '~config/providers/authProvider';
import paths from '~components/Routes/paths';

import usePromoterFormColaborator from './hooks/usePromoterFormColaborator';
import { Props, FormDataProps } from './promoterFormDefinitions';

function PromoterForm(props: Props) {
  const {
    promoterRoleChoices,
    filialesChoices,
    promoterPickedRole,
    permisoOSDEChoices,
    permisoBinariaChoices,
    filialPickedChoice,
    capPickedChoice
  } = useListProviders();

  const { activeFilial, capsOptions, calculateCapsOptions } = usePromoterFormColaborator();
  const t = useTranslate();
  const notify = useNotify();

  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const regexCharacters = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
  const regexNumero = /[^0-9]+/g;
  const [mutate, { data, error, loading }] = useMutation();
  const { isEdit, isEditing, onSetEditing, ...rest } = props;

  const defaultFilial: string = FILIALES_LIST['FILIAL METROPOLITANA'];
  const isSuperAdmin = props.record?.role === Roles.SUPER_ADMIN;
  const idProm = props.permissions.id;
  const rolProm = props.permissions.role;
  const [sameProm, setSameProm] = useState(false);
  useEffect(() => {
    if (data || error) {
      dispatch?.(modalActions.openModal(MODAL_NAMES.DIALOG));
    }
  }, [sameProm, data, dispatch, error]);

  const createPromoter = useCallback(
    (values: PromoterFormType) =>
      mutate({
        type: CREATE_PROMOTER,
        resource: props.resource,
        payload: { ...values }
      }),
    [mutate, props.resource]
  );

  const editPromoter = useCallback(
    (values: PromoterFormType) =>
      mutate({
        type: EDIT_PROMOTER,
        resource: props.resource,
        payload: { ...values }
      }),
    [mutate, props.resource]
  );

  const handleSave = async (values: PromoterFormType) => {
    let errMsg = '';
    const emailToVerify = values.email;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await getEmailValidation(emailToVerify).then((res: any) => {
      if (res.ok) {
        const invalidEmail = res.data.status === 'NV';
        // NOTA: Originalmente se debía validar con el statusReason, y devolver el mensaje correspondiente al front,
        // pero el servicio casi siempre devuelve "statusReason": "ED", para los emails invalidos o inexistentes.
        // Por eso se valida únicamente con el status. Es decir:
        // data.status = 'VA' para email válidos
        // data.status = 'NV' para email inválidos
        // Se adjunta documentación en la tarjeta #2252 del trello
        if (invalidEmail) {
          errMsg = t('validation.invalidEmailService');
        }
      } else {
        errMsg = t('validation.emailServiceDown');
      }
    });
    if (errMsg) {
      return notify(errMsg);
    }
    if (isEdit) {
      if (
        idProm === values?.id &&
        rolProm === ROLE_TYPES.Adminstrador &&
        values.role !== ROLE_TYPES.Adminstrador
      ) {
        setSameProm(true);
      }
      return editPromoter({ ...values });
    }
    return createPromoter({ ...values });
  };

  const handleExit = useCallback(() => {
    if (sameProm) {
      authProvider.logout();
      history.push(paths.login);
    }
    history.goBack();
  }, [sameProm, history]);

  return (
    <>
      <SimpleForm
        {...rest}
        className={styles.form}
        save={handleSave}
        saving={loading}
        toolbar={isEditing || !isEdit ? <Toolbar {...props} /> : null}
        validateOnBlur
      >
        <FormDataConsumer>
          {({ formData }: FormDataProps) => {
            calculateCapsOptions(formData, isEdit ? isEdit : false);
          }}
        </FormDataConsumer>

        {isEdit ? (
          <InputLabel
            label={t('resources.promoters.fields.legajo')}
            className={commonStyles.row}
            source={PROMOTER_FIELDS.id}
            isEdit={isEdit}
          />
        ) : (
          <InputLabel
            label={t('resources.promoters.fields.legajo')}
            className={commonStyles.row}
            source={PROMOTER_FIELDS.legajo}
            validate={[requiredValidator, numericalDigits, promoterId(t)]}
            editEnabled={!PROMOTER_FIELDS.id}
            type="text"
          />
        )}

        <InputLabel
          label={t('resources.promoters.fields.firstName')}
          className={commonStyles.row}
          source={PROMOTER_FIELDS.firstName}
          validate={[requiredValidator, regex(regexCharacters, 'No se permiten caracteres especiales.')]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
        />

        <InputLabel
          label={t('resources.promoters.fields.lastName')}
          className={commonStyles.row}
          source={PROMOTER_FIELDS.lastName}
          validate={[requiredValidator, regex(regexCharacters, 'No se permiten caracteres especiales.')]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
        />

        <InputLabel
          label={t('resources.promoters.fields.email')}
          className={commonStyles.row}
          type="email"
          source={PROMOTER_FIELDS.email}
          validate={[requiredValidator, email]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
        />

        <InputLabel
          label={t('resources.promoters.fields.phoneNumber')}
          className={commonStyles.row}
          source={PROMOTER_FIELDS.phoneNumber}
          validate={[requiredValidator, phoneDigits]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
          type="text"
          parse={(value: string) => value.replace(regexNumero, '')}
        />
        <FormDataConsumer>
          {({ formData }: FormDataProps) => (
            <InputSelect
              className={commonStyles.row}
              source={PROMOTER_FIELDS.role}
              choices={promoterRoleChoices}
              label={t('resources.promoters.fields.rol')}
              defaultValue={ROLE_TYPES['Usuario promotor']}
              validate={requiredValidator}
              isEdit={isEdit}
              onSetEditing={onSetEditing}
              specialFormatter={() => promoterPickedRole(formData)}
              editEnabled={!isSuperAdmin}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }: FormDataProps) => (
            <InputSelect
              key={`caps_${activeFilial}`}
              className={commonStyles.row}
              source={PROMOTER_FIELDS.filialCode}
              choices={filialesChoices}
              label={t('resources.promoters.fields.filial')}
              defaultValue={defaultFilial}
              validate={requiredValidator}
              isEdit={isEdit}
              onSetEditing={onSetEditing}
              specialFormatter={() => filialPickedChoice(String(formData.filialCode))}
              editEnabled
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }: FormDataProps) => (
            <InputSelect
              className={commonStyles.row}
              source={PROMOTER_FIELDS.capCode}
              choices={capsOptions}
              label={t('resources.promoters.fields.cap')}
              validate={requiredValidator}
              isEdit={isEdit}
              onSetEditing={onSetEditing}
              specialFormatter={() => capPickedChoice(String(formData.filialCode), String(formData.capCode))}
              editEnabled
            />
          )}
        </FormDataConsumer>

        {!isEdit && (
          <InputSelect
            className={commonStyles.row}
            source={PROMOTER_FIELDS.permisoOSDE}
            choices={permisoOSDEChoices}
            label={t('resources.promoters.fields.permisoOSDE')}
            defaultValue={PERMISO_OSDE_CHOICES.NO}
            validate={requiredValidator}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            editEnabled
          />
        )}

        {!isEdit && (
          <InputSelect
            className={commonStyles.row}
            source={PROMOTER_FIELDS.permisoBinaria}
            choices={permisoBinariaChoices}
            label={t('resources.promoters.fields.permisoBinaria')}
            defaultValue={PERMISO_BINARIA_CHOICES.NO}
            validate={requiredValidator}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            editEnabled
          />
        )}
      </SimpleForm>

      {(data || error) && (
        <CustomModal modalName={MODAL_NAMES.DIALOG} {...(data ? { onClose: handleExit } : {})}>
          <FeedbackModalContent
            error={error}
            successMessage={t(`resources.promoters.modals.${isEdit ? 'edit' : 'create'}.success`)}
          />
        </CustomModal>
      )}
    </>
  );
}

export default PromoterForm;
