import { AffiliationRequest, FamilyMember } from '~utils/types';
import { AFFILIATION_REQUEST_STATUS, APPLICATION_TYPES } from '~constants/affiliations';

export const getSendEmailRequestPayload = (affiliation?: AffiliationRequest) => ({ id: affiliation?.id });

export const getShowSendInitialEmail = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING ||
  status === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT ||
  status === AFFILIATION_REQUEST_STATUS.FORM_LINK_OPENED;

// eslint-disable-next-line complexity
export const isAffilliationFilled = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING ||
  status === AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION ||
  status === AFFILIATION_REQUEST_STATUS.FINISHED ||
  status === AFFILIATION_REQUEST_STATUS.REVISION ||
  status === AFFILIATION_REQUEST_STATUS.USER_REJECTED ||
  status === AFFILIATION_REQUEST_STATUS.EXPIRED ||
  status === AFFILIATION_REQUEST_STATUS.SAP ||
  status === AFFILIATION_REQUEST_STATUS.SAP_RPA ||
  status === AFFILIATION_REQUEST_STATUS.CANCELED ||
  status === AFFILIATION_REQUEST_STATUS.ZCAR ||
  status === AFFILIATION_REQUEST_STATUS.ENABLED;

export const isUserValidationPending = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION;

export const isPromoterValidationPending = (status: string) =>
  [AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION, AFFILIATION_REQUEST_STATUS.REVISION].includes(
    status
  );

export const isHabilitado = (status: string) => status === AFFILIATION_REQUEST_STATUS.ENABLED;

export const getSendEmailTexts = (status: string) => {
  let base = '';
  const modalBase = 'resources.affiliations.modals';
  if (isUserValidationPending(status)) {
    base = 'resources.affiliations.resendUserValidationEmail';
    return {
      label: `${base}.complete`,
      labelShort: `${base}.short`,
      title: `${base}.title`,
      modalMessage: `${modalBase}.userValidationEmail.resend`
    };
  }
  base = 'resources.affiliations.initialEmail';
  const type = status === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING ? 'send' : 'resend';
  return {
    label: `${base}.${type}.complete`,
    labelShort: `${base}.${type}.short`,
    title: `${base}.${type}.title`,
    modalMessage: `${modalBase}.initialEmail.${type}`
  };
};

export const isEvaluationPending = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === AFFILIATION_REQUEST_STATUS.REVISION;

export const isInitialEmailPending = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING;

export const getTipeCode = (code: any) => {
  let result = '';
  switch (code) {
    case 'D':
      result = 'DIRECTO';
      break;
    case 'M':
      result = 'MONOTRIBUTISTA';
      break;
    case 'O':
      result = 'OBLIGATORIO';
      break;
    case 'C':
      result = 'CONVENIO';
      break;
    case 'P':
      result = 'COMPLEMENTACION';
      break;
    case 'GD':
      result = 'GRUPO DIRECTO';
      break;
    default:
      result = '';
      break;
  }
  return result;
};

export const showInsuranceField = (
  status: string,
  applicationType: string,
  familyGroup: FamilyMember[] | undefined
) =>
  (status === AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
    status === AFFILIATION_REQUEST_STATUS.FINISHED ||
    status === AFFILIATION_REQUEST_STATUS.REVISION ||
    status === AFFILIATION_REQUEST_STATUS.CANCELED ||
    status === AFFILIATION_REQUEST_STATUS.SAP ||
    status === AFFILIATION_REQUEST_STATUS.ZCAR ||
    status === AFFILIATION_REQUEST_STATUS.EXPIRED) &&
  applicationType !== APPLICATION_TYPES.DDJJ &&
  familyGroup;
