import { makeStyles } from '@material-ui/core';
import { FULL_WIDTH } from '~constants/sizes';
import {
  WHITE,
  GRAY,
  DARK_GRAY,
  TERTIARY_BLUE,
  PRIMARY_BLUE,
  SECONDARY_BLUE,
  TRANSPARENCY
} from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  buttonDetail: {
    width: '100%'
  },
  label: {
    color: PRIMARY_BLUE,
    fontWeight: 'bold',
    marginBottom: '8px',
    fontSize: '16px'
  },
  value: {
    color: GRAY,
    fontSize: '14px',
    fontWeight: 'normal'
  },
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: 10
    }
  },
  commonModal: {
    backgroundColor: WHITE,
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    maxWidth: 'auto',
    padding: '32px 60px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: 300,
      padding: '30px 20px'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalInfo: {
    minWidth: '640px',
    padding: '16px 40px',
    [theme.breakpoints.only('xs')]: {
      minWidth: '300px',
      maxWidth: '90%',
      padding: '16px 10px',
      width: '100%'
    }
  },
  closeIconContainer: {
    position: 'absolute',
    right: 12,
    top: 18,
    padding: 0,
    '&:hover': {
      backgroundColor: TRANSPARENCY
    },
    [theme.breakpoints.down('xs')]: {
      top: 12
    }
  },
  closeIcon: {
    color: DARK_GRAY,
    height: 24,
    width: 24
  },
  cancelButton: {
    backgroundColor: `${WHITE}!important`,
    padding: '14px 20px',
    '&:hover': {
      backgroundColor: WHITE,
      color: `${PRIMARY_BLUE}!important`,
      opacity: '0.9',
      transform: 'scale(1.03, 1.03)'
    },
    '& span': {
      color: PRIMARY_BLUE,
      fontWeight: 600,
      padding: 0
    }
  },
  actionsContainer: {
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  commonTitle: {
    color: DARK_GRAY,
    textAlign: 'center'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '21px',
    marginBottom: 25
  },
  titleInfo: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: '60px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '24px',
      marginBottom: '25px'
    }
  },
  modalInfoInnerContainer: {
    maxHeight: '80vh',
    minHeight: '140px',
    overflowY: 'auto',
    width: '100%'
  },

  buttonUpload: {
    alignItems: 'center',
    border: '1px solid $action-primary',
    display: 'flex',
    height: '40px',
    minWidth: '180px',
    padding: '6px 10px'
  },

  hideInput: {
    display: 'none'
  },

  uploadIcon: {
    color: '$action-primary'
  },

  inputContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    position: 'relative',
    width: FULL_WIDTH
  },

  textareaContainer: {
    padding: '20px 16px'
  },

  container: {
    gridColumnStart: 'auto',
    marginBottom: '24px'
  },

  input: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '10px',
    width: '40%'
  },

  error: {
    color: '$error-primary'
  },

  button: {
    backgroundColor: PRIMARY_BLUE,
    borderRadius: '4px',
    margin: 1,
    padding: '14px 20px',
    textTransform: 'none',
    width: '100%',
    '& span': {
      color: WHITE,
      fontWeight: 500,
      lineHeight: '24px',
      padding: 0
    },
    '&:hover': {
      backgroundColor: TERTIARY_BLUE,
      transform: 'scale(1.03, 1.03)'
    },
    '&:focus': {
      backgroundColor: PRIMARY_BLUE,
      boxShadow: `0 0 4px 0 ${SECONDARY_BLUE}`
    },
    '&:disabled': {
      backgroundColor: GRAY,
      pointerEvents: 'none'
    }
  },
  progress: {
    color: 'white'
  },
  table: {
    minWidth: 650
  },
  detalle: {
    backgroundColor: 'white',
    color: '#B0AEAE'
  },
  detalleCell: {
    backgroundColor: 'white'
  },
  detalleRow: {
    color: '#424242',
    fontWeight: 600,
    lineHeight: '16px'
  },
  columnLarge: {
    flex: 2
  },
  remove: {
    '& button': {
      '& span': {
        '& svg': {
          color: '#717171'
        }
      }
    }
  },
  notDisplay: {
    '& button': {
      display: 'none'
    }
  }
}));
