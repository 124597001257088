import { SOLICITUDES_ON_REQUEST_STATUS } from '~constants/solicitudesON';
import { sortAlphabetically } from '~utils/general';
import { FamilyMember } from '~utils/types';

export const getEvaluatePayload = (data?: { id?: number; newState?: string }) => ({
  id: data?.id,
  newState: data?.newState
});

export const getFileName = (url: string, t: Function, index?: number) => {
  const cleanUrl = url.split(/[?#]/)[0];
  const result = cleanUrl.substring(cleanUrl.lastIndexOf('/')).replace('/', '');
  if (index !== undefined) {
    return result.split('-')[0];
  }
  return result;
};

type AttachmentType = Array<{ url: string; name: string }>;

const getSonIndex = (who: string, sons: AttachmentType) =>
  sons.filter(attachment => attachment.name.includes(who)).length + 1;

export const getCleanFileName = (url: string) => {
  const cleanUrl = url.split(/[?#]/)[0];
  return decodeURI(cleanUrl.substring(cleanUrl.lastIndexOf('/')).replace('/', ''));
};

export const getOrderedAttachments = (attachments: string[], t: Function): AttachmentType => {
  const holder: AttachmentType = [];
  const couple: AttachmentType = [];
  const sons: AttachmentType = [];
  const holderText = t('general.holder').toLowerCase();
  const coupleText = t('general.couple').toLowerCase();

  attachments.forEach((item: string) => {
    const cleanName = getCleanFileName(item);
    const who = cleanName.split('-')[0];
    const extension = cleanName.substring(cleanName.lastIndexOf('.'));
    const result = { url: item };
    if (who === holderText) {
      holder.push({
        ...result,
        name: `${holderText}-${t('general.document').toLowerCase()}-${holder.length + 1}${extension}`
      });
    } else if (who === coupleText) {
      couple.push({
        ...result,
        name: `${coupleText}-${t('general.document').toLowerCase()}-${couple.length + 1}${extension}`
      });
    } else {
      sons.push({
        ...result,
        name: `${who}-${t('general.document').toLowerCase()}-${getSonIndex(who, sons)}${extension}`
      });
    }
  });

  return [...holder, ...couple, ...sons.sort(sortAlphabetically('name'))];
};

export const shouldShowPotentialRiskField = (status: string) =>
  status === SOLICITUDES_ON_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === SOLICITUDES_ON_REQUEST_STATUS.REVISION ||
  status === SOLICITUDES_ON_REQUEST_STATUS.FINISHED ||
  status === SOLICITUDES_ON_REQUEST_STATUS.SAP;