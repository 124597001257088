import React, { useCallback, useState } from 'react';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import ViewInput from '~components/ViewInput';
import { useTranslate } from 'react-admin';
import StatusField from '~screens/SolicitudesF2/components/StatusField';
import { Nullable, SolicitudF2Request } from '~utils/types';
import { useHistory } from 'react-router';
import { linkToRecord } from 'ra-core';
import useModal from '~app/hooks/useModal';
import ActionField from '~screens/SolicitudesF2/components/ActionField';
import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';
import { EMAIL_CONFIG } from '~screens/SolicitudesF2/constants';
import CustomModal from '~components/CustomModal';
import { getSendEmailTexts, isAttorneyValidationPending } from '~screens/SolicitudesF2/utils';

import HistoricSolicitudF2Button from '../HistoricSolicitudF2Button';

interface RequestPayload {
  id?: number;
}

interface Props {
  record: SolicitudF2Request;
  basePath: string;
  id: string;
}
function ResponsiveList({ record, basePath, id }: Props) {
  const styles = useResponsiveListStyles();
  const t = useTranslate();
  const history = useHistory();
  const resource = 'f2';
  
  const handleRowClick = useCallback(() => history.push(linkToRecord(basePath, id, 'show')), [
    basePath,
    history,
    id
  ]);

  const [solicitdudF2State, setSolicitdudF2State] = useState<Nullable<string> | undefined>(null);

  const handleOpen = useCallback((solicitud?: SolicitudF2Request) => {
    setSolicitdudF2State(solicitud?.state);
  }, []);

  const handleClose = useCallback(() => {
    setSolicitdudF2State(null);
  }, []);

  const [, dispatchInitialRequest, loadingInitial, onOpenModalInitial] = useModal<
    SolicitudF2Request,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD]
  });

  const [, dispatchValidationRequest, loadingValidation, onOpenModalValidation] = useModal<
    SolicitudF2Request,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[SOLICITUDES_REQUEST_STATUS.PENDING_USER_VALIDATION.COD]
  });

 return (
    <>
      <ResponsiveListWrapper onClick={handleRowClick} >
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label="Empresa/Razón social"
            valueClassName={styles.value}
            value={record.companyName}
            className={styles.columnLarge}
          />
          <ViewInput
            labelClassName={styles.label}
            label="ID"
            valueClassName={styles.value}
            value={`${record.id}`}
            className={styles.columnShort}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label="Cuit"
            valueClassName={styles.value}
            value={record.cuit}
            className={styles.columnShort}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            className={styles.columnLarge}
            labelClassName={styles.label}
            valueClassName={styles.value}
            label="Estado"
            content={<StatusField labelClassName={styles.maxContent} state={record.state} rounded={false} />}
          />
          <HistoricSolicitudF2Button
            className={`${styles.columnShort} ${styles.middle}`}
            stateDatetime={record.stateDatetime}
            id={record.id}
          />
        </div>
        <div className={`${styles.row} ${styles.centered}`}>
          <ActionField
            solicitud={record}
            onEnvioMailInicial={onOpenModalInitial}
            onReenvioMailValidacion={onOpenModalValidation}
            onCheckDetail={handleRowClick}
            rl
          />
        </div>
      </ResponsiveListWrapper>
      {solicitdudF2State && (
        <CustomModal
          title={t('resources.affiliations.modals.title')}
          modalName={EMAIL_CONFIG[solicitdudF2State].modalName}
          onConfirm={
            isAttorneyValidationPending(solicitdudF2State)
              ? dispatchValidationRequest
              : dispatchInitialRequest
          }
          loading={isAttorneyValidationPending(solicitdudF2State) ? loadingValidation : loadingInitial}
          onClose={handleClose}
          showCloseIcon={false}
        >
          <p>{t(getSendEmailTexts(solicitdudF2State).modalMessage)}</p>
        </CustomModal>
      )}
    </>
  );
}

export default ResponsiveList;
