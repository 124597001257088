import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import CustomButton from '~components/CustomButton';
import { SOLICITUDES_ON_REQUEST_STATUS as STATUS } from '~constants/solicitudesON';
import {
  getSendEmailTexts,
  getShowSendInitialEmail,
  isEvaluationPending,
  isUserValidationPending
} from '~screens/SolicitudesON/utils';
import { SolicitudONRequest } from '~utils/types';

import { useStyles } from './styles';

interface Props {
  solicitud: SolicitudONRequest;
  onReenvioMailValidacion: (solicitud: SolicitudONRequest) => void;
  onEnvioMailInicial: (solicitud: SolicitudONRequest) => void;
}

export default function ActionField({ solicitud, onEnvioMailInicial, onReenvioMailValidacion }: Props) {
  const styles = useStyles();
  const { labelShort, title } = useMemo(() => getSendEmailTexts(solicitud.state), [solicitud.state]);
  const showSendInitialEmail = getShowSendInitialEmail(solicitud.state);
  const evaluationPending = isEvaluationPending(solicitud.state);
  const userValidationPending = isUserValidationPending(solicitud.state);
  const t = useTranslate();
  // const showMailInicial = () =>
  //   solicitud.state === STATUS.INITIAL_EMAIL_PENDING ||
  //   solicitud.state === STATUS.FORM_LINK_OPENED ||
  //   solicitud.state === STATUS.FORM_LINK_OPENED;

  // const pendienteProm = isPendienteProm(solicitud.state);
  // const pendienteUsr = isPendienteUsr(solicitud.state);
  // const { label, title } = getEtiquetas(solicitud.state);

  const handleClick = (event: SyntheticEvent<EventTarget>) => {
    if (showSendInitialEmail) {
      event.stopPropagation();
      onEnvioMailInicial(solicitud);
    }
    if (userValidationPending) {
      event.stopPropagation();
      onReenvioMailValidacion(solicitud);
    }
  };

  return (
    <>
      <div className={styles.button}>
        {(showSendInitialEmail || userValidationPending) && (
          <CustomButton
            label={t(labelShort)}
            title={t(title)}
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
        {evaluationPending && (
          <CustomButton
            label="Evaluar"
            title="Click para ir al detalle y evaluar la solicitud de afiliación"
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
      </div>
    </>
  );
}
