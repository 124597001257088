import { GenericObjectInterface } from '~utils/types';

export const FILE_TYPES: GenericObjectInterface<string> = {
  f2: 'f2',
  termsAndConditions: 'termsAndConditions',
  formData: 'formData',
  attachments: 'attachments'
};

export const FILES_UPLOADED: GenericObjectInterface<string> = {
  F2: 'Datos_Empresa_',
  TyC: 'Terminos_y_condiciones_',
  AFIP: 'A_AFIP-',
  DNI: 'A_DNI-',
  ESTATUTO: 'A_Estatuto-',
  APODERADO: 'aceptacion_apoderado',
  TITULAR: 'titular_DNI_',
  ROSTRO: '_rostro',
  FORM: 'form',
  FRENTE: '_frente',
  DORSO: '_dorso'
};

export const FILES_MIMETYPE: GenericObjectInterface<string> = {
  F2: 'Datos_Empresa.pdf',
  TyC: 'Terminos_y_condiciones.pdf',
  AFIP: 'AFIP.pdf',
  DNI: 'DNI.pdf',
  ESTATUTO: 'Estatuto.pdf',
  APODERADO: 'Aceptacion_apoderado.pdf',
  ROSTRO: 'ROSTRO.pdf'
};
