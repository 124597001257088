/* eslint-disable @typescript-eslint/camelcase */
import { useAuth } from 'react-oidc-context';
import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Notification, useTranslate, useAuthState, useNotify } from 'react-admin';
// import { getData as getDataEnviroment } from '~services/setupEnviroment';
import logoOsde from '~assets/logo_osde.svg';
import logoGoogle from '~assets/logo_google.svg';
import paths from '~components/Routes/paths';
import authProvider from '~config/providers/authProvider';

import styles from './styles.module.scss';

function Login() {
  const t = useTranslate();
  const notify = useNotify();
  const history = useHistory();
  const { loading, authenticated } = useAuthState();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.id_token && !authenticated) {
      authProvider
        .login(auth.user.id_token)
        .then(() => {
          const url = sessionStorage.getItem('url');
          if (url) {
            sessionStorage.removeItem('url');
            history.push(url);
          } else {
            history.push(paths.home);
          }
        })
        .catch(async (err: string) => {
          await auth.removeUser();
          history.push(paths.home);
          notify(err, 'error');
          return Promise.reject(err);
        });
    }
  }, [auth, authenticated, history, notify]);

  if (loading || auth.isLoading) {
    return null;
  }

  return authenticated ? (
    <Redirect to={paths.home} />
  ) : (
    <div className={`full-width column center middle ${styles.container}`}>
      <Notification />
      <img src={logoOsde} alt={t('title')} className={`m-bottom-5 ${styles.logo_osde}`} />

      <button
        type="button"
        onClick={() => auth.signinRedirect({ extraQueryParams: { access_type: 'offline' } })}
        className={`${styles.button_login}`}
      >
        <img src={logoGoogle} alt={t('title')} className={`${styles.logo_google}`} />
        <p>Sign in with Google</p>
      </button>
    </div>
  );
}

export default Login;
