import {
  AUT_ERRORS_RESTRICTED,
  AUT_ERRORS_UNAVAILABLE,
  login as authLogin,
  logout as authLogout,
  logoutSync as logout,
  newRejectPromise,
  promoterGetCurrent,
  resetTokenHeaderFromCurrentToken
} from '~services/AuthServices';
import { STATUS_CODES } from '~config/api/constants';
import { Promoter } from '~utils/types';
import { isLocalhost } from '~utils/isLocalhost';

const authProvider = {
  login: (token: string) =>
    new Promise<Promoter>((resolve, reject) => {
      if (!token) {
        logout();
        reject(AUT_ERRORS_RESTRICTED);
        return;
      }

      authLogin(token)
        .then((promoter: Promoter) => resolve(promoter))
        .catch(error => reject(error));
    }),

  logout: () => logout(),

  checkAuth: () => {
    const url = window.location.href;
    if (url.includes('show')) {
      const urlSplit = url.split('#')[1];
      sessionStorage.setItem('url', urlSplit);
    }
    return resetTokenHeaderFromCurrentToken() ? Promise.resolve() : newRejectPromise(AUT_ERRORS_UNAVAILABLE);
  },

  checkError: (error: any) => {
    switch (error?.status) {
      case STATUS_CODES.unauthorized:
        return new Promise((resolve, reject) => {
          authLogout();
          reject(AUT_ERRORS_RESTRICTED);
        });

      case STATUS_CODES.unavailable:
      case STATUS_CODES.notUpdated:
        return Promise.reject(AUT_ERRORS_UNAVAILABLE);

      default:
        // TODO xErrores: se propone en ambientes de desarrollo errores en el log
        // console.error('errorInAuthProvider', error);
        if (isLocalhost()) {
          console.error('errorInAuthProvider', error);
        }
        return Promise.resolve(error);
    }
  },

  getPermissions: () => promoterGetCurrent()
};

export default authProvider;
