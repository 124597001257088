export const FILIALES_LIST = {
  'FILIAL BAHIA BLANCA': '31',
  'FILIAL BARILOCHE': '30',
  'FILIAL BUENOS AIRES CENTRO': '32',
  'FILIAL CATAMARCA': '01',
  'FILIAL CHACO': '04',
  'FILIAL CHIVILCOY': '35',
  'FILIAL COMODORO RIVADAVIA': '26',
  'FILIAL CÓRDOBA': '02',
  'FILIAL CORRIENTES': '03',
  'FILIAL DEL PARANA': '06',
  'FILIAL FORMOSA': '07',
  'FILIAL JUJUY': '08',
  'FILIAL JUNIN': '37',
  'FILIAL LA PAMPA': '09',
  'FILIAL LA PLATA': '25',
  'FILIAL LA RIOJA': '10',
  'FILIAL LOBOS': '14',
  'FILIAL MAR DEL PLATA': '24',
  'FILIAL MENDOZA': '11',
  'FILIAL METROPOLITANA': '60',
  'FILIAL MISIONES': '12',
  'FILIAL NORPATAGONICA': '13',
  'FILIAL PERGAMINO': '33',
  'FILIAL RIO CUARTO': '40',
  'FILIAL RIO URUGUAY': '42',
  'FILIAL ROSARIO': '23',
  'FILIAL SALTA': '15',
  'FILIAL SAN JUAN': '16',
  'FILIAL SAN LUIS': '17',
  'FILIAL SANTA CRUZ': '18',
  'FILIAL SANTA FE': '19',
  'FILIAL SANTIAGO DEL ESTERO': '20',
  'FILIAL TIERRA DEL FUEGO': '22',
  'FILIAL TRELEW': '05',
  'FILIAL TRENQUE LAUQUEN': '36',
  'FILIAL TUCUMAN': '21',
  'FILIAL VILLA MARIA': '39',
  'NO APLICA': '9999',
  INEXISTENTE: '99999'
};
