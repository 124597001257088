import React, { ReactNode } from 'react';
import { Notification, Sidebar, setSidebarVisibility } from 'react-admin';
import { ThemeProvider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'ra-core';
import withProvider from '~components/WithProvider';
import { Context } from '~contexts/ModalContext';
import { reducer, modalState } from '~contexts/ModalContext/reducer';
import Rectangulo from '~components/Rectangulo/Rectangulo';
import { useLocation } from 'react-router';

import { useStyles, useNotificationStyles } from './styles';
import Menu from './components/Menu';
import AppLogo from './components/AppLogo';
import AppBar from './components/CustomAppBar';
import { NOTIFICATION_POSITION } from './constants';

interface Props {
  children: ReactNode;
  theme: object;
}

function CustomLayout({ children, theme }: Props) {
  const styles = useStyles();
  const notificationStyles = useNotificationStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const handleToggleSidebar = () => dispatch(setSidebarVisibility(!open));

  const location = useLocation();
  const linkRoute = location.search === '?link=true';

  return (
    <ThemeProvider theme={theme}>
      <div className={`${styles.root} ${styles.appFrame}`}>
        <main className={styles.contentWithSidebar}>
          {!linkRoute && (
            <Sidebar classes={{ drawerPaper: styles.Sidebar }}>
              <>
                <AppLogo />
                <Menu onToggleSidebar={handleToggleSidebar} />
              </>
            </Sidebar>
          )}
          <div className={`${styles.mainContentWrapper} ${styles.box}`}>
            <Rectangulo />
            <div className={styles.content}>
              <AppBar className={styles.appBar} onToggleSidebar={handleToggleSidebar} />
              {children}
            </div>
          </div>
        </main>
        <Notification className={notificationStyles.root} anchorOrigin={NOTIFICATION_POSITION} />
      </div>
    </ThemeProvider>
  );
}

export default withProvider({
  Context,
  reducer,
  state: modalState
})(CustomLayout);
