export const SOL_BINARIA_REQUEST_STATUS = {
  INITIAL_EMAIL_PENDING: {
    COD: 'initial_email_pending',
    DESCRIPCION: 'Inicio Pendiente'
  },
  INITIAL_EMAIL_SENT: {
    COD: 'initial_email_sent',
    DESCRIPCION: 'Email Enviado'
  },
  FORM_LINK_OPENED: {
    COD: 'form_link_opened',
    DESCRIPCION: 'Link Abierto'
  },
  PENDING_USER_VALIDATION: {
    COD: 'pending_user_validation',
    DESCRIPCION: 'Confirmación PDF Pendiente'
  },
  PENDING_PROMOTER_VALIDATION: {
    COD: 'pending_promoter_validation',
    DESCRIPCION: 'Revisar PDF'
  },
  PENDING_PROSPECT_VALIDATION: {
    COD: 'pending_prospect_validation',
    DESCRIPCION: 'Aceptación Pendiente'
  },
  FINISHED: {
    COD: 'finished',
    DESCRIPCION: 'En Proceso de Alta'
  },
  REVISION: {
    COD: 'revision',
    DESCRIPCION: 'En Revisión'
  },
  USER_REJECTED: {
    COD: 'user_rejected',
    DESCRIPCION: 'Datos Incorrectos'
  },
  FORM_REJECTED: {
    COD: 'form_rejected',
    DESCRIPCION: 'Solicitud Rechazada'
  },
  EXPIRED: {
    COD: 'expired',
    DESCRIPCION: 'Vencido'
  },
  CANCELED: {
    COD: 'canceled',
    DESCRIPCION: 'Cancelado'
  },
  RESEND_DOCUMENTATION: {
    COD: 'resend_documentation',
    DESCRIPCION: 'Rectificar Documentación'
  }
};

export const STATES = {
  INITIAL_EMAIL_PENDING: 'initial_email_pending',
  INITIAL_EMAIL_SENT: 'initial_email_sent',
  FORM_LINK_OPENED: 'form_link_opened',
  // PENDING_USER_VALIDATION: 'pending_user_validation',
  PENDING_PROMOTER_VALIDATION: 'pending_promoter_validation',
  PENDING_PROSPECT_VALIDATION: 'pending_prospect_validation',
  FINISHED: 'finished',
  REVISION: 'revision',
  // USER_REJECTED: 'user_rejected',
  EXPIRED: 'expired',
  FORM_REJECTED: 'form_rejected',
  CANCELED: 'canceled',
  RESEND_DOCUMENTATION: 'resend_documentation'
};

export const descripcionStates = new Map([
  [STATES.INITIAL_EMAIL_PENDING, 'Inicio Pendiente'],
  [STATES.INITIAL_EMAIL_SENT, 'Email Enviado'],
  [STATES.FORM_LINK_OPENED, 'Link Abierto'],
  // [STATES.PENDING_USER_VALIDATION, 'Confirmación PDF Pendiente'],
  [STATES.PENDING_PROMOTER_VALIDATION, 'Revisar PDF'],
  [STATES.PENDING_PROSPECT_VALIDATION, 'Aceptación Pendiente'],
  [STATES.FINISHED, 'En Proceso de Alta'],
  [STATES.REVISION, 'En Revisión'],
  // [STATES.USER_REJECTED, 'Datos Incorrectos'],
  [STATES.EXPIRED, 'Vencido'],
  [STATES.CANCELED, 'Cancelado'],
  [STATES.RESEND_DOCUMENTATION, 'Rectificar Documentación']
]);

export const descripcionTipoProductos = new Map([
  ['1', 'Nueva'],
  ['2', 'Conversión'],
  ['3', 'Continuidad']
]);

export const TIPO_PRODUCTO_GET = {
  NUEVA: {
    COD: '1',
    DESCRIPCION: 'Nueva'
  },
  CONVERSION: {
    COD: '2',
    DESCRIPCION: 'Conversión'
  },
  CONTINUIDAD: {
    COD: '3',
    DESCRIPCION: 'Continuidad'
  }
};

export const descripcionForms = new Map([['2', 'Formulario Policía de la Ciudad']]);

export const descripcionProductos = new Map([
  ['I', 'Vida Individual Temporario (VIT)'],
  ['L', 'Vida Individual con Ahorro (VICA)'],
  ['U', 'Vida Universal (VU)']
]);

export const PRODUCTO = {
  VIT: {
    COD: 'I',
    DESCRIPCION: 'Vida Individual Temporario (VIT)'
  },
  VICA: {
    COD: 'L',
    DESCRIPCION: 'Vida Individual con Ahorro (VICA)'
  },
  VU: {
    COD: 'U',
    DESCRIPCION: 'Vida Universal (VU)'
  }
};
