import React from 'react';
import { useCommonStyles } from '~app/styles';
import { AffiliationRequest, Promoter } from '~utils/types';
import CustomShow from '~components/CustomShow';

import AffiliationDetail from './components/AffiliateDetail';

interface Props {
  record: AffiliationRequest;
  resource: string;
  permissions: Promoter;
}

function AffiliationRequestShow(props: Props) {
  const commonStyles = useCommonStyles();
  return (
    <>
      <CustomShow
        {...props}
        actions={null}
        title={<span />}
        classes={{ card: commonStyles.card, main: commonStyles.main, root: commonStyles.root }}
      >
        <AffiliationDetail {...props} />
      </CustomShow>
    </>
  );
}
export default AffiliationRequestShow;
