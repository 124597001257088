/* eslint-disable consistent-return */
/* eslint-disable no-negated-condition */
/* eslint-disable no-magic-numbers */
import { ReactNode } from 'react';
import { Promoter } from '~utils/types';
import { validateFormatDate } from '~utils/date';

export const validateButtons = (files: any) => {
  if (files === null) {
    return true;
  }
  return false;
};

export const validateNumber = (field: string) => {
  const justNumber = new RegExp(/^\d+$/);
  if (field?.length > 4 && justNumber.test(field)) {
    return false;
  }
  return true;
};

export const validateName = (field: string) => {
  if (field?.length >= 3) {
    return false;
  }
  return true;
};

export const disableButton = (fields: Record<string, any>) => Object.values(fields).every(value => value);

export interface Props {
  title?: string;
  redirect?: string;
  isEdit?: boolean;
  toolbar?: ReactNode;
  resource?: string;
  onSetEditing?: () => void;
  isEditing?: boolean;
  permissions: Promoter;
}
export interface FormDataProps {
  idPromotorAlta: string;
  nombreEmpresa: string;
  datos: any;
}
export const RESPONSE_OK = 200;

export const repetedDni = (json: any) => {
  const dniArray = json.map((objeto: any) => objeto?.prospectNumeroDocumento);
  const indicesRepetidos: any = [];
  const apariciones: any = {};

  dniArray.forEach((dni: any, indice: any) => {
    if (dni in apariciones) {
      apariciones[dni].push(indice);
    } else {
      apariciones[dni] = [indice];
    }
  });

  for (const dni in apariciones) {
    if (apariciones[dni].length > 1) {
      indicesRepetidos.push(...[{ dniRepetido: dni, repeticiones: apariciones[dni] }]);
    }
  }
  return indicesRepetidos;
};

export const checkDateFormat = (json: any) => {
  const dateToCheck = json.map((objeto: any) => objeto?.solicVigencia);
  const indicesConError: any = [];
  dateToCheck.forEach((e: any, index: any) => {
    if (!validateFormatDate(e)) {
      indicesConError.push(...[{ fechaInvalida: e, apariciones: index }]);
    }
  });
  return indicesConError;
};
// esta validacion se eliminara proximamente
export const checkValidPlan = (json: any, invalidPlans: any) => {
  const validPlans = json.map((obj: any) => obj?.solicPlan);
  return validPlans.forEach((plan: any) => {
    if (invalidPlans.includes(plan)) {
      return true;
    }
  });
};
