import spanishMessages from '@blackbox-vision/ra-language-spanish';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import merge from 'lodash/merge';
import { TranslationMessages } from 'ra-core';

import { spanishResources } from '../translations/es';

export interface Messages {
  [index: string]: TranslationMessages;
}

const messages: Messages = {
  es: merge(spanishMessages, spanishResources)
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'es');
