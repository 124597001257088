import React from 'react';
import { Create, useTranslate } from 'react-admin';
import { useCommonStyles } from '~app/styles';
import WithProps from '~components/WithProps';
import DetailActionButtons from '~components/DetailActionButtons';
import { Promoter } from '~utils/types';

import SolicitudF2Form from '../../components/SolicitudF2Form';

export default function SolicitudF2Create(props: { permissions: Promoter }) {
  const t = useTranslate();
  const commonStyles = useCommonStyles();

  return (
    <Create
      {...props}
      title={<span />}
      classes={{ card: commonStyles.card, main: commonStyles.main, root: commonStyles.root }}
    >
      <WithProps>
        {(createProps: object) => (
          <>
            <DetailActionButtons />
            <h1 className={commonStyles.title}>{t('resources.solicitudF2.createTitle')}</h1>
            <SolicitudF2Form {...createProps} permissions={props.permissions} />
          </>
        )}
      </WithProps>
    </Create>
  );
}
