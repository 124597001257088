import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, EXTRA_LIGHT_GRAY } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  infoContainer: {
    borderBottom: `1px solid ${EXTRA_LIGHT_GRAY}`,
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  textProspect: {
    color: PRIMARY_BLUE,
    flexGrow: 1,
    fontWeight: 500,
    marginBottom: '12px',
    marginRight: 10
  },
  textStatus: {
    color: PRIMARY_BLUE,
    flexGrow: 3,
    fontWeight: 300,
    marginBottom: '12px',
    marginRight: 10,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'initial'
    }
  },
  textTime: {
    color: PRIMARY_BLUE,
    flexGrow: 1,
    fontWeight: 300,
    textAlign: 'end',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'initial'
    }
  }
}));
