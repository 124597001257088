/* eslint-disable babel/no-unused-expressions */
/* eslint-disable complexity */
/* eslint-disable no-negated-condition */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import CustomButton from '~components/CustomButton';
import { useCommonStyles } from '~app/styles';
import { Theme, useMediaQuery } from '@material-ui/core';

import GetPartialForm from '../GetPartialForm';
import SavePartialForm from '../SavePartialForm';

function EditarPartialDataForm({ props }: any) {
  const { isEdit, isEditing } = props;
  const commonStyles = useCommonStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [getActualPage, setGetActualPage] = useState<boolean>(true);

  const [idSolicitud, setIdSolicitud] = useState<string>('');
  const [partialDataResult, setPartialDataResult] = useState<string>('');

  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <div className={commonStyles.flex}>
      <div>
        <h1 className={commonStyles.titleDatosPago}>{getActualPage ? 'Editar' : 'Guardar'} Partial Data</h1>

        <div className={`${commonStyles.flex} ${commonStyles.field} ${isXSmall ? commonStyles.buttonsGroupFlexDirectionXs : ''}`}>
          <CustomButton
            label="Obtener"
            disabled={getActualPage}
            onClick={() => {
              setGetActualPage(true);
              setError('');
            }}
            className={isXSmall ? commonStyles.width50 : ''}
          />
          <CustomButton
            label="Editar"
            disabled={!getActualPage}
            onClick={() => {
              setGetActualPage(false);
              setError('');
            }}
            className={isXSmall ? commonStyles.width50 : ''}
          />
        </div>

        {getActualPage ? (
          <GetPartialForm
            loading={loading}
            setLoading={setLoading}
            error={error}
            setError={setError}
            partialDataResult={partialDataResult}
            setPartialDataResult={setPartialDataResult}
            idSolicitud={idSolicitud}
            setIdSolicitud={setIdSolicitud}
            isEditing={isEditing}
            isEdit={isEdit}
          />
        ) : (
          <SavePartialForm
            loading={loading}
            setLoading={setLoading}
            error={error}
            setError={setError}
            setPartialDataResult={setPartialDataResult}
            idSolicitud={idSolicitud}
            setIdSolicitud={setIdSolicitud}
            setGetActualPage={setGetActualPage}
            isEditing={isEditing}
            isEdit={isEdit}
          />
        )}
      </div>
    </div>
  );
}

export default EditarPartialDataForm;
