import affiliations from '~screens/AffiliationRequests/es';
import on from '~screens/SolicitudesON/es';
import solicitudF2 from '~screens/SolicitudesF2/es';
import binaria from '~screens/SolicitudesBinaria/es';
import promoters from '~screens/Promoters/es';
import altasmasivas from '~screens/AltasMasivas/es';
import datosPago from '~screens/DatosPago/es';
import cancelaciones from '~screens/Cancelaciones/es';
import editarpartialdata from '~screens/EditarPartialData/es';
import merlinValidator from '~screens/MerlinValidator/es';

/* eslint-disable @typescript-eslint/camelcase */
export const spanishResources: object = {
  title: 'Backoffice binario',
  resources: {
    emptyList: {
      title: 'Sin %{resource} todavía'
    },
    affiliations,
    altasmasivas,
    on,
    solicitudF2,
    binaria,
    promoters,
    datosPago,
    cancelaciones,
    editarpartialdata,
    merlinValidator,
    modals: {
      errorTitle: '¡Ups!',
      successTitle: '¡Todo listo!'
    }
  },
  validation: {
    invalidEmail: 'E-mail inválido',
    email: 'El email ingresado no contiene un formato correcto',
    validEmailService: 'E-mail validado',
    invalidEmailService: 'El email es inexistente. Por favor verificarlo',
    emailServiceDown: 'El servicio de validacion de email no funciona. Intentar de nuevo.',
    required: 'El campo es requerido',
    min_password: 'La contraseña debe contener al menos 8 caracteres',
    numerical: 'Debe ingresar sólo números',
    documentNumber: 'Entre %{min} y %{max} dígitos, sin puntos ni guiones',
    exactLength: 'Debe ser de %{length} dígitos',
    numericalWithSpaces: 'Debe ingresar sólo números y espacios',
    emailMatching: 'El correo de confirmación no coincide',
    cuilNumber: 'Número de CUIL inválido.',
    futureDate: 'La fecha debe ser igual o posterior a la actual.',
    futureDate60: 'No puede elegir una fecha anterior a los 60 días desde hoy.',
    loadingPromoter: 'Buscando promotor...',
    cuitNumber: 'Número de CUIT inválido.',
    exactDigits: 'El número debe ser de %{length} dígitos',
    phone: 'El número de teléfono  debe tener entre 10 y 15 dígitos',
    discountRate: 'El porcentaje de descuento debe ser entre %{min} y %{max}'
  },
  ra: {
    action: {
      bulk_actions: '1 ítem seleccionado |||| %{smart_count} ítems seleccionados'
    },
    message: {
      bulk_delete_content:
        '¿Estás seguro que querés eliminar este %{resource}? |||| ¿Estás seguro que querés eliminar estos %{smart_count} %{resource}?'
    },
    notification: {
      deleted: 'Registro eliminado |||| %{smart_count} Registros eliminados.'
    }
  },
  errors: {
    3001: 'El usuario ya tiene una solicitud en curso',
    3002: 'Un usuario ya fue cargado con ese documento y los datos no coinciden',
    // 3012: 'Ocurrió un error con el servicio de envío de mails',
    4301: 'Inicie sesión nuevamente',
    4302: 'Ya existe un promotor con el legajo especificado',
    4303: 'El promotor se encuentra inactivo',
    4304: 'El promotor seleccionado no existe',
    4305: 'No tiene empresas asignadas',
    4308: 'Ya existe un promotor con el email especificado',
    5003: 'Ocurrió un error al intentar enviar el email',
    errorRepeatability: 'No se pudieron recuperar los datos de las solicitudes anteriores',
    notFoundRepeatability: 'No se encontraron solicitudes anteriores',
    unauthorized: 'Se renovó la sesión. Si algo falló, volvé a intentarlo',
    maxErrorMessage:
      'El excel presenta datos inválidos. Por favor, complétalo con datos válidos e inténtalo nuevamente',
    default: 'Ha ocurrido un error, intentá de nuevo.',
    unavailable: 'El servicio no está disponible',
    restricted: 'Sólo cuentas autorizadas'
  },
  general: {
    continue: 'Continuar',
    exit: 'Salir',
    next: 'Siguiente',
    loading: 'Se está cargando el contenido...',
    loadingShow: 'Se está cargando el detalle...',
    confirm: 'Confirmar',
    delete: 'Eliminar',
    cancel: 'Cancelar',
    error: 'Error',
    logout: 'Cerrar sesión',
    save: 'Guardar',
    yes: 'Si',
    no: 'No',
    empty: '0',
    emptyString: '',
    document: 'Documento',
    holder: 'Titular',
    couple: 'Pareja',
    son: 'Hijo',
    accept: 'Aceptar',
    retry: 'Reintentar'
  },
  deleteResources: {
    users: 'usuario |||| usuarios'
  }
};
