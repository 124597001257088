import React from 'react';
import { DatagridBody as DatagridBodyRA } from 'react-admin';
import { TablePassing } from '~utils/types';

import DatagridRow from '../DatagridRow';

type Props = Omit<TablePassing, 'listComponent'>;

function DatagridBody({ selectable, showDetail, ...props }: Props) {
  return <DatagridBodyRA {...props} row={<DatagridRow selectable={selectable} showDetail={showDetail} />} />;
}

export default DatagridBody;
