import React from 'react';
import { useCommonStyles } from '~app/styles';
import { SolicitudF2Request, Promoter } from '~utils/types';
import CustomShow from '~components/CustomShow';

import SolicitudF2Detail from './components/SolicitudF2Detail';

interface Props {
  record: SolicitudF2Request;
  resource: string;
  permissions: Promoter;
}

function SolicitudF2Show(props: Props) {
  const commonStyles = useCommonStyles();
  return (
    <CustomShow
      {...props}
      actions={null}
      title={<span />}
      classes={{ card: commonStyles.card, main: commonStyles.main, root: commonStyles.root }}
    >
      <SolicitudF2Detail {...props} />
    </CustomShow>
  );
}
export default SolicitudF2Show;
