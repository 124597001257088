import React, { useCallback } from 'react';
import { Button } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import cn from 'classnames';

import { useStyles } from './styles';

interface Props {
  onCancel?: () => void;
  onBack?: () => void;
  showBack?: boolean;
  title?: string;
  content?: string;
  confirmText?: string;
  fromDatosPago?: boolean;
  setShowResults?: (show: boolean) => void;
}

function DetailActionButtons({ onBack, showBack, fromDatosPago = false, setShowResults }: Props) {
  const history = useHistory();
  const styles = useStyles();

  const clearDatosPagoFields = () => {
    setShowResults!(false);
  };

  const handleGoBack = useCallback(() => {
    localStorage.removeItem('planValidityDate');
    history.goBack();
    if (onBack) {
      onBack();
    }
  }, [history, onBack]);

  return (
    <div
      className={cn('row full-width', styles.buttonContainer, {
        end: !showBack,
        'space-between': showBack
      })}
    >
      {showBack && (
        <Button
          className={styles.back}
          onClick={fromDatosPago ? clearDatosPagoFields : handleGoBack}
          size="large"
        >
          <ArrowBack className={styles.arrowBack} />
        </Button>
      )}
    </div>
  );
}

DetailActionButtons.defaultProps = {
  showBack: true
};

export default DetailActionButtons;
