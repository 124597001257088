/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Route } from 'react-router-dom';
import { Assignment, AccountBalance, Person, Delete, Email } from '@material-ui/icons';
import AffiliationRequestsList from '~screens/AffiliationRequests/screens/AffiliationRequestsList';
import AffiliationRequestShow from '~screens/AffiliationRequests/screens/AffiliationRequestShow';
import AffiliationRequestCreate from '~screens/AffiliationRequests/screens/AffiliationRequestCreate';
import PromotersList from '~screens/Promoters/screens/PromotersList';
import SolicitudesBinariaList from '~screens/SolicitudesBinaria/SolicitudBinariaGrid/SolicitudesBinariaList';
import SolicitudBinariaShow from '~screens/SolicitudesBinaria/SolicitudBinariaShow/SolicitudBinariaShow';
import SolicitudBinariaCreate from '~screens/SolicitudesBinaria/SolicitudBinariaCreate/SolicitudBinariaCreate';
import SolicitudONCreate from '~screens/SolicitudesON/screens/SolicitudONCreate';
import SolicitudesONList from '~screens/SolicitudesON/screens/SolicitudONList';
import SolicitudONShow from '~screens/SolicitudesON/screens/SolicitudONShow';
import SolicitudF2Create from '~screens/SolicitudesF2/screens/SolicitudF2Create';
import SolicitudesF2List from '~screens/SolicitudesF2/screens/SolicitudF2List';
import SolicitudF2Show from '~screens/SolicitudesF2/screens/SolicitudF2Show';
import PromoterRequestCreate from '~screens/Promoters/screens/PromoterRequestCreate';
import PromoterRequestShow from '~screens/Promoters/screens/PromoterRequestShow';
import AltaMasivasList from '~screens/AltasMasivas/AltasMasivasList';
import MassiveUploadCreate from '~screens/AltasMasivas/AltasMasivasCreate';
import AltaMasivasShow from '~screens/AltasMasivas/AltasMasivasDetalle';
import DatosPagoWrapper from '~screens/DatosPago/components/DatosPagoWrapper';
import CancelacionesWrapper from '~screens/Cancelaciones/components/CancelacionesWrapper';
import EditarPartialDataWrapper from '~screens/EditarPartialData/components/EditarPartialDataWrapper';
import MerlinValidatorWrapper from '~screens/MerlinValidator/components/MerlinValidatorWrapper';

export const MENU_LABELS = {
  affiliations: 'resources.affiliations.name',
  promoters: 'resources.promoters.name',
  binaria: 'resources.binaria.name',
  altaEmpresas: 'resources.solicitudF2.name',
  solicitudesON: 'resources.on.name',
  datosPago: 'resources.datosPago.name',
  altasMasivas: 'resources.altasmasivas.name',
  cancelaciones: 'resources.cancelaciones.name',
  editarPartialData: 'resources.editarpartialdata.name',
  merlinValidator: 'resources.merlinValidator.name'
};

export const BASE_ROUTE_NAMES = {
  affiliations: 'affiliations',
  binaria: 'binaria',
  promoters: 'promoters',
  f2: 'f2',
  on: 'on',
  altasMasivas: 'altasmasivas',
  datosPago: 'datospago',
  cancelaciones: 'cancelaciones',
  editarPartialData: 'editarpartialdata',
  merlinValidator: 'merlinValidator'
};

export const customRoutesData: Array<RouteInterface> = [];

export interface RouteInterface {
  key: string;
  path: string;
  component: Function;
  basePath?: string;
  resource?: string;
  isAppointment?: boolean;
  exact: boolean;
  id?: number | string;
}

export const customRoutes = customRoutesData.map(
  ({ key, path, component: Comp, exact, basePath, resource }: RouteInterface) => (
    <Route
      key={key}
      path={path}
      exact={exact}
      render={props => <Comp {...props} {...(basePath && { basePath })} {...(resource && { resource })} />}
    />
  )
);

export const OSDE_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.affiliations,
    list: AffiliationRequestsList,
    // TODO: Temporal icon
    icon: Assignment,
    edit: '',
    create: AffiliationRequestCreate,
    show: AffiliationRequestShow,
    options: {
      label: MENU_LABELS.affiliations
    }
  }
];

export const BINARIA_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.binaria,
    list: SolicitudesBinariaList,
    // TODO: Temporal icon
    icon: Assignment,
    edit: '',
    create: SolicitudBinariaCreate,
    show: SolicitudBinariaShow,
    options: {
      label: MENU_LABELS.binaria
    }
  }
];

export const ON_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.on,
    list: SolicitudesONList,
    // TODO: Temporal icon
    icon: Assignment,
    edit: '',
    create: SolicitudONCreate,
    show: SolicitudONShow,
    options: {
      label: MENU_LABELS.solicitudesON
    }
  }
];

export const F2_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.f2,
    list: SolicitudesF2List,
    // TODO: Temporal icon
    icon: Assignment,
    edit: '',
    create: SolicitudF2Create,
    show: SolicitudF2Show,
    options: {
      label: MENU_LABELS.altaEmpresas
    }
  }
];

const PROMOTERS_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.promoters,
    list: PromotersList,
    icon: Person,
    edit: '',
    create: PromoterRequestCreate,
    show: PromoterRequestShow,
    options: {
      label: MENU_LABELS.promoters
    }
  }
];

export const ALTAS_MASIVAS_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.altasMasivas,
    list: AltaMasivasList,
    icon: Assignment,
    edit: '',
    create: MassiveUploadCreate,
    show: AltaMasivasShow,
    options: {
      label: MENU_LABELS.altasMasivas
    }
  }
];

export const DATOS_PAGO_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.datosPago,
    list: DatosPagoWrapper,
    icon: AccountBalance,
    edit: '',
    create: () => <></>,
    show: () => <></>,
    options: {
      label: MENU_LABELS.datosPago
    }
  }
];

export const CANCELACIONES_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.cancelaciones,
    list: CancelacionesWrapper,
    icon: Delete,
    edit: '',
    create: () => <></>,
    show: () => <></>,
    options: {
      label: MENU_LABELS.cancelaciones
    }
  }
];

export const EDIT_PARTIAL_DATA_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.editarPartialData,
    list: EditarPartialDataWrapper,
    icon: Assignment,
    edit: '',
    create: () => <></>,
    show: () => <></>,
    options: {
      label: MENU_LABELS.editarPartialData
    }
  }
];

export const MERLIN_VALIDATOR_RESOURCES = [
  {
    name: BASE_ROUTE_NAMES.merlinValidator,
    list: MerlinValidatorWrapper,
    icon: Email,
    edit: '',
    create: () => <></>,
    show: () => <></>,
    options: {
      label: MENU_LABELS.merlinValidator
    }
  }
];

export const showOnForAdminsAlways = () => window.config.REACT_APP_SHOW_ON;
export const showF2ForAdminsAlways = () => window.config.REACT_APP_SHOW_F2;
export const altasMasivasForAdminsAlways = () => window.config.REACT_APP_SHOW_ALTAS_MASIVAS;
export const datosPagoForAdminsAlways = () => window.config.REACT_APP_SHOW_DATOS_PAGO;
export const cancelacionForAdminsAlways = () => window.config.REACT_APP_SHOW_CANCELACIONES;
export const editPartialForAdminsAlways = () => window.config.REACT_APP_SHOW_EDIT_PARTIAL_DATA;
export const merlinValidatorForAdminsAlways = () => window.config.REACT_APP_SHOW_MERLIN_VALIDATOR;

export const adminResources = () => {
  const resources: any = [
    ...OSDE_RESOURCES,
    ...BINARIA_RESOURCES,
    ...(showOnForAdminsAlways() === true ? ON_RESOURCES : []),
    ...(showF2ForAdminsAlways() === true ? F2_RESOURCES : []),
    ...(altasMasivasForAdminsAlways() === true ? ALTAS_MASIVAS_RESOURCES : []),
    ...(datosPagoForAdminsAlways() === true ? DATOS_PAGO_RESOURCES : []),
    ...PROMOTERS_RESOURCES
  ];

  resources.push();
  return resources;
};

export const superAdminResources = () => {
  const resources: any = [
    ...adminResources(),
    ...(cancelacionForAdminsAlways() === true ? CANCELACIONES_RESOURCES : []),
    ...(editPartialForAdminsAlways() === true ? EDIT_PARTIAL_DATA_RESOURCES : []),
    ...(merlinValidatorForAdminsAlways() === true ? MERLIN_VALIDATOR_RESOURCES : []),
  ];

  resources.push();
  return resources;
};
