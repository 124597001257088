import { GenericObjectInterface } from '~utils/types';

export const FILE_TYPES: GenericObjectInterface<string> = {
  f1: 'f1',
  sworn: 'sworn',
  attachments: 'attachments',
  promoterAttachments: 'promoterAttachments',
  othersAttachments: 'othersAttachments',
  termAndConditions: 'termAndConditions'
};
