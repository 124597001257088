/* eslint-disable complexity */
import React, { ReactNode, MouseEvent } from 'react';
import { Button } from 'react-admin';
import { Theme, useMediaQuery } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  label?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  autoFocus?: boolean;
  title?: string;
  isLinkButton?: boolean;
  producto?: string;
  invalidPlanValidityDate?: any;
}

function CustomButton({
  label,
  onClick,
  disabled,
  children,
  className = '',
  autoFocus,
  title,
  isLinkButton = false,
  producto,
  invalidPlanValidityDate
}: Props) {
  const styles = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return producto && producto === 'OSDE' ? (
    <Button
      className={`${isLinkButton ? styles.linkButton : styles.button} ${className}`}
      disabled={disabled || invalidPlanValidityDate}
      onClick={onClick}
      label={label}
      autoFocus={autoFocus}
      {...(title && !isXSmall && { title })}
      data-cy="submitBtnOsdeApplication"
    >
      {isXSmall ? <span>{label}</span> : children}
    </Button>
  ) : (
    <Button
      className={`${isLinkButton ? styles.linkButton : styles.button} ${className}`}
      disabled={disabled}
      onClick={onClick}
      label={label}
      autoFocus={autoFocus}
      {...(title && !isXSmall && { title })}
    >
      {isXSmall ? <span>{label}</span> : children}
    </Button>
  );
}

export default CustomButton;
