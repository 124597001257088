import React, { FormEvent, ChangeEvent } from 'react';
import { Switch } from '@material-ui/core';
import { Button } from 'react-admin';

import { useStyles } from './styles';

interface Props {
  checked?: boolean;
  onChange?: (e: FormEvent<HTMLButtonElement>) => void;
}

function CustomSwitch({ onChange, checked = false }: Props) {
  const styles = useStyles();

  const handleChangeSwitch = (e: ChangeEvent<{}>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Button onClick={onChange}>
      <Switch
        checked={checked}
        onChangeCapture={handleChangeSwitch}
        className={(styles.switch, styles.margin)}
      />
    </Button>
  );
}

export default CustomSwitch;
