import { makeStyles } from '@material-ui/core/styles';
import { TERTIARY_BLUE } from '~constants/colors';

export const useStyles = makeStyles({
  button: {
    marginRight: '10px',
    minWidth: 0
  },
  historyText: {
    color: TERTIARY_BLUE,
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '4px',
    textTransform: 'capitalize'
  }
});

export const CUSTOM_ICON_STYLES = {
  height: 25,
  width: 25
};

export const CUSTOM_ICON_STYLES_RESPONSIVE = {
  color: TERTIARY_BLUE,
  height: 20,
  width: 20
};
