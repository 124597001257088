import React from 'react';
import { Datagrid, Responsive } from 'react-admin';
import { TablePassing } from '~utils/types';

import DatagridBody from '../DatagridBody';
import DataList from '../DataList';

import { useStyles } from './styles';

function CustomDatagrid({ selectable, showDetail, ...props }: TablePassing) {
  const styles = useStyles();
  return (
    <Responsive
      small={<DataList {...props} showDetail={showDetail} />}
      medium={
        <Datagrid
          {...props}
          classes={styles}
          hasBulkActions={selectable}
          isRowSelectable={() => selectable}
          body={<DatagridBody selectable={selectable} showDetail={showDetail} />}
        />
      }
    />
  );
}

export default CustomDatagrid;
