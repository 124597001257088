import React, { useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { SolicitudF2Request, Nullable } from '~utils/types';
import useModal from '~hooks/useModal';
import ViewInput from '~components/ViewInput';
import CustomButton from '~components/CustomButton';
import FileValue from '~components/FileValue';
// import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';
import CustomModal from '~components/CustomModal';
import cn from 'classnames';
import { useCommonStyles } from '~app/styles';
import {
  isEvaluationPending,
  isAttorneyValidationPending,
  getEvaluatePayload,
  hasVB
} from '~screens/SolicitudesF2/utils';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { EVALUATE_APPLICATION_F2 } from '~config/providers/constants';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import Loading from '~components/Spinner/components/loading';
import CustomModalF2 from '~components/CustomModalF2';

import { useStyles as useDetailStyles } from '../SolicitudF2Detail/styles';

import {
  getOrderedAttachments,
  getDocumentsUrlOrder,
  showFiles,
  getDocumentsF2Form,
  getDocumentsF2FormVB
} from './utils';
import { useStyles } from './styles';
import { FILE_TYPES, FILES_UPLOADED, FILES_MIMETYPE } from './constants';

interface Props {
  record: SolicitudF2Request;
  resource: string;
}

interface RequestPayload {
  id?: number;
  rejected?: boolean;
}

const listOfElement = (attachmentsResult: any) =>
  attachmentsResult?.map((element: any) => (
    <FileValue key={element.url} url={element.url} name={element.name} />
  ));

// eslint-disable-next-line complexity
function SolicitudF2Filled({ record, resource }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const detailStyles = useDetailStyles();
  const commonStyles = useCommonStyles();
  const { id, f2 = [], termsAndConditions = [], attachments = [], docsOthers } = record;
  const pendingEvaluation = isEvaluationPending(record.state);
  const pendienteDeConfirmacion = isAttorneyValidationPending(record.state);
  const [statusToChange, setStatusToChange] = useState<Nullable<string>>(null);
  const hasAttachments = !!attachments?.length;
  const vb = hasVB(record.vbSteps);
  const [clickRevision, setClickRevision] = useState<boolean>(false);
  const [clickCancel, setClickCancel] = useState<boolean>(false);
  const [clickFinished, setClickFinished] = useState<boolean>(false);

  const [modalData, dispatchRequest, loading, onOpenModal] = useModal<RequestPayload, RequestPayload>({
    resource,
    requestType: EVALUATE_APPLICATION_F2,
    requestPayload: getEvaluatePayload,
    modalName: MODAL_NAMES.EVALUATE,
    successMessageNotify: `resources.solicitudF2.evaluate.modal.${statusToChange}.success`
  });

  const handleOpenModal = useCallback(
    (action: string) => {
      setStatusToChange(action);
      onOpenModal({ id, newState: action });
    },
    [id, onOpenModal]
  );
  const evaluateAction = () => {
    if (clickCancel) {
      setClickCancel(false);
      dispatchRequest();
    }
    if (clickFinished) {
      setClickFinished(false);
      dispatchRequest();
    }
    if (clickRevision) {
      setClickRevision(false);
      dispatchRequest();
    }
  };
  return (
    <div className={`column full-width ${styles.spacing}`}>
      {!showFiles(record.state) && (
        <>
          {!pendienteDeConfirmacion && f2 && (
            <ViewInput
              label={t(`resources.solicitudF2.documents.${FILE_TYPES.f2}.title`)}
              content={listOfElement(getDocumentsF2Form(docsOthers))}
            />
          )}
          {!pendienteDeConfirmacion && termsAndConditions && (
            <ViewInput
              label={t(`resources.solicitudF2.documents.${FILE_TYPES.termsAndConditions}.title`)}
              content={[
                listOfElement(getDocumentsUrlOrder(FILES_UPLOADED.TyC, FILES_MIMETYPE.TyC, docsOthers)),
                listOfElement(
                  getDocumentsUrlOrder(FILES_UPLOADED.APODERADO, FILES_MIMETYPE.APODERADO, docsOthers)
                )
              ]}
              {...(!hasAttachments && { className: styles.separation })}
            />
          )}
          {!pendienteDeConfirmacion && vb && (
            <ViewInput
              label={t(`resources.solicitudF2.documents.${FILE_TYPES.attachments}.title`)}
              content={listOfElement(getDocumentsF2FormVB(docsOthers))}
              className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
            />
          )}
        </>
      )}
      {pendingEvaluation && (
        <>
          <CustomButton
            className={`${styles.button} ${styles.buttonFinished}`}
            onClick={() => {
              setClickRevision(false);
              setClickCancel(false);
              setClickFinished(true);
              handleOpenModal(AFFILIATION_REQUEST_STATUS.FINISHED);
            }}
            label={t(`resources.solicitudF2.evaluate.${AFFILIATION_REQUEST_STATUS.FINISHED}`)}
            disabled={loading}
          >
          </CustomButton>
          {record.state !== AFFILIATION_REQUEST_STATUS.REVISION && (
            <CustomButton
              className={`${styles.button} ${styles.buttonRevision}`}
              onClick={() => {
                setClickRevision(true);
                setClickCancel(false);
                setClickFinished(false);
                handleOpenModal(AFFILIATION_REQUEST_STATUS.REVISION);
              }}
              label={t(`resources.solicitudF2.evaluate.${AFFILIATION_REQUEST_STATUS.REVISION}`)}
              disabled={loading}
            />
          )}
          {modalData?.id && (
            <CustomModalF2
              title={t('resources.affiliations.modals.title')}
              modalName={MODAL_NAMES.EVALUATE}
              onConfirm={evaluateAction}
              loading={loading}
              showCloseIcon
            >
              <p className={detailStyles.modalContent}>
                {t(`resources.solicitudF2.evaluate.modal.${statusToChange}.message`)}
              </p>
            </CustomModalF2>
          )}
        </>
      )}
      {!showFiles(record.state) && (
        <CustomButton
          className={`${styles.button} ${styles.buttonCanceled}`}
          onClick={() => {
            setClickRevision(false);
            setClickCancel(true);
            setClickFinished(false);
            handleOpenModal(AFFILIATION_REQUEST_STATUS.CANCELED);
          }}
          label={t(`resources.solicitudF2.evaluate.${AFFILIATION_REQUEST_STATUS.CANCELED}`)}
          disabled={loading}
        />
      )}
    </div>
  );
}

export default SolicitudF2Filled;
