import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    minHeight: 350
  },
  text: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    lineHeight: '18px',
    textAlign: 'center'
  }
});
