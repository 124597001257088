import { RESEND_VALIDATION_EMAIL, SEND_INITIAL_EMAIL } from '~config/providers/constants';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
// eslint-disable-next-line import/namespace, import/named
import { GenericObjectInterface } from '~utils/types';

import { getSendEmailRequestPayload } from './utils';

export const AFFILIATION_REQUESTS_FIELDS = {
  id: 'id',
  promoterId: 'promoterId',
  affiliationType: 'affiliationType',
  applicationType: 'applicationType',
  applicationSubtype: 'applicationSubtype',
  planValidityDate: 'planValidityDate',
  state: 'state',
  firstName: 'user.firstName',
  documentType: 'user.documentType',
  documentNumber: 'user.documentNumber',
  cuit: 'cuit',
  lastName: 'user.lastName',
  userEmail: 'user.email',
  emailConfirmation: 'user.emailConfirmation',
  phoneNumber: 'user.phoneNumber',
  companyPlan: 'companyPlan',
  hiringMode: 'hiringMode',
  sourceApplication: 'sourceApplication',
  increasePlanAllowed: 'increasePlanAllowed',
  isPaymentResponsible: 'isPaymentResponsible',
  details: 'details',
  rpaState: 'rpaState',
  datetimestatus: 'datetimestatus',
  planTypes: 'planTypes',
  promoterFirstName: 'promoter.firstName',
  promoterLastName: 'promoter.lastName',
  preddjjId: 'preddjjId',
  questions: 'questions'
};

export const AFFILIATION_REQUESTS_FILTERS = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  documentNumber: 'documentNumber',
  filialCode: 'filialCode',
  capCode: 'capCode'
};

export const SEND_INITIAL_EMAIL_CONFIG = {
  requestType: SEND_INITIAL_EMAIL,
  requestPayload: getSendEmailRequestPayload,
  modalName: MODAL_NAMES.INITIAL_EMAIL,
  successMessageNotify: 'resources.affiliations.modals.initialEmail.success'
};
export const AGE_LIMIT_IN_MONTS = 310;
export const AGE_LIMIT_IN_MONTS_TWO = 430;

export const RESEND_VALIDATION_EMAIL_CONFIG = {
  requestType: RESEND_VALIDATION_EMAIL,
  requestPayload: getSendEmailRequestPayload,
  modalName: MODAL_NAMES.USER_VALIDATION,
  successMessageNotify: 'resources.affiliations.modals.userValidationEmail.success'
};

export const EMAIL_CONFIG: GenericObjectInterface<{
  requestType: string;
  requestPayload: any;
  modalName: string;
  successMessageNotify: string;
}> = {
  [AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING]: SEND_INITIAL_EMAIL_CONFIG,
  [AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT]: SEND_INITIAL_EMAIL_CONFIG,
  [AFFILIATION_REQUEST_STATUS.FORM_LINK_OPENED]: SEND_INITIAL_EMAIL_CONFIG,
  [AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION]: RESEND_VALIDATION_EMAIL_CONFIG
};
