import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  button: {
    width: '85px'
  },
  buttonText: {
    width: '100%',
    '& span': {
      fontSize: 14,
      lineHeigth: 20
    }
  }
});
