import React from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';

import { useStyles } from './styles';

interface Props {
  estado: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
}
function StatusField({ estado, rounded = true, className = '', labelClassName = '' }: Props) {
  const statusCheck = estado === 'NO_PROCESADO' ? 'CARGANDO' : estado;
  const t = useTranslate();
  const styles = useStyles();
  return (
    <>
      <div className={`full-width ${className}`}>
        <div
          className={cn(styles.label, styles[estado], labelClassName, {
            rounded
          })}
        >
          <span>{t(`resources.altasmasivas.statusTypes.${statusCheck}`)}</span>
        </div>
      </div>
    </>
  );
}

export default StatusField;
