import moment, { Moment } from 'moment';
import { DATE_INVALID, DATE_FORMAT_SHORT_FIXED_AR } from '~constants/datetime';

type MomentDateTimeFormats = {
  target?: string;
  source?: string;
  strict?: boolean;
};

export const VIGENCIA_ZIP_GAW = 95;

export const momentDateStadarize = (date: Moment) =>
  date.utcOffset(0).set({ hour: 3, minute: 0, second: 0, millisecond: 0 });

export const momentDateTimeToString = (dateTime?: string | Moment | Date, dateFormat?: string) => {
  const dateOut = moment(dateTime);
  if (dateOut.isValid()) {
    return dateFormat ? dateOut.format(dateFormat) : momentDateStadarize(dateOut).toISOString();
  }
  return DATE_INVALID;
};

export const momentDateFromString = (date: object | string, dateFormat?: string) => {
  const dateOut = momentDateStadarize(moment(date, dateFormat));
  return dateOut;
};

export const momentDateToString = (
  date: Moment | string,
  { target, source, strict }: MomentDateTimeFormats
) => {
  const dateOut = momentDateStadarize(source ? moment(date, source, strict) : moment.utc(date));
  return target ? dateOut.format(target) : dateOut.toISOString();
};

export const momentDateToStringShortAr = (date: Moment | string, { source, strict }: MomentDateTimeFormats) =>
  momentDateToString(date, { target: DATE_FORMAT_SHORT_FIXED_AR, source, strict });

export const newMomentDate = (datetime?: Date) => moment(datetime).utc();

export const momentToStringShort = (date: Moment | string) =>
  moment(date)
    .format(DATE_FORMAT_SHORT_FIXED_AR)
    .toString();

export const hasPassedXDaysFromDate = (days: number, date: string) => {
  const lastDate = moment(date);
  const currentDate = moment();
  const diasDiferencia = currentDate.diff(lastDate, 'days');
  return diasDiferencia >= days;
};

export const getDateLocaleFromString = (date: string) =>
  date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('/');

export const validateFormatDate = (date: any) => {
  const formatExpected = DATE_FORMAT_SHORT_FIXED_AR;

  const dateMoment = moment(date, formatExpected, true);
  if (dateMoment.isValid()) {
    return true;
  }
  return false;
};
