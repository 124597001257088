const editarPartialDataSpanish = {
  name: 'Editar Partial Data',
  editPartialData: {
    savePartialData: 'Guardar',
    savePartialDataSuccess: 'El partial data ha sido actualizado',
    copyToClipboardMessage: 'Copiado correctamente a portapapeles'
  }
};

export default editarPartialDataSpanish;
