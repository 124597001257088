import { makeStyles } from '@material-ui/core/styles';
import { STATES } from '~constants/binaria';
import {
  BLACK,
  GRAY,
  LIGHT_GRAY,
  PRIMARY_BLUE,
  PRIMARY_GREEN,
  PRIMARY_RED,
  PRIMARY_YELLOW,
  QUATERNARY_BLUE,
  SECONDARY_BLUE,
  SECONDARY_GREEN,
  SECONDARY_RED,
  SECONDARY_YELLOW,
  TERTIARY_BLUE,
  WHITE,
  PRIMARY_RED_OPACITY
} from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  content: {
    color: GRAY,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '18px',
    textAlign: 'center'
  },
  actions: {
    alignItems: 'center',
    display: 'flex'
  },
  historyIcon: {
    color: '#717171',
    fontSize: '26px',
    marginRight: '10px'
  },
  label: {
    borderRadius: '2px',
    maxWidth: '250px',
    textAlign: 'center',
    padding: '12px 20px',
    '& span': {
      color: PRIMARY_BLUE,
      fontSize: 14,
      lineHeight: '19px'
    },
    '&.rounded': {
      borderRadius: '20px',
      padding: '10px 12px'
    }
  },
  button: {
    width: '85px'
  },
  buttonText: {
    width: '100%',
    '& span': {
      fontSize: 14,
      lineHeigth: 20
    }
  },
  [STATES.INITIAL_EMAIL_PENDING]: {
    backgroundColor: LIGHT_GRAY,
    border: `1px solid ${GRAY}`,
    '& span': {
      color: GRAY
    }
  },
  [STATES.FORM_LINK_OPENED]: {
    backgroundColor: SECONDARY_BLUE,
    border: `1px solid ${PRIMARY_BLUE}`,
    '& span': {
      color: PRIMARY_BLUE
    }
  },
  [STATES.INITIAL_EMAIL_SENT]: {
    backgroundColor: QUATERNARY_BLUE,
    border: `1px solid ${TERTIARY_BLUE}`,
    '& span': {
      color: TERTIARY_BLUE
    }
  },
  // [STATES.PENDING_USER_VALIDATION]: {
  //   backgroundColor: SECONDARY_ORANGE,
  //   border: `1px solid ${PRIMARY_ORANGE}`,
  //   '& span': {
  //     color: PRIMARY_ORANGE
  //   }
  // },
  [STATES.PENDING_PROMOTER_VALIDATION]: {
    backgroundColor: SECONDARY_YELLOW,
    border: `1px solid ${PRIMARY_YELLOW}`,
    '& span': {
      color: PRIMARY_YELLOW
    }
  },
  [STATES.PENDING_PROSPECT_VALIDATION]: {
    backgroundColor: SECONDARY_YELLOW,
    border: `1px solid ${PRIMARY_YELLOW}`,
    '& span': {
      color: PRIMARY_YELLOW
    }
  },
  [STATES.FINISHED]: {
    backgroundColor: SECONDARY_GREEN,
    border: `1px solid ${PRIMARY_GREEN}`,
    '& span': {
      color: PRIMARY_GREEN
    }
  },
  [STATES.REVISION]: {
    backgroundColor: SECONDARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: PRIMARY_RED
    }
  },
  // [STATES.USER_REJECTED]: {
  //   backgroundColor: SECONDARY_RED,
  //   border: `1px solid ${PRIMARY_RED}`,
  //   '& span': {
  //     color: PRIMARY_RED
  //   }
  // },
  [STATES.EXPIRED]: {
    backgroundColor: LIGHT_GRAY,
    border: `1px solid ${BLACK}`,
    '& span': {
      color: BLACK
    }
  },
  [STATES.CANCELED]: {
    backgroundColor: PRIMARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: WHITE
    }
  },
  [STATES.FORM_REJECTED]: {
    backgroundColor: PRIMARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: WHITE
    }
  },
  [STATES.RESEND_DOCUMENTATION]: {
    backgroundColor: QUATERNARY_BLUE,
    border: `1px solid ${TERTIARY_BLUE}`,
    '& span': {
      color: TERTIARY_BLUE
    }
  },
  modalContent: {
    color: GRAY,
    fontSize: 14,
    lineHeight: '16px',
    marginBottom: '18px',
    textAlign: 'center'
  },
  sendEmailButton: {
    display: 'flex',
    '& span': {
      fontSize: 16
    }
  },
  buttonState: {
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  botonfinished: {
    marginBottom: 10
  },
  botonrevision: {
    border: `1px solid ${PRIMARY_BLUE}`,
    backgroundColor: WHITE,
    '&:hover, &:focus': {
      backgroundColor: WHITE
    },
    '& span': {
      color: PRIMARY_BLUE
    },
    marginBottom: 10
  },
  botonform_rejected: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: PRIMARY_RED,
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: WHITE
      }
    },
    '& span': {
      color: WHITE
    }
  },
  botoncanceled: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: PRIMARY_RED,
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: WHITE
      }
    },
    '& span': {
      color: WHITE
    }
  },
  botonresend_documentation: {
    border: '1px solid #3091c2',
    backgroundColor: '#3091c2',
    '&:hover, &:focus': {
      backgroundColor: '#7ebede',
      '& span': {
        color: WHITE
      }
    },
    marginBottom: 10
  },
  botonpending_prospect_validation: {
    display: 'flex',
    '& span': {
      fontSize: 16
    }
  },
  spacing: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px'
    }
  }
}));
