import React from 'react';
import { useTranslate } from 'react-admin';
import ViewInput from '~components/ViewInput';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';
import {
  getApplicationType,
  getAffiliationType
} from '~screens/AffiliationRequests/components/AffiliationForm/utils';

import StatusDetail from './StatusDetail';

function ResponsiveListDetail({ record }: any) {
  const styles = useResponsiveListStyles();
  const t = useTranslate();

  return (
    <ResponsiveListWrapper>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.nroRegistro')}
          valueClassName={styles.value}
          value={record.nroRegistro}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.nombre')}
          valueClassName={styles.value}
          value={record.nombre}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.apellido')}
          valueClassName={styles.value}
          value={record.apellido}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.tipo')}
          valueClassName={styles.value}
          value={t(getAffiliationType(record.prospectTipo))}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.formulario')}
          valueClassName={styles.value}
          value={t(getApplicationType(record.prospectTipoFormulario))}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.idSolicitud')}
          valueClassName={styles.value}
          value={record.idSolcitudCreada}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          className={styles.columnLarge}
          labelClassName={styles.label}
          label={t('resources.altasmasivas.detail.estado')}
          valueClassName={styles.value}
          content={
            <StatusDetail labelClassName={styles.maxContent} estado={record.estadoProceso} rounded={false} />
          }
        />
      </div>
    </ResponsiveListWrapper>
  );
}

export default ResponsiveListDetail;
