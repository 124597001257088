import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { ReactComponent as EditIcon } from '~assets/ic_edit.svg';
import { useCommonStyles } from '~app/styles';
import { i18nStringType } from '~utils/types';
import { Tooltip } from '@material-ui/core';
import ConditionalWrapper from '~components/ConditionalWrapper';

import { useStyles } from './styles';

interface Props {
  label?: i18nStringType;
  value?: string;
  isEditing?: boolean;
  onEnableEdit?: () => void;
  content?: any;
  specialFormatter?: Function;
  className?: string;
  editEnabled?: boolean;
  innerContainerClassName?: string;
  labelClassName?: string;
  valueClassName?: string;
  tooltip?: string;
}

function ViewInput({
  label,
  value,
  content,
  specialFormatter,
  className = '',
  innerContainerClassName = '',
  isEditing,
  editEnabled = false,
  onEnableEdit,
  labelClassName = '',
  valueClassName = '',
  tooltip
}: Props) {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const t = useTranslate();

  const val = useMemo(() => {
    if (specialFormatter) {
      return t(specialFormatter(value));
    }
    return value;
  }, [specialFormatter, t, value]);

  const defaultEmptyValuesFields = [
    'Teléfono del responsable empresa',
    'Teléfono',
    'Observaciones',
    'CUIT de la empresa'
  ];

  const defaultValueEmptyString = defaultEmptyValuesFields.includes(label?.toString() || '')
    ? t('general.emptyString')
    : t('general.empty');

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`${styles.innerContainer} ${innerContainerClassName}`}>
        <h3 className={`${styles.label} ${labelClassName}`}>
          <>{label}</>
        </h3>
        <ConditionalWrapper
          condition={tooltip}
          wrapper={children => (
            <Tooltip title={tooltip!} placement="bottom-start">
              {children}
            </Tooltip>
          )}
        >
          {content ? (
            <> {content} </>
          ) : (
            <p className={`${styles.value} ${valueClassName}`}>{val ? val : defaultValueEmptyString}</p>
          )}
        </ConditionalWrapper>
      </div>
      {!isEditing && editEnabled && <EditIcon onClick={onEnableEdit} className={commonStyles.icon} />}
    </div>
  );
}

export default ViewInput;
