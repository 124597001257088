import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY_BLUE } from '~constants/colors';

export const useStyles = makeStyles({
  activeLink: {
    '&::after': {
      backgroundColor: PRIMARY_BLUE,
      content: "''",
      display: 'block',
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '3px'
    },
    '&, &:focus, & svg, & svg > g': {
      color: PRIMARY_BLUE
    },
    '& svg > *, & svg > g > path, & svg > g > g': {
      fill: 'currentColor'
    }
  },
  notCapitalize: {
    textTransform: 'none!important' as any
  },
  link: {
    fontSize: 14,
    marginBottom: '5px',
    position: 'relative',
    padding: '15px',
    textTransform: 'capitalize',
    '&::before': {
      backgroundColor: PRIMARY_BLUE,
      content: "''",
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      transition: 'opacity 200ms, transform 200ms',
      transform: 'scale(0.9, 0.9)',
      width: '100%',
      zIndex: -1
    },
    '&:hover': {
      borderRadius: '5px',
      '& svg > g > g, & svg > g > path': {
        fill: 'currentColor'
      },
      '&, & svg, & svg > g > g, & svg > g > path': {
        color: WHITE
      },
      '&::before': {
        opacity: 1,
        transform: 'scale(1);'
      }
    }
  },
  container: {
    maxWidth: '350px',
    width: '100%'
  }
});
