/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslate, useInput } from 'react-admin';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import cn from 'classnames';
import esLocale from 'date-fns/locale/es';
import ViewInput from '~components/ViewInput';
import { getObjectValueFromString } from '~utils/object';
import { useEditInput } from '~hooks/useEditInput';
import { GenericObjectInterface } from '~utils/types';
import { DATE_FORMAT_FUCTION_SHORT_AR, DATE_FORMAT_SHORT_FIXED_AR } from '~constants/datetime';
import { momentDateToString, momentDateTimeToString } from '~utils/date';
import moment from 'moment';

import { useStyles } from './styles';

const ONE_DAY = 1;
const SIXTY_DAYS = 60;

interface Props {
  label?: string;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  formClassName?: string;
  source?: string;
  record?: GenericObjectInterface<any>;
  variant?: string;
  defaultValue?: any;
  parse?: Function;
  format?: Function;
  helperText?: string;
  validate?: any;
  onSetEditing?: () => void;
  onChange?: Function;
  showErrorOnEdit?: boolean;
  isEdit?: boolean;
  specialFormatter?: Function;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: string;
  editEnabled?: boolean;
  setInvalidPlanValidityDate?: any;
  fechaCreacion?: any;
  retroactivityDays?: number;
}

function AutoCompleteDate({
  source,
  validate,
  disabled = false,
  onSetEditing,
  isEdit = false,
  defaultValue,
  containerClassName,
  showErrorOnEdit,
  specialFormatter,
  disablePast = false,
  disableFuture = false,
  minDate,
  editEnabled = false,
  label,
  setInvalidPlanValidityDate,
  fechaCreacion,
  retroactivityDays = SIXTY_DAYS,
  ...props
}: Props) {
  const [enabled, handleEnableEdit] = useEditInput(onSetEditing);
  const [touched, setTouched] = useState(false);
  const [, setActive] = useState(false);
  const styles = useStyles();
  const t = useTranslate();
  const {
    meta: { error: errors }
  } = useInput(props);
  const formattedDate = momentDateToString(getObjectValueFromString(source!, props.record!) as string, {
    target: DATE_FORMAT_SHORT_FIXED_AR
  });

  const [value, setValue] = useState(
    formattedDate ? moment(formattedDate, DATE_FORMAT_SHORT_FIXED_AR) : moment()
  );

  const handleClose = useCallback(() => {
    if (!touched) {
      setTouched(true);
    }
    setActive(false);
  }, [touched]);

  const handleClickDate = useCallback(() => {
    setActive(true);
  }, []);

  const validateErrosAndDate = (e: any) => {
    setValue(moment(e));
    if (e === '' || e === null) {
      setInvalidPlanValidityDate(true);
      errors.planValidityDate = 'El campo es requerido';
      return;
    }
    if (disablePast) {
      if (
        moment(e).isBefore(
          fechaCreacion ? moment(fechaCreacion).subtract(ONE_DAY, 'days') : moment().subtract(ONE_DAY, 'days')
        )
      ) {
        setInvalidPlanValidityDate(true);
        errors.planValidityDate = 'Debe ser una fecha igual o superior a la actual';
        return;
      }
    } else {
      const newDate = moment(e);
      const currentFormDate = fechaCreacion ? moment(fechaCreacion) : moment();
      currentFormDate.set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0});
      const limitDate = moment(currentFormDate).subtract(retroactivityDays, 'days');
      
      if (newDate.isBefore(limitDate)) {
        setInvalidPlanValidityDate(true);
        errors.planValidityDate = `La fecha permite hasta ${retroactivityDays} dias de retroactividad`;
        return;
      }
    }
    if (!moment(e).isValid()) {
      setInvalidPlanValidityDate(true);
      errors.planValidityDate = 'Fecha invalida';
      return;
    }
    delete errors.planValidityDate;
    setInvalidPlanValidityDate(false);
    localStorage.setItem('planValidityDate', moment(e).toISOString());
  };

  const onChange = (e: any) => {
    validateErrosAndDate(e);
  };
  useEffect(() => {
    validateErrosAndDate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate]);

  useEffect(() => {
    if (isEdit && enabled) {
      localStorage.setItem(
        'planValidityDate',
        moment(formattedDate, DATE_FORMAT_SHORT_FIXED_AR).toISOString()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, enabled]);

  const inputDateId = momentDateTimeToString(value, DATE_FORMAT_SHORT_FIXED_AR);

  const insulatedCypressElement = source ? `${source.split('.').pop()}-input` : undefined;

  return isEdit && !enabled ? (
    <ViewInput
      label={label!}
      value={formattedDate}
      isEditing={enabled}
      onEnableEdit={handleEnableEdit}
      specialFormatter={specialFormatter}
      editEnabled={editEnabled}
    />
  ) : (
    <div className={cn(styles.container, containerClassName)}>
      {label && <label className={styles.label}>{label}</label>}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <KeyboardDatePicker
          label={label}
          className={cn('full-width', styles.input)}
          autoOk
          allowKeyboardControl
          onChange={onChange}
          minDate={minDate}
          placeholder="DD/MM/YYYY"
          value={value}
          invalidDateMessage="Fecha invalida"
          minDateMessage="Fecha invalida"
          maxDateMessage="Fecha invalida"
          defaultValue={defaultValue}
          format={DATE_FORMAT_FUCTION_SHORT_AR}
          onClose={handleClose}
          onOpen={handleClickDate}
          emptyLabel={DATE_FORMAT_SHORT_FIXED_AR.toLowerCase()}
          disabled={disabled}
          disablePast={disablePast}
          disableFuture={disableFuture}
          okLabel={t('general.accept')}
          cancelLabel={t('general.cancel')}
          InputProps={{ disableUnderline: false }}
          id={`inputDate-${inputDateId}`}
          data-cy={insulatedCypressElement}
        />
      </MuiPickersUtilsProvider>
      {errors.planValidityDate && <p className={styles.error}>{errors.planValidityDate}</p>}
    </div>
  );
}

export default AutoCompleteDate;
