const storageType = {
  local: 'local',
  session: 'session'
};

const tempStorage = {
  [storageType.local]: {},
  [storageType.session]: {}
};

const storageObject = {
  [storageType.local]: window.localStorage,
  [storageType.session]: window.sessionStorage
};

const removeValue = (storage, key) => {
  const encodedKey = window.btoa(key);

  try {
    storageObject[storage].removeItem(encodedKey);
  } catch (e) {
    tempStorage[storage][encodedKey] = undefined;
  }
};

const getValue = (storage, key) => {
  const encodedKey = window.btoa(key);
  let encodedValue = undefined;

  try {
    encodedValue = storageObject[storage].getItem(encodedKey);
  } catch (e) {
    encodedValue = tempStorage[storage][encodedKey];
  }

  try {
    const stringValue = encodedValue && window.atob(encodedValue);
    return stringValue && JSON.parse(stringValue);
  } catch (e) {
    // se ignora el error. Si hay un error es porque el valor almacenado encodeado
    //   fue manipulado o algo similar. Se resetea elimina el valor y se devuelve undefined
    removeValue(storage, key);
    return undefined;
  }
};

const setValue = (storage, key, value) => {
  const encodedKey = window.btoa(key);
  const stringValue = JSON.stringify(value);
  const encodedValue = window.btoa(stringValue);

  try {
    storageObject[storage].setItem(encodedKey, encodedValue);
  } catch (e) {
    tempStorage[storage][encodedKey] = encodedValue;
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ClientStorageService = {};

const defineProperty = (storage, prop, defaultKey = '', tag = '') => {
  const projectName = 'osde-binary-signup-backoffice-react'.replace(/-/g, '_').toUpperCase();
  const capitalizedKey = `${prop[0].toUpperCase()}${prop.substring(1)}`;
  if (!storageType[storage]) {
    throw new Error(`Storage [${storage}] undefined for propertie [${prop}].`);
  }

  ClientStorageService[`set${capitalizedKey}`] = (val, key = defaultKey) =>
    setValue(storage, `@@${projectName}:${prop}${tag}${key}`, val);
  ClientStorageService[`get${capitalizedKey}`] = (key = defaultKey) =>
    getValue(storage, `@@${projectName}:${prop}${tag}${key}`);
  ClientStorageService[`remove${capitalizedKey}`] = (key = defaultKey) =>
    removeValue(storage, `@@${projectName}:${prop}${tag}${key}`);
};

// ------------------------------ LOCAL STORAGE PROPERTIES ------------------------------
defineProperty(storageType.local, 'token');
defineProperty(storageType.local, 'promoterUser');
