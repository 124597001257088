import React from 'react';

import './styles.css';
import { IDEM, NOT_MODIFY } from '~screens/DatosPago/constants';

interface Props {
  label: string;
  value: string | number;
  isUnselectable?: boolean;
}
const InputLabelForm = ({ label, value, isUnselectable = true }: Props) => (
  <>
    <label className="label">{label}</label>
    <br />
    <div
      className={`
      ${isUnselectable ? 'unselectable' : 'selectable'}
    `}
    >
      {value === IDEM ? NOT_MODIFY : value}
    </div>
  </>
);

export default InputLabelForm;
