import React from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Promoter } from '~utils/types';
import CheckboxPromoter from '~components/CheckboxPromoter';
import dataProvider from '~config/providers/dataProvider';
import { MODIFY_PROMOTER_SUBROL } from '~config/providers/constants';

interface Props {
  promoter: Promoter;
  setLoader: Function;
}

interface Subroles {
  [index: string]: boolean;
  on: boolean;
  osde: boolean;
  binaria: boolean;
  analista: boolean;
  asesor: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateSubrolInput = ({ on, osde, binaria }: Subroles): boolean =>
  // if (on) {
  //   if (osde || binaria) {
  //     return false;
  //   }
  // } else if (osde || binaria) {
  //   if (on) {
  //     return false;
  //   }
  // }
  true;

const buildBody = (role: string, previusValue: any) => {
  const roleToAdd: string[] = [];
  const roleToDelete: string[] = [];

  if (previusValue) {
    roleToDelete.push(role);
  } else {
    roleToAdd.push(role);
  }

  return { add: roleToAdd, delete: roleToDelete };
};

const defineRoles = (roles: string[]) => {
  const ROLES = ['osde', 'binaria', 'on', 'analista', 'asesor'];
  const defaultRoles: Subroles = {
    on: false,
    osde: false,
    binaria: false,
    analista: false,
    asesor: false
  };
  for (const role of ROLES) {
    if (roles.includes(role)) {
      defaultRoles[role] = true;
    }
  }

  return defaultRoles;
};

function PromoterChangeRole({ promoter, setLoader }: Props) {
  const subroles = defineRoles(promoter.subroles);

  const notify = useNotify();
  const refresh = useRefresh();

  const handleRoleToggle = (newSubroles: any, subroleToChange: string) => {
    if (!promoter.active) {
      notify('Operación no valida en promotor inactivo');
      return;
    }
    setLoader(true);
    if (validateSubrolInput(newSubroles)) {
      const requestBody = {
        id: promoter.id,
        subroles: buildBody(subroleToChange, subroles[subroleToChange])
      };
      dataProvider(MODIFY_PROMOTER_SUBROL, '', requestBody)
        .then((success: any) => {
          refresh();
          setLoader(false);
          if (success) {
            notify('Se actualizó correctamente la empresa al promotor');
          } else {
            notify('Ocurrio un problema actualizando el promotor');
          }
        })
        .catch(() => {
          setLoader(false);
          notify('Ocurrió un error, intente nuevamente.');
        });
    } else {
      setLoader(false);
      notify('Combinación de empresas no permitida');
    }
  };
  return (
    <>
      {/* <CheckboxPromoter
        label="On"
        checked={subroles.on}
        name="On"
        onChange={e => {
          e.stopPropagation();
          handleRoleToggle({ ...subroles, on: !subroles.on }, 'on');
        }}
        onLabelClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      /> */}
      {!subroles.asesor && (
        <CheckboxPromoter
          label="Osde"
          checked={subroles.osde}
          name="Osde"
          onChange={() => handleRoleToggle({ ...subroles, osde: !subroles.osde }, 'osde')}
          onLabelClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
      <CheckboxPromoter
        label="Binaria"
        checked={subroles.binaria}
        name="Binaria"
        onChange={() => handleRoleToggle({ ...subroles, binaria: !subroles.binaria }, 'binaria')}
        onLabelClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      <CheckboxPromoter
        label="Datos de pago"
        checked={subroles.analista}
        name="Datos de pago"
        onChange={() => handleRoleToggle({ ...subroles, analista: !subroles.analista }, 'analista')}
        onLabelClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      <CheckboxPromoter
        label="Asesor de atención"
        checked={subroles.asesor}
        name="Asesor de atención"
        onChange={() => handleRoleToggle({ ...subroles, asesor: !subroles.asesor }, 'asesor')}
        onLabelClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </>
  );
}

export default PromoterChangeRole;
