import { makeStyles } from '@material-ui/core/styles';
import { GRAY } from '~constants/colors';

export const useStyles = makeStyles({
  content: {
    color: GRAY,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '18px',
    textAlign: 'center'
  },
  actions: {
    alignItems: 'center',
    display: 'flex'
  },
  historyIcon: {
    color: '#717171',
    fontSize: '26px',
    marginRight: '10px'
  }
});
