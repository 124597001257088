import { makeStyles } from '@material-ui/core/styles';
import { WHITE, TRANSPARENCY, BLACK, PRIMARY_BLUE } from '~constants/colors';

const THEME_SPACING = 3;

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: WHITE,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    zIndex: 1
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'auto',
    width: '100%'
  },
  contentWithSidebar: {
    display: 'flex',
    height: '100%'
  },
  mainContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      width: '100%'
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    overflowX: 'auto',
    padding: theme.spacing(THEME_SPACING),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      width: '100%'
    }
  },
  box: {
    boxShadow: `-25px 0px 30px -20px ${TRANSPARENCY}`,
    '& div.list-page': {
      height: '100%'
    }
  },
  appBar: {
    background: WHITE,
    boxShadow: 'none',
    position: 'relative',
    width: '100%'
  },
  Sidebar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 0,
    padding: '24px 24px',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '275px'
    }
  }
}));

export const useNotificationStyles = makeStyles({
  root: {
    backgroundColor: WHITE,
    borderLeft: `4px solid ${PRIMARY_BLUE}`,
    borderRadius: 0,
    color: BLACK,
    position: 'relative'
  }
});
