/* eslint-disable no-magic-numbers */
import { DOCUMENT_TYPES, DOCUMENT_TYPES_CUIT } from './solicitudesON';

export const INPUT_NUMBER_PROPS = {
  steps: 1,
  pattern: '[0-9]*'
};
export const DOCUMENT_NUMBER_PROPS_ON = {
  [DOCUMENT_TYPES.DNI]: {
    MIN: 7,
    MAX: 8
  },
  [DOCUMENT_TYPES.CUIL]: {
    MIN: 11,
    TYPES: [20, 23, 24, 25, 26, 27]
  },
  [DOCUMENT_TYPES.PASSPORT]: {
    MIN: 7,
    MAX: 9
  },
  [DOCUMENT_TYPES_CUIT]: {
    MIN: 11,
    TYPES: [20, 23, 24, 25, 26, 27, 30, 33, 34]
  }
};
