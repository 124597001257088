import { GenericObjectInterface, Affiliation, Roles, Promoter, EntryChannel } from './types';

export const getTargetLinkProperties = (keepInPage: boolean) =>
  keepInPage ? {} : { rel: 'noopener noreferrer', target: '_blank' };

export const sortAlphabetically = (property?: string) => (
  a: GenericObjectInterface<any>,
  b: GenericObjectInterface<any>
) => {
  const compareA = property ? a[property] : a;
  const compareB = property ? b[property] : b;
  if (compareA < compareB) {
    return -1;
  }
  if (compareA > compareB) {
    return 1;
  }
  return 0;
};

export const getCompleteName = ({ firstName, lastName }: Affiliation['user'] | Promoter) =>
  `${firstName} ${lastName}`;

export const getIsSuperAdmin = (role?: string) => (role === Roles.ADMIN || role === Roles.SUPER_ADMIN)|| false;

export const getIsAutogestion = (entryChannel?: string) => entryChannel === EntryChannel.AUTOGESTION || false;

// Esta funcion agrega el border del del tag de color rojo para las validaciones
export const colorInput = (errorText?: any, inputRed?: any, input?: any) => {
  if (errorText) {
    return inputRed;
  }
  return input;
};

export const affiliationScreen = (basePath?: string, isAltaMasivas?: boolean) =>
  (basePath === '/affiliations' || basePath === '/altasmasivas') && isAltaMasivas;

export const baseColor = (isXs: boolean) => (isXs ? 'primary' : 'inherit');
