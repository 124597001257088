import React, { useEffect } from 'react';
import { useTranslate, useMutation } from 'react-admin';
import { RejectedReason } from '~utils/types';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import { GET_ON_REJECTED_REASONS } from '~config/providers/constants';
import FamillyMemberField from '~screens/SolicitudesON/components/FamillyMemberField';

import { useStyles } from './styles';

interface Props {
  onApplicationId: number;
}

function PotentialRiskModal({ onApplicationId }: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const [getRejectedReasons, { data, loading, error }] = useMutation({
    type: GET_ON_REJECTED_REASONS,
    resource: 'get',
    payload: { id: onApplicationId }
  });

  useEffect(() => {
    getRejectedReasons();
  }, [getRejectedReasons]);

  const riskData = !loading && !error && data ? data : [];
  let childCount = 0;

  // eslint-disable-next-line react/no-multi-comp
  const showFamilyMemberRisk = ({ reasons, type, age, sex, index }: RejectedReason) => (
    <div className={styles.riskContainer} key={`${type}-${index}`}>
      <FamillyMemberField
        member={{ member: type, age, sex: sex || '', cuil: '' }}
        indexForExtraChild={() => ++childCount}
      />
      <div className={`full-width ${styles.reasonTextContainer}`}>
        <p className={styles.textReasons}>
          {t('resources.on.potentialRisk.riskText', {
            reasons: reasons.join(', ')
          })}
        </p>
      </div>
    </div>
  );

  return (
    <ModalInfoInnerContainer loading={loading} error={error} onRetry={getRejectedReasons}>
      {riskData?.reduce(
        (riskCollector: JSX.Element[], memberRisk: RejectedReason) =>
          riskCollector.concat(memberRisk.reasons.length ? showFamilyMemberRisk(memberRisk) : []),
        [] as JSX.Element[]
      )}
    </ModalInfoInnerContainer>
  );
}

export default PotentialRiskModal;
