/*
 * TODO Add this if you need it
 * export const sidebarSize = 100;
 * export const topbarSize = 60;
 */

export const WIDTH_ROW = '290px';
export const FULL_WIDTH = '100%';
export const INPUT_SPACING = 8;
export const EDITABLE_INPUT_MARGIN = 36;
