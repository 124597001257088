// COLORS PALETTE
export const PRIMARY_BLUE = '#19317E';
export const PRIMARY_BLUE_OPACITY = 'rgba(25, 49, 126, 0.7)';
export const SECONDARY_BLUE = '#E9EFFF';
export const TERTIARY_BLUE = '#49A1C9';
export const QUATERNARY_BLUE = '#E4F6FF';
export const PRIMARY_GREEN = '#57C000';
export const SECONDARY_GREEN = '#EFFFE0';
export const PRIMARY_YELLOW = '#F2BE2F';
export const SECONDARY_YELLOW = '#FFF9DC';
export const PRIMARY_RED = '#E94C4C';
export const PRIMARY_RED_OPACITY = 'rgba(233, 76, 76, 0.7)';
export const SECONDARY_RED = '#F5E4E4';
export const PRIMARY_ORANGE = '#FF871F';
export const SECONDARY_ORANGE = '#FFF5EC';
export const WHITE = '#FFF';
export const BLACK = '#000';

// GRAY SCALES
export const DARK_GRAY = '#424242';
export const MEDIUM_GRAY = '#777777';
export const GRAY = '#B0AEAE';
export const GRAY_SECONDARY = '#A7A7A7';
export const LIGHT_GRAY = '#F1F1F1';
export const LIGHT_GRAY_SECONDARY = '#E5E5E5';
export const EXTRA_LIGHT_GRAY = '#F7F7F7';

// BACKGROUND PALETTE
export const PRIMARY_BACKGROUND = WHITE;
export const SECONDARY_BACKGROUND = LIGHT_GRAY;

// TRANSPARENT COLORS
export const TRANSPARENCY = 'rgba(0, 0, 0, 0.1)';
export const MERCURY_TRANSPARENCY = 'rgba(224, 224, 224, 0.4)';
export const TRANSPARENT = 'rgba(0, 0, 0, 0)';
