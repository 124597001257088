/* eslint-disable dot-notation */
/* eslint-disable complexity */
import { sexos, paises } from './constants';

const checkCorrectAddresses = (localidades: any): boolean => {
  const correctAddresses = localidades.filter(
    (d: any) =>
      d.cp !== null &&
      d.numero !== null &&
      d.calle !== null &&
      d.barrio.localidad.descripcion !== null &&
      d.barrio.localidad.partido.provincia.descripcion !== null
  );

  if (correctAddresses.length === 0) {
    return false;
  }

  return true;
};

export const checkCorrectData = (data: any, gruposActivos: any) => {
  const {
    nombre,
    apellido,
    fechaNacimiento,
    sexo,
    nacionalidad,
    identificadores,
    direcciones
  } = data.datosInterlocutorComercial;

  if (
    !nombre ||
    !apellido ||
    !fechaNacimiento ||
    !sexo ||
    !nacionalidad ||
    !identificadores.find((i: any) => i.tipoDocumento === 'CUIL') ||
    !direcciones ||
    !checkCorrectAddresses(direcciones)
  ) {
    return {
      correctData: false,
      gruposActivos: []
    };
  }

  const correctGroups: any[] = [];

  gruposActivos.forEach((grupo: any) => {
    if (
      grupo.datosContrato.plan.codigoPlan !== null &&
      grupo.datosContrato.nroContrato !== null &&
      grupo.numeroOrden !== null
    ) {
      correctGroups.push(grupo);
    }
  });

  if (correctGroups.length === 0) {
    return {
      correctData: false,
      gruposActivos: []
    };
  }
  return {
    correctData: true,
    gruposActivos: correctGroups
  };
};

export const getEmail = (direcciones: any) => {
  let direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Correspondencia');

  if (direccion && direccion.email) {
    const email = direccion.email.find((e: any) => e.estandar === 'X');

    if (email) {
      return email.email;
    }

    return direccion.email[0].email;
  }

  direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Credencial');

  if (direccion && direccion.email) {
    const email = direccion.email.find((e: any) => e.estandar === 'X');

    if (email) {
      return email.email;
    }

    return direccion.email[0].email;
  }

  direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Legal');

  if (direccion && direccion.email) {
    const email = direccion.email.find((e: any) => e.estandar === 'X');

    if (email) {
      return email.email;
    }

    return direccion.email[0].email;
  }

  direccion = direcciones.filter((d: any) => d.email !== null);

  if (direccion.length !== 0) {
    const email = direccion[0].email.find((e: any) => e.estandar === 'X');

    if (email) {
      return email.email;
    }

    return direccion[0].email[0].email;
  }

  return '-';
};

export const getAddress = (direcciones: any) => {
  let direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Correspondencia');

  if (
    direccion &&
    direccion.cp !== null &&
    direccion.numero !== null &&
    direccion.calle !== null &&
    direccion.barrio.localidad.descripcion !== null &&
    direccion.barrio.localidad.partido.provincia.descripcion !== null
  ) {
    return direccion;
  }

  direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Credencial');

  if (
    direccion &&
    direccion.cp !== null &&
    direccion.numero !== null &&
    direccion.calle !== null &&
    direccion.barrio.localidad.descripcion !== null &&
    direccion.barrio.localidad.partido.provincia.descripcion !== null
  ) {
    return direccion;
  }

  direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Legal');

  if (
    direccion &&
    direccion.cp !== null &&
    direccion.numero !== null &&
    direccion.calle !== null &&
    direccion.barrio.localidad.descripcion !== null &&
    direccion.barrio.localidad.partido.provincia.descripcion !== null
  ) {
    return direccion;
  }

  direccion = direcciones.filter(
    (d: any) =>
      d &&
      d.cp !== null &&
      d.numero !== null &&
      d.calle !== null &&
      d.barrio.localidad.descripcion !== null &&
      d.barrio.localidad.partido.provincia.descripcion !== null
  );

  return direccion[0];
};

export const getPhone = (direcciones: any) => {
  let direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Correspondencia');

  if (direccion && direccion.telefono) {
    const telefono = direccion.telefono.find((e: any) => e.estandar === 'X');

    if (telefono) {
      return telefono.numero;
    }

    return direccion.telefono.numero;
  }

  direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Credencial');

  if (direccion && direccion.telefono) {
    const telefono = direccion.telefono.find((e: any) => e.estandar === 'X');

    if (telefono) {
      return telefono.numero;
    }

    return direccion.telefono.numero;
  }

  direccion = direcciones.find((d: any) => d.tipoDireccion.descripcion === 'Legal');

  if (direccion && direccion.telefono) {
    const telefono = direccion.telefono.find((e: any) => e.estandar === 'X');

    if (telefono) {
      return telefono.numero;
    }

    return direccion.telefono.numero;
  }

  direccion = direcciones.filter((d: any) => d.telefono);

  if (direccion.length === 0) {
    return '-';
  }

  direccion = direccion.find((e: any) => e.telefono?.estandar === 'X');

  if (direccion) {
    const telefono = direccion.telefono.find((t: any) => t.estandar === 'X');

    return telefono.numero;
  }

  return direccion[0].telefono[0].numero;
};

export const getSexo = (sexo: keyof typeof sexos) => sexos[sexo];

export const getPais = (pais: keyof typeof paises) => paises[pais];
