import React, { useState } from 'react';
import { useTranslate, TextField, FunctionField } from 'react-admin';
import CustomTable from '~components/CustomTable';
// import Checkbox from '~components/Checkbox';
import WithProps from '~components/WithProps';
import { PROMOTER_FIELDS } from '~screens/Promoters/constants';
import CustomModal from '~components/CustomModal';
import LoadingModal from '~components/LoadingModal';
import useModal from '~hooks/useModal';
import { TOGGLE_PROMOTER_ENABLE } from '~config/providers/constants';
import { Promoter } from '~utils/types';
import { getTogglePromoterPayload } from '~screens/Promoters/utils';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import { Typography } from 'antd';
import ActionField from '~screens/Promoters/components/ActionField';

import { useStyles } from './styles';
import PromotersFilters from './components/PromotersFilters';
import ResponsiveList from './components/ResponsiveList';
import PromoterSwitchStatus from './components/PromoterSwitchStatus';
import PromoterChangeRole from './components/PromoterChangeRole';
import { getPromoterRole } from './utils';

interface Props {
  resource: string;
}

function PromotersList({ resource, ...props }: Props) {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslate();

  const [toggledPromoter, dispatchTogglePromoterRequest, loading] = useModal({
    resource,
    requestType: TOGGLE_PROMOTER_ENABLE,
    requestPayload: getTogglePromoterPayload,
    modalName: MODAL_NAMES.TOGGLE_PROMOTER_STATUS,
    successMessageNotify: 'resources.promoters.modals.togglePromoterState.success'
  });

  return (
    <WithProps {...{ resource, ...props }}>
      {(withProps: Props) => (
        <>
          <CustomTable
            {...withProps}
            title={t('resources.promoters.listName')}
            showExport
            showDetail
            filters={<PromotersFilters />}
            listComponent={ResponsiveList}
          >
            <TextField label={t('resources.promoters.fields.promoter')} source={PROMOTER_FIELDS.id} />
            <TextField label={t('resources.promoters.fields.firstName')} source={PROMOTER_FIELDS.firstName} />
            <TextField label={t('resources.promoters.fields.lastName')} source={PROMOTER_FIELDS.lastName} />
            <FunctionField
              label={t('resources.promoters.fields.rol')}
              render={(promoter: Promoter) => {
                const { role, subroles } = promoter;
                const rol = getPromoterRole(role, subroles);

                return <Typography>{rol}</Typography>;
              }}
            />
            <TextField
              label={t('resources.promoters.fields.filialDescription')}
              source={PROMOTER_FIELDS.filialDescription}
            />
            <FunctionField
              label="Empresas"
              render={(promoter: Promoter) => (
                <PromoterChangeRole promoter={promoter} setLoader={setIsLoading} />
              )}
            />
            <FunctionField
              label={t('resources.promoters.fields.action')}
              render={(promoter: Promoter) => (
                <div className={styles.actions}>
                  <PromoterSwitchStatus promoter={promoter} />
                  <ActionField />
                </div>
              )}
            />
          </CustomTable>
          {isLoading && <LoadingModal textContent="Aplicando Cambios..." />}
          {toggledPromoter && (
            <CustomModal
              title={t('resources.promoters.modals.togglePromoterState.title')}
              modalName={MODAL_NAMES.TOGGLE_PROMOTER_STATUS}
              onConfirm={dispatchTogglePromoterRequest}
              loading={loading}
              showCloseIcon={false}
            >
              <p className={styles.content}>
                {t(
                  `resources.promoters.modals.togglePromoterState.${
                    toggledPromoter.active ? 'deactivate' : 'activate'
                  }`
                )}
              </p>
            </CustomModal>
          )}
        </>
      )}
    </WithProps>
  );
}

export default PromotersList;
