import React from 'react';
import { useTranslate } from 'react-admin';
import Loading from '~components/Spinner/components/loading';

import { useStyles } from './styles';

function Loader({ text }: { text?: string }) {
  const styles = useStyles();
  const t = useTranslate();

  return (
    <div className={`column center middle full-width ${styles.container}`}>
      <Loading className="m-bottom-10" />
      <h1 className={styles.text}>{text || t('general.loading')}</h1>
    </div>
  );
}

export default Loader;
