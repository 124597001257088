import { makeStyles } from '@material-ui/core/styles';
import { GRAY, PRIMARY_RED, PRIMARY_RED_OPACITY, WHITE } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  modalContent: {
    color: GRAY,
    fontSize: 14,
    lineHeight: '16px',
    marginBottom: '18px',
    textAlign: 'center'
  },
  sendEmailButton: {
    display: 'flex',
    '& span': {
      fontSize: 16
    }
  },
  button: {
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  buttonCanceled: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: PRIMARY_RED,
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: WHITE
      }
    },
    '& span': {
      color: WHITE
    }
  },
  spacing: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px'
    }
  }
}));
