import React from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';

import { useStyles } from './styles';

interface Props {
  state: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
  rpaStatus?: boolean;
}

export default function StatusField({
  state,
  rounded = true,
  className = '',
  labelClassName = '',
  rpaStatus
}: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const classSAP = rpaStatus ? 'sap' : 'sap_rpa';

  return (
    <div className={`full-width ${className}`}>
      <div
        className={cn(styles.label, styles[state === 'sap' ? classSAP : state], labelClassName, {
          rounded
        })}
      >
        <span>{t(`resources.solicitudF2.fields.statusTypes.${state}`)}</span>
      </div>
    </div>
  );
}
