import React from 'react';
import { useTranslate } from 'react-admin';
import CustomButton from '~components/CustomButton';

import { useStyles } from './styles';

function ActionField() {
  const styles = useStyles();
  const t = useTranslate();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleClick = () => {};

  return (
    <>
      <div className={styles.button}>
        <CustomButton
          label={t('resources.promoters.editPromoter')}
          title={t('resources.promoters.editPromoterTitle')}
          onClick={handleClick}
          className={styles.buttonText}
        />
      </div>
    </>
  );
}

export default ActionField;
