import React, { useCallback, useState } from 'react';
import { useNotify, regex, SimpleForm } from 'react-admin';
import { useDispatch } from '~contexts/ModalContext';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import CustomButton from '~components/CustomButton';
import CustomModal from '~components/CustomModal';
import InputLabel from '~components/InputLabel';
import { AFFILIATION_REQUESTS_FIELDS } from '~screens/AffiliationRequests/constants';
import { questionsApplication } from '~services/OsdeService';
import { maxLengthOptional, minLengthOptional } from '~utils/inputValidations';

import { useStyles } from './styles';

interface Props {
  label?: string;
  className?: string;
  applicationId: number;
}

const ApplicationQuestionsModal = (props: Props) => {
  const MAX_LENGTH_QUESTIONS = 500;
  const MIN_LENGTH_QUESTIONS = 3;
  const regexCharacters = /^(?!.*\s{2})[\w\d\s\S\n]*$/;
  const validateQuestions = maxLengthOptional(MAX_LENGTH_QUESTIONS, 'Máximo 500 caracteres');
  const validateQuestionMin = minLengthOptional(
    MIN_LENGTH_QUESTIONS,
    `Mínimo ${MIN_LENGTH_QUESTIONS} caracteres`
  );
  const styles = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSave = (q: any) => {
    setLoading(true);
    questionsApplication(props.applicationId, q)
      .then(() => {
        setLoading(false);
        dispatch?.(modalActions.closeModal(MODAL_NAMES.QUESTIONS));
        notify('Pregunta enviada correctamente');
      })
      .catch((error: Error) => {
        setLoading(false);
        dispatch?.(modalActions.closeModal(MODAL_NAMES.QUESTIONS));
        return notify(error.message);
      });
  };
  const handleOpenFilesModal = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.QUESTIONS));
  }, [dispatch]);

  const handleCloseFilesModal = useCallback(() => {
    if (!loading) {
      dispatch?.(modalActions.closeModal(MODAL_NAMES.QUESTIONS));
    }
  }, [dispatch, loading]);

  return (
    <>
      <CustomButton
        className={`${styles.button} ${styles.buttonFinished}`}
        onClick={handleOpenFilesModal}
        label="Solicitar más documentación"
        disabled={loading}
      />
      <CustomModal
        modalName={MODAL_NAMES.QUESTIONS}
        onClose={handleCloseFilesModal}
        title="Solicitud de documentación adicional"
        isInfo
        showCloseIcon={!loading}
      >
        <SimpleForm
          save={handleSave}
          saving={loading}
          className={styles.simpleForm}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <InputLabel
            label="Detallar la información o documentación a solicitar"
            className={styles.row}
            source={AFFILIATION_REQUESTS_FIELDS.questions}
            validate={[
              validateQuestions,
              validateQuestionMin,
              regex(regexCharacters, 'No se permiten solo espacios.')
            ]}
            multiline
            rows={3}
          />
        </SimpleForm>
      </CustomModal>
    </>
  );
};
export default ApplicationQuestionsModal;
