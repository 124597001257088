import React from 'react';
import { useTranslate } from 'react-admin';
import { useStyles } from '~components/ViewInput/styles';
import { FamilyMember, FamilyMembers } from '~utils/types';

interface Props {
  member: FamilyMember;
  indexForExtraChild?: () => number;
}

function FamillyMemberField({ member: fMember, indexForExtraChild }: Props) {
  const viewInputStyles = useStyles();
  const t = useTranslate();
  let indexChild: number | undefined = undefined;
  let childrenType = undefined;

  if (fMember.member === FamilyMembers.HIJO) {
    indexChild = indexForExtraChild?.();
    switch (fMember.sex) {
      case 'Femenino':
        childrenType = 'daughter';
        break;
      case 'No Binario':
        childrenType = 'childNB';
        break;
      default:
        childrenType = FamilyMembers.HIJO;
    }
  }

  return (
    <div className="full-width row">
      <span className={`m-right-1 ${viewInputStyles.label}`}>
        {t(`resources.on.familyGroup.${childrenType || fMember.member}`)}
        {childrenType ? ` [${indexChild}]` : ''}
        {!childrenType && fMember.member !== 'prospect' && fMember.sex ? ` (${fMember.sex})` : ''}:
      </span>
      <span className={viewInputStyles.value}>
        {t('resources.on.familyGroup.age', {
          age: fMember.age || 'N/D'
        })}
      </span>
    </div>
  );
}

export default FamillyMemberField;
