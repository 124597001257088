import moment from 'moment';
import {
  HIRING_MODE_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  SOLICITUDES_ON_REQUEST_STATUS
} from '~constants/solicitudesON';

export const denormalizeSolicitudON = (values: any, isEdit = false) => {
  const {
    affiliationType,
    planValidityDate,
    promoterId,
    applicationType,
    isSuperAdmin,
    state,
    hiringMode,
    companyName,
    companyCuit,
    isPaymentResponsible
  } = values;

  const { firstName, lastName, documentNumber, documentType, phoneNumber, email } = values.user;

  function notEditReturn() {
    return {
      email,
      affiliationType,
      applicationType,
      planValidityDate: moment(planValidityDate).toISOString(),
      promoterId,
      firstName,
      lastName,
      documentNumber: `${documentNumber}`,
      documentType,
      ...(phoneNumber && { phoneNumber: `${phoneNumber}` }),
      companyName,
      companyCuit,
      ...(hiringMode === HIRING_MODE_TYPES.PLA
        ? {
            hiringMode,
            isPaymentResponsible: isPaymentResponsible === IS_PAYMENT_RESPONSIBLE_TYPES.true
          }
        : { hiringMode })
    };
  }
  function isEditReturn() {
    return {
      email,
      firstName,
      lastName,
      phoneNumber: `${phoneNumber || ''}`,
      ...(isSuperAdmin && { promoterId }),
      ...(state === SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING && {
        optionalData: {
          affiliationType,
          applicationType,
          companyName,
          companyCuit,
          planValidityDate: moment(planValidityDate).toISOString,
          ...(hiringMode === HIRING_MODE_TYPES.PLA
            ? {
                hiringMode,
                isPaymentResponsible: isPaymentResponsible === IS_PAYMENT_RESPONSIBLE_TYPES.true
              }
            : {
                hiringMode,
                isPaymentResponsible
              })
        }
      })
    };
  }
  if (isEdit) {
    return isEditReturn();
  }
  return notEditReturn();
};
