import { Nullable } from '~utils/types';

import { MODAL_NAMES } from './constants';

export interface ModalState {
  [key: string]: Nullable<boolean | any>;
}

const INITIAL_STATE = {
  [MODAL_NAMES.DIALOG]: false,
  [MODAL_NAMES.INITIAL_EMAIL]: false,
  [MODAL_NAMES.USER_VALIDATION]: false,
  [MODAL_NAMES.EVALUATE]: false,
  [MODAL_NAMES.HISTORIC_STEPS]: false,
  [MODAL_NAMES.POTENTIAL_RISK]: false,
  [MODAL_NAMES.BINARIA_DIALOG]: false,
  [MODAL_NAMES.SAP]: false,
  data: null,
  file: []
};

export const modalState = { ...INITIAL_STATE };

export enum ActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_FILE = 'SET_FILE',
  CLEAN_FILE = 'CLEAN_FILE'
}

interface OpenModal {
  type: ActionTypes.OPEN_MODAL;
  payload: { modal: string; data: Nullable<any> };
}

interface CloseModal {
  type: ActionTypes.CLOSE_MODAL;
  payload: string;
}

interface SetFile {
  type: ActionTypes.SET_FILE;
  payload: any;
}

interface CleanFile {
  type: ActionTypes.CLEAN_FILE;
  payload: any;
}

export type Action = OpenModal | CloseModal | SetFile | CleanFile;

export const actionCreators = {
  openModal: (modal: string, data?: any): OpenModal => ({
    type: ActionTypes.OPEN_MODAL,
    payload: { modal, data: data || null }
  }),
  closeModal: (modal: string): CloseModal => ({ type: ActionTypes.CLOSE_MODAL, payload: modal }),
  setFile: (file: any): SetFile => ({ type: ActionTypes.SET_FILE, payload: file }),
  cleanFile: (): CleanFile => ({ type: ActionTypes.CLEAN_FILE, payload: [] })
};

export const reducer = (state: ModalState, action: Action): ModalState => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL: {
      const { modal, data } = action.payload;
      return { ...state, [modal]: true, data };
    }
    case ActionTypes.CLOSE_MODAL: {
      return INITIAL_STATE;
    }
    case ActionTypes.SET_FILE: {
      return { ...state, file: state.file.push(action.payload) };
    }
    case ActionTypes.CLEAN_FILE: {
      // eslint-disable-next-line no-return-assign
      return { ...state, file: state.file = [] };
    }
    default: {
      return state;
    }
  }
};
