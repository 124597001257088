import React, { useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { SolicitudONRequest, Nullable } from '~utils/types';
import useModal from '~hooks/useModal';
import ViewInput from '~components/ViewInput';
import CustomButton from '~components/CustomButton';
import FileValue from '~components/FileValue';
import { SOLICITUDES_ON_REQUEST_STATUS } from '~constants/solicitudesON';
import CustomModal from '~components/CustomModal';
import cn from 'classnames';
import { useCommonStyles } from '~app/styles';
import { isEvaluationPending, isUserValidationPending } from '~screens/SolicitudesON/utils';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { EVALUATE_APPLICATION_ON } from '~config/providers/constants';

import { useStyles as useDetailStyles } from '../SolicitudONDetail/styles';
import PotentialRiskField from '../../../../components/PotentialRiskField/index';

import {
  getOrderedAttachments,
  getCleanFileName,
  shouldShowPotentialRiskField,
  getEvaluatePayload
} from './utils';
// import { useStyles } from './on-styles';
import { useStyles } from './styles';
import { FILE_TYPES } from './constants';

interface Props {
  record: SolicitudONRequest;
  resource: string;
}

interface RequestPayload {
  id?: number;
  rejected?: boolean;
}

// eslint-disable-next-line complexity
function SolicitudONFilled({ record, resource }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const detailStyles = useDetailStyles();
  const commonStyles = useCommonStyles();
  const { id, f1Document, swornDocument, attachments = [] } = record;
  
  const pendingEvaluation = isEvaluationPending(record.state);
  
  const pendienteDeConfirmacion = isUserValidationPending(record.state);
  const mostrarRiesgoPotencial = shouldShowPotentialRiskField(record.state);

  
  
  const [statusToChange, setStatusToChange] = useState<Nullable<string>>(null);
  const hasAttachments = !!attachments?.length;
  const attachmentsResult = getOrderedAttachments(attachments || [], t);

  const [modalData, dispatchRequest, loading, onOpenModal] = useModal<RequestPayload, RequestPayload>({
    resource,
    requestType: EVALUATE_APPLICATION_ON,
    requestPayload: getEvaluatePayload,
    modalName: MODAL_NAMES.EVALUATE,
    successMessageNotify: `resources.on.evaluate.modal.${statusToChange}.success`
  });


  const handleOpenModal = useCallback(
    (action: string) => {
      setStatusToChange(action);
      onOpenModal({ id, newState: action });
    },
    [id, onOpenModal]
  );
  return (
    <div className={`column full-width ${styles.spacing}`}>
      
      {!pendienteDeConfirmacion && mostrarRiesgoPotencial && (
        <ViewInput
          label={t('resources.on.fields.potentialRisk')}
          content={<PotentialRiskField solicitud={record} />}
        />
      )}
      {!pendienteDeConfirmacion && f1Document && (
        <ViewInput
          label={t(`resources.on.documents.${FILE_TYPES.f1}.title`)}
          content={<FileValue name={getCleanFileName(f1Document)} url={f1Document} />}
        />
      )}
      {!pendienteDeConfirmacion && swornDocument && (
        <ViewInput
          label={t(`resources.on.documents.${FILE_TYPES.sworn}.title`)}
          content={<FileValue name={getCleanFileName(swornDocument)} url={swornDocument} />}
          {...(!hasAttachments && { className: styles.separation })}
        />
      )}
      {!pendienteDeConfirmacion && hasAttachments && (
        <ViewInput
          label={t(`resources.on.documents.${FILE_TYPES.attachments}.title`)}
          content={attachmentsResult.map(attachment => (
            <FileValue key={attachment.url} url={attachment.url} name={attachment.name} />
          ))}
          className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
        />
      )}
      {pendingEvaluation && (
        <>
          <CustomButton
            className={`${styles.button} ${styles.buttonFinished}`}
            onClick={() => handleOpenModal(SOLICITUDES_ON_REQUEST_STATUS.FINISHED)}
            label={t(`resources.on.evaluate.${SOLICITUDES_ON_REQUEST_STATUS.FINISHED}`)}
            disabled={loading}
          />
          {record.state !== SOLICITUDES_ON_REQUEST_STATUS.REVISION && (
            <CustomButton
              className={`${styles.button} ${styles.buttonRevision}`}
              onClick={() => handleOpenModal(SOLICITUDES_ON_REQUEST_STATUS.REVISION)}
              label={t(`resources.on.evaluate.${SOLICITUDES_ON_REQUEST_STATUS.REVISION}`)}
              disabled={loading}
            />
          )}
          <CustomButton
            className={`${styles.button} ${styles.buttonCanceled}`}
            onClick={() => handleOpenModal(SOLICITUDES_ON_REQUEST_STATUS.CANCELED)}
            label={t(`resources.on.evaluate.${SOLICITUDES_ON_REQUEST_STATUS.CANCELED}`)}
            disabled={loading}
          />
          {modalData?.id && (
            <CustomModal
              title={t('resources.affiliations.modals.title')}
              modalName={MODAL_NAMES.EVALUATE}
              onConfirm={dispatchRequest}
              loading={loading}
              showCloseIcon={false}
            >
              <p className={detailStyles.modalContent}>
                {t(`resources.on.evaluate.modal.${statusToChange}.message`)}
              </p>
            </CustomModal>
          )}
        </>
      )}
    </div>
  );
}

export default SolicitudONFilled;
