import preval from 'preval.macro';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import dataProvider from '~config/providers/dataProvider';
import { DELETE_PROSPECT_ATTACHMENT } from '~config/providers/constants';
import { FamilyMember } from '~utils/types';

export const UPLOAD_FILES_MIME_ACCEPTED = preval`
const mimeTable = require('mime-types').types;
module.exports = [mimeTable.pdf, mimeTable.png, mimeTable.jpg].join(', ');
`;

export const isUploadMimeAccepted = (mimeType: string) => {
  const mimesAsArray = UPLOAD_FILES_MIME_ACCEPTED.split(', ');
  return mimesAsArray.includes(mimeType);
};

export const getEvaluatePayload = (data?: { id?: number; newState?: string }) => ({
  id: data?.id,
  newState: data?.newState
});

// eslint-disable-next-line complexity
export const shouldShowPotentialRiskField = (status: string, familyGroup: FamilyMember[] | undefined) =>
  (status === AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
    status === AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION ||
    status === AFFILIATION_REQUEST_STATUS.FINISHED ||
    status === AFFILIATION_REQUEST_STATUS.CANCELED ||
    status === AFFILIATION_REQUEST_STATUS.EXPIRED ||
    status === AFFILIATION_REQUEST_STATUS.SAP ||
    status === AFFILIATION_REQUEST_STATUS.SAP_RPA ||
    status === AFFILIATION_REQUEST_STATUS.REVISION ||
    status === AFFILIATION_REQUEST_STATUS.ZCAR ||
    status === AFFILIATION_REQUEST_STATUS.ENABLED) &&
  familyGroup;

export const shouldUploadFile = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING ||
  status === AFFILIATION_REQUEST_STATUS.REVISION;

export const deleteFileFromBucket = async (fileName: string, applicationId: number) => {
  let result = false;
  try {
    const body = {
      url: {
        applicationId
      },
      query: {
        fileName
      }
    };

    result = await dataProvider(DELETE_PROSPECT_ATTACHMENT, '', body);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return result;
};
