import React, { useCallback, useState } from 'react';
import dataProvider from '~config/providers/dataProvider';
import {
  useNotify,
  useRefresh,
  SelectInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  SaveButton,
  required,
  FileInput,
  FileField
} from 'react-admin';
import { UPLOAD_FILE_APPLICATION } from '~config/providers/constants';
import { useDispatch } from '~contexts/ModalContext';
import CustomModal from '~components/CustomModal';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import CustomButton from '~components/CustomButton';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { getCorrectFileExtenssion, getFileBaseParts } from '~utils/attachmentFiles';

import { UPLOAD_FILES_MIME_ACCEPTED } from '../AffiliationFilled/utils';

import { checkFile, buildParams, documentChoices } from './utils';
import { useStyles } from './styles';

const TIMEOUT_REFRESH = 1000;

interface Props {
  label?: string;
  className?: string;
  applicationId: number;
  openInput: boolean;
  attachments: any;
  resetOpenInput: () => void;
  onSetEditing?: () => void;
}

function createNewBaseName(attachmentNames: string[], file: File, category: string) {
  const defaultNameHoledReplace = (paramValue: string) => {
    const indexExtesionHole = '{indexExtesionHole}';
    const defaultNameHoled = `P_${category}-${indexExtesionHole}`;
    return defaultNameHoled.replace(indexExtesionHole, paramValue);
  };

  const maxIndexForAttachCategory = () => {
    // una expresion regular donde aparece un ìndice y una extensiòn
    const newNameRegExp = new RegExp(`^${defaultNameHoledReplace('(\\d+)(?:[.][^.]*)?')}$`);

    const maxIndex = attachmentNames.reduce(
      (currentMax, name) => Math.max(currentMax, parseInt(newNameRegExp.exec(name)?.[1] || '0')),
      0
    );
    return maxIndex;
  };

  const maxIndex = maxIndexForAttachCategory();

  const finalExtension = getCorrectFileExtenssion(
    file.type,
    file.name && getFileBaseParts(file.name).extension
  );

  return defaultNameHoledReplace(String(maxIndex + 1) + finalExtension);
}

const AffiliationFileUpload = ({ label, applicationId, attachments }: Props) => {
  const styles = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleOpenFilesModal = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.FILES_PROMOTER));
  }, [dispatch]);

  const handleCloseFilesModal = useCallback(() => {
    if (!loading) {
      dispatch?.(modalActions.closeModal(MODAL_NAMES.FILES_PROMOTER));
    }
  }, [dispatch, loading]);

  const handleSave = (event: any) => {
    if (!event.adjuntos || event.adjuntos.length === 0) {
      return notify('El formulario no es valido. Por favor verifique si hay errores');
    }

    setLoading(true);

    const attachmentNames: string[] = attachments.map((attachment: any) => attachment.name as string);

    const arrayParams = event.adjuntos.reduce((acumUploads: any[], current: any) => {
      const {
        files: { rawFile },
        type: category
      }: any = current;
      const isFilePermitted = checkFile(rawFile);
      if (!isFilePermitted.ok) {
        notify(`${isFilePermitted.message}`);
        return acumUploads;
      }

      const newBaseName = createNewBaseName(attachmentNames, rawFile, category);

      attachmentNames.push(newBaseName);
      const params = buildParams(rawFile, applicationId, newBaseName);
      return [...acumUploads, params];
    }, [] as any[]);

    return Promise.all(
      arrayParams.map((params: any): any => dataProvider(UPLOAD_FILE_APPLICATION, '', params))
    )
      .then(() => {
        notify('Archivos agregados a la solicitud');
        setLoading(false);
        handleCloseFilesModal();
        setTimeout(() => refresh(), TIMEOUT_REFRESH);
      })
      .catch(err => {
        if (err) {
          notify('Ocurrio un error, intente nuevamente');
        }
      });
  };
  const validateAttachmentsForm = (values: any) => {
    if (Object.keys(values)?.length) {
      if (values.adjuntos?.length) {
        const attachment = values.adjuntos.filter((e: any) => e && Object.keys(e).length === 2).length;
        if (attachment === values.adjuntos.length) {
          setDisabled(false);
          return;
        }
      }
    }
    setDisabled(true);
  };
  return (
    <>
      <CustomButton
        className={`${styles.button} ${styles.buttonFinished}`}
        onClick={handleOpenFilesModal}
        label="Adjuntar archivos"
        disabled={loading}
      />
      <CustomModal
        modalName={MODAL_NAMES.FILES_PROMOTER}
        onClose={handleCloseFilesModal}
        title="Documentación"
        isInfo
        showCloseIcon={!loading}
      >
        <SimpleForm
          save={handleSave}
          saving={loading}
          className={styles.simpleForm}
          toolbar={<SaveButton label="general.save" disabled={disabled} />}
          validate={validateAttachmentsForm}
        >
          <ArrayInput source="adjuntos" label="">
            <SimpleFormIterator>
              <SelectInput
                label="Tipo de archivo"
                source="type"
                validate={[required()]}
                choices={documentChoices}
              />
              <FileInput
                className={styles.fileInput}
                source="files"
                validate={[required()]}
                label=""
                accept={UPLOAD_FILES_MIME_ACCEPTED}
                placeholder={<label htmlFor="fileInput"> {label || 'Adjuntar'}</label>}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </CustomModal>
    </>
  );
};

export default AffiliationFileUpload;
