import React, { useCallback, useState } from 'react';
import { useTranslate, TextField, FunctionField } from 'react-admin';
import WithProps from '~components/WithProps';
import CustomTable from '~components/CustomTable';
import StatusField from '~screens/SolicitudesON/components/StatusField';
import { EntryChannel, Nullable, SolicitudONRequest } from '~utils/types';
import useModal from '~app/hooks/useModal';
import { SOLICITUDES_ON_REQUEST_STATUS } from '~constants/solicitudesON';
import { EMAIL_CONFIG, SOLICITUDES_ON_REQUESTS_FIELDS } from '~screens/SolicitudesON/constants';
import { getSendEmailTexts, isUserValidationPending } from '~screens/SolicitudesON/utils';
import CustomModal from '~components/CustomModal';
import ActionField from '~screens/SolicitudesON/components/ActionField';

import { useStyles } from './styles';
import SolicitudONFilter from './components/SolicitudONFilter';
import ResponsiveList from './components/ResponsiveList';
import HistoricSolicitudONModal from './components/HistoricSolicitudONModal';
import HistoricSolicitudONButton from './components/HistoricSolicitudONButton';

interface Props {
  resource: string;
}

interface RequestPayload {
  id?: number;
}

export default function SolicitudesONList({ resource, ...props }: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const [solicitdudONState, setSolicitdudONState] = useState<Nullable<string> | undefined>(null);

  const handleOpen = useCallback((solicitud?: SolicitudONRequest) => {
    setSolicitdudONState(solicitud?.state);
  }, []);

  const handleClose = useCallback(() => {
    setSolicitdudONState(null);
  }, []);

  const [, dispatchInitialRequest, loadingInitial, onOpenModalInitial] = useModal<
    SolicitudONRequest,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING]
  });

  const [, dispatchValidationRequest, loadingValidation, onOpenModalValidation] = useModal<
    SolicitudONRequest,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[SOLICITUDES_ON_REQUEST_STATUS.PENDING_USER_VALIDATION]
  });

  const tipoSocio = (record: SolicitudONRequest) => {
    if (record.entryChannel === EntryChannel.AUTOGESTION) {
      return (
        record.affiliationType &&
        t(`resources.on.fields.affiliationTypesAutogestion.${record.affiliationType}`)
      );
    }
    return record.affiliationType && t(`resources.on.fields.affiliationTypes.${record.affiliationType}`);
  };

  const tipoFormulario = (record: SolicitudONRequest) =>
    record.applicationType && t(`resources.on.fields.applicationTypes.${record.applicationType}`);

  return (
    <WithProps {...{ resource, ...props }}>
      {(withProps: Props) => (
        <>
          <CustomTable
            {...withProps}
            title="Listado de solicitudes"
            filters={<SolicitudONFilter />}
            showDetail
            showExport
            listComponent={ResponsiveList}
          >
            <TextField label="ID" source={SOLICITUDES_ON_REQUESTS_FIELDS.id} sortable />
            <TextField label="Promotor" source={SOLICITUDES_ON_REQUESTS_FIELDS.promoterId} sortable />
            <TextField
              label={t('resources.on.fields.firstName')}
              source={SOLICITUDES_ON_REQUESTS_FIELDS.firstName}
              sortable
            />
            <TextField
              label={t('resources.on.fields.lastName')}
              source={SOLICITUDES_ON_REQUESTS_FIELDS.lastName}
              sortable
            />
            <TextField
              label={t('resources.on.fields.document')}
              source={SOLICITUDES_ON_REQUESTS_FIELDS.documentNumber}
              sortable
            />
            <FunctionField label={t('resources.on.fields.type')} render={tipoSocio} />
            <FunctionField label={t('resources.on.fields.applicationType')} render={tipoFormulario} />
            <FunctionField label={t('resources.on.fields.status')} render={StatusField} />
            <FunctionField
              label={t('resources.on.fields.action')}
              render={(solicitud: SolicitudONRequest) => (
                <div className={styles.actions}>
                  <HistoricSolicitudONButton id={solicitud.id} stateDatetime={solicitud.stateDatetime} />
                  <ActionField
                    solicitud={solicitud}
                    onEnvioMailInicial={onOpenModalInitial}
                    onReenvioMailValidacion={onOpenModalValidation}
                  />
                </div>
              )}
            />
          </CustomTable>
          {solicitdudONState && (
            <CustomModal
              title={t('resources.affiliations.modals.title')}
              modalName={EMAIL_CONFIG[solicitdudONState].modalName}
              onConfirm={
                isUserValidationPending(solicitdudONState)
                  ? dispatchValidationRequest
                  : dispatchInitialRequest
              }
              loading={isUserValidationPending(solicitdudONState) ? loadingValidation : loadingInitial}
              onClose={handleClose}
              showCloseIcon={false}
            >
              <p className={styles.content}>{t(getSendEmailTexts(solicitdudONState).modalMessage)}</p>
            </CustomModal>
          )}
          <HistoricSolicitudONModal />
        </>
      )}
    </WithProps>
  );
}
