import React from 'react';

import { useResponsiveListStyles } from './styles';

function ResponsiveListWrapper({
  children,
  onClick
}: {
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
}) {
  const styles = useResponsiveListStyles();
  return (
    <div onClick={onClick} className={styles.container}>
      {children}
    </div>
  );
}

export default ResponsiveListWrapper;
