import React from 'react';
import { Create } from 'react-admin';
import { useCommonStyles } from '~app/styles';
import WithProps from '~components/WithProps';
import DetailActionButtons from '~components/DetailActionButtons';
import { Promoter } from '~utils/types';

import CancelacionesForm from '../CancelacionesForm';

function CancelacionesWrapper(props: { permissions: Promoter }) {
  const commonStyles = useCommonStyles();

  return (
    <Create
      {...props}
      title={<span />}
      classes={{
        card: commonStyles.cardDatosPago,
        main: commonStyles.main,
        root: commonStyles.rootDatosPago
      }}
    >
      <WithProps>
        {(createProps: object) => (
          <>
            <DetailActionButtons />
            <CancelacionesForm {...createProps} props={{ ...props }} />
          </>
        )}
      </WithProps>
    </Create>
  );
}

export default CancelacionesWrapper;
