import React from 'react';
import { useShowController, ShowView, useTranslate } from 'react-admin';
import Loader from '~components/Loader';

function CustomShow(props: any) {
  const t = useTranslate();
  const { record, loading, ...controllerProps } = useShowController(props);

  return loading ? (
    <Loader text={t('general.loadingShow')} />
  ) : (
    <ShowView record={record} {...controllerProps} {...props} />
  );
}

export default CustomShow;
