import { DOCUMENT_TYPES } from './affiliations';

export const INPUT_NUMBER_PROPS = {
  steps: 1,
  pattern: '[0-9]*'
};
/* eslint-disable no-magic-numbers */
export const DOCUMENT_NUMBER_PROPS = {
  [DOCUMENT_TYPES.DNI]: {
    MIN: 7,
    MAX: 8
  },
  [DOCUMENT_TYPES.CUIL]: {
    MIN: 11,
    TYPES: [20, 23, 24, 25, 26, 27]
  },
  [DOCUMENT_TYPES.PASSPORT]: {
    MIN: 7,
    MAX: 14
  }
};

export const CUIT_PREFIXES = [20, 23, 24, 25, 26, 27, 30, 33, 34];
export const CUIT_MIN_LENGTH = 11;

export const PROMOTER_ID_LENGTH = {
  MIN: 1,
  MAX: 8
};

export const DISCOUNT_RATE = {
  MIN: 0,
  MAX: 30
};
