import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { Promoter } from '~utils/types';
import { getCompleteName } from '~utils/general';
import ViewInput from '~components/ViewInput';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import CustomButton from '~components/CustomButton';
import { linkToRecord } from 'ra-core';
import { useHistory } from 'react-router';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';

import PromoterSwitchStatus from '../PromoterSwitchStatus';

interface Props {
  record: Promoter;
  basePath: string;
  id: string;
}

function ResponsiveList({ record, basePath, id }: Props) {
  const history = useHistory();
  const styles = useResponsiveListStyles();
  const t = useTranslate();
  const handleRowClick = useCallback(() => history.push(linkToRecord(basePath, id, 'show')), [
    basePath,
    history,
    id
  ]);

  return (
    <div className={styles.container}>
      <ResponsiveListWrapper onClick={handleRowClick}>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.promoters.fields.firstName')}
            valueClassName={styles.value}
            value={getCompleteName(record)}
            className={styles.columnLarge}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.promoters.fields.promoter')}
            value={`${record.id}`}
            className={styles.columnShort}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            className={styles.columnLarge}
            labelClassName={styles.label}
            label={t('resources.promoters.fields.filial')}
            value={record.filialDescription}
          />
          <PromoterSwitchStatus promoter={record} />
        </div>
      </ResponsiveListWrapper>
      <div className={`${styles.row} ${styles.centered}`}>
        <CustomButton onClick={handleRowClick} label={t('resources.promoters.editPromoter')} />
      </div>
    </div>
  );
}

export default ResponsiveList;
