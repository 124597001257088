/* eslint-disable complexity */
import React from 'react';
import cn from 'classnames';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import CustomButton from '~components/CustomButton';
import { ReactComponent as VBLogo } from '~assets/logo_sid.svg';
import { IVBSteps } from '~utils/interfaces';

import { useStyles } from '../styles';

import Point from './Point';
import PointWrapper from './PointWrapper';

interface Props {
  vbSteps: IVBSteps | undefined;
  onClick: React.MouseEventHandler;
}

function VBModal({ vbSteps, onClick }: Props) {
  const styles = useStyles();

  const capDocumentoIniciada = vbSteps?.capturaDocumento.iniciado;
  const capDocumentoTerminada = vbSteps?.capturaDocumento.terminado;
  const capDocumentoConError = vbSteps?.capturaDocumento.terminadoConError;
  const capDocumentoNoVigente = vbSteps?.capturaDocumento.error === 'noVigente';
  const capDocumentoVigente = capDocumentoIniciada && capDocumentoTerminada && !capDocumentoConError;

  const capManualIniciada = vbSteps?.capturaManual.iniciado;
  const capManualTerminada = vbSteps?.capturaManual.terminado;
  const capManualNoVigente = vbSteps?.capturaManual.terminadoConError;
  const capManualVigente = capManualIniciada && capManualTerminada && !capManualNoVigente;

  const pruebaDeVidaIniciada = vbSteps?.pruebaDeVida.iniciado;
  const pruebaDeVidaTerminada = vbSteps?.pruebaDeVida.terminado;
  const pruebaDeVidaConError = vbSteps?.pruebaDeVida.terminadoConError;
  const pruebaDeVidaNoHit = vbSteps?.pruebaDeVida.error === 'desision: NO HIT';
  const pruebaDeVidaHit = pruebaDeVidaIniciada && pruebaDeVidaTerminada && !pruebaDeVidaConError;

  const valiIdentidadIniciada = vbSteps?.validacionIdentidad.iniciado;
  const valiIdentidadTerminada = vbSteps?.validacionIdentidad.terminado;
  const valiIdentidadConError = vbSteps?.validacionIdentidad.terminadoConError;
  const valiIdentidadNoHit = vbSteps?.validacionIdentidad.error === 'decision: NOT FOUND';
  const valiIdentidadHit = valiIdentidadIniciada && valiIdentidadTerminada && !valiIdentidadConError;

  const cpBiometrica = pruebaDeVidaHit || pruebaDeVidaNoHit || valiIdentidadHit || valiIdentidadNoHit;

  const capDocumentoMotivo = vbSteps?.capturaDocumento.error;
  const capManualMotivo = vbSteps?.capturaManual.error;
  const pruebaVidaMotivo = vbSteps?.pruebaDeVida.error;
  const validIdentidadMotivo = vbSteps?.validacionIdentidad.error;

  return (
    <ModalInfoInnerContainer loading={false}>
      <label className={styles.labelContainer}>
        El prospecto utiliz&oacute; el sistema de validaci&oacute;n biom&eacute;trica obteniendo los
        siguientes resultados.
      </label>

      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.left)}>
          <VBLogo height={300} width={300} />
        </div>

        {/* CAPTURA DOCUMENTO */}
        <div className={cn(styles.right)}>
          {capDocumentoIniciada && (
            <PointWrapper text="Captura de DNI" reason={capDocumentoMotivo}>
              {capDocumentoTerminada ? (
                <Point condition={capDocumentoVigente} />
              ) : (
                <div className={styles.label}>En proceso</div>
              )}
            </PointWrapper>
          )}

          {/* CAPTURA MANUAL */}
          {capManualIniciada && (
            <PointWrapper text="Ingreso manual de DNI " reason={capManualMotivo}>
              {capManualTerminada ? (
                <Point condition={capManualVigente} />
              ) : (
                <div className={styles.label}>En proceso</div>
              )}
            </PointWrapper>
          )}

          {/* VALIDACION BIOGRAFICA */}
          {capDocumentoTerminada && (capDocumentoVigente || capDocumentoNoVigente) && (
            <PointWrapper text="Validaci&oacute;n biogr&aacute;fica">
              <Point color="success" />
            </PointWrapper>
          )}

          {capManualTerminada && capManualVigente && (
            <PointWrapper text="Validaci&oacute;n biogr&aacute;fica">
              <Point color="success" />
            </PointWrapper>
          )}

          {capManualTerminada && capManualNoVigente && (
            <PointWrapper text="Validaci&oacute;n biogr&aacute;fica">
              <Point color="danger" />
            </PointWrapper>
          )}

          {((capDocumentoIniciada && !capDocumentoTerminada) ||
            (capManualIniciada && !capManualTerminada)) && (
            <PointWrapper text="Validaci&oacute;n biogr&aacute;fica">
              <div className={styles.label}>En proceso</div>
            </PointWrapper>
          )}

          {/* CAPTURA BIOMETRICA - IDENTIDAD VALIDADA */}
          {pruebaDeVidaTerminada && (
            <>
              <PointWrapper text="Captura biom&eacute;trica" reason={pruebaVidaMotivo}>
                <Point condition={cpBiometrica} />
              </PointWrapper>
              <PointWrapper text="Identidad validada" reason={pruebaVidaMotivo}>
                <Point condition={pruebaDeVidaHit} />
              </PointWrapper>
            </>
          )}

          {valiIdentidadTerminada && (
            <>
              <PointWrapper text="Captura biom&eacute;trica" reason={pruebaVidaMotivo}>
                <Point condition={cpBiometrica} />
              </PointWrapper>
              <PointWrapper text="Identidad validada" reason={pruebaVidaMotivo}>
                <Point condition={valiIdentidadHit} />
              </PointWrapper>
            </>
          )}

          {((pruebaDeVidaIniciada && !pruebaDeVidaTerminada) ||
            (valiIdentidadIniciada && !valiIdentidadTerminada)) && (
            <PointWrapper text="Captura biom&eacute;trica" reason={validIdentidadMotivo}>
              <div className={styles.label}>En proceso</div>
            </PointWrapper>
          )}
        </div>

        <CustomButton className={cn(styles.button, styles.center)} onClick={onClick} label="Aceptar" />
      </div>
    </ModalInfoInnerContainer>
  );
}

export default VBModal;
