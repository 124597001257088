import { getApi as api, getApi, removeTokenHeader, setTokenHeader } from '~config/api';
import { ApiDataErrorArray } from '~config/providers/utils';
import { AUTH_ERRORS } from '~config/providers/constants';
import { Promoter } from '~utils/types';
import { ApiResponse } from 'apisauce';
import { ROLE_TYPES } from '~constants/promoters';

import { ClientStorageServiceTs as ClientStorageService } from './ClientStorageServiceToTs';

interface PromoterUser extends Promoter {
  forToken: string;
}

const authToken = {
  get: () => {
    getApi();
    const token = ClientStorageService.getToken();
    return token ? token : null;
  },
  set: (token: string) => {
    getApi();
    setTokenHeader(token);
    ClientStorageService.setToken(token);
  },
  remove: () => {
    getApi();
    removeTokenHeader();
    ClientStorageService.removeToken();
  }
};

const promoterUser = {
  get: () => {
    const pu = ClientStorageService.getPromoterUser();
    return pu ? (pu as PromoterUser) : null;
  },
  set: (pu: PromoterUser) => {
    ClientStorageService.setPromoterUser(pu);
  },
  setFromPromoterAddingToken: (promoter: Promoter, token: string) => {
    const newPromoterUser: PromoterUser = promoter as PromoterUser;
    newPromoterUser.forToken = token;
    promoterUser.set(newPromoterUser);
  },
  remove: () => {
    ClientStorageService.removePromoterUser();
  }
};

const AUT_ERRORS_RESTRICTED = 'errors.restricted';
const AUT_ERRORS_UNAVAILABLE = 'errors.unavailable';

// se define la presente función ya que la presente estructura del login requiere multiples reject's con strings y no con errores
const newRejectPromise: (reason: string) => Promise<never> = (reason: string) =>
  // eslint-disable-next-line prefer-promise-reject-errors
  Promise.reject(reason);

const AuthService = {
  me: () => api().get<Promoter, ApiDataErrorArray>('me')
};

const logout: () => Promise<void> = () => {
  authToken.remove();
  promoterUser.remove();

  return Promise.resolve();
};

const logoutSync: () => Promise<void> = async () => {
  await logout();
};

const getPromoterDataPromise = () => {
  const SUPER_ADMIN = 'superadmin';
  const isPromoterInactive = (data: ApiDataErrorArray | undefined) =>
    data?.errors?.[0]?.code ? data?.errors?.[0]?.code === AUTH_ERRORS.e4303.code : undefined;

  const isPromoterWithAnyRol = (promoter: Promoter | undefined) =>
    promoter &&
    (promoter.role === ROLE_TYPES.Adminstrador ||
      promoter.role === SUPER_ADMIN ||
      promoter.subroles?.length > 0);

  const rejectErrorMessage = (
    isInactive: boolean | undefined,
    hasAnyRol: boolean | undefined,
    errorsOrFault: boolean | undefined,
    response: ApiResponse<Promoter, ApiDataErrorArray>
  ) => {
    if (errorsOrFault) {
      return AUT_ERRORS_RESTRICTED;
    } else if (isInactive) {
      return AUTH_ERRORS.e4303.message;
    } else if (hasAnyRol === false) {
      return AUTH_ERRORS.e4305.message;
    } else if (!response.ok) {
      return AUT_ERRORS_UNAVAILABLE;
    }

    return AUT_ERRORS_RESTRICTED;
  };

  return new Promise<Promoter>((resolve, reject) => {
    AuthService.me()
      .then((response: ApiResponse<Promoter, ApiDataErrorArray>) => {
        const data = response?.data as any;
        const promoter = response?.data as Promoter | undefined;
        const isInactive = isPromoterInactive(data);
        const hasAnyRol = isPromoterWithAnyRol(promoter);
        const errorsOrFault = data?.errors || data?.fault;

        if (!promoter || isInactive || !hasAnyRol || errorsOrFault || !response.ok) {
          // hay algún error, no se puede recuperar el promotor;
          const rejectMessage = rejectErrorMessage(isInactive, hasAnyRol, errorsOrFault, response);

          reject(rejectMessage);
          return;
        }

        // pPromoter is active and valid
        resolve(promoter);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const loadPromoter = (token: string) => {
  promoterUser.remove();

  return getPromoterDataPromise().then((promoter: Promoter) => {
    promoterUser.setFromPromoterAddingToken(promoter, token);

    return promoter;
  });
};

const login = (token: string) => {
  promoterUser.remove();
  authToken.set(token);

  return loadPromoter(token);
};

const promoterGetForTokenOrRemove = (token: string) => {
  const currentPU = promoterUser.get();
  let result: PromoterUser | undefined = undefined;

  if (currentPU && currentPU.forToken === token) {
    result = currentPU;
  } else {
    promoterUser.remove();
  }

  return result ? Promise.resolve(result as Promoter) : loadPromoter(token);
};

// eslint-disable-next-line id-length
const resetTokenHeaderFromCurrentToken = () => {
  const currentToken = authToken.get();
  if (currentToken) {
    setTokenHeader(currentToken);
    return true;
  }

  removeTokenHeader();
  return false;
};

const promoterGetCurrent = () =>
  promoterUser.get()
    ? promoterGetForTokenOrRemove(authToken.get()).catch(() => newRejectPromise(AUT_ERRORS_RESTRICTED))
    : newRejectPromise(AUT_ERRORS_RESTRICTED);

export const authTokenSet = authToken.set;
export {
  logout,
  logoutSync,
  login,
  resetTokenHeaderFromCurrentToken,
  promoterGetCurrent,
  AUT_ERRORS_RESTRICTED,
  AUT_ERRORS_UNAVAILABLE,
  newRejectPromise
};
