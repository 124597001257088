export const sexos = {
  FEMENINO: 'female',
  MASCULINO: 'male',
  INDEFINIDO: 'nonBinary'
};

export const paises = {
  AR: 'Argentina',
  VE: 'Venezuela',
  BR: 'Brasil',
  UY: 'Uruguay',
  CL: 'Chile',
  CO: 'Colombia',
  PY: 'Paraguay',
  PE: 'Perú',
  BO: 'Bolivia',
  EC: 'Ecuador',
  AD: 'Andorra',
  AE: 'E.A.U',
  AF: 'Afganistán',
  AG: 'Antigua/Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Antillas hol.',
  AO: 'Angola',
  AQ: 'Antártida',
  AS: 'Samoa americana',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaiyán',
  BA: 'Bosnia-Herz.',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Bélgica',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrein',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermudas',
  BN: 'Brunei Darussal',
  BS: 'Bahamas',
  BT: 'Bután',
  BV: 'Islas Bouvet',
  BW: 'Botsuana',
  BY: 'Bielorrusia',
  BZ: 'Belice',
  CA: 'Canadá',
  CC: 'Islas Coco',
  CD: 'República Congo',
  CF: 'Rep.Centroafr.',
  CG: 'Congo',
  CH: 'Suiza',
  CI: 'Costa de Marfil',
  CK: 'Islas Cook',
  CM: 'Camerún',
  CN: 'China',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CX: 'Islas Navidad',
  CY: 'Chipre',
  CZ: 'República Checa',
  DE: 'Alemania',
  DJ: 'Yibuti',
  DK: 'Dinamarca',
  DM: 'Dominica',
  DO: 'Rep.Dominicana',
  DZ: 'Argelia',
  EE: 'Estonia',
  EG: 'Egipto',
  EH: 'Sáhara occid.',
  ER: 'Eritrea',
  ES: 'España',
  ET: 'Etiopía',
  FI: 'Finlandia',
  FJ: 'Fiji',
  FM: 'Micronesia',
  FO: 'Islas Feroé',
  FR: 'Francia',
  GA: 'Gabón',
  GB: 'Reino Unido',
  GD: 'Granada',
  GE: 'Georgia',
  GF: 'Guayana Franc.',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Groenlandia',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadalupe',
  GQ: 'Guinea Ecuator.',
  GR: 'Grecia',
  GS: 'Isl.S.Sandwich',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'de Heard/Is.Mc.',
  HN: 'Honduras',
  HR: 'Croacia',
  HT: 'Haití',
  HU: 'Hungría',
  ID: 'Indonesia',
  IE: 'Irlanda',
  IL: 'Israel',
  IN: 'India',
  IO: 'Terr.br.Oc.Ind.',
  IQ: 'Irak',
  IR: 'Irán',
  IS: 'Islandia',
  IT: 'Italia',
  JM: 'Jamaica',
  JO: 'Jordania',
  JP: 'Japón',
  KE: 'Kenia',
  KG: 'Kirguizistán',
  KH: 'Camboya',
  KI: 'Kiribati',
  KM: 'Comoras',
  KN: 'St.Chr.,Nevis',
  KP: 'Corea del Norte',
  KR: 'Corea del Sur',
  KW: 'Kuwait',
  KY: 'Islas Caimán',
  KZ: 'Kazajstán',
  LA: 'Laos',
  LB: 'Líbano',
  LC: 'Santa Lucía',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  LV: 'Letonia',
  LY: 'Libia',
  MA: 'Marruecos',
  MC: 'Mónaco',
  MD: 'Moldavia',
  MG: 'Madagascar',
  MH: 'Islas Marshall',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macau',
  MP: 'Isla N.Mariana',
  MQ: 'Martinicas',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauricio (Isl.)',
  MV: 'Islas Maldivas',
  MW: 'Malaui',
  MX: 'México',
  MY: 'Malasia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'Nueva Caledonia',
  NE: 'Níger',
  NF: 'Islas Norfolk',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Países Bajos',
  NO: 'Noruega',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Islas Niue',
  NZ: 'Nueva Zelanda',
  OM: 'Omán',
  PA: 'Panamá',
  PF: 'Polinesia fran.',
  PG: 'PapuaNvaGuinea',
  PH: 'Filipinas',
  PK: 'Pakistán',
  PL: 'Polonia',
  PM: 'StPier.,Miquel.',
  PN: 'Islas Pitcairn',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PW: 'Palau',
  QA: 'Qatar',
  RE: 'Reunión',
  RO: 'Rumanía',
  RU: 'Federación Rusa',
  RW: 'Ruanda',
  SA: 'Arabia Saudí',
  SB: 'Islas Salomón',
  SC: 'Seychelles',
  SD: 'Sudán',
  SE: 'Suecia',
  SG: 'Singapur',
  SH: 'Santa Helena',
  SI: 'Eslovenia',
  SJ: 'Svalbard',
  SK: 'Eslovaquia',
  SL: 'Sierra Leona',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinám',
  ST: 'S.Tomé,Príncipe',
  SV: 'El Salvador',
  SY: 'Siria',
  SZ: 'Swazilandia',
  TC: 'de Turks,-Caic.',
  TD: 'Chad',
  TF: 'French S.Territ',
  TG: 'Togo',
  TH: 'Tailandia',
  TJ: 'Tadjikistán',
  TK: 'Islas Tokelau',
  TM: 'Turkmenistán',
  TN: 'Túnez',
  TO: 'Tonga',
  TP: 'Timor oriental',
  TR: 'Turquía',
  TT: 'Trinidad,Tobago',
  TV: 'Tuvalú',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ucrania',
  UG: 'Uganda',
  UM: 'de IslMinorOutl',
  US: 'EE.UU.',
  UZ: 'Uzbekistán',
  VA: 'Ciudad Vaticano',
  VC: 'San Vicente',
  VG: 'Isl.Vírgenes GB',
  VI: 'Is.Vírgenes USA',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis,Futuna',
  WS: 'Samoa Occident.',
  YE: 'Yemen',
  YT: 'Mayotte',
  YU: 'Yugoslavia',
  ZA: 'Sudáfrica',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};
