import { useMediaQuery } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import { Button } from 'react-admin';
import cn from 'classnames';

import { useStyles } from './styles';

interface Props {
  label?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isWhiteButton?: boolean;
}

const CustomButton = ({ label, onClick, disabled, isWhiteButton = false }: Props) => {
  const isSmall: any = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const styles = useStyles();

  return (
    <>
      {isSmall ? (
        <Button
          data-cy="submitAsociateSapData"
          className={cn({ [styles.whiteButtonSmall]: isWhiteButton, [styles.buttonSmall]: !isWhiteButton })}
          disabled={disabled}
          onClick={onClick}
        >
          <span>{label}</span>
        </Button>
      ) : (
        <>
          <Button
            data-cy="submitAsociateSapData"
            className={cn({ [styles.whiteButton]: isWhiteButton, [styles.button]: !isWhiteButton })}
            disabled={disabled}
            onClick={onClick}
          >
            <span>{label}</span>
          </Button>
        </>
      )}
    </>
  );
};

export default CustomButton;
