export const getAffiliationType = (value: string) => `resources.on.fields.affiliationTypes.${value}`;

export const getAffiliationTypeAutogestion = (value: string) => `resources.on.fields.affiliationTypesAutogestion.${value}`;

export const getApplicationType = (value: string) => `resources.on.fields.applicationTypes.${value}`;

export const getHiringModeTypes = (value: string) => `resources.on.fields.hiringModeTypes.${value}`;

export const getHiringModeTypesAutogestion = (value: string) => `resources.on.fields.hiringModeTypesAutogestion.${value}`;

export const getDocumentType = (value: string) => `resources.on.fields.documentTypes.${value}`;

export const getIsPaymentResponsibleType = (value: boolean) =>
  `resources.on.fields.isPaymentResponsibleTypes.${value}`;
