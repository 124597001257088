import React from 'react';
import { useTranslate, TextInput, SelectInput } from 'react-admin';
import CustomFilter from '~components/CustomFilter';
import { convertCodDescrCollAsArray, STATUS_FILTER } from '~constants/solicitudesF2';
import { SOLICITUD_F2_FILTER } from '~screens/SolicitudesF2/constants';

export default function SolicitudF2Filter(props: object) {
  const t = useTranslate();

  const solicitudesStatusTypes = convertCodDescrCollAsArray(STATUS_FILTER, cd => ({
    id: cd.COD,
    name: cd.DESCRIPCION
  }));
  return (
    <CustomFilter {...props}>
      <TextInput
        label={t('resources.solicitudF2.fields.applicationId')}
        source={SOLICITUD_F2_FILTER.applicationId}
      />
      <TextInput
        label={t('resources.solicitudF2.fields.companyName')}
        source={SOLICITUD_F2_FILTER.companyName}
      />
      <TextInput label={t('resources.solicitudF2.fields.cuit')} source={SOLICITUD_F2_FILTER.cuit} />
      <TextInput
        label={t('resources.solicitudF2.fields.promoterId')}
        source={SOLICITUD_F2_FILTER.promoterId}
      />
      <TextInput label={t('resources.solicitudF2.fields.firstName')} source={SOLICITUD_F2_FILTER.firstName} />
      <TextInput label={t('resources.solicitudF2.fields.lastName')} source={SOLICITUD_F2_FILTER.lastName} />
      <TextInput
        label={t('resources.solicitudF2.fields.documentNumber')}
        source={SOLICITUD_F2_FILTER.documentNumber}
      />
      <SelectInput
        label={t('resources.solicitudF2.fields.status')}
        source="state"
        choices={solicitudesStatusTypes}
      />
    </CustomFilter>
  );
}
