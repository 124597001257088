import { makeStyles } from '@material-ui/core/styles';
import { GRAY, LIGHT_GRAY, PRIMARY_BLUE, SECONDARY_BLUE, TERTIARY_BLUE, WHITE } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  title: {
    color: GRAY
  },
  container: {
    alignItems: 'center',
    backgroundColor: LIGHT_GRAY,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: PRIMARY_BLUE,
    borderRadius: '4px',
    padding: '10px 20px',
    '& span': {
      color: WHITE,
      fontWeight: 500,
      padding: 0
    },
    '& svg': {
      marginRight: '5px',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    '&:hover': {
      backgroundColor: TERTIARY_BLUE,
      transform: 'scale(1.03, 1.03)'
    },
    '&:focus': {
      backgroundColor: PRIMARY_BLUE,
      boxShadow: `0 0 4px 0 ${SECONDARY_BLUE}`
    },
    '&:disabled': {
      backgroundColor: GRAY,
      pointerEvents: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '50%'
    }
  }
}));
