import React, { useCallback, ReactNode, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { Modal, DialogActions, IconButton } from '@material-ui/core';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '~assets/ic_cross.svg';
import CustomButton from '~components/CustomButton';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { useDispatch, useSelector } from '~contexts/ModalContext';

import { useStyles } from './styles';
import Loading from './components/loading';

interface Props {
  children?: ReactNode;
  title?: string;
  subtitle?: string;
  loading?: boolean;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  showCloseIcon?: boolean;
  modalName: string;
  titleClassName?: string;
  onClose?: () => void;
  modalClassname?: string;
  contentClassName?: string;
  isInfo?: boolean;
}

function CustomModalF2({
  title,
  subtitle,
  modalName,
  children,
  loading,
  onConfirm,
  confirmText,
  cancelText,
  showCloseIcon,
  titleClassName,
  onClose,
  modalClassname = '',
  contentClassName = '',
  isInfo = false
}: Props) {
  const styles = useStyles({ titleClassName });
  const dispatch = useDispatch();
  const modal = useSelector(state => state);

  const handleClose = useCallback(() => {
    dispatch(modalActions.closeModal(modalName));
    onClose?.();
  }, [dispatch, modalName, onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm?.();
  }, [onConfirm]);

  const handleCloseClick = (reason: string) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };
  const t = useTranslate();
  const isOpen = useMemo(() => modal[modalName], [modal, modalName]);
  return isOpen ? (
    <Modal
      className={styles.modalContainer}
      open={isOpen}
      onClose={() => {
        handleCloseClick('backdropClick');
      }}
    >
      <div
        className={cn(styles.commonModal, modalClassname, {
          [styles.modal]: !isInfo,
          [styles.modalInfo]: isInfo
        })}
      >
        {showCloseIcon && (
          <IconButton className={styles.closeIconContainer} onClick={handleClose}>
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
        )}
        {title && (
          <h2
            className={cn(styles.commonTitle, titleClassName, {
              [styles.title]: !isInfo,
              [styles.titleInfo]: isInfo
            })}
          >
            {title}
          </h2>
        )}
        {subtitle && <h2 className={cn(styles.commonTitle, titleClassName, styles.subtitle)}>{subtitle}</h2>}
        <div className={cn(contentClassName, { [styles.modalInfoInnerContainer]: isInfo })}>{children}</div>
        {onConfirm && (
          <>
            {loading && (
              <div className={styles.loadingContainer}>
                <Loading />
              </div>
            )}
            <DialogActions className={styles.actionsContainer}>
              <CustomButton
                disabled={loading}
                onClick={handleConfirm}
                autoFocus
                label={confirmText || t('general.confirm')}
              />
              <CustomButton
                className={styles.cancelButton}
                disabled={false}
                onClick={handleClose}
                label={cancelText || t('general.cancel')}
              />
            </DialogActions>
          </>
        )}
      </div>
    </Modal>
  ) : null;
}

CustomModalF2.defaultProps = {
  disabled: false,
  showCloseIcon: true
};

export default CustomModalF2;
