import React, { ChangeEvent } from 'react';
import { SelectInput } from 'react-admin';
import { i18nStringType, GenericObjectInterface } from '~utils/types';
import { useEditInput } from '~hooks/useEditInput';
import ViewInput from '~components/ViewInput';
import { getObjectValueFromString } from '~utils/object';

export type InputSelectValidatorType =
  | ((value: any) => 'validation.required' | undefined)
  | ((value: any) => any)[];

export type InputSelectorItemType = { id: string; name: any };
export type InputSelectorListType = InputSelectorItemType[];

export const newInputSelectorItem: (id: string, name: any) => InputSelectorItemType = (id, name) => ({
  id,
  name
});

interface Props {
  label?: i18nStringType;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  formClassName?: string;
  source?: string;
  record?: GenericObjectInterface<any>;
  variant?: string;
  choices: InputSelectorListType;
  defaultValue?: string | number;
  validate?: InputSelectValidatorType;
  isEdit?: boolean;
  onSetEditing?: () => void;
  specialFormatter?: Function;
  editEnabled?: boolean;
  onSelectedValueChange?: (value: string) => void;
  props?: object;
}

function InputSelect({
  containerClassName = '',
  className = '',
  source,
  choices,
  label,
  defaultValue,
  validate,
  isEdit = false,
  onSetEditing,
  variant,
  specialFormatter,
  editEnabled = false,
  onSelectedValueChange,
  disabled,
  ...props
}: Props) {
  const [enabled, handleEnableEdit] = useEditInput(onSetEditing);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const val = event?.target?.value;
    if (onSelectedValueChange) {
      onSelectedValueChange(val);
    }
  };

  const insulatedCypressElement = source ? `${source.split('.').pop()}-input` : undefined;

  return isEdit && !enabled ? (
    <ViewInput
      label={label}
      value={getObjectValueFromString(source!, props.record!) as string}
      isEditing={enabled}
      onEnableEdit={handleEnableEdit}
      specialFormatter={specialFormatter}
      editEnabled={!disabled && editEnabled}
    />
  ) : (
    <div className={`row full-width ${containerClassName}`}>
      <SelectInput
        className={className}
        disabled={disabled}
        source={source}
        choices={choices}
        label={label}
        defaultValue={defaultValue}
        validate={validate}
        variant={variant || 'standard'}
        onChange={handleSelectChange}
        data-cy={insulatedCypressElement}
        // value={selectedValue}
      />
    </div>
  );
}

export default InputSelect;
