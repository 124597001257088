import React from 'react';
import { useTranslate } from 'react-admin';
import ViewInput from '~components/ViewInput';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import {
  getApplicationType,
  getAffiliationType
} from '~screens/AffiliationRequests/components/AffiliationForm/utils';
import { useCommonStyles } from '~app/styles';
import { momentToStringShort } from '~utils/date';

function AltaDetails({ data }: any) {
  const t = useTranslate();
  const styles = useResponsiveListStyles();
  const commonStyles = useCommonStyles();

  return (
    <>
      <ResponsiveListWrapper>
        <h1 className={commonStyles.title}>{t('resources.altasmasivas.detail.title')}</h1>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.nroRegistro')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={data.nroRegistro}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.nombre')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={data.nombre}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.apellido')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={data.apellido}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.tipo')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={t(getAffiliationType(data.prospectTipo))}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.prospectTipoPlan')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={data.prospectTipoPlan}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.prospectPlan')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={data.prospectPlan}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.formulario')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={t(getApplicationType(data.prospectTipoFormulario))}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.altasEmail')}
            valueClassName={styles.value}
            className={styles.columnLarge}
            value={data.altasEmail}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.idSolicitud')}
            className={styles.columnShort}
            value={data.idSolcitudCreada}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.prospectVigencia')}
            valueClassName={styles.value}
            className={styles.columnShort}
            value={momentToStringShort(data.prospectVigencia)}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.altasmasivas.detail.observaciones')}
            valueClassName={styles.value}
            className={styles.columnLarge}
            value={data.observaciones}
          />
        </div>
      </ResponsiveListWrapper>
    </>
  );
}

export default AltaDetails;
