/* eslint-disable max-lines */
/* eslint-disable no-negated-condition */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable complexity */
import { GET_LIST, GET_ONE, CREATE, UPDATE } from 'react-admin';
import { trimTextFilter } from '~utils/strings';
// eslint-disable-next-line import/named, import/namespace
import { ErrorResponse } from '~utils/types';

import { getApi as api, createApi } from '../api';

import {
  RESOURCES_PATH,
  SPECIAL_QUERY_PARAMS,
  SEND_INITIAL_EMAIL,
  CREATE_APPLICATION,
  EDIT_APPLICATION,
  EVALUATE_APPLICATION,
  RESEND_VALIDATION_EMAIL,
  GET_HISTORIC_APPLICATION,
  GET_REJECTED_REASONS,
  TOGGLE_PROMOTER_ENABLE,
  GET_ONE_PROMOTER_LIST,
  UPDATE_ESTADO_BINARIA,
  CREAR_SOLICITUD_BINARIA,
  EDITAR_SOLICITUD_BINARIA,
  CREAR_SOLICITUD_ON,
  EDITAR_SOLICITUD_ON,
  EVALUATE_APPLICATION_ON,
  GET_HISTORIC_SOLICITUD_ON,
  SEND_INITIAL_EMAIL_ON,
  MODIFY_PROMOTER_SUBROL,
  CREAR_SOLICITUD_F2,
  UPDATE_SOLICITUD_F2,
  GET_HISTORIC_SOLICITUD_F2,
  SEND_INITIAL_EMAIL_F2,
  RESEND_VALIDATION_EMAIL_F2,
  EVALUATE_APPLICATION_F2,
  UPLOAD_FILE_APPLICATION,
  RESEND_VALIDATION_EMAIL_ON,
  GET_DATOS_BENEFICIARIO,
  GET_HISTORIC_APPLICATION_BINARIA,
  GET_ON_REJECTED_REASONS,
  DELETE_PROSPECT_ATTACHMENT,
  CREATE_PROMOTER,
  EDIT_PROMOTER,
  MASSIVE_UPLOAD,
  GET_DETALLE_MASSIVE_UPLOAD
} from './constants';
import {
  parseResponse,
  setListSort,
  denormalizeApplication,
  returnApiError,
  messageError,
  formatCreatePromoterBody,
  denormalizeMassiveUpload,
  messageBulkDetails
  // formatFilialAndCapFromPromoter
  // buildParams
} from './utils';
import { denormalizeSolicitudON } from './utilsOn';
import { denormalizeSolicitudF2 } from './utilsF2';
// eslint-disable-next-line max-statements
export default (type: string, resource: string, params: any) => {
  switch (type) {
    case GET_LIST: {
      const {
        pagination: { page, perPage },
        sort,
        filter
      } = params;
      return api()
        .get<any, ErrorResponse>(RESOURCES_PATH[resource], {
          page,
          limit: perPage,
          ...setListSort(sort),
          ...trimTextFilter(filter),
          ...SPECIAL_QUERY_PARAMS[resource]
        })
        .then(response => {
          if (response.ok) {
            return parseResponse(response);
          }
          return returnApiError(response);
        });
    }

    case GET_ONE_PROMOTER_LIST: {
      const { id, appId } = params;
      return api()
        .get<any, ErrorResponse>(RESOURCES_PATH.promoters, {
          page: 1,
          limit: 1,
          promoterId: id,
          applicationId: appId
        })
        .then(response => {
          if (response.ok) {
            if (response.data.page?.[0]?.id !== id) {
              return { error: 'errors.4304' };
            }
            return response.data;
          }
          return { error: 'errors.default' };
        });
    }

    case CREATE_PROMOTER: {
      return api()
        .post<any, ErrorResponse>(RESOURCES_PATH.promoters, {
          ...formatCreatePromoterBody(params),
          chanel: 'backoffice'
        })
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case EDIT_PROMOTER: {
      return api()
        .put<any, ErrorResponse>(`${RESOURCES_PATH.promoters}/${params.id}`, {
          ...formatCreatePromoterBody(params),
          chanel: 'backoffice'
        })
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case GET_ONE: {
      const { id } = params;
      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${id}`)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case CREATE: {
      return api()
        .post<any, ErrorResponse>(`${RESOURCES_PATH[resource]}`, params.data)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case UPDATE: {
      const { id, data } = params;

      return api()
        .put<any>(`${RESOURCES_PATH[resource]}/${id}`, data)
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case CREATE_APPLICATION: {
      const checkFile: any = params?.attachment;
      if (checkFile.length === 0) {
        return api()
          .post<any, ErrorResponse>(RESOURCES_PATH.affiliations, {
            ...denormalizeApplication(params),
            chanel: 'backoffice'
          })
          .then(response => {
            if (response.ok) {
              return { data: [] };
            }
            return returnApiError(response);
          });
      } else {
        return api()
          .post<any, ErrorResponse>(RESOURCES_PATH.affiliations, {
            ...denormalizeApplication(params),
            chanel: 'backoffice'
          })
          .then(async (response: any) => {
            if (response.ok) {
              const files: any = params?.attachment[0];
              const { id } = response?.data;
              for await (const element of files) {
                const { file } = element;
                const { query } = element;
                if (id && file) {
                  await api()
                    .get<any, ErrorResponse>(`${RESOURCES_PATH.affiliations}/${id}/signed_url`, query)
                    .then(async (res: any) => {
                      if (res.ok) {
                        const signedUrl = res.data.url;
                        await createApi(signedUrl)
                          .put('', file, { headers: { 'Content-Type': file.type } })
                          .then((responseUrl: any) => {
                            if (!responseUrl.ok) {
                              return returnApiError(responseUrl);
                            }
                          });
                      }
                    });
                }
              }
              return { data: [] };
            }
            return returnApiError(response);
          });
      }
    }
    case GET_DATOS_BENEFICIARIO: {
      const { values } = params;

      if (values.user.documentType === 'passport') {
        values.user.documentType = 'pas';
      }

      return api()
        .get<any, ErrorResponse>(`socio/datos/${values.user.documentType}/${values.user.documentNumber}`)
        .then(response => ({ response }));
    }

    case EDIT_APPLICATION: {
      return api()
        .put<any, ErrorResponse>(
          `${RESOURCES_PATH.affiliations}/${params.id}`,
          denormalizeApplication(params, true)
        )
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case SEND_INITIAL_EMAIL: {
      const { id } = params;

      return api()
        .post<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${id}/send_forms_url`, {})
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case RESEND_VALIDATION_EMAIL: {
      const { id } = params;

      return api()
        .post<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${id}/send_confirmation_email`)
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case EVALUATE_APPLICATION: {
      const { id, newState } = params;
      return api()
        .patch<any, ErrorResponse>(
          `${RESOURCES_PATH.affiliations}/${id}/evaluate`,
          { newState },
          { timeout: 120000 }
        )
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case EVALUATE_APPLICATION_ON: {
      const { id, newState } = params;
      return api()
        .patch<any, ErrorResponse>(`${RESOURCES_PATH.on}/${id}/estado`, { newState })
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case GET_HISTORIC_APPLICATION: {
      const { id } = params;

      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.affiliations}/${id}/audits`)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case GET_HISTORIC_APPLICATION_BINARIA: {
      const { id } = params;

      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.binaria}/${id}/audits`)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case GET_REJECTED_REASONS: {
      const { id } = params;

      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.affiliations}/${id}/rejected_reasons`)
        .then((response: any) => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case GET_ON_REJECTED_REASONS: {
      const { id } = params;

      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.on}/${id}/datos_incorrectos`)
        .then((response: any) => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case TOGGLE_PROMOTER_ENABLE: {
      const { id } = params;

      return api()
        .patch<any, ErrorResponse>(`${RESOURCES_PATH.promoters}/${id}/active`, {})
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }

    case UPDATE_ESTADO_BINARIA: {
      const { id, newState } = params;
      return api()
        .put<any, ErrorResponse>(
          `${RESOURCES_PATH.binaria}/${id}/updateEstado`,
          {},
          { params: { state: newState } }
        )
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case EDITAR_SOLICITUD_BINARIA: {
      return api()
        .put<any, ErrorResponse>(`${RESOURCES_PATH.binaria}/${params.id}`, params)
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case CREAR_SOLICITUD_BINARIA: {
      return api()
        .post<any, ErrorResponse>(RESOURCES_PATH.binaria, {
          ...params
        })
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return messageError(response);
        });
    }

    case CREAR_SOLICITUD_ON: {
      return api()
        .post<any, ErrorResponse>(RESOURCES_PATH.on, {
          ...denormalizeSolicitudON(params),
          chanel: 'backoffice',
          /* eslint-disable @typescript-eslint/camelcase */
          company_plan: '7-700'
        })
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }

    case EDITAR_SOLICITUD_ON: {
      return api()
        .put<any, ErrorResponse>(`${RESOURCES_PATH.on}/${params.id}`, denormalizeSolicitudON(params, true))
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    case GET_HISTORIC_SOLICITUD_ON: {
      const { id } = params;
      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.on}/${id}/audits`)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }
    case SEND_INITIAL_EMAIL_ON: {
      const { id } = params;

      return api()
        .post<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${id}/prospect/mail/initial`, {})
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    case RESEND_VALIDATION_EMAIL_ON: {
      const { id } = params;

      return api()
        .post<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${id}/prospect/mail/confirmation`)
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    // F2 begin
    case CREAR_SOLICITUD_F2: {
      return api()
        .post<any, ErrorResponse>(RESOURCES_PATH.f2, denormalizeSolicitudF2(params))
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }
    case UPDATE_SOLICITUD_F2: {
      return api()
        .put<any, ErrorResponse>(`${RESOURCES_PATH.f2}/${params.id}`, denormalizeSolicitudF2(params, true))
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    case GET_HISTORIC_SOLICITUD_F2: {
      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.f2}/${params.id}/audits`)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }
    case SEND_INITIAL_EMAIL_F2: {
      return api()
        .put<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${params.id}/attorney/mail/initial`, {})
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    // FIXME F2 eliminar emil validacion
    case RESEND_VALIDATION_EMAIL_F2: {
      return api()
        .put<any, ErrorResponse>(`${RESOURCES_PATH[resource]}/${params.id}/prospect/mail/confirmation`)
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    case EVALUATE_APPLICATION_F2: {
      return api()
        .patch<any, ErrorResponse>(
          `${RESOURCES_PATH.f2}/${params.id}/evaluation`,
          {
            state: params.newState
          },
          { timeout: 120000 }
        )
        .then(response => {
          if (response.ok) {
            return { data: [] };
          }
          return returnApiError(response);
        });
    }
    // F2 begin
    case MODIFY_PROMOTER_SUBROL: {
      const { subroles } = params;
      return api()
        .patch<any, ErrorResponse>(`${RESOURCES_PATH.promoters}/${params.id}/subroles`, { ...subroles })
        .then(response => {
          if (response.ok) {
            return true;
          }
          return returnApiError(response);
        });
    }
    case UPLOAD_FILE_APPLICATION: {
      const { query, url, file } = params;
      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.affiliations}/${url.applicationId}/signed_url`, query)
        .then((response: any) => {
          if (response.ok) {
            const signedUrl = response.data.url;
            return createApi(signedUrl).put('', file, { headers: { 'Content-Type': file.type } });
          }
          return returnApiError(response);
        })
        .then((googleAPIResponse: any) => ({ ok: googleAPIResponse.ok }))
        .catch(err => returnApiError(err));
    }
    case DELETE_PROSPECT_ATTACHMENT: {
      const { url, query } = params;
      return api()
        .delete<any, ErrorResponse>(
          `${RESOURCES_PATH.affiliations}/${url.applicationId}/prospect_attachmets`,
          query
        )
        .then((response: any) => response.ok)
        .catch(err => returnApiError(err));
    }
    case MASSIVE_UPLOAD: {
      return api()
        .post<any, ErrorResponse | any>(
          RESOURCES_PATH.altasmasivas,
          JSON.stringify(denormalizeMassiveUpload(params)),
          { timeout: 120000 }
        )
        .then(response => {
          if (response.ok) {
            return response;
          }
          return messageBulkDetails(response);
        });
    }
    case GET_DETALLE_MASSIVE_UPLOAD: {
      return api()
        .get<any, ErrorResponse>(`${RESOURCES_PATH.altasmasivas}/${params}`)
        .then(response => {
          if (response.ok) {
            return { data: response.data };
          }
          return returnApiError(response);
        });
    }
    default:
      throw new Error('general.error');
  }
};
