import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { RESEND_VALIDATION_EMAIL_ON, SEND_INITIAL_EMAIL_ON } from '~config/providers/constants';
import { SOLICITUDES_ON_REQUEST_STATUS } from '~constants/solicitudesON';
import { getSendEmailRequestPayload } from '~screens/SolicitudesON/utils';
import { GenericObjectInterface } from '~utils/types';

export const SOLICITUDES_ON_REQUESTS_FIELDS = {
  id: 'id',
  promoterId: 'promoterId',
  affiliationType: 'affiliationType',
  applicationType: 'applicationType',
  planValidityDate: 'planValidityDate',
  state: 'state',
  firstName: 'user.firstName',
  documentType: 'user.documentType',
  documentNumber: 'user.documentNumber',
  lastName: 'user.lastName',
  userEmail: 'user.email',
  phoneNumber: 'user.phoneNumber',
  companyPlan: 'companyPlan',
  hiringMode: 'hiringMode',
  companyName: 'companyName',
  companyCuit: 'companyCuit',
  isPaymentResponsible: 'isPaymentResponsible'
};

export const SOLICITUD_ON_FILTER = {
  firstName: 'firstName',
  lastName: 'lastName',
  documentNumber: 'documentNumber',
  promoterId: 'promoterId',
  id: 'id',
  cuitEmpresa: 'companyCuit'
};

export const SEND_INITIAL_EMAIL_CONFIG = {
  requestType: SEND_INITIAL_EMAIL_ON,
  requestPayload: getSendEmailRequestPayload,
  modalName: MODAL_NAMES.INITIAL_EMAIL,
  successMessageNotify: 'resources.on.modals.initialEmail.success'
};

export const RESEND_VALIDATION_EMAIL_CONFIG = {
  requestType: RESEND_VALIDATION_EMAIL_ON,
  requestPayload: getSendEmailRequestPayload,
  modalName: MODAL_NAMES.USER_VALIDATION,
  successMessageNotify: 'resources.on.modals.userValidationEmail.success'
};

export const EMAIL_CONFIG: GenericObjectInterface<{
  requestType: string;
  requestPayload: any;
  modalName: string;
  successMessageNotify: string;
}> = {
  [SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING]: SEND_INITIAL_EMAIL_CONFIG,
  [SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_SENT]: SEND_INITIAL_EMAIL_CONFIG,
  [SOLICITUDES_ON_REQUEST_STATUS.FORM_LINK_OPENED]: SEND_INITIAL_EMAIL_CONFIG,
  [SOLICITUDES_ON_REQUEST_STATUS.PENDING_USER_VALIDATION]: RESEND_VALIDATION_EMAIL_CONFIG
};
