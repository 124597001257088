import React from 'react';
import { Modal, IconButton } from '@material-ui/core';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '~assets/ic_cross.svg';
// import AltaMasivaCreate from '~screens/AltasMasivas/componets/AltaMasivaCreate';

import { useStyles } from './styles';

interface Props {
  touched: boolean;
  callback: any;
  children?: any;
  showCloseIcon?: boolean;
}

function MasiveUploadPopUp({ touched, callback, children, showCloseIcon = true }: Props) {
  const styles = useStyles();
  const isInfo = false;

  return (
    <div className={styles.modalContainer}>
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={styles.modal}
          open={touched}
          closeAfterTransition
          BackdropProps={{ style: { opacity: 0.3 } }}
        >
          <div
            className={cn(styles.commonModal, '', {
              [styles.modal]: !isInfo,
              [styles.modalInfo]: isInfo
            })}
          >
            {showCloseIcon && (
              <IconButton
                className={styles.closeIconContainer}
                onClick={() => {
                  callback(false);
                }}
              >
                <CloseIcon className={styles.closeIcon} />
              </IconButton>
            )}
            {children}
          </div>
        </Modal>
      </>
    </div>
  );
}

export default MasiveUploadPopUp;
