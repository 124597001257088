import React, { useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { SolicitudBinariaRequest, Nullable } from '~utils/types';
import useModal from '~hooks/useModal';
import ViewInput from '~components/ViewInput';
import CustomButton from '~components/CustomButton';
import FileValue from '~components/FileValue';
import { STATES } from '~constants/binaria';
import CustomModal from '~components/CustomModal';
import cn from 'classnames';
import { useCommonStyles } from '~app/styles';

import { isPendienteProm, evaluatePayload, isPendienteProspect } from '../helperFunctions';
import { useStyles } from '../binaria-styles';

interface Props {
  record: SolicitudBinariaRequest;
  id: number;
  resource: string;
  forms: any;
  documents: any;
  actions: any;
}

interface RequestPayload {
  id?: number;
  rejected?: boolean;
}

// eslint-disable-next-line complexity
const SolicitudBinariaFilled = ({ id, documents, forms, actions, record, resource }: Props) => {
  const t = useTranslate();
  const styles = useStyles();
  // const detailStyles = useDetailStyles();
  const commonStyles = useCommonStyles();
  // const { id, documents, forms, actions } = record;
  const hasForms = !!forms?.length;
  const hasDocuments = !!documents?.length;

  const pendingEvaluation = isPendienteProm(record.state);
  const pendingUserConfirmation = isPendienteProspect(record.state);
  const [statusToChange, setStatusToChange] = useState<Nullable<string>>(null);

  const buttons = new Map([
    [STATES.CANCELED, 'Cancelar solicitud'],
    [STATES.REVISION, 'Llevar a revisión'],
    [STATES.FINISHED, 'Dar alta proceso'],
    [STATES.RESEND_DOCUMENTATION, 'Reenviar documentación'],
    [STATES.PENDING_PROSPECT_VALIDATION, 'Reenviar email de Aceptación'],
    [STATES.FORM_REJECTED, 'Desistir solicitud']
  ]);

  const [modalData, dispatchRequest, loading, onOpenModal] = useModal<RequestPayload, RequestPayload>({
    resource,
    requestType: 'UPDATE_ESTADO_SOLICITUD_BINARIA',
    requestPayload: evaluatePayload,
    modalName: 'evaluate',
    successMessageNotify: `resources.affiliations.evaluate.modal.${statusToChange}.success`
  });

  const handleOpenModal = useCallback(
    (action: string) => {
      setStatusToChange(action);
      onOpenModal({ id, newState: action });
    },
    [id, onOpenModal]
  );

  return (
    <div className={`column full-width ${styles.spacing}`}>
      {hasForms && (
        // {!pendingUserConfirmation && hasForms && (
        <ViewInput
          className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
          label="Formularios"
          content={forms.map((form: any) => (
            <FileValue key={form.urlPublica} url={form.urlPublica} name={form.name} />
          ))}
        />
      )}
      {hasDocuments && (
        // {!pendingUserConfirmation && hasDocuments && (
        <ViewInput
          className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
          label="Documentos"
          content={documents?.map((document: any) => (
            <FileValue
              key={document.urlPublica}
              url={document.urlPublica}
              name={document.name}
              estado={document.estado}
            />
          ))}
        />
      )}
      {(pendingEvaluation || pendingUserConfirmation) && (
        <>
          {actions?.map((action: any) => (
            <CustomButton
              className={`${styles.buttonState} ${styles[`boton${action.state}`]}`}
              onClick={() => handleOpenModal(action.state)}
              label={buttons.get(action.state)}
              disabled={loading}
              key={action.state}
            />
          ))}
          {modalData?.id && (
            <CustomModal
              title="¿Estás seguro?"
              modalName="evaluate"
              onConfirm={dispatchRequest}
              loading={loading}
              showCloseIcon={false}
            >
              <p className={styles.modalContent}>
                {t(`resources.affiliations.evaluate.modal.${statusToChange}.message`)}
              </p>
            </CustomModal>
          )}
        </>
      )}
    </div>
  );
};

export default SolicitudBinariaFilled;
