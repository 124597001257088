import { isUploadMimeAccepted } from '../AffiliationFilled/utils';

const FILE_SIZE_MB_MAX = 5;
const FIXED_CONSTANT = 4;
const ONE_KB_IN_BYTES = 1024;

export const checkFile = (file: File) => {
  const errMessages: string[] = [];
  const fileSize = file.size;
  const fileSizeToMB = parseFloat((fileSize / ONE_KB_IN_BYTES / ONE_KB_IN_BYTES).toFixed(FIXED_CONSTANT));
  if (fileSizeToMB > FILE_SIZE_MB_MAX) {
    errMessages.push(`pesa más de ${FILE_SIZE_MB_MAX}MB`);
  }

  if (!isUploadMimeAccepted(file.type)) {
    errMessages.push(`tipo ${file.type} no es uno permitido`);
  }

  return errMessages.length
    ? { ok: false, message: `Error: El archivo ${file.name} ${errMessages.join(' y ')}.` }
    : { ok: true, message: 'File is premitted' };
};

export const buildParams = (file: File, applicationId: number, baseName?: string) => ({
  file,
  query: {
    fileName: baseName ? baseName : file.name,
    contentType: file.type
  },
  url: {
    applicationId
  }
});

export const DOCUMENT_TYPES = {
  ALTA_EMPRESA: 'Alta de la empresa',
  CAMBIO_PRESTADOR: 'Carta cambio de prestador',
  CARTA_COMPROMISO_SIN_OPCION: 'Carta compromiso sin opción',
  CARTA_CONDICION_IVA: 'Carta condición IVA',
  CARTA_DIFERENTES_FECHAS: 'Carta de diferentes fechas',
  CARTA_TRASPASO: 'Carta de traspaso _ Constancia de opción',
  CARTA_IMC: 'Carta IMC',
  CARTA_JERARQUIZACION: 'Carta jerarquización',
  CERTIFICADO_DISCAPACIDAD: 'Certificado discapacidad',
  DESCUENTOS_MEDICOS: 'Descuento médicos',
  DNI: 'DNI',
  FORMULARIO_5_3: 'Formulario 5.3',
  FORMULARIO_5_9: 'Formulario 5.9',
  PLAN_510: 'Plan 510',
  RESPUESTA_AUDITORIA: 'Respuesta auditoría',
  SENIOR: 'Senior',
  MAS_2_PLANES: 'Sube mas de 2 planes',
  SUBSIDIO_FALLECIMIENTO: 'Subsidio _ Fallecimiento',
  SUMATORIA_APORTES_PLURIEMPLEO: 'Sumatoria de aportes _ Pluriempleo',
  ESTUDIOS_MEDICOS: 'Estudios médicos',
  OTROS: 'Otros'
};

export const documentChoices = Object.values(DOCUMENT_TYPES).map(type => ({
  id: type,
  name: type
}));
