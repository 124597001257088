import React, { SyntheticEvent } from 'react';
import CustomButton from '~components/CustomButton';
import { SolicitudBinariaRequest } from '~utils/types';

import { canSendInitialEmail, isPendienteProm, getEtiquetas } from '../helperFunctions';
import { useStyles } from '../binaria-styles';

interface Props {
  solicitud: SolicitudBinariaRequest;
  onEnvioMailInicial: (estado: string, estadoActual: string, id: number) => void;
}

export default function ActionField({ solicitud, onEnvioMailInicial }: Props) {
  const styles = useStyles();
  const showMailInicialBtn = canSendInitialEmail(solicitud.state);
  const pendienteProm = isPendienteProm(solicitud.state);
  const { labelShort, title } = getEtiquetas(solicitud.state);

  const handleClick = (event: SyntheticEvent<EventTarget>) => {
    if (showMailInicialBtn) {
      event.stopPropagation();
      onEnvioMailInicial(solicitud.nextstate, solicitud.state, solicitud.id);
    }
  };

  return (
    <>
      <div className={styles.button}>
        {showMailInicialBtn && (
          <CustomButton
            label={labelShort}
            title={title}
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
        {pendienteProm && (
          <CustomButton
            label="Evaluar"
            title="Click para ir al detalle y evaluar la solicitud de afiliación"
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
      </div>
    </>
  );
}
