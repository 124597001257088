import { makeStyles } from '@material-ui/core/styles';
import { GRAY } from '~constants/colors';

export const useStyles = makeStyles({
  content: {
    color: GRAY,
    fontSize: 14,
    lineHeight: '16x',
    marginBottom: '24px',
    textAlign: 'center',
    width: '100%'
  },
  actions: {
    alignItems: 'center',
    display: 'flex'
  },
  hideLabel: {
    display: 'none'
  }
});
