/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import * as XLSX from 'xlsx';
import React, { useEffect, useCallback } from 'react';
import { MASSIVE_UPLOAD } from '~config/providers/constants';
import {
  useMutation,
  SimpleForm,
  useTranslate,
  FileInput,
  FileField,
  required,
  useNotify
} from 'react-admin';
import { useHistory } from 'react-router';
import InputLabel from '~components/InputLabel';
import { useFormStyles, useCommonStyles } from '~app/styles';
import Toolbar from '~components/Toolbar';
import { useDispatch } from '~contexts/ModalContext';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import FeedbackModalContent from '~components/FeedbackModalContent';
import { required as requiredValidator } from '~utils/inputValidations';
import preval from 'preval.macro';
import {
  AFFILIATION_TYPES,
  APPLICATION_TYPES,
  COMPANY_PLAN_TYPES,
  INCREASE_PLAN_ALLOWED_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  PLAN_TYPES,
  TYPE_DOCUMENT
} from '~constants/excel';
import { HIRING_MODE_TYPES } from '~constants/affiliations';
import { useRefresh } from 'ra-core';
import cn from 'classnames';

import { ALTAS_MASIVAS_FORM } from '../constants';

import { FormDataProps, repetedDni, checkValidPlan } from './utils';
import { useStyles } from './styles';

function AltaMasivaCreate({ props, callback, setLoading }: any) {
  const { permissions, isEdit, isEditing, ...rest } = props;
  const t = useTranslate();
  const dispatch = useDispatch();
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const fileDelete = useStyles();
  const [mutate, { data, error, loading }] = useMutation();
  const refresh = useRefresh();
  const history = useHistory();
  const notify = useNotify();
  const planesCorporativos: any = ['8260', '8360', '8430'];
  // const [errorDetail, setErrorDetail] = React.useState();

  const UPLOAD_FILES_MIME_ACCEPTED = preval`
  const mimeTable = require('mime-types').types;
  module.exports = [mimeTable.xls,mimeTable.xlsx].join(', ');
  `;

  const isUploadMimeAccepted = (mimeType: string) => {
    const mimesAsArray = UPLOAD_FILES_MIME_ACCEPTED.split(', ');
    return mimesAsArray.includes(mimeType);
  };

  useEffect(() => {
    if (data || error) {
      dispatch?.(modalActions.openModal(MODAL_NAMES.DIALOG));
    }
  }, [data, error, dispatch]);

  const { location } = history;
  const handleExit = useCallback(() => {
    if (location.pathname === '/altasmasivas') {
      callback(false);
      refresh();
    } else {
      history.push('/altasmasivas');
    }
  }, [location.pathname, callback, refresh, history]);
  useEffect(() => {
    if (setLoading) {
      setLoading(!loading);
    }
  }, [setLoading, loading]);

  const bulkApplication = useCallback(
    async (values: FormDataProps) => {
      notify('Procesando alta masiva');
      const file = values.datos?.rawFile;
      if (file) {
        const rejectedType: string = file.name.slice(-4);
        if (isUploadMimeAccepted(file.type)) {
          const masiveData = await file?.arrayBuffer();
          const opt = {
            cellDates: true,
            dateNF: 'dd/mm/yyyy'
          };
          const workbook = XLSX.read(masiveData, opt);
          const jsonData = XLSX.utils
            .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
            .map((alta: any, index: any) => ({
              nroRegistro: index + 1,
              prospectNombre: alta.nombre,
              prospectApellido: alta.apellido,
              prospectTipoDocumento:
                TYPE_DOCUMENT[alta['tipo de documento'] as keyof typeof TYPE_DOCUMENT]?.toLowerCase() ||
                alta['tipo de documento'],
              prospectNumeroDocumento: alta['nro de documento'],
              prospectCuit: alta.cuit,
              prospectEmail: alta.email,
              prospectTelefono: alta.telefono,
              solicTipoSocio: AFFILIATION_TYPES[alta['tipo de socio'] as keyof typeof AFFILIATION_TYPES],
              solicTipoPlan: PLAN_TYPES[alta['tipo de plan'] as keyof typeof PLAN_TYPES],
              solicModalidad: HIRING_MODE_TYPES[alta.modalidad as keyof typeof HIRING_MODE_TYPES],
              solicPlan: COMPANY_PLAN_TYPES[alta.plan as keyof typeof COMPANY_PLAN_TYPES],
              solicPermiteMejora:
                INCREASE_PLAN_ALLOWED_TYPES[
                  alta['permite mejora'] as keyof typeof INCREASE_PLAN_ALLOWED_TYPES
                ],
              solicAbonaDiferencia:
                IS_PAYMENT_RESPONSIBLE_TYPES[
                  alta['abona diferencia'] as keyof typeof IS_PAYMENT_RESPONSIBLE_TYPES
                ],
              solicTipoFormulario:
                APPLICATION_TYPES[alta['tipo de formulario'] as keyof typeof APPLICATION_TYPES],
              solicVigencia: alta.vigencia,
              observaciones: alta.observaciones
            }));
          if (
            !jsonData[0]?.prospectNombre &&
            !jsonData[0]?.prospectNumeroDocumento &&
            !jsonData[0]?.prospectEmail
          ) {
            notify('El archivo no corresponde a una solicitud de altas masivas');
          } else if (checkValidPlan(jsonData, planesCorporativos)) {
            notify('El documenta presenta datos invalidos, revisar excel de datos.');
          } else if (repetedDni(jsonData)[0]?.repeticiones?.length >= 2) {
            repetedDni(jsonData).forEach((c: any) =>
              notify(
                `El número de documento: ${c?.dniRepetido}, se encuentra repetido en las posiciones: ${c?.repeticiones}, revisar excel de datos.`
              )
            );
          } else {
            mutate({
              type: MASSIVE_UPLOAD,
              resource: 'post',
              payload: { ...values, datos: jsonData }
            });
          }
        } else {
          notify(`El archivo de tipo: ${rejectedType}, no es un tipo de archivo permitido`);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate, notify]
  );

  return (
    <>
      <h1 className={commonStyles.title}>{t('resources.altasmasivas.createTitle')}</h1>
      <SimpleForm
        {...rest}
        className={styles.form}
        save={bulkApplication}
        saving={loading}
        toolbar={isEditing || !isEdit ? <Toolbar {...props} /> : null}
        mode="onBlur"
        reValidateMode="onBlur"
      >
        <InputLabel
          label={t('resources.altasmasivas.fields.promotor')}
          className={commonStyles.row}
          source={ALTAS_MASIVAS_FORM.idPromotor}
          editEnabled
          validate={[requiredValidator]}
          type="text"
          defaultValue={permissions?.id.toString()}
        />
        <InputLabel
          label={t('resources.altasmasivas.fields.name')}
          className={commonStyles.row}
          source={ALTAS_MASIVAS_FORM.nombre}
          validate={[requiredValidator]}
          editEnabled
          type="text"
        />
        <>
          <FileInput
            className={cn(fileDelete.remove, {
              [fileDelete.notDisplay]: loading
            })}
            source={ALTAS_MASIVAS_FORM.datos}
            validate={[required()]}
          >
            <FileField
              source={ALTAS_MASIVAS_FORM.datos}
              title="title"
              sx={{
                '& .MuiButtonBase-root': {
                  '& .MuiIconButton-label': {
                    '& .MuiSvgIcon-root': {
                      color: 'red'
                    }
                  }
                }
              }}
            />
          </FileInput>
        </>
      </SimpleForm>
      {(data || error) && (
        <CustomModal modalName={MODAL_NAMES.DIALOG} {...(data ? { onClose: handleExit } : {})}>
          <FeedbackModalContent
            error={error}
            successMessage={t('resources.altasmasivas.modals.create.success')}
            showError={error}
          />
        </CustomModal>
      )}
    </>
  );
}

export default AltaMasivaCreate;
