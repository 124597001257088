import React, { useState, useEffect } from 'react';
import { ReactComponent as ClipIcon } from '~assets/ic_clip.svg';
import { ReactComponent as CrossIcon } from '~assets/ic_cross.svg';
import { useCommonStyles } from '~app/styles';
import { getTargetLinkProperties } from '~utils/general';
import { ReactComponent as EditIcon } from '~assets/link_document.svg';

import { useStyles } from './styles';

interface Props {
  url: string;
  name: string;
  documentNumber?: string;
  canDelete?: boolean;
  onDelete?: Function;
  id?: number | undefined;
  estado?: boolean;
  icon?: boolean;
}

function FileValue({ url, name, canDelete = false, onDelete, estado, icon = true }: Props) {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => setShowIcon(estado === false), [estado]);

  return (
    <div className={styles.container}>
      {icon && <ClipIcon className={`${commonStyles.icon} ${styles.icon}`} />}
      <a className={styles.link} href={url} {...getTargetLinkProperties(false)}>
        {name}
      </a>
      {showIcon && <EditIcon className={commonStyles.icon} />}
      {canDelete && (
        <CrossIcon className={`${commonStyles.icon} ${styles.crossIcon}`} onClick={() => onDelete?.()} />
      )}
    </div>
  );
}

export default FileValue;
