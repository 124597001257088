import { makeStyles } from '@material-ui/core';
import { PRIMARY_BLUE, LIGHT_GRAY_SECONDARY, GRAY_SECONDARY } from '~constants/colors';

export const useStyles = makeStyles({
  switch: {
    '& > .MuiSwitch-switchBase > .MuiIconButton-label > .MuiSwitch-thumb': {
      color: GRAY_SECONDARY
    },
    '& > .MuiSwitch-track': {
      backgroundColor: LIGHT_GRAY_SECONDARY
    },
    '& > .Mui-checked': {
      '& > .MuiIconButton-label > .MuiSwitch-thumb': {
        color: PRIMARY_BLUE
      },
      '& + .MuiSwitch-track': {
        backgroundColor: LIGHT_GRAY_SECONDARY
      }
    }
  },
  margin: {
    marginRight: '10px',
    minWidth: 0
  }
});
