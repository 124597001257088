import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  buttonFinished: {
    marginBottom: 10
  },
  simpleForm: {
    '& li': {
      border: 'none !important',
      '& p': {
        display: 'none !important'
      }
    },
    '& section': {
      '& div:nth-child(2)': {
        '& button': {
          display: 'none'
        }
      }
    },
    '& div:nth-child(2)': {
      '& button': {
        display: 'inline',
        width: '100%'
      }
    },
    '& button': {
      width: '90%'
    },
    overflow: 'hidden'
  },
  fileInput: {
    width: '25%',
    padding: '0',
    position: 'relative',
    top: '-55px',
    left: '300px',
    marginBottom: '-200px',
    '& a': {
      position: 'relative',
      left: '30px',
      width: 'auto',
      maxWidth: '50%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    '& label:nth-child(1)': {
      display: 'none !important'
    }
  }
}));
