import React from 'react';
import { useTranslate, TextInput, SelectInput } from 'react-admin';
import {
  AFFILIATION_REQUESTS_FILTERS,
  AFFILIATION_REQUESTS_FIELDS
} from '~screens/AffiliationRequests/constants';
import { AFFILIATION_REQUEST_STATUS_OSD } from '~constants/affiliations';
import CustomFilter from '~components/CustomFilter';
import useListProviders from '~config/hooks/useListProviders';

import usePromoterFormColaborator from '../../../../../Promoters/components/PromoterForm/hooks/usePromoterFormColaborator';

function AffiliationRequestsFilter(props: object) {
  const t = useTranslate();
  const affiliationStatusTypes = Object.values(AFFILIATION_REQUEST_STATUS_OSD).map(type => ({
    id: type,
    name: t(`resources.affiliations.fields.statusTypes.${type}`)
  }));
  const { filialesChoices } = useListProviders();
  const { activeFilial, capsOptions, calculateCapsOptions } = usePromoterFormColaborator();
  const [selectedFilial, setSelectedFilial] = React.useState(activeFilial);
  const [selectedCap, setSelectedCap] = React.useState('');
  const handleFilialChange = (event: { target: { value: any } }) => {
    const newFilial = event.target.value;
    setSelectedFilial(newFilial);
  };
  const handleCapChange = (event: { target: { value: any } }) => {
    const newCap = event.target.value;
    setSelectedCap(newCap);
  };
  React.useEffect(() => {
    const obj = {
      role: 'regular',
      filialCode: selectedFilial,
      permisoOSDE: false,
      permisoBinaria: false,
      capCode: selectedCap
    };
    calculateCapsOptions(obj, false);
  }, [selectedFilial, selectedCap, calculateCapsOptions]);
  return (
    <CustomFilter {...props}>
      <TextInput label="Solicitud" source={AFFILIATION_REQUESTS_FILTERS.id} />
      <TextInput source={AFFILIATION_REQUESTS_FIELDS.promoterId} />
      <TextInput source={AFFILIATION_REQUESTS_FILTERS.firstName} />
      <TextInput source={AFFILIATION_REQUESTS_FILTERS.lastName} />
      <TextInput
        label={t('resources.affiliations.fields.document')}
        source={AFFILIATION_REQUESTS_FILTERS.documentNumber}
      />
      <SelectInput
        source={AFFILIATION_REQUESTS_FIELDS.state}
        choices={affiliationStatusTypes}
        label={t('resources.affiliations.fields.status')}
      />
      <TextInput label={t('resources.affiliations.fields.cuit')} source={AFFILIATION_REQUESTS_FIELDS.cuit} />
      <SelectInput
        key={`caps_${activeFilial}`}
        label={t('resources.affiliations.fields.filial')}
        source={AFFILIATION_REQUESTS_FILTERS.filialCode}
        choices={filialesChoices}
        onChange={handleFilialChange}
        value={selectedFilial}
      />
      <SelectInput
        label={t('resources.affiliations.fields.cap')}
        source={AFFILIATION_REQUESTS_FILTERS.capCode}
        choices={capsOptions}
        onChange={handleCapChange}
        value={selectedCap}
      />
    </CustomFilter>
  );
}

export default AffiliationRequestsFilter;
