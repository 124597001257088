/* eslint-disable babel/no-unused-expressions */
/* eslint-disable complexity */
/* eslint-disable no-negated-condition */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { SimpleForm, FormDataConsumer } from 'react-admin';
import InputLabel from '~components/InputLabel';
import { useFormStyles, useCommonStyles } from '~app/styles';
import Toolbar from '~components/Toolbar';
import { requiredField as requiredValidator } from '~utils/inputValidations';
import InputSelect from '~components/InputSelect';
import { getPaymentData } from '~services/OsdeService';
import { useTranslate } from 'ra-core';

import { DATOS_PAGO_FORM, DOCUMENT_TYPES, FORMS_DOCUMENT_TYPES } from '../../constants';
import { getDocumentNumberValidation } from '../../utils';
import InputLabelForm from '../InputLabel';

function DatosPagoForm({ props }: any) {
  const { permissions, isEdit, isEditing, ...rest } = props;
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [resultsF1, setResultsF1] = useState<any>(null);
  const [resultsF2, setResultsF2] = useState<any>(null);
  const [error, setError] = useState<string>('');

  const notEmptyObject = (myObject: object) => Object.keys(myObject).length > 0;
  const t = useTranslate();

  const regexAlpha = /[^a-zA-Z0-9]+/g;
  const regexNumero = /[^0-9]+/g;

  const saving = async (values: any) => {
    setLoading(true);
    setError('');
    const response = await getPaymentData({
      documentNumber: values.nroDocumentoTitular,
      documentType: values.tipoDocumento,
      applicationId: values.idSolicitud,
      promoterId: values.idPromotor,
      producto: values.formulario
    });
    if (response.data.errors) {
      setError(response.data.errors[0].message || 'Ocurrio un error');
      setLoading(false);
      setResultsF1(null);
      setResultsF2(null);
    } else {
      props.setShowResults(true);
      if (values.formulario === 'OSDE') {
        setResultsF1(response.data);
        setResultsF2(null);
      } else {
        setResultsF2(response.data);
        setResultsF1(null);
      }
      setLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ASCII_DIGIT_ZERO = 48;
    const ASCII_DIGIT_NINE = 57;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < ASCII_DIGIT_ZERO || charCode > ASCII_DIGIT_NINE) {
      event.preventDefault();
    }
  };

  return (
    <div className={commonStyles.flex}>
      <div>
        <h1 className={commonStyles.titleDatosPago}>Datos de pago</h1>
        <SimpleForm
          {...rest}
          className={styles.form}
          save={saving}
          saving={loading}
          toolbar={isEditing || !isEdit ? <Toolbar label="Consultar" {...props} /> : null}
          validateOnBlur
        >
          <InputLabel
              label="ID Promotor"
              className={commonStyles.row}
              source={DATOS_PAGO_FORM.idPromotor}
              validate={[requiredValidator]}
              type="text"
              disabled
              defaultValue={permissions?.id.toString()}
          />
          <InputSelect
              {...props}
              label="Formulario"
              className={commonStyles.row}
              source={DATOS_PAGO_FORM.formulario}
              validate={requiredValidator}
              choices={[
                { id: 'OSDE', name: 'F1' },
                { id: 'F2', name: 'F2' }
              ]}
              defaultValue="OSDE"
          />
          <InputLabel
              label="ID Solicitud"
              className={commonStyles.row}
              source={DATOS_PAGO_FORM.idSolicitud}
              validate={[requiredValidator]}
              editEnabled
              type="text"
              onKeyPress={handleKeyPress}
          />
          <FormDataConsumer>
            {({ formData, ...formDataProps }: any) => {
              const selectedForm = formData?.formulario || 'OSDE';
              const selectedDocumentType = formData?.tipoDocumento;
              const documentChoices = FORMS_DOCUMENT_TYPES[selectedForm];

              const defaultDocumentValue = documentChoices.length > 0 ? documentChoices[0].id : '';
              const choicesHaveSelectedDocument = documentChoices.some(d => d.id === selectedDocumentType);
              if (!choicesHaveSelectedDocument) {
                formData.tipoDocumento = defaultDocumentValue;
              }

              return (
                <InputSelect
                  {...formDataProps}
                  label="Tipo de documento"
                  className={commonStyles.row}
                  source={DATOS_PAGO_FORM.tipoDocumento}
                  validate={requiredValidator}
                  choices={documentChoices}
                  defaultValue={defaultDocumentValue}
                />
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...formDataProps }: any) => {
              const type = formData.tipoDocumento;
              const selectedForm = formData.formulario as string || 'OSDE';
              const labelText = selectedForm === 'F2' ? 'CUIT de la empresa' : 'Nro de documento del titular';
              const parserRegex = type === DOCUMENT_TYPES.Passport.id ? regexAlpha : regexNumero;

              return (
                <InputLabel
                  {...formDataProps}
                  label={labelText}
                  className={commonStyles.row}
                  source={DATOS_PAGO_FORM.nroDocumentoTitular}
                  validate={[
                    requiredValidator,
                    ...(getDocumentNumberValidation({ t, type }) as any[])
                  ]}
                  parse={(value: string) => value.replace(parserRegex, '')}
                  editEnabled
                  type="text"
                />
              );
            }}
          </FormDataConsumer>
          <span className={commonStyles.errorMessage}>{error}</span>
        </SimpleForm>
      </div>

      {/* Resultados para formulario F1 */}
      {resultsF1 && props.showResults === true && (
        <div>
          <div className={commonStyles.flex}>
            <div className={commonStyles.column}>
              {/* Datos para reintegros */}
              {resultsF1?.datosReintegro && (
                <>
                  <h1 className={commonStyles.titleDatosPago}>Datos para reintegros</h1>
                  <InputLabelForm label="Número de CBU" value={resultsF1?.datosReintegro?.cbu} isUnselectable={false} />
                  {resultsF1?.datosReintegro?.cuilTitular && <InputLabelForm label="CUIL titular 1" value={resultsF1?.datosReintegro?.cuilTitular} isUnselectable={false} />}
                  <InputLabelForm label="Apellido titular 1" value={resultsF1?.datosReintegro?.apellidoTitular} isUnselectable={false} />
                  <InputLabelForm label="Nombre titular 1" value={resultsF1?.datosReintegro?.nombreTitular} isUnselectable={false} />
                  <InputLabelForm label="Número de cuenta" value={resultsF1?.datosReintegro?.nroCuenta} />
                </>
              )}
            </div>
            <div className={commonStyles.column}>
              {/* Debito en cuenta */}
              {resultsF1?.datosDebito?.debitoCuenta && (
                <>
                  <h1 className={commonStyles.titleDatosPago}>Débito automático en cuenta</h1>
                  <InputLabelForm label="Número de CBU" value={resultsF1?.datosDebito?.debitoCuenta?.cbu} isUnselectable={false} />
                </>
              )}

              {/* Debito en tarjeta */}
              {resultsF1?.datosDebito?.debitoTarjeta && (
                <>
                  <h1 className={commonStyles.titleDatosPago}>Débito automático en tarjeta</h1>
                  <InputLabelForm label="Clase de tarjeta" value={resultsF1?.datosDebito?.debitoTarjeta?.claseTarjeta} />
                  <InputLabelForm
                    label="Número de tarjeta"
                    value={resultsF1?.datosDebito?.debitoTarjeta?.nroTarjeta.split(' ').join('')}
                    isUnselectable={false}
                  />
                  <InputLabelForm label="Nombre del titular" value={resultsF1?.datosDebito?.debitoTarjeta?.nombreTitular} isUnselectable={false} />
                  <InputLabelForm label="Vencimiento" value={resultsF1?.datosDebito.debitoTarjeta?.vencimiento} />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Resultados para formulario F2 */}
      {resultsF2 && props.showResults === true && (
        <div>
          <div className={commonStyles.flex}>
            {
              notEmptyObject(resultsF2.datosDirectorio) && (
                <div className={commonStyles.column}>
                  <h1 className={commonStyles.titleDatosPago}>Directorio o adherentes</h1>
                  <InputLabelForm label="Pago" value={resultsF2?.datosDirectorio?.tipo} />
                  {resultsF2?.datosDirectorio?.cbu !== '' ? (
                    <InputLabelForm label="CBU" value={resultsF2?.datosDirectorio?.cbu} isUnselectable={false} />
              ) : (
                <>
                  <InputLabelForm label="Tarjeta" value={resultsF2?.datosDirectorio?.claseTarjeta} />
                  <InputLabelForm label="Número de tarjeta" value={resultsF2?.datosDirectorio?.nroTarjeta} isUnselectable={false} />
                  <InputLabelForm label="Vencimiento" value={resultsF2?.datosDirectorio?.vencimiento} />
                </>
              )}
                </div>
              )
            }

            {
            notEmptyObject(resultsF2.datosRelacionDependencia) && (
              <div className={commonStyles.column}>
                <h1 className={commonStyles.titleDatosPago}>En relación de dependencia</h1>
                <InputLabelForm label="Pago" value={resultsF2?.datosRelacionDependencia?.tipo} />
                {resultsF2?.datosRelacionDependencia?.cbu !== '' ? (
                  <InputLabelForm label="CBU" value={resultsF2?.datosRelacionDependencia?.cbu} isUnselectable={false} />
              ) : (
                <>
                  <InputLabelForm label="Tarjeta" value={resultsF2?.datosRelacionDependencia?.claseTarjeta} />
                  <InputLabelForm label="Número de tarjeta" value={resultsF2?.datosRelacionDependencia?.nroTarjeta} isUnselectable={false} />
                  <InputLabelForm label="Vencimiento" value={resultsF2?.datosRelacionDependencia?.vencimiento} />
                </>
              )}
              </div>
            )
           }
          </div>
        </div>
      )}
    </div>
  );
}

export default DatosPagoForm;
