import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import CustomButton from '~components/CustomButton';
import { AffiliationRequest } from '~utils/types';
import {
  getShowSendInitialEmail,
  getSendEmailTexts,
  isEvaluationPending,
  isUserValidationPending,
  isPromoterValidationPending
} from '~screens/AffiliationRequests/utils';

import { useStyles } from './styles';

interface Props {
  affiliation: AffiliationRequest;
  onResendUserValidationEmail: (affiliation: AffiliationRequest) => void;
  onSendInitialEmailAction: (affiliation: AffiliationRequest) => void;
  onCheckDetail?: any;
}

function ActionField({
  affiliation,
  onSendInitialEmailAction,
  onResendUserValidationEmail,
  onCheckDetail
}: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const showSendInitialEmail = getShowSendInitialEmail(affiliation.state);
  const evaluationPending = isEvaluationPending(affiliation.state);
  const userValidationPending = isUserValidationPending(affiliation.state);
  const promoterValidationPending = isPromoterValidationPending(affiliation.state);

  const { labelShort, title } = useMemo(() => getSendEmailTexts(affiliation.state), [affiliation.state]);

  const handleClick = (event: SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    event.stopPropagation();
    if (showSendInitialEmail) {
      onSendInitialEmailAction(affiliation);
    }
    if (userValidationPending) {
      onResendUserValidationEmail(affiliation);
    }
    if (promoterValidationPending) {
      onCheckDetail();
    }
  };

  return (
    <>
      <div className={styles.button}>
        {(showSendInitialEmail || userValidationPending) && (
          <CustomButton
            label={t(labelShort)}
            title={t(title)}
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
        {evaluationPending && (
          <CustomButton
            label={t('resources.affiliations.validateAffiliation')}
            title={t('resources.affiliations.validateAffiliationTitle')}
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
      </div>
    </>
  );
}

export default ActionField;
