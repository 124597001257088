import { Roles, SubRoles } from '~utils/types';

const defaultRoleNames = {
  [Roles.REGULAR]: 'PROMOTOR',
  [Roles.ADMIN]: 'ADMINISTRADOR',
  [Roles.SUPER_ADMIN]: 'SUPER ADMINISTRADOR'
};

const containsOnlySubrole = (subrolesArr: string[], subrole: SubRoles) => {
  const subrolesSet = new Set(subrolesArr);
  return subrolesSet.size === 1 && subrolesSet.has(subrole);
};

export const getPromoterRole = (role: Roles, subroles: string[]) => {
  let promoterRole = defaultRoleNames[role] || '';

  const hasRegularRole = role === Roles.REGULAR;
  if (hasRegularRole && containsOnlySubrole(subroles, SubRoles.ANALISTA)) {
    promoterRole = 'ANALISTA';
  }

  return promoterRole;
};
