import React, { useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { i18nProvider } from '~config/providers/i18nProvider';
import dataProvider from '~config/providers/dataProvider';
import CustomLayout from '~components/CustomLayout';
import authProvider from '~config/providers/authProvider';
import Login from '~screens/Login';
import { Promoter, Roles, SubRoles } from '~utils/types';
import Loader from '~components/Loader';
import {
  customRoutes,
  adminResources,
  ON_RESOURCES,
  OSDE_RESOURCES,
  BINARIA_RESOURCES,
  DATOS_PAGO_RESOURCES,
  showOnForAdminsAlways,
  ALTAS_MASIVAS_RESOURCES,
  F2_RESOURCES,
  superAdminResources
} from '~constants/routes';

import '../scss/typography.scss';
import '../scss/application.scss';
import { appTheme } from './styles';

const defineResources = (promoter: Promoter) => {
  if (!promoter) {
    return [];
  }

  const res: any[] = [];

  if (promoter.role === Roles.ADMIN) {
    return adminResources();
  }

  if (promoter.role === Roles.SUPER_ADMIN) {
    return superAdminResources();
  }

  promoter.subroles.forEach(role => {
    switch (role) {
      case SubRoles.OSDE:
        res.push(...OSDE_RESOURCES);
        res.push(...ALTAS_MASIVAS_RESOURCES);
        res.push(...F2_RESOURCES);
        break;
      case SubRoles.BINARIA:
        res.push(...BINARIA_RESOURCES);
        break;
      case SubRoles.ANALISTA:
        res.push(...DATOS_PAGO_RESOURCES);
        break;
      case SubRoles.ON:
        if (!showOnForAdminsAlways()) {
          res.push(...ON_RESOURCES);
        }
        break;
      case SubRoles.SUPER_ADMIN:
        res.push(adminResources());
        break;
      case SubRoles.ASESOR:
        res.push(...OSDE_RESOURCES);
        break;
      default:
        break;
    }
  });

  return res;
};

function App() {
  const getResources = (promoter: Promoter) => {
    if (!promoter) {
      authProvider.logout();
      return [];
    }

    const resources = defineResources(promoter);
    if (resources.length) {
      const result = resources.map((resource: any) => <Resource key={resource.name} {...resource} />);
      const res = Promise.resolve(result);
      return res;
    }
    return [];
  };

  useEffect(() => {
    localStorage.removeItem('planValidityDate');
  }, []);

  return (
    <Admin
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      loginPage={Login}
      layout={CustomLayout}
      theme={appTheme}
      loading={Loader}
    >
      {getResources}
    </Admin>
  );
}

export default App;
