import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import './LogoStyle.scss';
import { VERSION_APP } from '~constants/app';

export default function LogoEnLista(props: any) {
  return (
    <div>
      {props.isBinaria && (
        <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
          <img src={props.logoBinaria} className={props.claseBinaria} />
        </Tooltip>
      )}
      {props.isOSDE && (
        <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
          <img src={props.logoOsde} className={props.claseOsde} />
        </Tooltip>
      )}
      {props.isON && (
        <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
          <img src={props.logoON} className={props.claseON} />
        </Tooltip>
      )}
      <br />
      <Typography variant="h6">
        <span className="titulo">{props.title}</span>
      </Typography>
    </div>
  );
}
