/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useCallback } from 'react';
// import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery
} from '@material-ui/core';
import cn from 'classnames';
import { useTranslate } from 'ra-core';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import { getDateLocaleFromString } from '~utils/date';

import CustomButton from '../CustomButton';

import { useStyles } from './styles';

interface Props {
  data?: any;
  error?: any;
  beneficiaryData?: any;
  titleClassName?: string;
  modalClassname?: string;
  contentClassName?: string;
  onClose: () => void;
  onContinue: (values: any) => any;
  isOpen: boolean;
  disableContinue?: boolean;
}

const RepeatabilityModal = ({
  data,
  titleClassName,
  contentClassName = '',
  onClose,
  onContinue,
  isOpen,
  disableContinue
}: Props) => {
  const t = useTranslate();
  const styles = useStyles({ titleClassName });
  const isSmall: any = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const warningStates = [
    AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING,
    AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT,
    AFFILIATION_REQUEST_STATUS.FORM_LINK_OPENED,
    AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION,
    AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION,
    AFFILIATION_REQUEST_STATUS.REVISION
  ];

  const shouldDisableContinue = data.applications.some((application: { state: string }) =>
    warningStates.includes(application.state)
  );

  const warningStyles = [styles.warningColorText, styles.textBold];

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Dialog open={isOpen} scroll="paper" onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle disableTypography>
        <h1
          className={cn(isSmall ? styles.commonTitleSmall : styles.commonTitle, titleClassName, styles.title)}
        >
          Historial de solicitudes
        </h1>
      </DialogTitle>
      <DialogContent>
        <div className={cn(contentClassName)}>
          {isSmall ? (
            <TableContainer className={cn(styles.tableContainer)}>
              <Table>
                <TableBody>
                  {data.applications.map((application: any) => (
                    <TableRow key={application.id} hover className={cn(styles.rowTableSmall)}>
                      <TableCell className={cn(styles.cell2Small)}>
                        {/* <Link target="_blank" rel="noopener noreferrer" to={`/${application.id}/show`}> */}
                        <div className={cn(styles.margin20, styles.divTableSmall)}>
                          <p className={cn(styles.titleDivTableSmall)}>Nro de solicitud</p>
                          <p
                            className={cn(
                              warningStates.includes(application.state) ? warningStyles : styles.colorText
                            )}
                          >
                            {/* <Link target="_blank" rel="noopener noreferrer" to={`/${application.id}/show`}> */}
                            {application.id}
                            {/* </Link> */}
                          </p>
                        </div>
                        {/* </Link> */}

                        <div className={cn(styles.margin20, styles.divTableSmall)}>
                          <p className={cn(styles.titleDivTableSmall)}>Id promotor</p>
                          <p
                            className={cn(
                              warningStates.includes(application.state) ? warningStyles : styles.colorText
                            )}
                          >
                            {application.promoterId}
                          </p>
                        </div>

                        <div className={cn(styles.divTableSmall)}>
                          <p className={cn(styles.titleDivTableSmall)}>Tipo de socio</p>
                          <p
                            className={cn(
                              warningStates.includes(application.state) ? warningStyles : styles.colorText
                            )}
                          >
                            {t(
                              `resources.affiliations.fields.affiliationTypes.${application.affiliationType}`
                            )}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={cn(styles.margin20, styles.divTableSmall)}>
                          <p className={cn(styles.titleDivTableSmall)}>Tipo de formulario</p>
                          <p
                            className={cn(
                              warningStates.includes(application.state) ? warningStyles : styles.colorText
                            )}
                          >
                            {t(
                              `resources.affiliations.fields.applicationTypes.${application.applicationType}`
                            )}
                          </p>
                        </div>
                        <div className={cn(styles.divTableSmall)}>
                          <p className={cn(styles.titleDivTableSmall)}>Estado</p>
                          <p
                            className={cn(
                              warningStates.includes(application.state) ? warningStyles : styles.colorText
                            )}
                          >
                            {t(`resources.affiliations.fields.statusTypes.${application.state}`)}
                          </p>
                        </div>
                        <div className={cn(styles.divTableSmall)}>
                          <p className={cn(styles.titleDivTableSmall)}>Fecha</p>
                          <p
                            className={cn(
                              warningStates.includes(application.state) ? warningStyles : styles.colorText
                            )}
                          >
                            {getDateLocaleFromString(application.updatedAt)}
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={cn(styles.colWidthMail)}>
                    <TableCell align="center" className={cn(styles.textBold)}>
                      Nro de solicitud
                    </TableCell>
                    <TableCell align="left" className={cn(styles.textBold)}>
                      Id promotor
                    </TableCell>
                    <TableCell align="left" className={cn(styles.textBold)}>
                      Tipo de socio
                    </TableCell>
                    <TableCell align="left" className={cn(styles.textBold)}>
                      Tipo de formulario
                    </TableCell>
                    <TableCell align="left" className={cn(styles.textBold)}>
                      Estado
                    </TableCell>
                    <TableCell align="left" className={cn(styles.textBold)}>
                      Fecha
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.applications.map((application: any) => (
                    <TableRow key={application.id} hover>
                      <TableCell
                        align="center"
                        className={cn(warningStates.includes(application.state) ? warningStyles : '')}
                      >
                        {/* <Link target="_blank" rel="noopener noreferrer" to={`/${application.id}/show`}> */}
                        {application.id}
                        {/* </Link> */}
                      </TableCell>
                      {/* <TableCell onClick={() => window.open(`/${application.id}/show`)}> */}
                      <TableCell
                        className={cn(warningStates.includes(application.state) ? warningStyles : '')}
                      >
                        {application.promoterId}
                      </TableCell>
                      <TableCell
                        className={cn(warningStates.includes(application.state) ? warningStyles : '')}
                      >
                        {t(`resources.affiliations.fields.affiliationTypes.${application.affiliationType}`)}
                      </TableCell>
                      <TableCell
                        className={cn(warningStates.includes(application.state) ? warningStyles : '')}
                      >
                        {t(`resources.affiliations.fields.applicationTypes.${application.applicationType}`)}
                      </TableCell>
                      <TableCell
                        className={cn(warningStates.includes(application.state) ? warningStyles : '')}
                      >
                        {t(`resources.affiliations.fields.statusTypes.${application.state}`)}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={cn(warningStates.includes(application.state) ? warningStyles : '')}
                      >
                        {getDateLocaleFromString(application.updatedAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <div className={styles.root}>
          <CustomButton onClick={onClose} label="Cancelar" isWhiteButton />
          <CustomButton onClick={onContinue} label="Aceptar" disabled={shouldDisableContinue || disableContinue} />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RepeatabilityModal;
