import React from 'react';
import './Rectangulo.scss';
import { useLocation } from 'react-router-dom';

export default function Rectangulo() {
  const location = useLocation();
  // const on = location.pathname === '/on';
  const on = location.pathname.startsWith('/on');
  // const binaria = location.pathname === '/binaria';
  const binaria = location.pathname.startsWith('/binaria');
  let color = '';
  if (on) {
    color = 'color-on';
  } else if (binaria) {
    color = 'color-binaria';
  } else {
    color = 'color-osde';
  }
  // const color = location.pathname === '/binaria' ? 'color-binaria' : 'color-osde';
  const clases = `rectangulo ${color}`;

  return <span className={clases} />;
}
