/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useEffect } from 'react';
import { useTranslate, useMutation } from 'react-admin';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { GET_HISTORIC_SOLICITUD_F2 } from '~config/providers/constants';
import { momentDateTimeToString } from '~utils/date';
import { DATE_TIME_FORMAT_SHORT } from '~constants/datetime';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import { useDispatch, useSelector } from '~contexts/ModalContext';

import { useStyles } from './styles';

interface HistoricEvent {
  datetimeState: string;
  id: number;
  responsible: number;
  newState: string;
}

function HistoricSolicitudF2Modal() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const currentAffiliationId = useSelector(state => state.data?.id);
  const styles = useStyles();

  const handleClose = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.HISTORIC_STEPS));
  }, [dispatch]);

  const [getHistoric, { data, loading, error }] = useMutation({
    type: GET_HISTORIC_SOLICITUD_F2,
    resource: 'get',
    payload: { id: currentAffiliationId }
  });

  useEffect(() => {
    if (currentAffiliationId) {
      getHistoric();
    }
  }, [currentAffiliationId, getHistoric]);

  return (
    <CustomModal
      title={t('resources.solicitudF2.modals.titleHistorial')}
      modalName={MODAL_NAMES.HISTORIC_STEPS}
      onClose={handleClose}
      isInfo
    >
      <ModalInfoInnerContainer loading={loading} error={error} onRetry={getHistoric}>
        <>
          {data?.map(({ id, responsible, newState, datetimeState }: HistoricEvent) => (
            <div className={`full-width ${styles.infoContainer}`} key={id}>
              <p className={styles.textProspect}>
                {Number.isInteger(Number(responsible))
                  ? `${t('resources.solicitudF2.fields.applicationId')}: ${responsible}`
                  : responsible}
              </p>
              <p className={styles.textStatus}>{t(`resources.solicitudF2.fields.statusTypes.${newState}`)}</p>
              <p className={styles.textTime}>
                {momentDateTimeToString(datetimeState, DATE_TIME_FORMAT_SHORT)}
              </p>
            </div>
          ))}
        </>
      </ModalInfoInnerContainer>
    </CustomModal>
  );
}

export default HistoricSolicitudF2Modal;
