import React from 'react';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

interface Props {
  textContent: string;
}

const LoadingModal = ({ textContent }: Props) => {
  const styles = useStyles();
  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown>
      <div className={styles.container}>
        <CircularProgress />
        <DialogContent className={styles.text}>{textContent || 'Cargando'}</DialogContent>
      </div>
    </Dialog>
  );
};

export default LoadingModal;
