import React, { useCallback, useState } from 'react';
import { useTranslate, TextField, FunctionField } from 'react-admin';
import WithProps from '~components/WithProps';
import CustomTable from '~components/CustomTable';
import StatusField from '~screens/SolicitudesF2/components/StatusField';
import { Nullable, SolicitudF2Request } from '~utils/types';
import useModal from '~app/hooks/useModal';
import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';
import { EMAIL_CONFIG, SOLICITUDES_F2_REQUESTS_FIELDS } from '~screens/SolicitudesF2/constants';
import { getSendEmailTexts, isAttorneyValidationPending } from '~screens/SolicitudesF2/utils';
import CustomModal from '~components/CustomModal';
import ActionField from '~screens/SolicitudesF2/components/ActionField';
import StatusRPA from '~screens/AffiliationRequests/screens/AffiliationRequestsList/StatusRPA';

import { useStyles } from './styles';
import SolicitudF2Filter from './components/SolicitudF2Filter';
import ResponsiveList from './components/ResponsiveList';
import HistoricSolicitudF2Modal from './components/HistoricSolicitudF2Modal';
import HistoricSolicitudF2Button from './components/HistoricSolicitudF2Button';
import TooltipPromoterName from './components/TooltipPromoterName';

interface Props {
  resource: string;
  rpaStatus: boolean;
}

interface RequestPayload {
  id?: number;
}

export default function SolicitudesF2List({ resource, ...props }: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const [solicitdudF2State, setSolicitdudF2State] = useState<Nullable<string> | undefined>(null);

  const handleOpen = useCallback((solicitud?: SolicitudF2Request) => {
    setSolicitdudF2State(solicitud?.state);
  }, []);

  const handleClose = useCallback(() => {
    setSolicitdudF2State(null);
  }, []);

  const [, dispatchInitialRequest, loadingInitial, onOpenModalInitial] = useModal<
    SolicitudF2Request,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD]
  });

  const [, dispatchValidationRequest, loadingValidation, onOpenModalValidation] = useModal<
    SolicitudF2Request,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[SOLICITUDES_REQUEST_STATUS.PENDING_USER_VALIDATION.COD]
  });

  return (
    <WithProps {...{ resource, ...props }}>
      {(withProps: Props) => (
        <>
          <CustomTable
            {...withProps}
            title={t('resources.solicitudF2.listName')}
            filters={<SolicitudF2Filter />}
            showDetail
            listComponent={ResponsiveList}
          >
            <TextField label="ID" source={SOLICITUDES_F2_REQUESTS_FIELDS.id} sortable={false} />
            <FunctionField
              label="Promotor"
              source={SOLICITUDES_F2_REQUESTS_FIELDS.promoterId}
              render={(affiliation: SolicitudF2Request) => <TooltipPromoterName {...affiliation} />}
            />
            <TextField
              label={t('resources.solicitudF2.fields.companyNameList')}
              source={SOLICITUDES_F2_REQUESTS_FIELDS.companyName}
              sortable={false}
            />
            <TextField
              label={t('resources.solicitudF2.fields.cuit')}
              source={SOLICITUDES_F2_REQUESTS_FIELDS.cuit}
              sortable={false}
            />
            <FunctionField label={t('resources.solicitudF2.fields.status')} render={StatusField} />
            <FunctionField label={t('Proceso RPA')} render={StatusRPA} />
            <FunctionField
              label={t('resources.solicitudF2.fields.action')}
              render={(solicitud: SolicitudF2Request) => (
                <div className={styles.actions}>
                  <HistoricSolicitudF2Button id={solicitud.id} stateDatetime={solicitud.stateDatetime} />
                  <ActionField
                    solicitud={solicitud}
                    onEnvioMailInicial={onOpenModalInitial}
                    onReenvioMailValidacion={onOpenModalValidation}
                  />
                </div>
              )}
            />
          </CustomTable>
          {solicitdudF2State && (
            <CustomModal
              title={t('resources.affiliations.modals.title')}
              modalName={EMAIL_CONFIG[solicitdudF2State].modalName}
              onConfirm={
                isAttorneyValidationPending(solicitdudF2State)
                  ? dispatchValidationRequest
                  : dispatchInitialRequest
              }
              loading={isAttorneyValidationPending(solicitdudF2State) ? loadingValidation : loadingInitial}
              onClose={handleClose}
              showCloseIcon={false}
            >
              <p className={styles.content}>{t(getSendEmailTexts(solicitdudF2State).modalMessage)}</p>
            </CustomModal>
          )}
          <HistoricSolicitudF2Modal />
        </>
      )}
    </WithProps>
  );
}
