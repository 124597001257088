import { makeStyles } from '@material-ui/core/styles';
import { GRAY } from '~constants/colors';

export const useStyles = makeStyles({
  content: {
    color: GRAY,
    fontSize: 14,
    lineHeight: '16x',
    marginBottom: '24px',
    textAlign: 'center',
    width: '100%'
  },
  hideLabel: {
    display: 'none'
  },
  promoterContent: {
    color: '#424242',
    fontWeight: 'bold'
  }
});
