/* eslint-disable max-lines */
/* eslint-disable max-params */
import { useState } from 'react';
import useListProviders, { CompanyPlanChoicesTypes } from '~config/hooks/useListProviders';
import {
  AFFILIATION_TYPES,
  PLAN_TYPES,
  HIRING_MODE_TYPES,
  INCREASE_PLAN_ALLOWED_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  COMPANY_PLAN_TYPES,
  APPLICATION_TYPES,
  APPLICATION_SUBTYPES,
  CORPORATIVE_PLAN_TYPES
} from '~constants/affiliations';
import { AFFILIATION_REQUESTS_FIELDS as ARFS } from '~screens/AffiliationRequests/constants';
import { getObjectValueFromString, setObjectValueFromString } from '~utils/object';
// eslint-disable-next-line import/namespace, import/named
import { AffiliationRequest, AffiliationRequestForm, User } from '~utils/types';
import { required as requiredValidator } from '~utils/inputValidations';

import { FormDataProps, Props } from '../affiliationFormDefinitions';

// activar código de desarrollo
const ENABLE_DEVELOPMENT_CODE = false;

const runIfDevelopmentEnabled = (f: () => void) => ENABLE_DEVELOPMENT_CODE && f();

const log = (...messageArgs: string[]) => {
  if (ENABLE_DEVELOPMENT_CODE) {
    // desarrollo: seguimiento
    // eslint-disable-next-line no-console
    console.log(...messageArgs);
  }
};

const stringify2 = (obj: any) => JSON.stringify(obj, undefined, 2);

type Dictionary = {
  [key: string]: any;
};

const correctValueInContainers = (containers: Dictionary) => (
  correctQ: boolean,
  fieldPath: string,
  newValue: string
) => {
  const correctIn = (targetContainer: any) => {
    const currentValue = getObjectValueFromString(fieldPath, targetContainer);
    if (currentValue !== newValue) {
      setObjectValueFromString(fieldPath, targetContainer, newValue);
      return { currentValue, newValue };
    }
    return undefined;
  };

  if (!correctQ) {
    return undefined as Dictionary | undefined;
  }

  const result = Object.keys(containers).reduce(
    (reduce: Dictionary, aKey) => ({ ...reduce, ...{ [aKey]: correctIn(containers[aKey]) } }),
    {} as Dictionary
  );

  runIfDevelopmentEnabled(() => {
    const logMessage = Object.keys(containers)
      .reduce(
        (reduce: string[], aKey) => [
          ...reduce,
          `for ${aKey}: ${result[aKey] ? `[${result[aKey].currentValue}-->${result[aKey].newValue}]` : '!!'}`
        ],
        [`correctValue: ${fieldPath}`]
      )
      .join(' ');

    log(logMessage);
  });

  return result;
};

// permite identificar la cantidad de actualizaciones.
//   usado con objetivos de de desarrollo
const updatesCount = (() => {
  let count = 0;
  return () => ++count;
})();

// eslint-disable-next-line id-length
const useAfiliationOptionsColaborator = (isEdit: boolean) => {
  const [isDirectGroup, setIsDirectGroup] = useState(false);
  const [showPlanType, setShowPlanType] = useState(false);
  const [showHiringMode, setShowHiringMode] = useState(false);
  const [showIncreasePlanAllowed, setShowIncreasePlanAllowed] = useState(false);
  const [showIsPaymentResponsible, setShowIsPaymentResponsible] = useState(false);
  const [showCompanyPlanSelector, setShowCompanyPlanSelector] = useState(false);
  const [showCompanyCuit, setShowCompanyCuit] = useState(false);
  const [previousAffiliationType, setPreviousAffiliationType] = useState('');
  const [previousPlanTypes, setPreviousPlanTypes] = useState('');
  const [isCorporative, setIsCorporative] = useState(false);
  const [isComplementacion, setIsComplementacion] = useState(false);
  const [isShowPlanEnable, setIsShowPlanEnable] = useState(false);

  enum CompanyPlanValidatorTypes {
    Empty,
    Required
  }
  const [companyPlanSelectorValidatorType, setCompanyPlanSelectorValidatorType] = useState(
    CompanyPlanValidatorTypes.Empty
  );
  const [companyPlanSelectorChoicesTypes, setCompanyPlanSelectorChoicesTypes] = useState(
    CompanyPlanChoicesTypes.Full
  );

  const { companyPlanChoices } = useListProviders();

  const companyPlanSelectorValidator = (type: CompanyPlanValidatorTypes) => {
    const emptyValidator = () => undefined;
    switch (type) {
      case CompanyPlanValidatorTypes.Required:
        return requiredValidator;
      case CompanyPlanValidatorTypes.Empty:
        return emptyValidator;
      default:
        // case unspected. Alert.
        // eslint-disable-next-line no-console
        console.warn('Value for formData.planTypes unspected.');
        return emptyValidator;
    }
  };

  type CompanyPlanSelectorOptionsType = {
    correctQ: boolean;
    newValue: string;
    show: boolean;
    choicesType: CompanyPlanChoicesTypes;
    validatorType: CompanyPlanValidatorTypes;
  };

  const updateStatesOptions = (
    isDirectGroupQ: boolean,
    isMonotaxQ: boolean,
    isCorporativeQ: boolean,
    isComplementacionQ: boolean,
    showPlanTypeQ: boolean,
    showHiringModeQ: boolean,
    showHiringModeT: boolean,
    companyPlanSelectorOptions: CompanyPlanSelectorOptionsType,
    showIncreasePlanAllowedQ: boolean,
    showIsPaymentResponsibleQ: boolean,
    showCompanyCuitQ: boolean
    // eslint-disable-next-line max-params
  ) => {
    const logUpdateState = (name: string, currentValue: any, newValue: any) => {
      if (currentValue !== newValue) {
        log(`Update state ${name} [${currentValue}-->${newValue}]`);
      }
    };

    logUpdateState('isDirectGroup', isDirectGroup, isDirectGroupQ);
    setIsDirectGroup(isDirectGroupQ);

    logUpdateState('showPlanType', showPlanType, showPlanTypeQ);
    setShowPlanType(showPlanTypeQ);

    logUpdateState('showHiringMode', showHiringMode, showHiringModeQ);
    setShowHiringMode(showHiringModeQ);

    logUpdateState(
      'showCompanyPlanSelector',
      showCompanyPlanSelector,
      isMonotaxQ ? false : companyPlanSelectorOptions.show
    );
    setShowCompanyPlanSelector(isMonotaxQ ? false : companyPlanSelectorOptions.show);

    logUpdateState(
      'companyPlanSelectorValidatorType',
      companyPlanSelectorValidatorType,
      companyPlanSelectorOptions.validatorType
    );
    setCompanyPlanSelectorValidatorType(companyPlanSelectorOptions.validatorType);

    logUpdateState(
      'companyPlanSelectorChoicesTypes',
      companyPlanSelectorChoicesTypes,
      companyPlanSelectorOptions.choicesType
    );
    setCompanyPlanSelectorChoicesTypes(companyPlanSelectorOptions.choicesType);

    logUpdateState('showIncreasePlanAllowed', showIncreasePlanAllowed, showIncreasePlanAllowedQ);
    setShowIncreasePlanAllowed(showIncreasePlanAllowedQ);

    logUpdateState('showIsPaymentResponsible', showIsPaymentResponsible, showIsPaymentResponsibleQ);
    setShowIsPaymentResponsible(showIsPaymentResponsibleQ);

    logUpdateState('showCompanyCuit', showCompanyCuit, showCompanyCuitQ);
    setShowCompanyCuit(showCompanyCuitQ);

    setIsCorporative(isCorporativeQ);
    setIsComplementacion(isComplementacionQ);
    setIsShowPlanEnable(showHiringModeT);
  };

  function companyPlanOptionsCalculate(
    showHiringModeQ: boolean,
    ishiringModePLAQ: boolean,
    isCorporativeQ: boolean,
    formData: AffiliationRequestForm
  ): CompanyPlanSelectorOptionsType {
    const sOptions: CompanyPlanSelectorOptionsType = {
      correctQ: false,
      newValue: '',
      show: false,
      choicesType: isCorporativeQ
        ? CompanyPlanChoicesTypes.NotP8360AndP8430AndP8260
        : CompanyPlanChoicesTypes.Full,
      validatorType: ishiringModePLAQ ? CompanyPlanValidatorTypes.Required : CompanyPlanValidatorTypes.Empty
    };

    const listOfIdsForList = companyPlanChoices(sOptions.choicesType).map(({ id }) => id);
    sOptions.correctQ = ishiringModePLAQ && !listOfIdsForList.includes(formData.companyPlan!);
    if (sOptions.correctQ) {
      sOptions.newValue = COMPANY_PLAN_TYPES.P210;
    }
    if (isCorporative) {
      sOptions.newValue = CORPORATIVE_PLAN_TYPES.P8360;
    }

    sOptions.show = showHiringModeQ;

    return sOptions;
  }

  // eslint-disable-next-line complexity, max-statements, id-length
  const checkAndCorrectAfiliationOptions = (props: Props) => ({ formData }: FormDataProps) => {
    const currentCount = updatesCount();
    const containers = { formData, recordInProps: props.record! };
    const correctValue = correctValueInContainers(containers);

    log(`updatesCount: ${currentCount}`, stringify2(containers));

    // only the first time in create mode props.record?.user?.documentType is undefined
    if (!props.record?.user?.documentType && !props.isEdit) {
      log('TRANSFIRIENDO DE FORMDATA A RECORD');

      // check record in props
      if (!props.record) {
        // record is not on props, create record
        props.record = {} as AffiliationRequest;
      }

      // copy values from formData into record, mantain values in record
      //   mantain the user object from props.record, if it undefined mantain undefined
      Object.assign(props.record, {
        ...formData,
        ...props.record,
        user: (props?.record as any)?.user
      });

      // check record.user in props
      if (!props.record.user) {
        // user is not on props.record, create user
        props.record.user = {} as User;
      }

      // copy values from formData into record, mantain values in record
      //   mantain the user object from props.record, if it undefined mantain undefined
      Object.assign(props.record.user, formData.user);

      log(`afterUpdateRecord: ${currentCount}`, stringify2(containers));
    }

    const type = formData.affiliationType;
    const isAPO = formData.hiringMode === 'APO';
    const typesWithHiringMode = [
      AFFILIATION_TYPES.DIRECT_GROUP,
      AFFILIATION_TYPES.AGREEMENT,
      AFFILIATION_TYPES.MANDATORY,
      AFFILIATION_TYPES.COMPLEMENT
    ];
    const hasHiringMode = type && typesWithHiringMode.includes(type);
    const isTypeDifferent = type && type !== previousAffiliationType;

    const { planTypes } = formData;
    const isPlanTypesDifferent = planTypes && planTypes !== previousPlanTypes;

    if (type && !previousAffiliationType) {
      setPreviousAffiliationType(type);
    } else if (planTypes && !previousPlanTypes) {
      setPreviousPlanTypes(planTypes);
    } else if (hasHiringMode && !isEdit && (isTypeDifferent || isPlanTypesDifferent)) {
      correctValue(true, ARFS.hiringMode, HIRING_MODE_TYPES.PLA);
      setPreviousAffiliationType(type || '');
      setPreviousPlanTypes(planTypes || '');
    }

    const isObligatorioQ = type === AFFILIATION_TYPES.MANDATORY;
    const isConvenioQ = type === AFFILIATION_TYPES.AGREEMENT;
    const isComplementacionQ = type === AFFILIATION_TYPES.COMPLEMENT;
    const isDirectGroupQ = type === AFFILIATION_TYPES.DIRECT_GROUP;
    const isDirectQ = type === AFFILIATION_TYPES.DIRECT;
    const isMonotaxQ = type === AFFILIATION_TYPES.MONOTAX;

    const isCorporativeQ = PLAN_TYPES.CORPORATIVO === formData.planTypes;

    const showPlanTypeQ = isObligatorioQ || isConvenioQ || isDirectGroupQ;
    const showHiringModeT = (showPlanTypeQ || isComplementacionQ) && !isAPO;
    const showHiringModeQ = showPlanTypeQ || isComplementacionQ;

    const correctPlanTypesTo0Q = isDirectQ;
    const correctPlanTypesToBinarioQ =
      !correctPlanTypesTo0Q &&
      showPlanTypeQ &&
      !Object.values(PLAN_TYPES).includes(formData.planTypes || '0');

    correctValue(correctPlanTypesTo0Q, ARFS.planTypes, '0');
    // eslint-disable-next-line prettier/prettier
    correctValue(correctPlanTypesToBinarioQ, ARFS.planTypes, PLAN_TYPES.BINARIO);
    correctValue(isComplementacionQ, ARFS.planTypes, PLAN_TYPES.BINARIO);

    correctValue(isDirectQ, ARFS.hiringMode, HIRING_MODE_TYPES.APO);
    // eslint-disable-next-line prettier/prettier
    correctValue(isDirectGroupQ, ARFS.hiringMode, HIRING_MODE_TYPES.PLA);

    const ishiringModePLAQ = formData.hiringMode === HIRING_MODE_TYPES.PLA;

    const companyPlanSelectorOptions: CompanyPlanSelectorOptionsType = companyPlanOptionsCalculate(
      showHiringModeQ,
      ishiringModePLAQ,
      isCorporativeQ,
      formData
    );

    // eslint-disable-next-line prettier/prettier
    correctValue(companyPlanSelectorOptions.correctQ, ARFS.companyPlan, companyPlanSelectorOptions.newValue);

    const isCompanyPlanP510Q = formData.companyPlan === COMPANY_PLAN_TYPES.P510;

    const showIncPlanAndPlanResponsibleQ = ishiringModePLAQ && showPlanTypeQ && !isDirectGroupQ;

    const showIncreasePlanAllowedQ = !isCompanyPlanP510Q && showIncPlanAndPlanResponsibleQ;

    const correctIncreasePlanAllowedQ =
      isDirectQ ||
      !Object.values(INCREASE_PLAN_ALLOWED_TYPES).includes(formData.increasePlanAllowed!) ||
      formData.increasePlanAllowed?.toString() === INCREASE_PLAN_ALLOWED_TYPES.true;
    // eslint-disable-next-line id-length
    const correctIncreasePlanAllowedValue = formData.increasePlanAllowed || INCREASE_PLAN_ALLOWED_TYPES.false;
    correctValue(correctIncreasePlanAllowedQ, ARFS.increasePlanAllowed, correctIncreasePlanAllowedValue);

    const correctIsPaymentResponsibleQ =
      isDirectQ ||
      !Object.values(IS_PAYMENT_RESPONSIBLE_TYPES).includes(formData.increasePlanAllowed!) ||
      formData.isPaymentResponsible?.toString() === IS_PAYMENT_RESPONSIBLE_TYPES.true;
    // eslint-disable-next-line id-length
    const correctIsPaymentResponsibleValue =
      formData.isPaymentResponsible || IS_PAYMENT_RESPONSIBLE_TYPES.false;
    correctValue(correctIsPaymentResponsibleQ, ARFS.isPaymentResponsible, correctIsPaymentResponsibleValue);

    const isNoincreasePlanAllowedQ =
      formData.increasePlanAllowed?.toString() === INCREASE_PLAN_ALLOWED_TYPES.false;
    const showIsPaymentResponsibleQ = isNoincreasePlanAllowedQ && showIncPlanAndPlanResponsibleQ;

    const showCompanyCuitQ = isObligatorioQ || isConvenioQ || isDirectGroupQ;

    const isOthers = formData.applicationType === APPLICATION_TYPES.OTHERS;
    const isCurrentSubtypeNull = formData.applicationSubtype === null;

    if (isOthers && isCurrentSubtypeNull) {
      const defaultApplicationSubtype = APPLICATION_SUBTYPES.CAMBIO_DE_CONDICION_IVA;
      correctValue(isCurrentSubtypeNull, ARFS.applicationSubtype, defaultApplicationSubtype);
    }

    updateStatesOptions(
      isDirectGroupQ,
      isMonotaxQ,
      isCorporativeQ,
      isComplementacionQ,
      showPlanTypeQ,
      showHiringModeQ,
      showHiringModeT,
      companyPlanSelectorOptions,
      showIncreasePlanAllowedQ,
      showIsPaymentResponsibleQ,
      showCompanyCuitQ
    );
  };

  /* eslint-disable id-length */
  return {
    isDirectGroup,
    isCorporative,
    isComplementacion,
    isShowPlanEnable,
    showPlanType,
    showHiringMode,
    showIncreasePlanAllowed,
    showIsPaymentResponsible,
    showCompanyPlanSelector,
    showCompanyCuit,
    companyPlanSelectorValidatorType,
    companyPlanSelectorChoicesTypes,
    companyPlanSelectorValidator,
    checkAndCorrectAfiliationOptions
  };
  /* eslint-enable id-length */
};

export default useAfiliationOptionsColaborator;
