import React, { useState } from 'react';
import { SimpleForm } from 'react-admin';
import InputLabel from '~components/InputLabel';
import { useFormStyles, useCommonStyles } from '~app/styles';
import Toolbar from '~components/Toolbar';
import { email, requiredField as requiredValidator } from '~utils/inputValidations';
import { MERLIN_VALIDATOR_FORM } from '~screens/MerlinValidator/constants';
import { getEmailValidation } from '~services/OsdeService';

const STATUS_OK = 200;
const STATUS_EI = 'EI';
const STATUS_ES = 'ES';

function MerlinValidatorForm({ props }: any) {
  const { isEdit, isEditing, ...rest } = props;
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const handleOnChange = () => {
    setError('');
    setMessage('');
  };

  const saving = (values: { email: string }) => {
    setError('');
    setMessage('');
    setLoading(true);
    getEmailValidation(values.email)
      .then(response => {
        if (response.status === STATUS_OK) {
          if (response.data.status === STATUS_EI) {
            setError('E-mail inválido');
          } else if (response.data.status === STATUS_ES) {
            setError('Hay un error de sintaxis en la cuenta de correo electrónico');
          } else {
            setMessage('E-mail válido');
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setError('Ocurrio un error');
        setLoading(false);
      });
  };

  return (
    <div className={commonStyles.flex}>
      <div>
        <h1 className={commonStyles.titleDatosPago}>Validar E-mail</h1>
        <SimpleForm
          {...rest}
          className={styles.form}
          save={saving}
          saving={loading}
          toolbar={isEditing || !isEdit ? <Toolbar label="Validar" {...props} /> : null}
          validateOnBlur
        >
          <InputLabel
            onChange={handleOnChange}
            label="E-mail"
            className={commonStyles.row}
            source={MERLIN_VALIDATOR_FORM.email}
            validate={[requiredValidator, email]}
            editEnabled
            type="text"
          />
          <span className={commonStyles.errorMessage}>{error}</span>
          <span className={commonStyles.successMessage}>{message}</span>
        </SimpleForm>
      </div>
    </div>
  );
}

export default MerlinValidatorForm;
