import { makeStyles } from '@material-ui/core/styles';
import {
  PRIMARY_BLUE,
  PRIMARY_BLUE_OPACITY,
  WHITE,
  PRIMARY_RED,
  PRIMARY_RED_OPACITY
} from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  buttonDownloadZip: {
    width: 'max-content',
    backgroundColor: PRIMARY_BLUE,
    color: WHITE,
    borderRadius: '4px',
    padding: '20px',
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_BLUE_OPACITY,
      color: WHITE
    }
  },
  button: {
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  buttonFinished: {
    marginBottom: 10
  },
  buttonRevision: {
    border: `1px solid ${PRIMARY_BLUE}`,
    backgroundColor: WHITE,
    '&:hover, &:focus': {
      backgroundColor: WHITE
    },
    '& span': {
      color: PRIMARY_BLUE
    },
    marginBottom: 10
  },
  buttonCanceled: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: PRIMARY_RED,
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: WHITE
      }
    },
    '& span': {
      color: WHITE
    }
  },
  buttonZcar: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: '#F5E4E4',
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: PRIMARY_BLUE
      }
    },
    '& span': {
      color: PRIMARY_BLUE
    },
    marginBottom: 10
  },
  separation: {
    marginBottom: '40px'
  },
  modalContent: {
    maxWidth: '75%',
    padding: '32px 50px'
  },
  spacing: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px'
    }
  },
  icon: {
    flex: 1,
    padding: 0,
    margin: '5px'
  }
}));
