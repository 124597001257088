/* eslint-disable react/button-has-type */
import React from 'react';
import { useTranslate } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  getApplicationType,
  getAffiliationType
} from '~screens/AffiliationRequests/components/AffiliationForm/utils';
import { useMediaQuery, Theme } from '@material-ui/core';
import MasiveUploadPopUp from '~components/BulkUploadPopUp/index';

import StatusDetail from './StatusDetail';
import { useStyles } from './styles';
import ResponsiveListDetail from './ResponsiveListDetail';
import AltaDetails from './AltaDetail';

function TablaDetalles(props: any) {
  const styles = useStyles();
  const t = useTranslate();
  const { data } = props;
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [click, setClick] = React.useState<boolean>(false);
  const [rowDetail, setRowDetail] = React.useState<object>({});

  const handleClick = (e: React.MouseEvent<HTMLElement>, row: object) => {
    e.preventDefault();
    setRowDetail(row);
    setClick(true);
  };

  const handleClose = (state: boolean) => {
    setClick(state);
  };

  return (
    <>
      {isXSmall ? (
        data.map((detail: any) => (
          <div
            key={detail.nroRegistro}
            onClick={(e: any) => {
              handleClick(e, detail);
            }}
          >
            <ResponsiveListDetail record={detail} />
          </div>
        ))
      ) : (
        <TableContainer component={Paper}>
          <Table className={styles.table} aria-label="simple table">
            <TableHead className={styles.detalle}>
              <TableRow>
                <TableCell className={styles.detalle}>
                  {t('resources.altasmasivas.detail.nroRegistro')}
                </TableCell>
                <TableCell className={styles.detalle} align="left">
                  {t('resources.altasmasivas.detail.nombre')}
                </TableCell>
                <TableCell className={styles.detalle} align="left">
                  {t('resources.altasmasivas.detail.apellido')}
                </TableCell>
                <TableCell className={styles.detalle} align="left">
                  {t('resources.altasmasivas.detail.tipo')}
                </TableCell>
                <TableCell className={styles.detalle} align="left">
                  {t('resources.altasmasivas.detail.formulario')}
                </TableCell>
                <TableCell className={styles.detalle} align="left">
                  {t('resources.altasmasivas.detail.idSolicitud')}
                </TableCell>
                <TableCell className={styles.detalle} align="left">
                  {t('resources.altasmasivas.detail.estado')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any) => (
                <TableRow
                  key={row.id}
                  onClick={(e: any) => {
                    handleClick(e, row);
                  }}
                >
                  <TableCell component="th" scope="row" className={styles.detalleRow}>
                    {row.nroRegistro}
                  </TableCell>
                  <TableCell className={styles.detalleRow} align="left">
                    {row.nombre}
                  </TableCell>
                  <TableCell className={styles.detalleRow} align="left">
                    {row.apellido}
                  </TableCell>
                  <TableCell className={styles.detalleRow} align="left">
                    {t(getAffiliationType(row.prospectTipo))}
                  </TableCell>
                  <TableCell className={styles.detalleRow} align="left">
                    {t(getApplicationType(row.prospectTipoFormulario))}
                  </TableCell>
                  <TableCell className={styles.detalleRow} align="left">
                    {row.idSolcitudCreada}
                  </TableCell>
                  <TableCell className={styles.detalleRow} align="left">
                    <StatusDetail estado={row.estadoProceso} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <MasiveUploadPopUp touched={click} callback={handleClose}>
        <AltaDetails data={rowDetail} />
      </MasiveUploadPopUp>
    </>
  );
}

export default TablaDetalles;
