import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, PRIMARY_RED, PRIMARY_GREEN } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  label: {
    color: PRIMARY_BLUE,
    fontSize: '16px',
    marginRight: '22px'
  },
  point: {
    backgroundColor: PRIMARY_GREEN,
    borderRadius: '6px',
    height: '12px',
    marginRight: '8px',
    width: '12px',
    '&.hasInsurance': {
      backgroundColor: PRIMARY_RED
    }
  }
});
