import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, PRIMARY_RED } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    flexGrow: 1
  },
  label: {
    marginTop: '20px',
    marginBottom: '20px',
    color: PRIMARY_BLUE,
    fontWeight: 'bold'
  },
  labelError: {
    color: PRIMARY_RED,
    fontSize: '12px'
  },
  input: {
    border: '1px solid #e6e6e6',
    padding: '10px'
  },
  inputError: {
    border: `1px solid ${PRIMARY_RED}`,
    padding: '10px'
  },
  inputCheckbox: {
    marginBottom: '20px',
    marginRight: '10px'
  },
  textArea: {
    marginBottom: '20px'
  },
  wrapperButton: {
    overflow: 'hidden'
  },
  button: {
    marginTop: '20px',
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  checkbox: {
    width: '110%',
    '& label': {
      textOverflow: 'ellipsis'
    }
  }
});
