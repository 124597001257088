/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { FileCopy } from '@material-ui/icons';
import { useCommonStyles, useFormStyles } from '~app/styles';
import InputLabel from '~components/InputLabel';
import Toolbar from '~components/Toolbar';
import { EDIT_PARTIAL_FORM } from '~screens/EditarPartialData/constants';
import { getPartialDataService } from '~services/OsdeService';
import { requiredField as requiredValidator, numericalDigits } from '~utils/inputValidations';
import { IconButton } from '@material-ui/core';
import { useNotify } from 'ra-core';

import { useStyles } from '../../styles';

interface Props {
  loading: boolean;
  setLoading: any;
  error: string;
  setError: any;
  partialDataResult: string;
  setPartialDataResult: any;
  idSolicitud: string;
  setIdSolicitud: any;
  isEditing: boolean;
  isEdit: boolean;
}

function GetPartialForm({
  loading,
  setLoading,
  error,
  setError,
  partialDataResult,
  setPartialDataResult,
  idSolicitud,
  setIdSolicitud,
  isEditing,
  isEdit
}: Props) {
  const notify = useNotify();
  const t = useTranslate();
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const partialStyles = useStyles();

  const setErrorMessage = (response: any) => {
    if (response.data.errors[0]?.message) {
      if (typeof response.data.errors[0]?.message === 'string') {
        return response.data.errors[0]?.message;
      }
    }
    return 'Ocurrio un error';
  };

  const getPartialData = async (values: any) => {
    setLoading(true);
    setError('');
    setPartialDataResult('');
    setIdSolicitud(values.idSolicitud);

    const response: any = await getPartialDataService(values.idSolicitud);
    if (response.data && response.data.errors) {
      setError(setErrorMessage(response));
    } else {
      setPartialDataResult(JSON.stringify(response.data.partial_data));
    }
    setLoading(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(partialDataResult);
    notify(t('resources.editarpartialdata.editPartialData.copyToClipboardMessage'));
  };

  return (
    <SimpleForm
      className={styles.form}
      save={getPartialData}
      saving={loading}
      toolbar={isEditing || !isEdit ? <Toolbar label="Consultar" /> : null}
      validateOnBlur
    >
      <InputLabel
        label="ID Solicitud"
        className={commonStyles.row}
        source={EDIT_PARTIAL_FORM.idSolicitud}
        validate={[requiredValidator, numericalDigits]}
        editEnabled
        type="text"
        defaultValue={idSolicitud}
      />

      {partialDataResult && (
        <>
          <InputLabel
            label="Partial Data"
            className={commonStyles.row}
            source={EDIT_PARTIAL_FORM.partialData}
            validate={[requiredValidator]}
            disabled
            editEnabled
            type="text"
            defaultValue={partialDataResult}
          />

          <IconButton
            onClick={copyToClipboard}
            className={partialStyles.icon}
            edge="end"
            color="inherit"
            aria-label="menu"
          >
            <FileCopy />
          </IconButton>
        </>
      )}

      <span className={commonStyles.errorMessage}>{error}</span>
    </SimpleForm>
  );
}

export default GetPartialForm;
