/* eslint-disable complexity */
import React, { useCallback, useState } from 'react';
import { SimpleShowLayout, useTranslate } from 'react-admin';
import { useCommonStyles } from '~app/styles';
import DetailActionButtons from '~components/DetailActionButtons';
import PromoterForm from '~screens/Promoters/components/PromoterForm';
import { Promoter, PromoterForm as PromoterFormInterface } from '~utils/types';

interface Props {
  record: PromoterFormInterface;
  resource: string;
  permissions: Promoter;
}

const PromoterDetail = (extra: Props) => {
  const commonStyles = useCommonStyles();
  const t = useTranslate();
  const [isEditing, setIsEditing] = useState(false);

  const handleSetIsEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <>
      <DetailActionButtons />
      <SimpleShowLayout {...extra}>
        <>
          <h1 className={commonStyles.title}>{t('resources.promoters.detailTitle')}</h1>
        </>
        <PromoterForm
          {...extra}
          isEdit
          isEditing={isEditing}
          onSetEditing={handleSetIsEditing}
          permissions={extra.permissions}
        />
      </SimpleShowLayout>
    </>
  );
};

export default PromoterDetail;
