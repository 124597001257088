/* eslint-disable complexity */
export const errorCapturaDeDocumento = (error?: string) => {
  if (error === 'Reintentos excedidos [step:560]') {
    return 'Motivo: Supera máximo de intentos permitidos (step 560)';
  }
  if (error === 'Escanea documento de menor [step:400]') {
    return 'Motivo: DNI de un menor de edad (step 400)';
  }
  if (error === 'DNI diferente al solicitado [step:401]') {
    return 'Motivo: DNI diferente al solicitado (step 401)';
  }
  if (error === 'Browser incompatible [step:445]') {
    return 'Motivo: Supera máximo de intentos permitidos (Browser incompatible, step 445)';
  }
  if (error === 'Deteccion de segunda validacion en transaccion finalizada [step:600]') {
    return 'Motivo: Deteccion de un segunda validacion en transaccion finalizada (step 600)';
  }
  if (error === 'Documento no vigente') {
    return 'Motivo: El DNI no coincide con los datos del Renaper (Documento no vigente)';
  }
  if (error === 'Validacion de indentidad sin exito') {
    return 'Motivo: El prospecto no fue encontrado en la base del Renaper';
  }
  if (error === 'Sin acceso a la camara') {
    return 'Motivo: El prospecto no aceptó el acceso de su cámara';
  }
  if (error === 'Prueba de vida sin exito') {
    return 'Motivo: El rostro no concuerda con el rostro del Renaper';
  }
  if (error === 'Cancelada por el usuario') {
    return 'Motivo: Cancelada por el prospecto';
  }
  if (error === 'Reintentos excedidos') {
    return 'Motivo: Supera máximo de intentos permitidos';
  }
  return 'Motivo: Supera máximo de intentos permitidos';
};
