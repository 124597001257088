import React, { ReactNode, useState, useEffect } from 'react';
import { List } from 'react-admin';
import { useMediaQuery, Theme, Tooltip } from '@material-ui/core';
import { Promoter } from '~utils/types';
import LogoOSDE from '~assets/logo_osde.svg';
import LogoBinaria from '~assets/logo_binaria.png';
import LogoON from '~assets/logo_on.svg';
import { useLocation } from 'react-router-dom';
import { VERSION_APP } from '~constants/app';

import ListActions from '../ListActions';

import LogoEnLista from './components/LogoEnLista/LogoEnLista';
import Datagrid from './components/Datagrid';
import EmptyTable, { EmptyTableProps } from './components/EmptyTable';
import { useStyles, useClasses } from './styles';

interface Props {
  bulkActionButtons?: ReactNode;
  filters?: ReactNode;
  selectable?: boolean;
  showDetail?: boolean;
  children: ReactNode[] | ReactNode;
  sort?: object;
  showExport?: boolean;
  title?: string;
  listComponent: React.ComponentType<any>;
  permissions?: Promoter;
}

function CustomTable({
  bulkActionButtons,
  filters,
  children: TableRows,
  selectable = false,
  showDetail = false,
  sort = {},
  title,
  showExport,
  listComponent,
  ...props
}: Props) {
  const styles = useStyles();
  const classes = useClasses();
  const [loaded, setLoaded] = useState(false);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const location = useLocation();
  const isBinaria = location.pathname === '/binaria';
  const isON = location.pathname === '/on';
  const isOSDE = !isBinaria && !isON;
  useEffect(() => {
    if (props.permissions && !loaded) {
      setLoaded(true);
    }
  }, [loaded, props.permissions]);

  if (!loaded) {
    return null;
  }

  return (
    <>
      {isXSmall && (
        <h6 className={styles.tableTitleMobile}>
          {isBinaria && (
            <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
              <img src={LogoBinaria} className={styles.logoBinaria} />
            </Tooltip>
          )}
          {isOSDE && (
            <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
              <img src={LogoOSDE} className={styles.logoOsde} />
            </Tooltip>
          )}
          {isON && (
            <Tooltip title={`versión ${VERSION_APP}`} placement="right-end">
              <img src={LogoON} className={styles.logoON} />
            </Tooltip>
          )}
        </h6>
      )}
      <List
        {...props}
        title={
          <LogoEnLista
            logoOsde={LogoOSDE}
            logoBinaria={LogoBinaria}
            logoON={LogoON}
            claseOsde={styles.logoOsde}
            claseBinaria={styles.logoBinaria}
            claseON={styles.logoON}
            title={title}
            isBinaria={isBinaria}
            isOSDE={isOSDE}
            isON={isON}
          />
        }
        actions={<ListActions showExport={showExport} props={props} />}
        classes={classes}
        className={styles.spacing}
        bulkActionButtons={bulkActionButtons}
        filters={filters}
        empty={<EmptyTable {...(props as EmptyTableProps)} />}
        sort={sort}
      >
        <Datagrid selectable={selectable} showDetail={showDetail} listComponent={listComponent}>
          {TableRows}
        </Datagrid>
      </List>
    </>
  );
}

export default CustomTable;
