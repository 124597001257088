import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, DARK_GRAY } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '24px',
    width: '100%'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  label: {
    color: PRIMARY_BLUE,
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  value: {
    color: DARK_GRAY,
    fontSize: '14px'
  }
});
