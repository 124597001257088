/* eslint-disable max-lines */
/* eslint-disable import/named */
/* eslint-disable import/namespace */
/* eslint-disable no-throw-literal */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { ApiErrorResponse } from 'apisauce';
import { ErrorResponse, PromoterForm, SortParams } from '~utils/types';
import { camelToSnake } from '~utils/strings';
import { STATUS_CODES } from '~config/api/constants';
import { MAIL_ERRORS, FILE_NOT_FOUND_BUCKET_ERROR } from '~config/providers/constants';
import {
  AFFILIATION_REQUEST_STATUS,
  AFFILIATION_TYPES,
  APPLICATION_TYPES,
  HIRING_MODE_TYPES,
  INCREASE_PLAN_ALLOWED_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  PLAN_TYPES,
  SOURCE_APPICATION
} from '~constants/affiliations';
import { momentDateTimeToString } from '~utils/date';
// import { DATE_TIME_FORMAT_SHORT } from '~constants/datetime';
import { FILIALES_LIST } from '~constants/filiales';
import { CAP_LIST } from '~constants/filialesCaps';
import { isLocalhost } from '~utils/isLocalhost';
import { forIn } from 'lodash';

export const parseResponse = (response: any) => ({
  data: response.data.page,
  total: response.data.totalCount
});

export const setListSort = (sort: SortParams) => {
  if (sort?.field) {
    return { orderColumn: camelToSnake(sort.field), orderType: sort.order };
  }
  return {};
};

export const denormalizeApplication = (values: any, isEdit = false, fileData?: any) => {
  const {
    affiliationType,
    planValidityDate,
    promoterId,
    applicationType,
    applicationSubtype,
    isSuperAdmin,
    state,
    hiringMode,
    increasePlanAllowed,
    companyPlan,
    planTypes,
    isPaymentResponsible,
    details,
    idContrato,
    datosTitularContrato,
    cuit,
    sourceApplication
  } = values;

  const { firstName, lastName, documentNumber, documentType, phoneNumber, email } = values.user;
  const zero = 0;

  function isEditReturn() {
    // TODO -elinar al resolver el bug por la no grabación de isPaymentResponsible-
    // se contempla para DIRECT_GROUP almacenar siempre isPaymentResponsible pero debiera revisarse para
    // todas las opciones posibles que otras cosas no se estan grabando.
    // La forma de decidir como se estructura la generación del json esta relacionado a bug reportado por esta
    // no-grabación que provoca el no enviar los campos.
    // Observación: en DIRECT_GROUP siempre es 'hiringMode === HIRING_MODE_TYPES.PLA'
    const isDirectGroup = AFFILIATION_TYPES.DIRECT_GROUP === affiliationType;

    return {
      email,
      firstName,
      lastName,
      details,
      phoneNumber: `${phoneNumber || ''}`,
      ...((isSuperAdmin || sourceApplication === SOURCE_APPICATION.AUTOGESTION) && { promoterId }),
      ...((state === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING ||
        state === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT) && {
        optionalData: {
          affiliationType,
          applicationType,
          ...(applicationType === APPLICATION_TYPES.OTHERS && {
            applicationSubtype: applicationSubtype ? applicationSubtype : ''
          }),
          planValidityDate: momentDateTimeToString(planValidityDate),
          ...((APPLICATION_TYPES.BENEFICIARY === applicationType ||
            APPLICATION_TYPES.OTHERS === applicationType) && {
            datosTitularContrato,
            idContrato
          }),
          ...(AFFILIATION_TYPES.DIRECT === affiliationType && {
            hiringMode,
            increasePlanAllowed: increasePlanAllowed.toString() === INCREASE_PLAN_ALLOWED_TYPES.true,
            companyPlan: zero,
            planTypes: zero,
            isPaymentResponsible: isPaymentResponsible.toString() === IS_PAYMENT_RESPONSIBLE_TYPES.true
          }),
          ...([
            AFFILIATION_TYPES.MANDATORY,
            AFFILIATION_TYPES.AGREEMENT,
            AFFILIATION_TYPES.DIRECT_GROUP
          ].includes(affiliationType) && { cuit }),
          ...([
            AFFILIATION_TYPES.MANDATORY,
            AFFILIATION_TYPES.COMPLEMENT,
            AFFILIATION_TYPES.AGREEMENT,
            AFFILIATION_TYPES.DIRECT_GROUP
          ].includes(affiliationType) && {
            ...(hiringMode === HIRING_MODE_TYPES.PLA
              ? {
                  hiringMode,
                  increasePlanAllowed: increasePlanAllowed.toString() === INCREASE_PLAN_ALLOWED_TYPES.true,
                  companyPlan,
                  planTypes,
                  ...((increasePlanAllowed || isDirectGroup) && {
                    isPaymentResponsible:
                      isPaymentResponsible.toString() === IS_PAYMENT_RESPONSIBLE_TYPES.true
                  })
                }
              : {
                  hiringMode,
                  increasePlanAllowed: increasePlanAllowed.toString() === INCREASE_PLAN_ALLOWED_TYPES.true,
                  companyPlan: planTypes === PLAN_TYPES.BINARIO ? zero : companyPlan,
                  planTypes,
                  isPaymentResponsible: isPaymentResponsible.toString() === IS_PAYMENT_RESPONSIBLE_TYPES.true
                })
          })
        }
      })
    };
  }

  function notEditReturn() {
    // TODO -elinar al resolver el bug por la no grabación de isPaymentResponsible-
    // se contempla para DIRECT_GROUP almacenar siempre isPaymentResponsible pero debiera revisarse para
    // todas las opciones posibles que otras cosas no se estan grabando.
    // La forma de decidir como se estructura la generación del json esta relacionado a bug reportado por esta
    // no-grabación que provoca el no enviar los campos.
    // Observación: en DIRECT_GROUP siempre es 'hiringMode === HIRING_MODE_TYPES.PLA'
    const isDirectGroup = AFFILIATION_TYPES.DIRECT_GROUP === affiliationType;

    return {
      email,
      affiliationType,
      applicationType,
      applicationSubtype,
      planValidityDate: momentDateTimeToString(planValidityDate),
      promoterId,
      firstName,
      lastName,
      details,
      idContrato,
      datosTitularContrato,
      documentNumber: `${documentNumber}`,
      documentType,
      ...(phoneNumber && { phoneNumber: `${phoneNumber}` }),
      ...([AFFILIATION_TYPES.MANDATORY, AFFILIATION_TYPES.AGREEMENT, AFFILIATION_TYPES.DIRECT_GROUP].includes(
        affiliationType
      ) && { cuit }),
      ...([
        AFFILIATION_TYPES.MANDATORY,
        AFFILIATION_TYPES.COMPLEMENT,
        AFFILIATION_TYPES.AGREEMENT,
        AFFILIATION_TYPES.DIRECT_GROUP
      ].includes(affiliationType) && {
        ...(hiringMode === HIRING_MODE_TYPES.PLA
          ? {
              hiringMode,
              increasePlanAllowed: increasePlanAllowed.toString() === INCREASE_PLAN_ALLOWED_TYPES.true,
              companyPlan,
              planTypes,
              ...((increasePlanAllowed || isDirectGroup) && {
                isPaymentResponsible: isPaymentResponsible.toString() === IS_PAYMENT_RESPONSIBLE_TYPES.true
              })
            }
          : { hiringMode, companyPlan, planTypes })
      }),
      ...(fileData && { attachment: fileData.fileName }),
      ...(fileData && { attachContentType: fileData.contentType })
    };
  }

  if (isEdit) {
    return isEditReturn();
  }
  return notEditReturn();
};

export const denormalizeMassiveUpload = (value: any) => ({
  ...value,
  datos: value?.datos?.map((a: any) => {
    const keys: any = Object.keys(a);
    // eslint-disable-next-line no-param-reassign
    const values: any = Object.values(a).map((b: any) => (typeof b === 'number' ? (b = b.toString()) : b));
    const entries: any = {};
    keys.forEach((element: any, index: any) => (entries[element] = values[index]));
    return entries;
  })
});

export interface ApiDataErrorArray {
  errors: Array<{
    code: string;
  }>;
}

/* eslint-disable no-magic-numbers */
export const mockedService = (mock: any, alwaysPass = false) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const rnd = alwaysPass ? 5 : Math.random() * 10;
      if (rnd <= 5) {
        return resolve({ ok: true, data: mock });
      }

      return reject(new Error('Error'));
    }, 2500);
  });
/* eslint-enable no-magic-numbers */

export const returnApiError = (response: ApiErrorResponse<ErrorResponse>) => {
  const renderErrorMessage = (responseToRender: ApiErrorResponse<ErrorResponse>) => {
    // FIXME xErrores: se están encubriendo errores, en ambientes de desarrollo debieran mostrarse en el log
    if (responseToRender.data && responseToRender.data.errors) {
      if (isLocalhost()) {
        console.log(responseToRender.data.errors);
      }
      if (responseToRender.data.errors[0].code === MAIL_ERRORS.e3012.code) {
        return responseToRender.data.errors[0].message;
      }
      if (responseToRender.data.errors[0].code === FILE_NOT_FOUND_BUCKET_ERROR.e4401.code) {
        return responseToRender.data.errors[0].message;
      }
      return `errors.${responseToRender.data.errors[0].code}`;
    }
    if (responseToRender.status === STATUS_CODES.unauthorized) {
      return 'errors.unauthorized';
    }
    return 'errors.default';
  };

  if (response.status === STATUS_CODES.unauthorized || response.status === STATUS_CODES.unavailable) {
    return Promise.reject(response);
  }
  throw new Error(renderErrorMessage(response));
};

export const messageError = (response: ApiErrorResponse<ErrorResponse>) => {
  const messageFromResponse = (response4Message: ApiErrorResponse<ErrorResponse | any>) => {
    // FIXME xErrores: se están encubriendo errores, en ambientes de desarrollo debieran mostrarse en el log
    if (response4Message.data && response4Message.data.errors) {
      if (isLocalhost()) {
        console.log(response4Message.data.errors);
      }
      const msg = response4Message.data.errors[0].message;
      return msg;
    }
    if (response4Message.status === STATUS_CODES.unauthorized) {
      return 'errors.unauthorized';
    }
    return 'errors.default';
  };

  if (response.status === STATUS_CODES.unauthorized || response.status === STATUS_CODES.unavailable) {
    return Promise.reject(response);
  }
  // const details: any | null = response.data?.errors[0]?.detail || null;
  throw new Error(messageFromResponse(response));
};

export const messageBulkDetails = (response: ApiErrorResponse<ErrorResponse>) => {
  const messageFromResponse = (response4Message: ApiErrorResponse<ErrorResponse | any>) => {
    // FIXME xErrores: se están encubriendo errores, en ambientes de desarrollo debieran mostrarse en el log
    if (response4Message.data && response4Message.data.errors) {
      if (isLocalhost()) {
        console.log(response4Message.data.errors);
      }
      const msg = response4Message.data.errors[0].message;
      return msg;
    }
    if (response4Message.status === STATUS_CODES.unauthorized) {
      return 'errors.unauthorized';
    }
    return 'errors.default';
  };

  if (response.status === STATUS_CODES.unauthorized || response.status === STATUS_CODES.unavailable) {
    return Promise.reject(response);
  }
  const details: any | null = response.data?.errors[0]?.detail || null;
  if (details) {
    throw { cause: { message: messageFromResponse(response) }, detail: details };
  } else {
    throw new Error(messageFromResponse(response));
  }
};

const getFilialFromDesc = (filialCode: string) => {
  const filialesKeys = Object.keys(FILIALES_LIST);
  let result = '';
  filialesKeys.forEach(filial => {
    if (FILIALES_LIST[filial as keyof typeof FILIALES_LIST] === filialCode) {
      result = filial;
    }
  });
  return result;
};

const getCapFromDec = (filialCode: string, capCode: string) => {
  let nameCapital = '';
  forIn(CAP_LIST, (value, key) => {
    if (key === filialCode) {
      forIn(value, (keyCap, nameCap) => {
        if (keyCap === capCode) {
          nameCapital = nameCap;
          return false;
        }
        return true;
      });
      return false;
    }
    return true;
  });
  return nameCapital;
};

export const formatCreatePromoterBody = (values: any) => {
  const {
    legajo,
    firstName,
    lastName,
    email,
    role,
    filialCode,
    capCode,
    permisoOSDE,
    permisoBinaria,
    phoneNumber
  } = values;

  const filialSelected = getFilialFromDesc(filialCode);

  const capSelected = getCapFromDec(filialCode, capCode);

  /* eslint-disable  @typescript-eslint/camelcase */
  return {
    legajo,
    first_name: firstName,
    last_name: lastName,
    email,
    rol: role,
    filial_code: Number(filialCode),
    cap_code: Number(capCode),
    filial: filialSelected,
    cap: capSelected,
    permiso_osde: permisoOSDE === 'true',
    permiso_binaria: permisoBinaria === 'true',
    phone_number: String(phoneNumber)
  };
};

export const formatFilialAndCapFromPromoter = (promoter: PromoterForm) => {
  const promoterFormatted = { ...promoter };
  if (String(promoterFormatted.filialCode).length === 1) {
    promoterFormatted.filialCode = `0${String(promoter.filialCode)}`;
  }
  if (String(promoterFormatted.capCode).length === 1) {
    promoterFormatted.capCode = `0${String(promoter.capCode)}`;
  }
  promoterFormatted.filialCode = String(promoterFormatted.filialCode);
  promoterFormatted.capCode = String(promoterFormatted.capCode);
  return promoterFormatted;
};

export const buildParams = (file: File, applicationId: number, baseName?: string) => ({
  file,
  query: {
    fileName: baseName ? baseName : file.name,
    contentType: file.type
  },
  url: {
    applicationId
  }
});
