import React from 'react';
import { errorCapturaDeDocumento } from '~utils/vbError';

import { useStyles } from '../../styles';

interface Props {
  children: React.ReactNode;
  text: string;
  reason?: string;
}

function PointWrapper({ children, text, reason }: Props) {
  const styles = useStyles();
  return (
    <div className={styles.labelWrapper}>
      <div className={styles.centerReason}>
        <div className={styles.centerChildren}>
          {children}
          <div className={styles.label}>{text}</div>
        </div>
        {reason ? <span className={styles.label}>{errorCapturaDeDocumento(reason)}</span> : ''}
      </div>
    </div>
  );
}

export default PointWrapper;
