import React from 'react';
import { TextInput, NumberInput, useTranslate, useInput } from 'react-admin';
import cn from 'classnames';
import ViewInput from '~components/ViewInput';
import { getObjectValueFromString } from '~utils/object';
import { useEditInput } from '~hooks/useEditInput';
import { GenericObjectInterface, Nullable } from '~utils/types';

import { useStyles } from './styles';

interface Props {
  label?: string;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  formClassName?: string;
  source?: string;
  type?: string;
  record?: GenericObjectInterface<any>;
  variant?: string;
  defaultValue?: any;
  parse?: Function;
  format?: Function;
  helperText?: string;
  validate?:
    | ((value: any) => 'validation.required' | undefined)
    | ((value: any) => any)[]
    | ((value: any, record: any, fieldProps: any) => any)[];
  onSetEditing?: () => void;
  onChange?: Function;
  onKeyPress?: Function;
  showErrorOnEdit?: boolean;
  isEdit?: boolean;
  specialFormatter?: Function;
  editEnabled?: boolean;
  validateOnBlur?: boolean;
  fieldMessage?: Nullable<string>;
  multiline?: boolean;
  rows?: number;
  tooltipOnView?: string;
  field?: string;
}

// eslint-disable-next-line complexity
function InputLabel({
  className,
  formClassName,
  source,
  validate,
  disabled,
  type,
  variant,
  onSetEditing,
  isEdit,
  onChange,
  field,
  parse,
  defaultValue,
  containerClassName,
  showErrorOnEdit,
  specialFormatter,
  fieldMessage,
  editEnabled = false,
  multiline = false,
  rows,
  tooltipOnView,
  ...props
}: Props) {
  const [enabled, handleEnableEdit] = useEditInput(onSetEditing);
  const styles = useStyles();
  const t = useTranslate();
  const ALTAS_MASIVAS = 'ALTASMASIVAS';

  const {
    meta: { error }
  } = useInput(props);

  // const parse = (value: string) => (isNaN(parseFloat(value)) ? '' : parseFloat(value));
  const isNumberInput = type === 'number';
  const isDiscountRateField = field === 'discountRate';
  const InputComponent = isNumberInput ? NumberInput : TextInput;

  const errorText = showErrorOnEdit && enabled && source && error[source];
  const insulatedCypressElement = source ? `${source.split('.').pop()}-input` : undefined;

  return isEdit && !enabled ? (
    <ViewInput
      label={props.label!}
      value={
        (getObjectValueFromString(source!, props.record!) as string) === ALTAS_MASIVAS
          ? 'ALTAS MASIVAS'
          : (getObjectValueFromString(source!, props.record!) as string)
      }
      isEditing={enabled}
      onEnableEdit={handleEnableEdit}
      specialFormatter={specialFormatter}
      editEnabled={editEnabled}
      tooltip={tooltipOnView}
    />
  ) : (
    <div className={cn(styles.container, containerClassName)}>
      <InputComponent
        {...props}
        className={cn(className, showErrorOnEdit && styles.showErrorOnEdit)}
        formClassName={formClassName}
        source={source}
        validate={validate}
        type={type}
        variant={variant || 'standard'}
        disabled={disabled}
        onChange={onChange}
        parse={parse}
        multiline={multiline}
        rows={rows}
        defaultValue={defaultValue}
        helperText={(errorText && t(errorText)) || fieldMessage || undefined}
        {...(isNumberInput && {
          min: '0',
          step: '1',
          inputProps: { inputMode: 'numeric', pattern: '[0-9]*' }
        })}
        {...(isDiscountRateField && {
          min: '0',
          step: '1',
          max: '30',
          inputProps: { inputMode: 'numeric', pattern: '[0-9]*' }
        })}
        data-cy={insulatedCypressElement}
      />
    </div>
  );
}

export default InputLabel;
