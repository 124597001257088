/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  useMediaQuery
} from '@material-ui/core';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '~assets/ic_cross.svg';
import { getTipeCode } from '~screens/AffiliationRequests/utils';
import { APPLICATION_TYPES } from '~constants/affiliations';

import CustomButtonBeneficiary from '../CustomButton';

import { checkCorrectData, getEmail, getAddress, getPhone, getSexo, getPais } from './utils';
import { useStyles } from './styles';
import MessageError from './MessageError';

const NOT_FOUND_ERROR = 404;

interface Props {
  data?: any;
  error?: any;
  beneficiaryData?: any;
  titleClassName?: string;
  modalClassname?: string;
  contentClassName?: string;
  onClose?: () => void;
  onCreate?: (beneficiaryData: any) => void;
  isOpen: boolean;
}

const CreateBeneficiatyModal = ({
  data,
  error,
  beneficiaryData,
  titleClassName,
  modalClassname = '',
  contentClassName = '',
  onClose,
  onCreate,
  isOpen
}: Props) => {
  const isSmall: any = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const styles = useStyles({ titleClassName });

  const [gruposActivos, setGruposActivos] = useState<any>([]);
  const [correctData, setCorrectData] = useState<boolean>(true);
  const [valueRadio, setValueRadio] = useState<number | null>(null);
  const { applicationType } = beneficiaryData;

  useEffect(() => {
    if (data) {
      const activeGroups = data.gruposFamiliares.filter((g: any) => g.fechaBaja === '0000-00-00');

      if (activeGroups.length !== 0) {
        const result = checkCorrectData(data, activeGroups);

        if (result.correctData) {
          setCorrectData(true);
          setGruposActivos(result.gruposActivos);
        } else {
          setCorrectData(false);
          setGruposActivos([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleManualClick = useCallback(() => {
    onCreate?.(beneficiaryData);
  }, [onCreate, beneficiaryData]);

  const handleAsociarClick = useCallback(() => {
    const selected = data.gruposFamiliares.find(
      (grupo: any) => grupo.datosContrato.nroContrato === valueRadio
    );

    beneficiaryData.idContrato = String(valueRadio);

    const {
      interlocutorComercial,
      nombre,
      apellido,
      fechaNacimiento,
      estadoCivil,
      sexo,
      nacionalidad,
      identificadores,
      direcciones
    } = data.datosInterlocutorComercial;

    const cuil = identificadores.find((i: any) => i.tipoDocumento === 'CUIL');

    const dni = identificadores.find((i: any) => i.tipoDocumento === 'DNI');

    const direccion = getAddress(direcciones);

    const telefono = getPhone(direcciones);

    const email = getEmail(direcciones);

    const datosTitularContrato = {
      datosFiliatorios: {
        numeroIC: interlocutorComercial,
        nroSocio: selected.datosContrato.nroContrato + selected.numeroOrden,
        planServicio: selected.datosContrato.plan.codigoPlan,
        nombre: `${nombre}`,
        apellido: `${apellido}`,
        fechaNacimiento,
        estadoCivil: estadoCivil ? estadoCivil : '-',
        cuil: cuil.numeroDocumento,
        dni: dni ? dni.numeroDocumento : null,
        sexo: getSexo(sexo),
        nacionalidad: getPais(nacionalidad),
        condicionIva: '-',
        empresa: '-',
        cuitEmpresa: '-',
        sueldo: 0
      },
      domicilio: {
        calle: direccion.calle,
        nro: direccion.numero,
        piso: direccion.piso ? direccion.piso : '-',
        dpto: direccion.departamento ? direccion.departamente : '-',
        localidad: direccion.barrio.localidad.descripcion,
        provincia: direccion.barrio.localidad.partido.provincia.descripcion,
        codPostal: direccion.cp,
        telefonoPrincipal: telefono,
        email
      },
      // datosCredito: {
      //   nombreBanco: data.datosCredito.nombreBanco ? data.datosCredito.nombreBanco : '-',
      //   cuentaBancaria: data.datosCredito.cuentaBancaria ? data.datosCredito.cuentaBancaria : '-'
      // },
      // datosDebito: {
      //   ultimosDigitosCbu: data.datosDebito.ultimosDigitosCbu ? data.datosDebito.ultimosDigitosCbu : '0000',
      //   ultimosDigitosTarjeta: data.datosDebito.ultimosDigitosTarjeta
      //     ? data.datosDebito.ultimosDigitosTarjeta
      //     : '0000',
      //   descripcion: data.datosDebito.descripcion ? data.datosDebito.descripcion : '-'
      // }
    };

    beneficiaryData.datosTitularContrato = datosTitularContrato;

    onCreate?.(beneficiaryData);
  }, [beneficiaryData, valueRadio, data, onCreate]);

  return (
    <Modal
      className={cn({ [styles.modalContainer]: !isSmall, [styles.modalContainerSmall]: isSmall })}
      open={isOpen}
      onClose={handleClose}
    >
      <div
        className={cn(styles.commonModal, modalClassname, {
          [styles.modal]: !isSmall,
          [styles.modalSmall]: isSmall
        })}
      >
        <IconButton className={styles.closeIconContainer} onClick={handleClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
        <h2 className={cn(styles.commonTitle, titleClassName, styles.title)}>Detalles del socio</h2>

        <div className={cn(contentClassName)}>
          {(error === NOT_FOUND_ERROR || (gruposActivos.length === 0 && correctData)) && (
            <div>
              <p className={styles.colorText}>
                Para el documento indicado,{' '}
                <span className={cn(styles.textBold, styles.colorText)}>
                  {beneficiaryData.user.documentNumber} No encontramos datos en nuestros sistemas
                </span>
                {'. '}
                Te sugerimos verificar el numero de documento ingresado en la consola y volver a intentar.
              </p>
              <br />
              {isSmall ? null : <br />}
              {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                <p className={styles.colorText}>
                  En caso de no encontrar socios, recomendamos seleccionar{' '}
                  <span className={cn(styles.textBold, styles.colorText)}>
                    &quot;Carga de datos manual&quot;
                  </span>{' '}
                  para que el prospecto pueda cargar sus datos.
                </p>
              )}
            </div>
          )}

          {error && error !== NOT_FOUND_ERROR && (
            <p>No he podido conectarme con SAP, por favor reintenta en unos minutos.</p>
          )}

          {gruposActivos.length === 0 && !correctData && <MessageError />}

          {data && gruposActivos.length !== 0 && correctData && (
            <div>
              <div>
                <p className={cn(styles.colorText)}>
                  Para el documento indicado,{' '}
                  <span className={cn(styles.textBold, styles.colorText)}>
                    {beneficiaryData.user.documentNumber}
                  </span>{' '}
                  encontramos los siguientes datos en nuestros sistemas:
                </p>
                <br />
                <p className={cn(styles.colorText)}>
                  Por favor, verificá si coinciden y seleccioná el contrato que corresponde.
                </p>
                <br />
                {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                  <p className={cn(styles.colorText)}>
                    En caso de dudas o inconsistencias, podés continuar sin recuperar los datos presionando en
                    el Botón: Carga de datos manual.
                  </p>
                )}
              </div>

              {isSmall ? (
                <TableContainer className={cn(styles.tableContainer)}>
                  <Table>
                    <TableBody>
                      {gruposActivos.map((grupo: any) => {
                        const isItemSelected = valueRadio === grupo.datosContrato.nroContrato;

                        return (
                          <TableRow
                            key={grupo.numeroIc}
                            hover
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            className={cn(styles.rowTableSmall)}
                            onClick={() => setValueRadio(grupo.datosContrato.nroContrato)}
                          >
                            <TableCell className={cn(styles.cellRadioSmall)}>
                              <Radio
                                data-cy="asociateSapData-checkbox"
                                checked={valueRadio === grupo.datosContrato.nroContrato}
                              />
                            </TableCell>
                            <TableCell className={cn(styles.cell2Small)}>
                              <div className={cn(styles.margin20, styles.divTableSmall)}>
                                <p className={cn(styles.titleDivTableSmall)}>Nro de contrato</p>
                                <p className={cn(styles.colorText)}>
                                  {grupo.datosContrato.nroContrato}
                                  {grupo.numeroOrden}
                                </p>
                              </div>

                              <div className={cn(styles.margin20, styles.divTableSmall)}>
                                <p className={cn(styles.titleDivTableSmall)}>Nombre y apellido</p>
                                <p className={cn(styles.colorText)}>
                                  {data.datosInterlocutorComercial.nombre}{' '}
                                  {data.datosInterlocutorComercial.apellido}
                                </p>
                              </div>

                              <div className={cn(styles.divTableSmall)}>
                                <p className={cn(styles.titleDivTableSmall)}>E-mail</p>
                                <p className={cn(styles.colorText)}>
                                  {getEmail(data.datosInterlocutorComercial.direcciones)}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className={cn(styles.margin20, styles.divTableSmall)}>
                                <p className={cn(styles.titleDivTableSmall)}>Plan</p>
                                <p className={cn(styles.colorText)}>{grupo.datosContrato.plan.codigoPlan}</p>
                              </div>

                              <div className={cn(styles.divTableSmall)}>
                                <p className={cn(styles.titleDivTableSmall)}>Documento</p>
                                <p className={cn(styles.colorText)}>{beneficiaryData.user.documentNumber}</p>
                              </div>
                              {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                                <>
                                  <div className={cn(styles.margin20, styles.divTableSmall)}>
                                    <p className={cn(styles.titleDivTableSmall)}>Relacíon familiar</p>
                                    <p className={cn(styles.colorText)}>{grupo.descripcion}</p>
                                  </div>

                                  <div className={cn(styles.divTableSmall)}>
                                    <p className={cn(styles.titleDivTableSmall)}>Tipo de socio</p>
                                    <p className={cn(styles.colorText)}>
                                      {getTipeCode(grupo.datosContrato.tipoSocio)}
                                    </p>
                                  </div>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow className={cn(styles.colWidthMail)}>
                        <TableCell className={cn(styles.colWidth8)} />
                        <TableCell align="left" className={cn(styles.textBold)}>
                          Nro de contrato
                        </TableCell>
                        <TableCell
                          align={applicationType === APPLICATION_TYPES.BENEFICIARY ? 'right' : 'left'}
                          className={cn(styles.textBold)}
                        >
                          Plan
                        </TableCell>
                        <TableCell align="left" className={cn(styles.textBold)}>
                          Nombre
                        </TableCell>
                        <TableCell align="left" className={cn(styles.textBold)}>
                          Apellido
                        </TableCell>
                        <TableCell align="center" className={cn(styles.textBold, styles.colWidthMail)}>
                          E-mail
                        </TableCell>
                        {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                          <>
                            <TableCell align="left" className={cn(styles.textBold)}>
                              Relación familiar
                            </TableCell>
                            <TableCell align="left" className={cn(styles.textBold)}>
                              Tipo de socio
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {gruposActivos.map((grupo: any) => {
                        const isItemSelected = valueRadio === grupo.datosContrato.nroContrato;
                        return (
                          <TableRow
                            key={grupo.numeroIc}
                            hover
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            onClick={() => setValueRadio(grupo.datosContrato.nroContrato)}
                          >
                            <TableCell>
                              <Radio
                                data-cy="asociateSapData-checkbox"
                                checked={valueRadio === grupo.datosContrato.nroContrato}
                              />
                            </TableCell>
                            <TableCell>
                              {grupo.datosContrato.nroContrato}
                              {grupo.numeroOrden}
                            </TableCell>
                            <TableCell
                              size="small"
                              align={applicationType === APPLICATION_TYPES.BENEFICIARY ? 'right' : 'left'}
                            >
                              {grupo.datosContrato.plan.codigoPlan}
                            </TableCell>
                            <TableCell>{data.datosInterlocutorComercial.nombre}</TableCell>
                            <TableCell>{data.datosInterlocutorComercial.apellido}</TableCell>
                            <TableCell>{getEmail(data.datosInterlocutorComercial.direcciones)}</TableCell>
                            {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                              <>
                                <TableCell>{grupo.descripcion}</TableCell>
                                <TableCell className={cn(styles.colWidthMail)}>
                                  {getTipeCode(grupo.datosContrato.tipoSocio)}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}

          {(error === NOT_FOUND_ERROR || gruposActivos.length === 0) && (
            <>
              {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                <div className={styles.root}>
                  <Grid
                    container
                    spacing={3}
                    className={(styles.alignVertically, cn({ [styles.containerButtons]: isSmall }))}
                  >
                    <CustomButtonBeneficiary
                      onClick={() => handleManualClick()}
                      label="Carga de datos manual"
                      isWhiteButton
                    />
                  </Grid>
                </div>
              )}
            </>
          )}

          {data && gruposActivos.length !== 0 && (
            <div className={styles.root}>
              <Grid container spacing={3} className={cn({ [styles.containerButtons]: isSmall })}>
                {applicationType === APPLICATION_TYPES.BENEFICIARY && (
                  <Grid item sm={6} md={6} xl={6} className={styles.alignVertically}>
                    <CustomButtonBeneficiary
                      onClick={() => handleManualClick()}
                      label="Datos de carga manual"
                      isWhiteButton
                    />
                  </Grid>
                )}
                {applicationType === APPLICATION_TYPES.BENEFICIARY ? (
                  <Grid item sm={6} md={6} xl={6} className={styles.alignVertically}>
                    <CustomButtonBeneficiary
                      onClick={() => handleAsociarClick()}
                      label="Asociar datos"
                      disabled={error || !valueRadio}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={12} md={12} xl={12} className={styles.alignVertically}>
                    <CustomButtonBeneficiary
                      onClick={() => handleAsociarClick()}
                      label="Asociar datos"
                      disabled={error || !valueRadio}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreateBeneficiatyModal;
