export const getDocumentType = (value: string) => `resources.affiliations.fields.documentTypes.${value}`;

export const getAffiliationType = (value: string) => {
  if (value === 'convenio_8-360' || value === 'convenio 8-360') {
    return 'Convenio 8-360';
  } else if (value === 'obligatorio_8-360' || value === 'obligatorio 8-360') {
    return 'Obligatorio 8-360';
  } else if (value === 'convenio_8-430' || value === 'convenio 8-430') {
    return 'Convenio 8-8430';
  } else if (value === 'obligatorio_8-360' || value === 'obligatorio 8-360') {
    return 'Obligatorio 8-8430';
  }

  return `resources.affiliations.fields.affiliationTypes.${value}`;
};

export const getApplicationType = (value: string) =>
  `resources.affiliations.fields.applicationTypes.${value}`;

export const getApplicationSubtype = (value: string) =>
  `resources.affiliations.fields.applicationSubtypes.${value}`;

export const getHiringModeTypes = (value: string) => `resources.affiliations.fields.hiringModeTypes.${value}`;

export const getCompanyPlanType = (value: string) =>
  `resources.affiliations.fields.companyPlanTypes.${value}`;

export const getCorporativePlanType = (value: string) =>
  `resources.affiliations.fields.corporativePlantypes.${value}`;

export const getMejoraPlanType = (value: string) => `resources.affiliations.fields.mejoraPlanTypes.${value}`;
export const getIncreasePlanAllowedType = (value: boolean) =>
  `resources.affiliations.fields.increasePlanAllowedTypes.${value}`;

export const getIsPaymentResponsibleType = (value: boolean) =>
  `resources.affiliations.fields.isPaymentResponsibleTypes.${value}`;

export const getPlanType = (value: string) => `resources.affiliations.fields.planTypes.${value}`;
