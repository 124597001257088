import React from 'react';
import cn from 'classnames';
import { SOL_BINARIA_REQUEST_STATUS as STATUS } from '~constants/binaria';

import { useStyles } from '../binaria-styles';

interface Props {
  state: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
}

export default function StatusField({ state, rounded = true, className = '', labelClassName = '' }: Props) {
  const styles = useStyles();
  const estado = Object.values(STATUS).map(status => {
    if (state === status.COD) {
      return status.DESCRIPCION;
    }
  });
  return (
    <div className={`full-width ${className}`}>
      <div
        className={cn(styles.label, styles[state], labelClassName, {
          rounded
        })}
      >
        <span>{estado}</span>
      </div>
    </div>
  );
}
