/* eslint-disable max-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, { useCallback, useState } from 'react';
import { useTranslate, useRefresh, useNotify } from 'react-admin';
import cn from 'classnames';
import ViewInput from '~components/ViewInput';
import FileValue from '~components/FileValue';
import PotentialRiskField from '~screens/AffiliationRequests/components/PotentialRiskField';
import { AffiliationRequest, Nullable } from '~utils/types';
import CustomButton from '~components/CustomButton';
import { EVALUATE_APPLICATION } from '~config/providers/constants';
import {
  isEvaluationPending,
  isUserValidationPending,
  showInsuranceField
} from '~screens/AffiliationRequests/utils';
import useModal from '~hooks/useModal';
import CustomModal from '~components/CustomModal';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import { useCommonStyles } from '~app/styles';
import { AFFILIATION_REQUEST_STATUS, APPLICATION_TYPES } from '~constants/affiliations';
import { useDispatch, useSelector } from '~contexts/ModalContext';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import WithProps from '~components/WithProps';
import { getCleanFileBaseFromUrl, getOrderedAttachments, isPromoterAttachment } from '~utils/attachmentFiles';
import { VIGENCIA_ZIP_GAW, hasPassedXDaysFromDate } from '~utils/date';
import DownloadZipButton from '~components/DownloadZipButton';

import AffiliationFileUpload from '../AffiliationFileUpload';
import { useStyles as useDetailStyles } from '../AffiliateDetail/styles';
import InsuranceField from '../../../../components/InsuranceField';

import FileUploadModal from './FileUploadModal';
import {
  getEvaluatePayload,
  shouldShowPotentialRiskField,
  shouldUploadFile,
  deleteFileFromBucket
} from './utils';
import { useStyles } from './styles';
import { FILE_TYPES } from './constants';
import FileUploadModalZCAR from './FileUploadModalZCAR';
import ApplicationQuestionsModal from './ApplicationQuestionsModal/index';

interface Props {
  record: AffiliationRequest;
  resource: string;
}

interface RequestPayload {
  id?: number;
  rejected?: boolean;
}

// eslint-disable-next-line complexity
function AffilliationFilled({ record, resource }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const detailStyles = useDetailStyles();
  const commonStyles = useCommonStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const {
    id,
    f1Document,
    swornDocument,
    preSwornDocument,
    attachments = [],
    applicationType,
    others,
    termsAndCondDocument
  } = record;
  const swornDocuments = preSwornDocument ? [preSwornDocument, swornDocument] : [swornDocument];
  const pendingEvaluation = isEvaluationPending(record.state);
  const finished = record.state === AFFILIATION_REQUEST_STATUS.FINISHED;
  const sap =
    record.state === AFFILIATION_REQUEST_STATUS.SAP || record.state === AFFILIATION_REQUEST_STATUS.SAP_RPA;
  const lastAudit = record.lastAudit;
  const zipGawUrl = record.zipGawUrl;
  const pendienteDeConfirmacion = isUserValidationPending(record.state);
  const mostrarSeguro = showInsuranceField(record.state, record.applicationType, record.familyGroupDetails);
  const mostrarRiesgoPotencial = shouldShowPotentialRiskField(record.state, record.familyGroupDetails);
  const showPotentialRisk =
    applicationType !== APPLICATION_TYPES.F1 && applicationType !== APPLICATION_TYPES.OTHERS;

  const [statusToChange, setStatusToChange] = useState<Nullable<string>>(null);
  const hasAttachments = !!attachments?.length;
  const attachmentsResult = getOrderedAttachments(attachments || [], t);
  const [uploadFileOnCancel, setUploadFileOnCancel] = useState<boolean>(false);
  const familyGroup = record?.familyGroupDetails || [];
  const [modalData, dispatchRequest, loading, onOpenModal] = useModal<RequestPayload, RequestPayload>({
    resource,
    requestType: EVALUATE_APPLICATION,
    requestPayload: getEvaluatePayload,
    modalName: MODAL_NAMES.EVALUATE,
    successMessageNotify: `resources.affiliations.evaluate.modal.${statusToChange}.success`
  });
  const handleOpenModal = useCallback(
    (action: string) => {
      setStatusToChange(action);
      onOpenModal({ id, newState: action });
    },
    [id, onOpenModal]
  );

  const dispatch = useDispatch();
  const modal = useSelector(state => state);
  const isOpen = modal[MODAL_NAMES.FILES];
  const isOpenZCAR = modal[MODAL_NAMES.FILES_ZCAR];
  const [clickCancel, setClickCancel] = useState<boolean>(false);
  const [clickFinished, setClickFinished] = useState<boolean>(false);
  const [clickZCAR, setClickZCAR] = useState<boolean>(false);
  const [clickHabilitado, setClickHabilitado] = useState<boolean>(false);
  const [clickRevision, setClickRevision] = useState<boolean>(false);
  const [answerModal, setAnswerModal] = useState<string>('');
  const [cancelText, setCancelText] = useState<string>('');
  const isDDJJ = applicationType === APPLICATION_TYPES.DDJJ;
  const handleOpenFilesModal = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.FILES));
  }, [dispatch]);

  const handleCloseFilesModal = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.FILES));
  }, [dispatch]);

  const handleOpenFilesModalZCAR = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.FILES_ZCAR));
  }, [dispatch]);

  const handleCloseFilesModalZCAR = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.FILES_ZCAR));
  }, [dispatch]);

  const openModalFilesPromoter = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.FILES_PROMOTER));
  }, [dispatch]);

  const uploadFilePromoter = () => {
    openModalFilesPromoter();
    setUploadFileOnCancel(true);
  };
  const evaluateAction = () => {
    if (clickCancel) {
      setClickCancel(false);
      dispatchRequest();
    }
    if (clickFinished) {
      setClickFinished(false);
      handleOpenFilesModal();
    }
    if (clickZCAR) {
      setClickZCAR(false);
      handleOpenFilesModalZCAR();
    }
    if (clickHabilitado) {
      setClickHabilitado(false);
      dispatchRequest();
    }
    if (clickRevision) {
      setClickRevision(false);
      dispatchRequest();
    }
  };
  const handleDelete = (fileName: string, idApplication: number) => () => {
    deleteFileFromBucket(fileName, idApplication)
      .then(result => {
        if (!result) {
          notify('Ocurrio un error no se puedo eliminar');
          return;
        }
        refresh();
        notify(`${fileName}, Archivos eliminado`);
      })
      .catch(error => notify(error, 'Ocurrio un error no se puedo eliminar'));
  };

  return (
    <div className={`column full-width ${styles.spacing}`}>
      {!pendienteDeConfirmacion && mostrarRiesgoPotencial && showPotentialRisk && (
        <ViewInput
          label={t('resources.affiliations.fields.potentialRisk')}
          content={<PotentialRiskField application={record} />}
        />
      )}
      {mostrarSeguro && applicationType !== APPLICATION_TYPES.OTHERS && (
        <ViewInput
          label={t('resources.affiliations.fields.titleInsurance')}
          content={<InsuranceField application={record} />}
        />
      )}
      {!pendienteDeConfirmacion && f1Document && (
        <ViewInput
          label={t(`resources.affiliations.documents.${FILE_TYPES.f1}.title`)}
          content={<FileValue name={getCleanFileBaseFromUrl(f1Document)} url={f1Document} />}
        />
      )}
      {!pendienteDeConfirmacion && swornDocument && (
        <ViewInput
          label={t(`resources.affiliations.documents.${FILE_TYPES.sworn}.title`)}
          content={swornDocuments.map((attachment: any) => (
            <FileValue key={attachment} url={attachment} name={getCleanFileBaseFromUrl(attachment)} />
          ))}
          {...(!hasAttachments && { className: styles.separation })}
        />
      )}
      {!pendienteDeConfirmacion && others && (
        <ViewInput
          label={t(`resources.affiliations.documents.${FILE_TYPES.othersAttachments}.title`)}
          content={<FileValue key={others} url={others} name={getCleanFileBaseFromUrl(others)} />}
          {...(!hasAttachments && { className: styles.separation })}
        />
      )}
      {!pendienteDeConfirmacion && termsAndCondDocument && (
        <ViewInput
          label={t(`resources.affiliations.documents.${FILE_TYPES.termAndConditions}.title`)}
          content={
            <FileValue
              key={termsAndCondDocument}
              url={termsAndCondDocument}
              name={getCleanFileBaseFromUrl(termsAndCondDocument)}
            />
          }
          {...(!hasAttachments && { className: styles.separation })}
        />
      )}
      {!pendienteDeConfirmacion && hasAttachments && (
        <>
          <ViewInput
            label={t(`resources.affiliations.documents.${FILE_TYPES.attachments}.title`)}
            content={attachmentsResult.fromProspect.map(attachment => (
              <FileValue
                key={attachment.url}
                url={attachment.url}
                name={attachment.name}
                id={id}
                onDelete={handleDelete(attachment.name, id)}
                canDelete={isPromoterAttachment(attachment)}
              />
            ))}
            className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
          />

          {attachmentsResult.fromPromoter && attachmentsResult.fromPromoter.length > 0 && (
            <ViewInput
              label={t(`resources.affiliations.documents.${FILE_TYPES.promoterAttachments}.title`)}
              content={attachmentsResult.fromPromoter.map(attachment => (
                <FileValue
                  key={attachment.url}
                  url={attachment.url}
                  name={attachment.name}
                  id={id}
                  onDelete={handleDelete(attachment.name, id)}
                  canDelete={isPromoterAttachment(attachment)}
                />
              ))}
              className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
            />
          )}
          {attachmentsResult.fromQuestions && attachmentsResult.fromQuestions.length > 0 && (
            <ViewInput
              label="Documentación adicional"
              content={attachmentsResult.fromQuestions.map(attachment => (
                <FileValue
                  key={attachment.url}
                  url={attachment.url}
                  name={attachment.name}
                  id={id}
                  canDelete={false}
                />
              ))}
              className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
            />
          )}
        </>
      )}
      {pendingEvaluation && (
        <WithProps>
          {(createProps: object) => <ApplicationQuestionsModal {...createProps} applicationId={id} />}
        </WithProps>
      )}

      {shouldUploadFile(record.state) && !isDDJJ && (
        <WithProps>
          {(createProps: object) => (
            <AffiliationFileUpload
              {...createProps}
              openInput={uploadFileOnCancel}
              resetOpenInput={() => setUploadFileOnCancel(false)}
              attachments={attachmentsResult.fromPromoter}
              applicationId={id}
            />
          )}
        </WithProps>
      )}
      {(finished || sap) &&
        lastAudit &&
        zipGawUrl &&
        zipGawUrl !== '' &&
        !hasPassedXDaysFromDate(VIGENCIA_ZIP_GAW, lastAudit) && <DownloadZipButton fileUrl={zipGawUrl} />}
      {pendingEvaluation && (
        <>
          {isDDJJ && (
            <CustomButton
              className={`${styles.button} ${styles.buttonRevision}`}
              onClick={() => {
                setClickHabilitado(true);
                setClickZCAR(false);
                setClickCancel(false);
                setClickFinished(false);
                setClickRevision(false);
                handleOpenModal(AFFILIATION_REQUEST_STATUS.ENABLED);
                setAnswerModal('¿Deseas cambiar a estado Habilitado?');
                setCancelText('Cancelar');
              }}
              label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.ENABLED}`)}
              disabled={loading}
            />
          )}
          {!isDDJJ && (
            <CustomButton
              className={`${styles.button} ${styles.buttonFinished}`}
              onClick={() => {
                setClickFinished(true);
                setClickCancel(false);
                setClickZCAR(false);
                setClickHabilitado(false);
                setClickRevision(false);
                handleOpenModal(AFFILIATION_REQUEST_STATUS.FINISHED);
                setAnswerModal('¿Desea confirmar el alta sin cargar más archivos?');
                setCancelText('Subir Archivos');
              }}
              label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.FINISHED}`)}
              disabled={loading}
            />
          )}

          {record.state !== AFFILIATION_REQUEST_STATUS.REVISION &&
            applicationType !== APPLICATION_TYPES.DDJJ && (
              <CustomButton
                className={`${styles.button} ${styles.buttonRevision}`}
                onClick={() => {
                  setClickRevision(true);
                  setClickHabilitado(false);
                  setClickZCAR(false);
                  setClickCancel(false);
                  setClickFinished(false);
                  handleOpenModal(AFFILIATION_REQUEST_STATUS.REVISION);
                  setAnswerModal('¿Deseas cambiar a estado revisión?');
                  setCancelText('Cancelar');
                }}
                label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.REVISION}`)}
              />
            )}

          <CustomButton
            className={`${styles.button} ${styles.buttonZcar}`}
            onClick={() => {
              setClickZCAR(true);
              setClickCancel(false);
              setClickFinished(false);
              setClickHabilitado(false);
              setClickRevision(false);
              handleOpenModal(AFFILIATION_REQUEST_STATUS.ZCAR);
              setAnswerModal('¿Deseas confirmar el estado ZCAR sin cargar más archivos?');
              setCancelText('Subir Archivos');
            }}
            label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.ZCAR}`)}
            disabled={loading}
          />
          <CustomButton
            className={`${styles.button} ${styles.buttonCanceled}`}
            onClick={() => {
              setClickCancel(true);
              setClickFinished(false);
              setClickZCAR(false);
              setClickHabilitado(false);
              setClickRevision(false);
              handleOpenModal(AFFILIATION_REQUEST_STATUS.CANCELED);
              setAnswerModal('¿Desea cancelar la solicitud?');
              setCancelText('Cancelar');
            }}
            label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.CANCELED}`)}
            disabled={loading}
          />
          {modalData?.id && (
            <CustomModal
              title={t('resources.affiliations.modals.title')}
              modalName={MODAL_NAMES.EVALUATE}
              onConfirm={evaluateAction}
              onSecondConfirm={
                clickFinished || clickZCAR
                  ? uploadFilePromoter
                  : () => modalActions.closeModal(MODAL_NAMES.EVALUATE)
              }
              cancelText={cancelText}
              loading={loading}
            >
              <p className={detailStyles.modalContent}>{answerModal}</p>
            </CustomModal>
          )}
          <CustomModal
            modalName={MODAL_NAMES.FILES}
            modalClassname={styles.modalContent}
            onClose={handleCloseFilesModal}
            title="Comunicaci&oacute;n a afiliaciones"
            isInfo
          >
            {isOpen && <FileUploadModal applicationId={id} />}
          </CustomModal>

          <CustomModal
            modalName={MODAL_NAMES.FILES_ZCAR}
            modalClassname={styles.modalContent}
            onClose={handleCloseFilesModalZCAR}
            title="Comunicaci&oacute;n a Afiliaciones"
            subtitle="Recorda marcar para ZCAR solo a las personas que tengan preexistencias que cotizan"
            isInfo
          >
            {isOpenZCAR && <FileUploadModalZCAR applicationId={id} familyGroupDetails={familyGroup} />}
          </CustomModal>
        </>
      )}
    </div>
  );
}

export default AffilliationFilled;
