import React from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';

import { useStyles } from './styles';

interface Props {
  state: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
}

export default function StatusField({ state, rounded = true, className = '', labelClassName = '' }: Props) {
  const styles = useStyles();
  const t = useTranslate();

  return (
    <div className={`full-width ${className}`}>
      <div
        className={cn(styles.label, styles[state], labelClassName, {
          rounded
        })}
      >
        <span>{t(`resources.on.fields.statusTypes.${state}`)}</span>
      </div>
    </div>
  );
}
