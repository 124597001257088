import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import CustomButton from '~components/CustomButton';
import { useDispatch, useSelector } from '~contexts/ModalContext';
import { SolicitudON } from '~utils/types';

import { useStyles } from './styles';
import PotentialRiskModal from './components/PotentialRiskModal';

interface Props {
  solicitud: SolicitudON;
}

function PotentialRiskField({ solicitud }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { potentialRisk: risk } = solicitud;

  const modal = useSelector(state => state);
  const isOpen = modal[MODAL_NAMES.POTENTIAL_RISK];

  const handleOpenPotentialRisk = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.POTENTIAL_RISK));
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.POTENTIAL_RISK));
  }, [dispatch]);

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.point, { risk })} />
        <p className={styles.label}>{t(`general.${risk ? 'yes' : 'no'}`)}</p>
        {!!risk && (
          <CustomButton
            onClick={handleOpenPotentialRisk}
            label={t('resources.on.potentialRisk.detail')}
            isLinkButton
          />
        )}
      </div>
      {!!risk && (
        <CustomModal
          modalName={MODAL_NAMES.POTENTIAL_RISK}
          onClose={handleClose}
          title={t('resources.on.modals.titlePotentialRisk')}
          isInfo
        >
          {isOpen && <PotentialRiskModal onApplicationId={solicitud.id as number} />}
        </CustomModal>
      )}
    </>
  );
}

export default PotentialRiskField;
