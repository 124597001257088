/* eslint-disable complexity */
/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-dom-props */
import React, { useEffect, useState } from 'react';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import Checkbox from '~components/Checkbox';
import CustomButton from '~components/CustomButton';
import { editApplication, updateStateApplication } from '~services/OsdeService';
import { useRefresh, useNotify } from 'react-admin';
import { useDispatch } from '~contexts/ModalContext';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { WARNING_TYPE } from '~config/providers/constants';
import Loading from '~components/Spinner/components/loading';
import Grid from '@material-ui/core/Grid';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import RadioGroup from '~components/RadioGroup';
import RadioOption from '~components/RadioGroup/components/RadioOption';
import { FamilyMembers } from '~utils/types';

import { useStyles } from './styles';

const clasificaciones: Array<any> = [
  { type: '9500', description: 'Grupo A' },
  { type: '9501', description: 'Grupo B' },
  { type: '9502', description: 'Grupo C' },
  { type: '9503', description: 'Grupo D' },
  { type: '9504', description: 'Grupo E' },
  { type: '9505', description: 'Grupo F' },
  { type: '9999', description: 'Sin Clasificar' }
];

const traduccion = (member: string): string => {
  let memberDescription = '';
  if (member === FamilyMembers.PROSPECT) {
    memberDescription = 'Titular';
  } else if (member === FamilyMembers.PARTNER) {
    memberDescription = 'Cónyuge';
  } else {
    memberDescription = 'Hijo';
  }
  return memberDescription;
};

interface Props {
  applicationId: number;
  familyGroupDetails: Array<any>;
}

const FileUploadModalZCAR = ({ applicationId, familyGroupDetails = [] }: Props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  let childsCounter = 0;

  const [informacion, setInformacion] = useState<{
    grupoFamiliar: Array<any>;
    clasificacion: string;
    observaciones: string;
  }>({
    grupoFamiliar: [],
    clasificacion: '9999',
    observaciones: ''
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [errorGrupoFamiliar, setErrorGrupoFamiliar] = useState<boolean>(false);
  const [errorObservaciones, setErrorObservaciones] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (informacion.grupoFamiliar.length === 0) {
      setErrorGrupoFamiliar(true);
    } else {
      setErrorGrupoFamiliar(false);
    }

    if (informacion.observaciones.length === 0) {
      setErrorObservaciones(true);
    } else {
      setErrorObservaciones(false);
    }
  }, [informacion]);

  const styles = useStyles();

  const handleCheckboxGrupoFamiliar = (e: any) => {
    const member = JSON.parse(e.target.value);
    setInformacion({
      ...informacion,
      grupoFamiliar: e.target.checked ? [...informacion.grupoFamiliar, member] : informacion.grupoFamiliar.filter(item => item.nroDocumento !== member.nroDocumento)
    });
  };

  const handleCheckboxClasificacion = (e: any) => {
    setInformacion({
      ...informacion,
      clasificacion: e.target.value
    });
  };

  const handleObservacionesChange = (e: any) => {
    setInformacion({
      ...informacion,
      observaciones: e.target.value
    });
  };

  const handleClick = () => {
    if (errorGrupoFamiliar === false && errorObservaciones === false) {
      setLoading(true);
      editApplication(applicationId, { infoAfiliacionZcar: { ...informacion } })
        .then(() =>
          updateStateApplication(applicationId, AFFILIATION_REQUEST_STATUS.ZCAR)
            .then(() => {
              setLoading(false);
              refresh();
              dispatch?.(modalActions.closeModal(MODAL_NAMES.FILES_ZCAR));
              notify('Solicitud actualizada a estado ZCAR correctamente');
            })
            .catch((error: Error) => {
              setLoading(false);
              notify(error.message, WARNING_TYPE);
            })
        )
        .catch((error: Error) => {
          setLoading(false);
          notify(error.message, WARNING_TYPE);
        });
    } else {
      setShowErrors(true);
    }
  };

  return (
    <ModalInfoInnerContainer loading={false}>
      {/* Informacion grupo familiar */}
      <label className={styles.label}>Integrante familiar a cargar</label>
      <div className={styles.container}>
        <Grid container spacing={1} alignItems="flex-start" justify="flex-start">
          {familyGroupDetails?.length > 0 &&
            familyGroupDetails.map(integrante => {
              const isProspectWithDatosZCAR = integrante.member === FamilyMembers.PROSPECT && integrante.datosZcar && integrante.datosZcar !== null;
              let label = '';
              if ([FamilyMembers.CHILD, FamilyMembers.STEPSON, FamilyMembers.SON_UNDER_GUARD].includes(integrante.member)) {
                childsCounter++;
                label = `${traduccion(integrante.member)} ${childsCounter}`;
              } else {
                label = traduccion(integrante.member);
              }
              const value = {
                tipoDocumento: isProspectWithDatosZCAR ? integrante.datosZcar.tipoDocumento : integrante.tipoDocumento,
                nroDocumento: isProspectWithDatosZCAR ? integrante.datosZcar.nroDocumento : integrante.nroDocumento
              };
              return (
                <Grid key={integrante.member} item sm={12} md={6} lg={4}>
                  <Checkbox
                    onChange={handleCheckboxGrupoFamiliar}
                    inputClassName={styles.inputCheckbox}
                    columnClassName={styles.checkbox}
                    checked={informacion.grupoFamiliar[integrante.member]}
                    name={integrante.member}
                    label={label}
                    value={JSON.stringify(value)}
                  />
                </Grid>
              );
            })}
        </Grid>
        {showErrors && errorGrupoFamiliar && <label className={styles.labelError}>Debes seleccionar almenos un integrante</label>}
      </div>
      {/* Clasificacion ZCAR */}
      <label className={styles.label}>Clasificaci&oacute;n ZCAR</label>
      <div className={styles.container}>
        <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
          <RadioGroup onChange={handleCheckboxClasificacion} initialValue="9999" name="radioButton">
            {clasificaciones.map(clasificacion => (
              <div key={clasificacion.type} style={{ marginBottom: '10px', marginLeft: '10px' }}>
                <RadioOption id={clasificacion.type} label={clasificacion.description} />
              </div>
            ))}
          </RadioGroup>
        </Grid>
      </div>
      {/* Observaciones */}
      <label className={styles.label}>Observaciones</label>
      <textarea
        className={styles.input}
        id="observaciones"
        name="observaciones"
        onChange={handleObservacionesChange}
        maxLength={125}
        cols={30}
        rows={5}
      />
      {showErrors && errorObservaciones && <label className={styles.labelError}>El campo observaciones es obligatorio</label>}
      <div className={styles.wrapperButton}>
        <CustomButton
          disabled={loading || showErrors && (errorGrupoFamiliar || errorObservaciones)}
          className={`${styles.button}`}
          onClick={handleClick}
          label={loading ? '' : 'Confirmar'}
        >
          {loading && <Loading />}
        </CustomButton>
      </div>
    </ModalInfoInnerContainer>
  );
};

export default FileUploadModalZCAR;
