/* eslint-disable react-hooks/rules-of-hooks */
import { useNotify } from 'ra-core';
import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { useCommonStyles, useFormStyles } from '~app/styles';
import InputLabel from '~components/InputLabel';
import Toolbar from '~components/Toolbar';
import { EDIT_PARTIAL_FORM } from '~screens/EditarPartialData/constants';
import { savePartialDataService } from '~services/OsdeService';
import { requiredField as requiredValidator, numericalDigits, validateJSON } from '~utils/inputValidations';

interface Props {
  loading: boolean;
  setLoading: any;
  error: string;
  setError: any;
  setPartialDataResult: any;
  idSolicitud: string;
  setIdSolicitud: any;
  setGetActualPage: any;
  isEditing: boolean;
  isEdit: boolean;
}

function SavePartialForm({
  loading,
  setLoading,
  error,
  setError,
  setPartialDataResult,
  idSolicitud,
  setIdSolicitud,
  setGetActualPage,
  isEditing,
  isEdit
}: Props) {
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();

  const notify = useNotify();
  const t = useTranslate();

  const handleClickSavePartial = async (values: any) => {
    setLoading(true);
    setError('');
    const response: any = await savePartialDataService(values.idSolicitud, JSON.parse(values.partialData));
    if (response.data && response.data.errors) {
      setError(response.data.errors[0]?.message || 'Ocurrio un error');
    } else {
      notify(t('resources.editarpartialdata.editPartialData.savePartialDataSuccess'));
      setPartialDataResult('');
      setIdSolicitud('');
      setGetActualPage(true);
    }
    setLoading(false);
  };

  return (
    <SimpleForm
      className={styles.form}
      save={handleClickSavePartial}
      saving={loading}
      toolbar={isEditing || !isEdit ? <Toolbar label="Guardar" /> : null}
      validateOnBlur
    >
      <InputLabel
        label="ID Solicitud"
        className={commonStyles.row}
        source={EDIT_PARTIAL_FORM.idSolicitud}
        validate={[requiredValidator, numericalDigits]}
        editEnabled
        type="text"
        defaultValue={idSolicitud}
      />

      <InputLabel
        label="Partial Data"
        className={commonStyles.row}
        source={EDIT_PARTIAL_FORM.partialData}
        validate={[requiredValidator, validateJSON]}
        editEnabled
        type="text"
      />

      <span className={commonStyles.errorMessage}>{error}</span>
    </SimpleForm>
  );
}

export default SavePartialForm;
