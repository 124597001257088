import { makeStyles } from '@material-ui/core/styles';
import { GRAY } from '~constants/colors';

const HEADER_PADDING = '15px 15px 10px 5px';

export const useStyles = makeStyles({
  headerCell: {
    border: 'none',
    padding: HEADER_PADDING,
    '& span': {
      color: GRAY
    }
  },
  row: {
    '& .MuiTableCell-head': {
      border: 'none',
      padding: HEADER_PADDING
    }
  }
});
