import React, { Children, cloneElement } from 'react';
import { Filter } from 'react-admin';

import { useFilterStyles, useFilterClasses } from './styles';

interface Props {
  children: JSX.Element[] | JSX.Element;
}
function CustomFilter(props: Props) {
  const styles = useFilterStyles();
  const classes = useFilterClasses();

  return (
    <Filter {...props} classes={classes}>
      {Children.map(props.children, child => cloneElement(child, { className: styles.input }))}
    </Filter>
  );
}

export default CustomFilter;
