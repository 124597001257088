import React, { useCallback, ChangeEvent } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import CustomSwitch from '~components/CustomSwitch';
import { Promoter } from '~utils/types';
import { actionCreators } from '~contexts/ModalContext/reducer';
import { useDispatch } from '~contexts/ModalContext';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';

interface Props {
  promoter: Promoter;
}

function PromoterSwitchStatus({ promoter }: Props) {
  const dispatch = useDispatch();
  const t = useTranslate();

  const handleToggle = useCallback(
    (e: ChangeEvent<{}>) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(actionCreators.openModal(MODAL_NAMES.TOGGLE_PROMOTER_STATUS, promoter));
    },
    [dispatch, promoter]
  );

  return (
    <FormControlLabel
      control={<CustomSwitch onChange={handleToggle} checked={promoter.active} />}
      label={t('resources.promoters.fields.enabled')}
      labelPlacement="start"
    />
  );
}

export default PromoterSwitchStatus;
