/* eslint-disable complexity */
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { SimpleShowLayout, useTranslate, useRefresh, useNotify } from 'react-admin';
import { useCommonStyles } from '~app/styles';
import cn from 'classnames';
import CustomButton from '~components/CustomButton';
import CustomModal from '~components/CustomModal';
import DetailActionButtons from '~components/DetailActionButtons';
import ViewInput from '~components/ViewInput';
import { EVALUATE_APPLICATION, RESOURCES_PATH } from '~config/providers/constants';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import useModal from '~hooks/useModal';
import AffiliationForm from '~screens/AffiliationRequests/components/AffiliationForm';
import StatusField from '~screens/AffiliationRequests/components/StatusField';
import { EMAIL_CONFIG } from '~screens/AffiliationRequests/constants';
import {
  getSendEmailTexts,
  getShowSendInitialEmail,
  isAffilliationFilled,
  isUserValidationPending
} from '~screens/AffiliationRequests/utils';
import { AffiliationRequest as AffiliationRequestType, Nullable, Promoter } from '~utils/types';
import { getOrderedAttachments, isPromoterAttachment } from '~utils/attachmentFiles';
import FileValue from '~components/FileValue';

import { MODAL_NAMES } from '../../../../../../contexts/ModalContext/constants';
import AffilliationFilled from '../AffiliationFilled';
import { getEvaluatePayload, deleteFileFromBucket } from '../AffiliationFilled/utils';
import { FILE_TYPES } from '../AffiliationFilled/constants';

import { useStyles } from './styles';

interface Props {
  record: AffiliationRequestType;
  resource: string;
  permissions: Promoter;
}

interface RequestPayload {
  id?: number;
}

const AffiliationDetail = (extra: Props) => {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isEditing, setIsEditing] = useState(false);
  const status = useMemo(() => extra.record.state, [extra.record.state]);
  const { attachments = [], id } = extra.record;
  const [statusToChange, setStatusToChange] = useState<Nullable<string>>(null);
  const attachmentsResult = getOrderedAttachments(attachments || [], t);
  const pendingEvaluation = false;
  const handleSetIsEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const [affiliationOpen, dispatchRequest, loading, onOpenModal] = useModal<
    AffiliationRequestType,
    RequestPayload
  >({
    resource: extra.resource,
    ...(EMAIL_CONFIG[status] || EMAIL_CONFIG[AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING])
  });

  const [modalData, dispatchRequestCancel, loadingCancel, onOpenModalCancel] = useModal<
    RequestPayload,
    RequestPayload
  >({
    resource: extra.resource,
    requestType: EVALUATE_APPLICATION,
    requestPayload: getEvaluatePayload,
    modalName: MODAL_NAMES.EVALUATE,
    successMessageNotify: `resources.affiliations.evaluate.modal.${statusToChange}.success`
  });

  const handleClickSendEmail = (event: SyntheticEvent<EventTarget>) => {
    event.stopPropagation();
    onOpenModal(extra.record);
  };

  const showSendInitialEmail = getShowSendInitialEmail(status);
  const userValidationPending = isUserValidationPending(status);
  const isFilled = isAffilliationFilled(status);
  const { label, title, modalMessage } = useMemo(() => getSendEmailTexts(status), [status]);

  const handleOpenModal = useCallback(
    (action: string) => {
      setStatusToChange(action);
      onOpenModalCancel({ id, newState: action });
    },
    [id, onOpenModalCancel]
  );

  const handleDelete = (fileName: string, idApplication: number) => () => {
    deleteFileFromBucket(fileName, idApplication)
      .then(result => {
        if (!result) {
          notify('Ocurrio un error no se puedo eliminar');
          return;
        }
        refresh();
        notify(`${fileName}, Archivos eliminado`);
      })
      .catch(error => notify(error, 'Ocurrio un error no se puedo eliminar'));
  };
  return (
    <>
      <DetailActionButtons />
      <SimpleShowLayout {...extra}>
        <>
          <h1 className={commonStyles.title}>{t('resources.affiliations.detailTitle')}</h1>
        </>
        {extra && (
          <AffiliationForm {...extra} isEdit isEditing={isEditing} onSetEditing={handleSetIsEditing} />
        )}
        <ViewInput
          label={t('resources.affiliations.fields.status')}
          content={<StatusField state={status} rounded={false} rpaStatus={extra.record.rpaStatus} />}
          innerContainerClassName={styles.spacing}
        />
        {!isFilled && attachmentsResult.fromPromoter && attachmentsResult.fromPromoter.length > 0 && (
          <ViewInput
            label={t(`resources.affiliations.documents.${FILE_TYPES.promoterAttachments}.title`)}
            content={attachmentsResult.fromPromoter.map(attachment => (
              <FileValue
                key={attachment.url}
                url={attachment.url}
                name={attachment.name}
                id={id}
                onDelete={handleDelete(attachment.name, id)}
                canDelete={isPromoterAttachment(attachment)}
              />
            ))}
            className={cn({ [commonStyles.noMarginBottom]: !pendingEvaluation })}
          />
        )}
        <>
          {isFilled && <AffilliationFilled record={extra.record} resource={RESOURCES_PATH.affiliations} />}
          {showSendInitialEmail && (
            <>
              <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
                <CustomButton label={t(label)} title={t(title)} onClick={handleClickSendEmail} />
              </div>
              <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
                <CustomButton
                  className={`${styles.button} ${styles.buttonCanceled}`}
                  label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.CANCELED}`)}
                  title={t(title)}
                  onClick={() => handleOpenModal(AFFILIATION_REQUEST_STATUS.CANCELED)}
                />
              </div>
            </>
          )}
          {userValidationPending && (
            <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
              <CustomButton label={t(label)} title={t(title)} onClick={handleClickSendEmail} />
            </div>
          )}
        </>
      </SimpleShowLayout>
      {affiliationOpen?.id && (
        <CustomModal
          title={t('resources.affiliations.modals.title')}
          modalName={EMAIL_CONFIG[status].modalName}
          onConfirm={dispatchRequest}
          loading={loading}
          showCloseIcon={false}
        >
          <p className={styles.modalContent}>{t(modalMessage)}</p>
        </CustomModal>
      )}
      {modalData?.id && (
        <CustomModal
          title={t('resources.affiliations.modals.title')}
          modalName={MODAL_NAMES.EVALUATE}
          onConfirm={dispatchRequestCancel}
          loading={loadingCancel}
        >
          {showSendInitialEmail && <p className={styles.modalContent}>¿Desea cancelar la solicitud?</p>}
        </CustomModal>
      )}
    </>
  );
};

export default AffiliationDetail;
