import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE } from '~constants/colors';
import { CUSTOM_ICON_STYLES } from '~app/styles';

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '10px'
  },
  icon: { marginRight: 8, ...CUSTOM_ICON_STYLES },
  crossIcon: { marginLeft: '8px', width: '18px', height: '18px' },
  link: {
    color: PRIMARY_BLUE,
    fontSize: 15,
    lineHeight: '19px',
    textDecoration: 'underline'
  }
});
