import { ReactNode } from 'react';
import { AffiliationRequestForm, AffiliationRequest, Promoter } from '~utils/types';

export interface Props {
  record?: AffiliationRequest;
  title?: string;
  isEdit?: boolean;
  redirect?: string;
  toolbar?: ReactNode;
  resource?: string;
  onSetEditing?: () => void;
  isEditing?: boolean;
  permissions: Promoter;
}
export interface FormDataProps {
  formData: AffiliationRequestForm;
}
export const RESPONSE_OK = 200;
