import React, { useState, useCallback, useMemo } from 'react';
import { useTranslate, useInput } from 'react-admin';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
import cn from 'classnames';
import esLocale from 'date-fns/locale/es';
import { ReactComponent as IconCalendar } from '~assets/ic_calendar.svg';
import ViewInput from '~components/ViewInput';
import { getObjectValueFromString } from '~utils/object';
import { useEditInput } from '~hooks/useEditInput';
import { GenericObjectInterface } from '~utils/types';
import { DATE_FORMAT_FUCTION_SHORT_AR, DATE_FORMAT_SHORT_FIXED_AR } from '~constants/datetime';
import { colorInput } from '~utils/general';
import { momentDateToString } from '~utils/date';

import { useStyles } from './styles';

interface Props {
  label?: string;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  formClassName?: string;
  source?: string;
  record?: GenericObjectInterface<any>;
  variant?: string;
  defaultValue?: any;
  parse?: Function;
  format?: Function;
  helperText?: string;
  validate?: ((value: any) => 'validation.required' | undefined) | ((value: any) => any)[];
  onSetEditing?: () => void;
  onChange?: Function;
  showErrorOnEdit?: boolean;
  isEdit?: boolean;
  specialFormatter?: Function;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: string;
  editEnabled?: boolean;
}

function InputDate({
  className,
  source,
  validate,
  disabled = false,
  onSetEditing,
  isEdit = false,
  defaultValue,
  containerClassName,
  showErrorOnEdit,
  specialFormatter,
  disablePast = false,
  disableFuture = false,
  minDate,
  editEnabled = false,
  label,
  ...props
}: Props) {
  const [enabled, handleEnableEdit] = useEditInput(onSetEditing);
  const [touched, setTouched] = useState(false);
  const [active, setActive] = useState(false);
  const styles = useStyles();
  const t = useTranslate();
  const {
    meta: { error: errors }
  } = useInput(props);
  const error = useMemo(() => errors[source!], [errors, source]);
  const errorText = showErrorOnEdit && (enabled || !isEdit) && touched && error;
  const formattedDate = momentDateToString(getObjectValueFromString(source!, props.record!) as string, {
    target: DATE_FORMAT_SHORT_FIXED_AR
  });

  const handleClose = useCallback(() => {
    if (!touched) {
      setTouched(true);
    }
    setActive(false);
  }, [touched]);

  const handleClickDate = useCallback(() => {
    setActive(true);
  }, []);

  return isEdit && !enabled ? (
    <ViewInput
      label={label!}
      value={formattedDate}
      isEditing={enabled}
      onEnableEdit={handleEnableEdit}
      specialFormatter={specialFormatter}
      editEnabled={editEnabled}
    />
  ) : (
    <div className={cn(styles.container, containerClassName)}>
      {label && <label className={styles.label}>{label}</label>}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DateInput
          className={cn(className, colorInput(errorText, styles.inputRed, styles.input))}
          source={source}
          validate={validate}
          defaultValue={defaultValue}
          options={{
            format: DATE_FORMAT_FUCTION_SHORT_AR,
            onClose: handleClose,
            onOpen: handleClickDate,
            emptyLabel: DATE_FORMAT_SHORT_FIXED_AR.toLowerCase(),
            disabled,
            disablePast,
            disableFuture,
            minDate,
            okLabel: t('general.accept'),
            cancelLabel: t('general.cancel')
          }}
        />
        <div className={cn(styles.iconContainer, { active: active || !error })}>
          <IconCalendar className={styles.icon} />
        </div>
      </MuiPickersUtilsProvider>
      {errorText && <p className={styles.error}>{t(errorText)}</p>}
    </div>
  );
}

export default InputDate;
