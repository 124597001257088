import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, PRIMARY_BLUE_OPACITY, WHITE } from '~constants/colors';

export const useStyles = makeStyles(() => ({
  buttonDownloadZip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'max-content',
    backgroundColor: PRIMARY_BLUE,
    color: WHITE,
    borderRadius: '4px',
    padding: '15px',
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_BLUE_OPACITY,
      color: WHITE
    }
  },
  linkButton: {
    color: WHITE
  },
  iconDownload: {
    height: 22,
    width: 22,
    marginLeft: '10px'
  }
}));
