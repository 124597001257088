export const TYPE_DOCUMENT = {
  DNI: 'DNI',
  CUIL: 'CUIL',
  PASAPORTE: 'PASSPORT'
};

export const AFFILIATION_TYPES = {
  OBLIGATORIO: 'mandatory',
  COMPLEMENTACION: 'complement',
  DIRECTO_POR_EMPRESA: 'direct_group',
  CONVENIO: 'agreement'
};

export const PLAN_TYPES = {
  '': '0',
  0: '0',
  'S/D': '0',
  BINARIO: 'Binario',
  CORPORATIVO: 'Corporativo'
};

export const APPLICATION_TYPES = {
  F1: 'f1_application',
  F1_DDJJ: 'full_application',
  ALTA_BENEFICIARIO: 'beneficiary_application'
};

export const COMPANY_PLAN_TYPES = {
  '': '0',
  0: '0',
  'S/D': '0',
  210: '210',
  310: '310',
  410: '410',
  450: '450',
  510: '510',
  8360: '8360',
  8430: '8430',
  8260: '8260',
  6030: '6030'
  // 6031: '6031'
};

export const IS_PAYMENT_RESPONSIBLE_TYPES = {
  '': false,
  0: false,
  'S/D': false,
  SI: true,
  NO: false
};

export const INCREASE_PLAN_ALLOWED_TYPES = {
  '': false,
  0: false,
  'S/D': false,
  SI: true,
  NO: false
};
