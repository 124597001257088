import { makeStyles } from '@material-ui/core';
import { EXTRA_LIGHT_GRAY, MEDIUM_GRAY } from '~constants/colors';

export const useResponsiveListStyles = makeStyles({
  container: {
    backgroundColor: EXTRA_LIGHT_GRAY,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
    padding: '24px 16px',
    position: 'relative',
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    width: '100%'
  },
  columnLarge: {
    flex: 2
  },
  columnShort: {
    flex: 1
  },
  label: {
    color: MEDIUM_GRAY,
    fontWeight: 500
  },
  value: {
    fontWeight: 500
  },
  maxContent: {
    maxWidth: 'max-content'
  },
  middle: {
    alignSelf: 'center'
  },
  centered: {
    width: '50%',
    marginInline: 'auto'
  }
});
