import React from 'react';
import { useTranslate, TextInput } from 'react-admin';
import { PROMOTER_FILTERS } from '~screens/Promoters/constants';
import CustomFilter from '~components/CustomFilter';

function PromotersFilters(props: object) {
  const t = useTranslate();

  return (
    <CustomFilter {...props}>
      <TextInput label={t('resources.promoters.fields.promoter')} source={PROMOTER_FILTERS.id} />
      <TextInput source={PROMOTER_FILTERS.firstName} />
      <TextInput source={PROMOTER_FILTERS.lastName} />
    </CustomFilter>
  );
}

export default PromotersFilters;
