export type CodigoDescripcionType = { COD: string; DESCRIPCION: string };
export type CodigoDescripcionCollectionType = { [sourceName: string]: CodigoDescripcionType };

export const convertCodDescrCollection = (
  coll: CodigoDescripcionCollectionType,
  extractor: (cd: CodigoDescripcionType) => {}
) => Object.keys(coll).reduce((acum, key) => ({ ...acum, ...extractor(coll[key]) }), {});

const emptyArrayOfObjects: any[] = [];
export const convertCodDescrCollAsArray = (
  coll: CodigoDescripcionCollectionType,
  extractor: (cd: CodigoDescripcionType) => {}
) => Object.keys(coll).reduce((acum, key) => [...acum, extractor(coll[key])], emptyArrayOfObjects);

export const STATUS_FILTER: CodigoDescripcionCollectionType = {
  INITIAL_EMAIL_PENDING: {
    COD: 'initial_email_pending',
    DESCRIPCION: 'Inicio pendiente'
  },
  INITIAL_EMAIL_SENT: {
    COD: 'initial_email_sent',
    DESCRIPCION: 'E-mail enviado'
  },
  FORM_LINK_OPENED: {
    COD: 'form_link_opened',
    DESCRIPCION: 'Link abierto'
  },
  PENDING_PROMOTER_VALIDATION: {
    COD: 'pending_promoter_validation',
    DESCRIPCION: 'Revisar PDF'
  },
  FINISHED: {
    COD: 'finished',
    DESCRIPCION: 'En proceso de alta'
  },
  REVISION: {
    COD: 'revision',
    DESCRIPCION: 'En revisión'
  },
  EXPIRED: {
    COD: 'expired',
    DESCRIPCION: 'Vencido'
  },
  CANCELED: {
    COD: 'canceled',
    DESCRIPCION: 'Cancelado'
  },
  SAP: {
    COD: 'sap',
    DESCRIPCION: 'SAP'
  }
};

export const SOLICITUDES_REQUEST_STATUS: CodigoDescripcionCollectionType = {
  INITIAL_EMAIL_PENDING: {
    COD: 'initial_email_pending',
    DESCRIPCION: 'Inicio pendiente'
  },
  INITIAL_EMAIL_SENT: {
    COD: 'initial_email_sent',
    DESCRIPCION: 'Email enviado'
  },
  FORM_LINK_OPENED: {
    COD: 'form_link_opened',
    DESCRIPCION: 'Link abierto'
  },
  PENDING_USER_VALIDATION: {
    COD: 'pending_user_validation',
    DESCRIPCION: 'Confirmación PDF pendiente'
  },
  PENDING_PROMOTER_VALIDATION: {
    COD: 'pending_promoter_validation',
    DESCRIPCION: 'Revisar PDF'
  },
  FINISHED: {
    COD: 'finished',
    DESCRIPCION: 'En Proceso de alta'
  },
  SAP: {
    COD: 'sap',
    DESCRIPCION: 'SAP'
  },
  REVISION: {
    COD: 'revision',
    DESCRIPCION: 'En revisión'
  },
  USER_REJECTED: {
    COD: 'user_rejected',
    DESCRIPCION: 'Datos Incorrectos'
  },
  EXPIRED: {
    COD: 'expired',
    DESCRIPCION: 'Vencido'
  },
  CANCELED: {
    COD: 'canceled',
    DESCRIPCION: 'Cancelado'
  }
};

export const DOCUMENT_TYPES: CodigoDescripcionCollectionType = {
  DNI: {
    COD: 'DNI',
    DESCRIPCION: 'DNI'
  },
  PASSPORT: {
    COD: 'Pasaporte',
    DESCRIPCION: 'Pasaporte'
  },
  CUIL: {
    COD: 'CUIL',
    DESCRIPCION: 'CUIL'
  }
};

export const DOCUMENT_TYPES_CUIT: CodigoDescripcionType = {
  COD: 'cuit',
  DESCRIPCION: 'CUIT'
};

export const PLAN_TYPES: CodigoDescripcionCollectionType = {
  BINARIO: {
    COD: 'binario',
    DESCRIPCION: 'Binario'
  },
  CORPORATIVO: {
    COD: 'corporativo',
    DESCRIPCION: 'Corporativo'
  }
};

export const DISCOUNT_TYPES: CodigoDescripcionCollectionType = {
  APLICA_A_TODOS: {
    COD: 'true',
    DESCRIPCION: 'Aplica a todos los planes'
  },
  ESPECIFICA_DESCUENTOS: {
    COD: 'false',
    DESCRIPCION: 'Especificar descuento por plan'
  }
};
