/* eslint-disable @typescript-eslint/camelcase */
import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig = () => ({
  authority: 'https://accounts.google.com/',
  client_id: window.config.REACT_APP_OAUTH_CLIENT_ID,
  client_secret: window.config.REACT_APP_OAUTH_CLIENT_SECRET,
  redirect_uri: window.config.REACT_APP_OAUTH_CLIENT_REDIRECT,
  automaticSilentRenew: true,
  scope: 'openid profile email',
  userStore: new WebStorageStateStore({ store: window.sessionStorage })
});
