import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, PRIMARY_RED, PRIMARY_GREEN, PRIMARY_YELLOW } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  labelWrapper: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    marginTop: '20px'
  },
  label: {
    color: PRIMARY_BLUE,
    fontSize: '16px',
    marginRight: '22px'
  },
  labelContainer: {
    color: PRIMARY_BLUE,
    fontSize: '16px',
    marginRight: '22px',
    marginBottom: '20px'
  },
  success: {
    borderRadius: '6px',
    height: '12px',
    marginRight: '8px',
    width: '12px',
    backgroundColor: PRIMARY_GREEN
  },
  danger: {
    borderRadius: '6px',
    height: '12px',
    marginRight: '8px',
    width: '12px',
    backgroundColor: PRIMARY_RED
  },
  info: {
    borderRadius: '6px',
    height: '12px',
    marginRight: '8px',
    width: '12px',
    backgroundColor: PRIMARY_YELLOW
  },
  button: {
    width: '150px'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr 1fr'
  },
  left: {
    gridColumn: '1/2'
  },
  center: {
    gridColumn: '2/3'
  },
  right: {
    gridColumn: '3/4'
  },
  centerChildren: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2%'
  },
  centerReason: {
    display: 'flex',
    flexDirection: 'column'
  }
});
