/* eslint-disable max-lines */
import React, { ReactNode, useEffect, useCallback, useState } from 'react';
import { useDispatch } from '~contexts/ModalContext';
import { useHistory } from 'react-router';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { useFormStyles, useCommonStyles } from '~app/styles';
import {
  AFFILIATION_TYPES,
  DOCUMENT_TYPES,
  HIRING_MODE_TYPES,
  APPLICATION_TYPES,
  SOLICITUDES_ON_REQUEST_STATUS,
  HIRING_MODE_TYPES_AUTOGESTION,
  AFFILIATION_TYPES_AUTOGESTION
} from '~constants/solicitudesON';
import {
  getAffiliationType,
  getAffiliationTypeAutogestion,
  getApplicationType,
  getDocumentType,
  getHiringModeTypes,
  getHiringModeTypesAutogestion
} from '~screens/SolicitudesON/components/SolicitudONForm/utils';
import { SOLICITUDES_ON_REQUESTS_FIELDS } from '~screens/SolicitudesON/constants';
import { getIsAutogestion, getIsSuperAdmin } from '~utils/general';
// import { required, numericalDigits, email, validateFutureDate } from '~utils/inputValidations';
import {
  getDocumentNumberValidationON,
  required,
  numericalDigits,
  email,
  validateFutureDate,
  getCuitValidationON,
  regex,
  regexAlpha,
  phoneValidationOn
} from '~utils/inputValidationsOn';
import { SolicitudONRequest, Promoter, SolicitudONRequestForm, Nullable } from '~utils/types';
import { SimpleForm, FormDataConsumer, useMutation, useTranslate } from 'react-admin';
import InputLabel from '~components/InputLabel';
import InputSelect from '~components/InputSelect';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import FeedbackModalContent from '~components/FeedbackModalContent';
import CustomModal from '~components/CustomModal';
import Toolbar from '~components/Toolbar';
import InputDate from '~components/InputDate';
import { INPUT_NUMBER_PROPS } from '~constants/validationsOn';
import dataProvider from '~config/providers/dataProvider';
import { GET_ONE_PROMOTER_LIST, RESOURCES_PATH } from '~config/providers/constants';

import FamilyGroup from './components/FamilyGroup';

interface Props {
  record?: SolicitudONRequest;
  title?: string;
  esEdicion?: boolean;
  redirect?: string;
  toolbar?: ReactNode;
  resource?: string;
  onSetEditing?: () => void;
  enEdicion?: boolean;
  permissions: Promoter;
}

interface FormDataProps {
  formData: SolicitudONRequestForm;
}

// eslint-disable-next-line complexity
export default function SolicitudONForm(props: Props) {
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuperAdmin = getIsSuperAdmin(props.permissions?.role);
  const isAutogestion = getIsAutogestion(props.record?.entryChannel);
  // const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [formattedPromoter, setFormattedPromoter] = useState<Nullable<string>>(null);
  const [mutate, { data, error, loading }] = useMutation();

  const { esEdicion, onSetEditing, enEdicion, ...rest } = props;
  const t = useTranslate();

  const documentTypesChoices = Object.values(DOCUMENT_TYPES).map(type => ({
    id: type,
    name: t(`resources.on.fields.documentTypes.${type}`)
  }));

  const hiringModesTypesChoices = Object.values(HIRING_MODE_TYPES).map(type => ({
    id: type,
    name: t(`resources.on.fields.hiringModeTypes.${type}`)
  }));

  // eslint-disable-next-line id-length
  const hiringModesTypesChoicesAutogestion = Object.values(HIRING_MODE_TYPES_AUTOGESTION).map(type => ({
    id: type,
    name: t(`resources.on.fields.hiringModeTypesAutogestion.${type}`)
  }));
  const affiliationTypeChoices = Object.values(AFFILIATION_TYPES).map(type => ({
    id: type,
    name: t(`resources.on.fields.affiliationTypes.${type}`)
  }));
  // eslint-disable-next-line id-length
  const affiliationTypeChoicesAutogestion = Object.values(AFFILIATION_TYPES_AUTOGESTION).map(type => ({
    id: type,
    name: t(`resources.on.fields.affiliationTypesAutogestion.${type}`)
  }));
  const appliationTypeChoices = Object.values(APPLICATION_TYPES).map(type => ({
    id: type,
    name: t(`resources.on.fields.applicationTypes.${type}`)
  }));
  // TODO esto se debe descomentar mas adelante
  // const isPaymentResponsibleChoices = Object.values(IS_PAYMENT_RESPONSIBLE_TYPES).map(type => ({
  //   id: type,
  //   name: t(`resources.affiliations.fields.isPaymentResponsibleTypes.${type}`)
  // }));
  // const isPaymentResponsibleChoices = [
  //   {
  //     id: IS_PAYMENT_RESPONSIBLE_TYPES.false,
  //     name: t(`resources.affiliations.fields.isPaymentResponsibleTypes.${IS_PAYMENT_RESPONSIBLE_TYPES.false}`)
  //   }
  // ];

  const extraFieldsEdit = props.record?.state === SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING;
  useEffect(() => {
    if (data || error) {
      dispatch?.(modalActions.openModal(MODAL_NAMES.DIALOG));
    }
  }, [data, dispatch, error]);

  const createSolicitud = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: 'CREAR_SOLICITUD_ON',
        resource: props.resource,
        payload: values
      }),
    [mutate, props.resource]
  );

  const editarSolicitud = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: 'EDITAR_SOLICITUD_ON',
        resource: props.resource,
        payload: { ...values, isSuperAdmin }
      }),
    [props.resource, mutate, isSuperAdmin]
  );

  const handleExit = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSave = useCallback(
    (values: FormDataProps) => {
      if (esEdicion) {
        return editarSolicitud(values);
      }
      return createSolicitud(values);
    },
    [createSolicitud, editarSolicitud, esEdicion]
  );
  const validatePromoter = useCallback(
    async (
      currentPromoterId: number,
      _: SolicitudONRequest,
      fieldProps: { pristine: boolean; initial: any; modified: boolean }
    ) => {
      if (!fieldProps.pristine || (fieldProps.initial === currentPromoterId && fieldProps.modified)) {
        setIsValidationLoading(true);
        setFormattedPromoter(t('validation.loadingPromoter'));
        const promoterData = await dataProvider(GET_ONE_PROMOTER_LIST, RESOURCES_PATH.promoters, {
          id: currentPromoterId
        });
        setIsValidationLoading(false);
        if (!promoterData || promoterData.error) {
          setFormattedPromoter(null);
          return promoterData?.error || 'errors.default';
        }
        const { id, firstName, lastName } = promoterData.page?.[0] || {};
        setFormattedPromoter(`${id} - ${firstName} ${lastName}`);
      }
      return undefined;
    },
    [t]
  );
  return (
    <>
      <SimpleForm
        {...rest}
        className={styles.form}
        save={handleSave}
        saving={loading || isValidationLoading}
        toolbar={enEdicion || !esEdicion ? <Toolbar {...props} /> : null}
        validateOnBlur
      >
        {esEdicion && (
          <InputLabel
            label="ID"
            className={commonStyles.row}
            source={SOLICITUDES_ON_REQUESTS_FIELDS.id}
            isEdit={esEdicion}
          />
        )}

        <InputLabel
          label="Nombre"
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.firstName}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}                               
          editEnabled={!isAutogestion}
          parse={(value: string) => value.replace(regex, '')}
        />
        <InputLabel
          label="Apellido"
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.lastName}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled={!isAutogestion}
          parse={(value: string) => value.replace(regex, '')}
        />

        <InputSelect
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.documentType}
          choices={documentTypesChoices}
          label="Tipo de Documento"
          defaultValue={DOCUMENT_TYPES.DNI}
          specialFormatter={getDocumentType}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
        />

        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const type = formData.user.documentType;
            const isPassport = type === DOCUMENT_TYPES.PASSPORT;
            if (!isPassport) {
              return (
                <InputLabel
                  {...formDataProps}
                  className={commonStyles.row}
                  source={SOLICITUDES_ON_REQUESTS_FIELDS.documentNumber}
                  label="Numero de Documento"
                  validate={[
                    required,
                    numericalDigits,
                    ...(getDocumentNumberValidationON({ t, type }) as any[])
                  ]}
                  {...(!isPassport && { ...INPUT_NUMBER_PROPS, type: 'number' })}
                  variant="standard"
                  isEdit={esEdicion}
                  onSetEditing={onSetEditing}
                  type="number"
                />
              );
            }

            return (
              <InputLabel
                {...formDataProps}
                className={commonStyles.row}
                source={SOLICITUDES_ON_REQUESTS_FIELDS.documentNumber}
                label="Numero de Documento"
                validate={[
                  required,
                  numericalDigits,
                  ...(getDocumentNumberValidationON({ t, type }) as any[])
                ]}
                variant="standard"
                isEdit={esEdicion}
                onSetEditing={onSetEditing}
                parse={(value: string) => value.replace(regexAlpha, '')}
                type="text"
              />
            );
          }}
        </FormDataConsumer>

        <InputLabel
          label="Email"
          className={commonStyles.row}
          type="email"
          source={SOLICITUDES_ON_REQUESTS_FIELDS.userEmail}
          validate={[required, email]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled={isSuperAdmin}
        />

        <InputLabel
          label="Número de Teléfono"
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.phoneNumber}
          validate={[phoneValidationOn]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled={!isAutogestion}
          type="number"
        />
        <InputSelect
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.affiliationType}
          choices={isAutogestion ? affiliationTypeChoicesAutogestion : affiliationTypeChoices}
          label="Tipo de Socio"
          defaultValue={AFFILIATION_TYPES.MANDATORY}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          specialFormatter={isAutogestion ? getAffiliationTypeAutogestion : getAffiliationType}
          editEnabled={extraFieldsEdit && !isAutogestion}
        />

        <InputSelect
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.hiringMode}
          choices={isAutogestion ? hiringModesTypesChoicesAutogestion : hiringModesTypesChoices}
          label="Modalidad de Contratación"
          defaultValue={HIRING_MODE_TYPES.PLA}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          specialFormatter={isAutogestion ? getHiringModeTypesAutogestion : getHiringModeTypes}
          editEnabled={extraFieldsEdit && !isAutogestion}
        />
        <InputSelect
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.applicationType}
          choices={appliationTypeChoices}
          label="Tipo de Formulario"
          defaultValue={APPLICATION_TYPES.FULL}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          specialFormatter={getApplicationType}
          editEnabled={extraFieldsEdit}
        />

        <InputLabel
          label="Nombre de la Empresa "
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.companyName}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled={extraFieldsEdit && !isAutogestion}
        />
        <FormDataConsumer>
          {({ ...formDataProps }: FormDataProps) => (
            <InputLabel
              {...formDataProps}
              label="CUIT"
              className={commonStyles.row}
              source={SOLICITUDES_ON_REQUESTS_FIELDS.companyCuit}
              // validate={[required, ...(getDocumentNumberValidationON({ t, type }) as any[])]}
              validate={[required, numericalDigits, ...(getCuitValidationON({ t }) as any[])]}
              isEdit={esEdicion}
              onSetEditing={onSetEditing}
              editEnabled={extraFieldsEdit && !isAutogestion}
              type="text"
            />
          )}
        </FormDataConsumer>
        {props?.record?.familyGroupDetails && (
          <FamilyGroup familyData={props.record.familyGroupDetails} isEdit={esEdicion} />
        )}
        <InputLabel
          label="Promotor"
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.promoterId}
          type="number"
          validate={numericalDigits}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          fieldMessage={formattedPromoter}
          defaultValue={props?.permissions?.id}
          editEnabled={isSuperAdmin}
        />

        <InputDate
          label="Vigencia"
          className={commonStyles.row}
          source={SOLICITUDES_ON_REQUESTS_FIELDS.planValidityDate}
          validate={[required, validateFutureDate]}
          disablePast
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          showErrorOnEdit
          editEnabled={extraFieldsEdit && !isAutogestion}
        />
      </SimpleForm>
        
      {(data || error) && (
        <CustomModal modalName={MODAL_NAMES.DIALOG} {...(data ? { onClose: handleExit } : {})}>
          <FeedbackModalContent
            error={error}
            successMessage={t(`resources.on.modals.form.${esEdicion ? 'edit' : 'create'}Success`)}
          />
        </CustomModal>
      )}
    </>
  );
}
