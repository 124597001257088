/* eslint-disable no-magic-numbers */
import { DOCUMENT_TYPES, DOCUMENT_TYPES_CUIT } from './solicitudesF2';

const CUIL_PREFIXES = [20, 23, 24, 25, 26, 27];
const CUIT_PREFIXES = [20, 23, 24, 25, 26, 27, 30, 33, 34];
export const DOCUMENT_NUMBER_PROPS_F2 = {
  [DOCUMENT_TYPES.DNI.COD]: {
    MIN: 7,
    MAX: 8
  },
  [DOCUMENT_TYPES.CUIL.COD]: {
    MIN: 11,
    PREFIXES: CUIL_PREFIXES
  },
  [DOCUMENT_TYPES_CUIT.COD]: {
    MIN: 11,
    PREFIXES: CUIT_PREFIXES
  },
  [DOCUMENT_TYPES.PASSPORT.COD]: {
    MIN: 7,
    MAX: 14
  }
};

export const PROMOTER_ID_LENGTH = {
  MIN: 1,
  MAX: 8
};
