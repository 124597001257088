// export const SOLICITUDES_ON_REQUEST_STATUS = {
//   INITIAL_EMAIL_PENDING: {
//     COD: 'initial_email_pending',
//     DESCRIPCION: 'Inicio Pendiente'
//   },
//   INITIAL_EMAIL_SENT: {
//     COD: 'initial_email_sent',
//     DESCRIPCION: 'Email Enviado'
//   },
//   FORM_LINK_OPENED: {
//     COD: 'form_link_opened',
//     DESCRIPCION: 'Link Abierto'
//   },
//   PENDING_USER_VALIDATION: {
//     COD: 'pending_user_validation',
//     DESCRIPCION: 'Confirmación PDF Pendiente'
//   },
//   PENDING_PROMOTER_VALIDATION: {
//     COD: 'pending_promoter_validation',
//     DESCRIPCION: 'REVISAR PDF'
//   },
//   FINISHED: {
//     COD: 'finished',
//     DESCRIPCION: 'En Proceso de Alta'
//   },
//   REVISION: {
//     COD: 'revision',
//     DESCRIPCION: 'En Revisión'
//   },
//   USER_REJECTED: {
//     COD: 'user_rejected',
//     DESCRIPCION: 'Datos Incorrectos'
//   },
//   EXPIRED: {
//     COD: 'expired',
//     DESCRIPCION: 'Vencido'
//   },
//   CANCELED: {
//     COD: 'canceled',
//     DESCRIPCION: 'Cancelado'
//   }
// };

//   export const AFFILIATION_TYPES = {
//     MANDATORY:{
//       COD: 'mandatory',
//       DESCRIPCION: 'Obligatiorio'
//     },
//     AGREEMENT:{
//       COD: 'agreement',
//       DESCRIPCION: 'Convenio'
//   },
//     COMPLEMENT: {
//       COD: 'complement',
//       DESCRIPCION: 'Complementacion'
//   }
//   };

// export const APPLICATION_TYPES = {
//     FULL: {
//       COD: 'full_application',
//       DESCRIPCION: 'F1 + DDJJ'
//   }
//   };

//   export const DOCUMENT_TYPES = {
//     DNI:{
//       COD: 'dni',
//       DESCRIPCION: 'DNI'
//     },
//     PASSPORT:{
//       COD: 'passport',
//       DESCRIPCION: 'PASAPORTE'
//     } ,
//     CUIL:{
//       COD: 'cuil',
//       DESCRIPCION: 'CUIL'
//     }
//   };

//   export const HIRING_MODE_TYPES = {
//     PLA:{
//       COD: 'PLA',
//       DESCRIPCION: 'PLA'
//   },
//     APO:{
//       COD: 'APO',
//       DESCRIPCION: 'APO'
//   }
//   };

export const SOLICITUDES_ON_REQUEST_STATUS = {
  INITIAL_EMAIL_PENDING: 'initial_email_pending',
  INITIAL_EMAIL_SENT: 'initial_email_sent',
  FORM_LINK_OPENED: 'form_link_opened',
  PENDING_USER_VALIDATION: 'pending_user_validation',
  PENDING_PROMOTER_VALIDATION: 'pending_promoter_validation',
  FINISHED: 'finished',
  REVISION: 'revision',
  USER_REJECTED: 'user_rejected',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  SAP: 'sap',
};

export const AFFILIATION_TYPES = {
  MANDATORY: 'mandatory',
  AGREEMENT: 'agreement'
  // COMPLEMENT: 'complement'
};

export const AFFILIATION_TYPES_AUTOGESTION = {
  MANDATORY: 'mandatory',
  AGREEMENT: 'agreement',
  DIRECT: 'direct'
};

export const APPLICATION_TYPES = {
  FULL: 'full_application'
};

export const DOCUMENT_TYPES = {
  DNI: 'dni',
  PASSPORT: 'passport',
  CUIL: 'cuil'
};

// TODO
// el cuit seguramente va dentro de DOCUMENT_TYPES
export const DOCUMENT_TYPES_CUIT = 'cuit';

export const HIRING_MODE_TYPES = {
  PLA: 'PLA'
  // APO: 'APO'
};

export const HIRING_MODE_TYPES_AUTOGESTION = {
  PLA: 'PLA',
  APO: 'APO'
};

export const IS_PAYMENT_RESPONSIBLE_TYPES = {
  true: 'true',
  false: 'false'
};
