import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import paths from '~components/Routes/paths';

import { useStyles } from './styles';

interface Props {
  containerClassName?: string;
  showTitle?: boolean;
}

function AppLogo({ containerClassName, showTitle = true }: Props) {
  const styles = useStyles();
  return (
    <NavLink className={cn(styles.container, containerClassName)} to={paths.home}>
      {showTitle && <h1 className={cn(styles.title, styles.titlePaddingTop)}>Solicitudes Digitales</h1>}
      {!showTitle && <h6 className={cn(styles.title)}>Solicitudes</h6>}
    </NavLink>
  );
}

export default AppLogo;
