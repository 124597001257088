import { makeStyles } from '@material-ui/core/styles';
import { SECONDARY_RED } from '~constants/colors';
import { FULL_WIDTH } from '~constants/sizes';

export const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    position: 'relative',
    width: FULL_WIDTH
  },
  showErrorOnEdit: {
    '& > .MuiFormHelperText-root.Mui-disabled': {
      color: SECONDARY_RED
    },
    '& > .MuiInputBase-root.Mui-disabled': {
      '&:before': {
        borderColor: SECONDARY_RED
      }
    }
  }
});
