/* eslint-disable max-lines */
export const CAP_LIST = {
  '01': {
    'CAP CATAMARCA': '00',
    'CAP BELEN': '01',
    'CAP ANDALGALA': '02'
  },
  '02': {
    'CAP CORDOBA': '00',
    'CAP RIO TERCERO': '01',
    'CAP JESUS MARIA': '02',
    'CAP VILLA CARLOS PAZ': '03',
    'CAP LA FALDA': '04',
    'CAP ALTA GRACIA': '05',
    'CAP PILAR': '06',
    'CAP VILLA DOLORES': '07',
    'CAP NUEVA CORDOBA': '11',
    'CAP VILLA GENERAL BELGRANO': '15',
    'CAP VILLA ALLENDE': '16'
  },
  '03': {
    'CAP CORRIENTES': '00',
    'CAP GOYA': '01',
    'CAP CURUZU CUATIA': '02',
    'CAP MERCEDES': '03',
    'CAP PASOS DE LOS LIBRES': '04',
    'CAP MONTE CASEROS': '05',
    'CAP BELLA VISTA': '06',
    'CAP ESQUINA': '08',
    'CAP VIRASORO': '10',
    'CAP ITUZAINGO': '11',
    'CAP SANTO TOME': '12'
  },
  '04': {
    'CAP CHACO': '00',
    'CAP PTE. ROQUE SAENZ PEÑA': '01',
    'CAP VILLA ANGELA': '02',
    'CAP CHARATA': '03'
  },
  '05': {
    'CAP TRELEW': '00',
    'CAP PUERTO MADRYN': '01',
    'CAP RAWSON': '02',
    'CAP SAN ANTONIO': '04',
    'CAP ESQUEL': '06'
  },
  '06': {
    'CAP DEL PARANA': '00',
    'CAP VICTORIA': '01',
    'CAP DIAMANTE': '02',
    'CAP NOGOYA': '03',
    'CAP VILLAGUAY': '04',
    'CAP CRESPO': '05',
    'CAP LA PAZ': '06',
    'CAP GUALEGUAY': '09',
    'CAP ROSARIO DEL TALA': '10'
  },
  '07': {
    'CAP FORMOSA': '00',
    'CAP EL COLORADO': '01',
    'CAP CLORINDA': '02',
    'CAP LAS LOMITAS': '03'
  },
  '08': {
    'CAP JUJUY': '00',
    'CAP LDOR. GRAL.SAN MARTIN': '01',
    'CAP SAN PEDRO': '02',
    'CAP PERICO': '03',
    'CAP LA QUIACA': '05'
  },
  '09': {
    'CAP LA PAMPA': '00',
    'CAP GENERAL PICO': '01',
    'CAP MACACHIN': '03',
    'CAP GENERAL ACHA': '04',
    'CAP REALICO': '05',
    'CAP VICTORICA': '06',
    'CAP EDUARDO CASTE': '07',
    'CAP CATRILO': '12',
    'CAP 25 DE MAYO': '14',
    'CAP INTENDENTE ALVEAR': '15'
  },
  10: {
    'CAP LA RIOJA': '00',
    'CAP CHILECITO': '01',
    'CAP CHAMICAL': '02',
    'CAP AIMOGASTA': '03',
    'CAP CHEPES': '05'
  },
  11: {
    'CAP MENDOZA': '00',
    'CAP SAN RAFAEL': '02',
    'CAP MALARGUE': '03',
    'CAP LUJAN DE CUYO': '04',
    'CAP SAN MARTIN': '05',
    'CAP TUNUYAN': '07',
    'CAP GODOY CRUZ': '08',
    'CAP PALMARES': '10'
  },
  12: {
    'CAP ELDORADO': '04',
    'CAP OBERA': '05',
    'CAP PUERTO RICO': '07',
    'CAP PTO. IGUAZU': '08',
    'CAP APOSTOLES': '09',
    'CAP SAN VICENTE': '10',
    'CAP LEANDRO N. ALEM': '12',
    'CAP JARDIN AMERICA': '13',
    'CAP SANATORIO BORATTI': '16'
  },
  13: {
    'CAP NORPATAGONICA': '00',
    'CAP ZAPALA': '01',
    'CAP CUTRAL CO': '02',
    'CAP CATRIEL': '03',
    'CAP GENERAL ROCA': '05',
    'CAP VILLA REGINA': '07',
    'CAP CIPOLLETTI': '08',
    'CAP RINCON DE LOS SAUCES': '11',
    'CAP PLOTTIER': '12',
    'CAP CHOELE CHOEL': '13',
    'CAP CENTENARIO': '15',
    'CAP AÑELO': '18'
  },
  14: {
    'CAP LOBOS': '00',
    'CAP SALADILLO': '01',
    'CAP CHASCOMUS': '02',
    'CAP SAN MIGUEL DEL MONTE': '03',
    'CAP NAVARRO': '04',
    'CAP LAS FLORES': '06',
    'CAP CAÑUELAS': '08',
    'CAP MARCOS PAZ': '09',
    'CAP LAS HERAS': '10',
    'CAP SAN VICENTE': '13'
  },
  15: {
    'CAP SALTA': '00',
    'CAP ORAN': '01',
    'CAP TARTAGAL': '02',
    'CAP GENERAL GUEMES': '03',
    'CAP METAN': '05',
    'CAP VILLA SAN LORENZO': '06',
    'CAP CAFAYATE': '07'
  },
  16: {
    'CAP SAN JUAN': '00',
    'CAP JACHAL': '03',
    'CAP RIVADAVIA': '06'
  },
  17: {
    'CAP SAN LUIS': '00',
    'CAP VILLA MERCEDES': '01',
    'CAP MERLO': '03'
  },
  18: {
    'CAP SANTA CRUZ': '00',
    'CAP PUERTO SAN JULIAN': '01',
    'CAP EL CALAFATE': '03',
    'CAP RIO TURBIO': '04',
    'CAP GOBERNADOR GREGORES': '05'
  },
  19: {
    'CAP SANTA FE': '00',
    'CAP RAFAELA': '02',
    'CAP SUNCHALES': '03',
    'CAP RECONQUISTA': '05',
    'CAP ESPERANZA': '07',
    'CAP SAN JUSTO': '09',
    'CAP SAN CARLOS CENTRO': '10',
    'CAP LAS TOSCAS': '12',
    'CAP SAN GUILLERMO': '13',
    'CAP TOSTADO': '14'
  },
  20: {
    'CAP SGO DEL ESTERO': '00',
    'CAP FRIAS': '01',
    'CAP TERMAS DE RIO HONDO': '02',
    'CAP FERNANDEZ': '03',
    'CAP LA BANDA': '04'
  },
  21: {
    'CAP TUCUMAN': '01',
    'CAP CONCEPCION': '02',
    'CAP SANTA MARIA': '03',
    'CAP YERBA BUENA': '04'
  },
  22: {
    'CAP TIERRA DEL FUEGO': '00',
    'CAP USHUAIA': '01'
  },
  23: {
    'CAP ROSARIO CENTRO': '00',
    'CAP SAN JORGE': '01',
    'CAP LAS ROSAS': '02',
    'CAP VILLA CONSTITUCION': '04',
    'CAP CAÑADA DE GOMEZ': '05',
    'CAP SAN LORENZO': '06',
    'CAP VENADO TUERTO': '07',
    'CAP CASILDA': '08',
    'CAP RUFINO': '09',
    'CAP GALVEZ': '10',
    'CAP ARROYO SECO': '11',
    'CAP FISHERTON': '13',
    'CAP SUR': '14',
    'CAP FIRMAT': '17',
    'CAP ARMSTRONG': '19',
    'CAP VILLA CAÑAS': '22',
    'CAP FUNES': '29'
  },
  24: {
    'CAP MAR DEL PLATA': '00',
    'CAP MIRAMAR': '02',
    'CAP BALCARCE': '03',
    'CAP PINAMAR': '04',
    'CAP VILLA GESELL': '05',
    'CAP SANTA TERESITA': '07',
    'CAP DOLORES': '08',
    'CAP GRAL.MADARIAGA': '09',
    'CAP MAR DE AJO/SAN BERNARDO': '14',
    'CAP PUERTO': '15',
    'CAP NECOCHEA': '17',
    'CAP CONSTITUCION': '20',
    'CAP SAN MANUEL': '22'
  },
  25: {
    'CAP LA PLATA': '00',
    'CAP CITY BELL': '01',
    'CAP BRANDSEN': '02',
    'CAP ENSENADA': '04',
    'CAP IRAOLA': '07'
  },
  26: {
    'CAP COMODORO RIVADAVIA': '00',
    'CAP CALETA OLIVIA': '01',
    'CAP PICO TRUNCADO': '02',
    'CAP LAS HERAS': '03',
    'CAP SARMIENTO': '04',
    'CAP PUERTO DESEADO': '05',
    'CAP PERITO MORENO': '06',
    'RADA TILLY': '07',
    'CAP SANATORIO LA ESPAÑOLA': '08',
    'CAP CLINICA DEL VALLE': '09'
  },
  30: {
    'CAP BARILOCHE': '00',
    'CAP EL BOLSON': '01',
    'CAP VILLA LA ANGOSTURA': '03',
    'CAP SAN MARTIN DE LOS ANDES': '05',
    'CAP BUSTILLO': '07'
  },
  31: {
    'CAP BAHIA BLANCA': '00',
    'CAP VIEDMA': '05',
    'CAP CNEL PRINGLES': '07',
    'CAP RIO COLORADO': '09',
    'CAP PIGUE': '10',
    'CAP TRES ARROYOS': '19'
  },
  32: {
    'CAP BS AS CENTRO': '00',
    'CAP AZUL': '01',
    'CAP OLAVARRIA': '10'
  },
  33: {
    'CAP PERGAMINO': '00',
    'CAP SAN NICOLAS': '01',
    'CAP SAN PEDRO': '02',
    'CAP BARADERO': '03',
    'CAP VILLA RAMALLO': '04',
    'CAP COLON': '08',
    'CAP ZARATE': '10',
    'CAP CAMPANA': '11'
  },
  35: {
    'CAP CHIVILCOY': '00',
    'CAP BRAGADO': '01',
    'CAP 25 DE MAYO': '02',
    'CAP MERCEDES': '05',
    'CAP 9 DE JULIO': '07',
    'CAP LUJAN': '08',
    'CAP SAN ANDRES DE GILES': '09',
    'CAP SAN ANTONIO DE ARECO': '10',
    'CAP CAPILLA DEL SEÑOR': '12',
    'CAP GENERAL RODRIGUEZ': '13'
  },
  36: {
    'CAP TRENQUE LAUQUEN': '00',
    'CAP AMERICA': '02',
    'CAP PEHUAJO': '03',
    'CAP SALLIQUELO': '04',
    'CAP 30 DE AGOSTO': '06',
    'CAP CARLOS CASARES': '08',
    'CAP DAIREAUX': '13',
    'CAP CNEL SUAREZ': '15',
    'CAP BOLIVAR': '16'
  },
  37: {
    'CAP JUNIN': '00',
    'CAP CHACABUCO': '01',
    'CAP SALTO': '04',
    'CAP LINCOLN': '05',
    'CAP VEDIA': '06',
    'CAP ROJAS': '11',
    'CAP CAPITAN SARMIENTO': '12',
    'CAP GENERAL VILLEGAS': '13'
  },
  39: {
    'CAP VILLA MARIA': '00',
    'CAP MARCOS JUAREZ': '01',
    'CAP BELL VILLE': '02',
    'CAP CRUZ ALTA': '03',
    'CAP JUSTINIANO POSSE': '05',
    'CAP MONTE MAIZ': '07',
    'CAP SAN FRANCISCO': '10',
    'CAP ARROYITO': '13'
  },
  40: {
    'CAP RIO CUARTO': '00',
    'CAP LABOULAYE': '01',
    'CAP GENERAL DEHEZA': '02',
    'CAP HUINCA RENANCO': '03',
    'CAP LA CARLOTA': '07'
  },
  42: {
    'FILIAL RIO URUGUAY': '00',
    'CAP GUALEGUAYCHU': '01',
    'CAP CONCEPCION DEL URUGUAY': '02',
    'CAP CHAJARI': '03',
    'CAP COLON': '04'
  },
  60: {
    'CAP METROPOLITANA': '00',
    'ADMINISTRACION CENTRAL': '01',
    'CAP LOMAS DE ZAMORA': '05',
    'CAP PILAR': '08',
    'CAP QUILMES': '09',
    'CAP RAMOS MEJIA': '10',
    'CAP SAN ISIDRO': '12',
    'CAP SAN MARTIN': '13',
    'CAP SAN MIGUEL': '14',
    'CAP VICENTE LOPEZ': '15',
    'CAP CABALLITO': '18',
    'CAP BELGRANO': '19',
    'CAP ESCOBAR': '21',
    'CAP LANUS': '23',
    'CAP PACHECO': '25',
    'CAP DEVOTO': '26',
    'CAP PALERMO': '28',
    'CAP BARRACAS': '29',
    'CAP CASTELAR': '30',
    'CAP ADROGUE': '31',
    'CAP ONCE': '32',
    'CAP BARRIO NORTE': '33',
    'CAP MARTINEZ': '34',
    'CAP SAN ISIDRO (LOMAS)': '35',
    'CAP OLIVOS': '37',
    'CAP MORENO': '38',
    'CAP PARQUE PATRICIOS': '40',
    'CAP MONTE GRANDE': '41',
    'CAP MICROCENTRO': '42',
    'CAP VILLA URQUIZA': '43',
    'CAP FLORES': '44',
    OSTVLA: '48',
    'CAP BELGRANO II': '50',
    'CAP NORDELTA': '54',
    CAT: '55',
    AFILIACIONES: '56',
    BINARIA: '57',
    OSCOMM: '81',
    OSEN: '82',
    OSPACARP: '84',
    'CAP PYM': '91'
  },
  9999: {
    'NO APLICA': '9999'
  },
  99999: {
    INEXISTENTE: '99999'
  }
};
