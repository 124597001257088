import React from 'react';
import { GetApp as Download } from '@material-ui/icons';

import { useStyles } from './styles';

interface Props {
  fileUrl: string;
}

const DownloadZipButton = ({ fileUrl }: Props) => {
  const styles = useStyles();
  return (
    <a href={fileUrl} className={styles.buttonDownloadZip}>
      Descargar ZIP
      <Download className={styles.iconDownload} />
    </a>
  );
};

export default DownloadZipButton;
