/* eslint-disable @typescript-eslint/camelcase */
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { RESEND_VALIDATION_EMAIL_F2, SEND_INITIAL_EMAIL_F2 } from '~config/providers/constants';
import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';
import { getSendEmailRequestPayload } from '~screens/SolicitudesF2/utils';
import { GenericObjectInterface } from '~utils/types';

export const SOLICITUDES_F2_REQUESTS_FIELDS = {
  id: 'id',
  promoterId: 'promoterId',
  planValidityDate: 'planValidityDate',
  state: 'state',
  firstName: 'attorney.firstName',
  documentType: 'attorney.documentType',
  documentNumber: 'attorney.documentNumber',
  lastName: 'attorney.lastName',
  attorneyEmail: 'attorney.email',
  emailConfirmation: 'attorney.emailConfirmation',
  planType: 'planType',
  discountRate: 'discountRate',
  applyDiscountAllPlans: 'applyDiscountAllPlans',
  discountRate8260: 'discountRate8260',
  discountRate8360: 'discountRate8360',
  discountRate8430: 'discountRate8430',
  phoneNumber: 'attorney.phoneNumber',
  companyName: 'companyName',
  cuit: 'cuit'
};

export const SOLICITUD_F2_FILTER = {
  applicationId: 'applicationId',
  companyName: 'companyName',
  cuit: 'cuit',
  firstName: 'firstName',
  lastName: 'lastName',
  documentNumber: 'documentNumber',
  promoterId: 'promoterId',
  state: 'state'
};

export const SEND_INITIAL_EMAIL_CONFIG = {
  requestType: SEND_INITIAL_EMAIL_F2,
  requestPayload: getSendEmailRequestPayload,
  modalName: MODAL_NAMES.INITIAL_EMAIL,
  successMessageNotify: 'resources.solicitudF2.modals.initialEmail.success'
};

export const RESEND_VALIDATION_EMAIL_CONFIG = {
  requestType: RESEND_VALIDATION_EMAIL_F2,
  requestPayload: getSendEmailRequestPayload,
  modalName: MODAL_NAMES.USER_VALIDATION,
  successMessageNotify: 'resources.solicitudF2.modals.userValidationEmail.success'
};

export const EMAIL_CONFIG: GenericObjectInterface<{
  requestType: string;
  requestPayload: any;
  modalName: string;
  successMessageNotify: string;
}> = {
  [SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD]: SEND_INITIAL_EMAIL_CONFIG,
  [SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_SENT.COD]: SEND_INITIAL_EMAIL_CONFIG,
  [SOLICITUDES_REQUEST_STATUS.FORM_LINK_OPENED.COD]: SEND_INITIAL_EMAIL_CONFIG,
  [SOLICITUDES_REQUEST_STATUS.PENDING_USER_VALIDATION.COD]: RESEND_VALIDATION_EMAIL_CONFIG
};
