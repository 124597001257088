import React from 'react';
import cn from 'classnames';

import { useStyles } from '../../styles';

interface Props {
  condition?: boolean | undefined;
  color?: 'success' | 'danger' | 'info' | null;
}

function Point({ condition = true, color = null }: Props) {
  const styles = useStyles();
  return color ? (
    <div className={cn(styles[color])} />
  ) : (
    <div className={condition ? cn(styles.success) : cn(styles.danger)} />
  );
}

export default Point;
