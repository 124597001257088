import { GenericObjectInterface } from '~utils/types';

export interface Resources {
  [index: string]: string;
}

export const DEFAULT_SORT = { field: 'id', order: 'asc' };

export const RESOURCES_PATH: Resources = {
  affiliations: 'applications',
  binaria: 'binaria/solicitudes',
  promoters: 'promoters',
  on: 'on/solicitudes',
  f2: 'f2/applications',
  altasmasivas: 'altasmasivas/pedido'
};

export const SPECIAL_QUERY_PARAMS: GenericObjectInterface<any> = {
  affiliations: {},
  promoters: {}
};

export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_PROMOTER = 'CREATE_PROMOTER';
export const EDIT_PROMOTER = 'EDIT_PROMOTER';
export const GET_DATOS_BENEFICIARIO = 'GET_DATOS_BENEFICIARIO';
export const EDIT_APPLICATION = 'EDIT_APPLICATION';
export const EVALUATE_APPLICATION = 'EVALUATE_APPLICATION';
export const EVALUATE_APPLICATION_ON = 'EVALUATE_APPLICATION_ON';
export const SEND_INITIAL_EMAIL = 'SEND_INITIAL_EMAIL';
export const RESEND_VALIDATION_EMAIL = 'RESEND_VALIDATION_EMAIL';
export const WARNING_TYPE = 'warning';
export const GET_HISTORIC_APPLICATION = 'GET_HISTORIC_APPLICATION';
// eslint-disable-next-line id-length
export const GET_HISTORIC_APPLICATION_BINARIA = 'GET_HISTORIC_APPLICATION_BINARIA';
export const GET_REJECTED_REASONS = 'GET_REJECTED_REASONS';
export const GET_ON_REJECTED_REASONS = 'GET_ON_REJECTED_REASONS';
export const TOGGLE_PROMOTER_ENABLE = 'TOGGLE_PROMOTER_ENABLE';
export const GET_ONE_PROMOTER_LIST = 'GET_ONE_PROMOTER_LIST';
export const UPDATE_ESTADO_BINARIA = 'UPDATE_ESTADO_SOLICITUD_BINARIA';
export const CREAR_SOLICITUD_BINARIA = 'CREAR_SOLICITUD_BINARIA';
export const EDITAR_SOLICITUD_BINARIA = 'EDITAR_SOLICITUD_BINARIA';
export const EDITAR_SOLICITUD_ON = 'EDITAR_SOLICITUD_ON';
export const CREAR_SOLICITUD_ON = 'CREAR_SOLICITUD_ON';
export const GET_HISTORIC_SOLICITUD_ON = 'GET_HISTORIC_SOLICITUD_ON';
export const SEND_INITIAL_EMAIL_ON = 'SEND_INITIAL_EMAIL_ON';
export const RESEND_VALIDATION_EMAIL_ON = 'RESEND_VALIDATION_EMAIL_ON';
// F2
export const UPDATE_SOLICITUD_F2 = 'UPDATE_SOLICITUD_F2';
export const CREAR_SOLICITUD_F2 = 'CREAR_SOLICITUD_F2';
export const GET_HISTORIC_SOLICITUD_F2 = 'GET_HISTORIC_SOLICITUD_F2';
export const SEND_INITIAL_EMAIL_F2 = 'SEND_INITIAL_EMAIL_F2';
export const RESEND_VALIDATION_EMAIL_F2 = 'RESEND_VALIDATION_EMAIL_F2';
export const EVALUATE_APPLICATION_F2 = 'EVALUATE_APPLICATION_F2';

export const MODIFY_PROMOTER_SUBROL = 'MODIFY_PROMOTER_SUBROL';
export const UPLOAD_FILE_APPLICATION = 'UPLOAD_FILE_APPLICATION';
export const DELETE_PROSPECT_ATTACHMENT = 'DELETE_PROSPECT_ATTACHMENT';
export const AUTH_ERRORS = {
  e4303: { code: '4303', message: 'errors.4303' },
  e4305: { code: '4305', message: 'errors.4305' }
};
export const MAIL_ERRORS = {
  e3012: { code: '3012' }
};
export const FILE_NOT_FOUND_BUCKET_ERROR = {
  e4401: { code: '4401' }
};
export const MASSIVE_UPLOAD = 'MASSIVE_UPLOAD';
export const GET_DETALLE_MASSIVE_UPLOAD = 'GET_MASSIVE_UPLOAD';
