import React from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';

import { useStyles } from './styles';

interface Props {
  state: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
  rpaStatus?: boolean;
  zcarStatus?: boolean;
}
function StatusField({
  state,
  rounded = true,
  className = '',
  labelClassName = '',
  rpaStatus,
  zcarStatus
}: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const classSAP = rpaStatus ? 'sap' : 'sap_rpa';
  const classZCAR = zcarStatus ? 'zcar' : '';
  return (
    <div className={`full-width ${className}`}>
      <div
        className={cn(
          styles.label,
          styles[(state === 'sap' && classSAP) || (state === 'zcar' && classZCAR) || state],
          labelClassName,
          {
            rounded
          }
        )}
      >
        <span>{t(`resources.affiliations.fields.statusTypes.${state}`)}</span>
      </div>
    </div>
  );
}

export default StatusField;
