/* eslint-disable react/forbid-dom-props */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import { useTranslate } from 'react-admin';
import { ReactComponent as Success } from '~assets/success.svg';
import { ReactComponent as Error } from '~assets/error.svg';

import { useStyles } from './styles';

interface Props {
  error?: { message: string } | any;
  successMessage?: string;
  showError?: any;
}

function FeedbackModalContent({ error, successMessage, showError }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const type = error ? 'error' : 'success';
  const ModalIcon = error ? Error : Success;
  const errorDefault = showError ? error?.cause?.message : t('errors.default');
  const errorMessage = error?.message ? t(error.message) : errorDefault;
  const message = error ? errorMessage : successMessage;
  const renderErrors = showError?.detail.map((index: any) => {
    // Lo hice de esta forma porque le agrego desde el backend una coma al final de cada error. Entonces asi puedo separarlos
    const errorValues = Object.values(index);
    // @ts-ignore
    const splitErrors = errorValues[0]?.slice(0, -1)?.split(',');
    const errorIndex = Object.keys(index);

    // eslint-disable-next-line no-shadow
    const renderErrors = splitErrors?.map((error: string) => (
      <div>
        <span key={error}>Error: {error}</span>
      </div>
    ));
    return (
      <>
        <span
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '4px'
          }}
        >
          {`Registro: ${errorIndex}`}
        </span>
        <ul
          style={{
            marginBottom: '12px'
          }}
        >
          {renderErrors}
        </ul>
      </>
    );
  });

  const renderErrorIndex = showError?.detail.map((i: any) => {
    const index: any = Object.keys(i);
    const causeError = i[index];
    return <span key={i}>{`${index}: ${causeError} `}</span>;
  });

  const MAX_ERRORS_SHOWN = 10;
  const shouldShowErrors = showError && showError?.detail.length <= MAX_ERRORS_SHOWN;

  return (
    <div className="column center">
      <ModalIcon />
      <h1 className={`m-bottom-2 ${styles.modalTitle}`}>{t(`resources.modals.${type}Title`)}</h1>
      {!!message?.length && <h2 className={styles.modalSubtitle}>{message}</h2>}
      {showError && (
        <div className={styles.errorDisplay}>
          <br />
          {shouldShowErrors ? (
            renderErrors
          ) : (
            <>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '12px'
                }}
              >
                Registros:
              </div>
              <ul>{renderErrorIndex}</ul>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default FeedbackModalContent;
