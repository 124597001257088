import { useEffect, useState } from 'react';
import useListProviders from '~config/hooks/useListProviders';
import { FILIALES_LIST } from '~constants/filiales';
import { CAP_LIST } from '~constants/filialesCaps';
import { getObjectValueFromString, setObjectValueFromString } from '~utils/object';
import { PromoterForm, FilterAux } from '~utils/types';

// const invertedFilials = Object.keys(FILIALES_LIST).reduce(
//   (dic, current) => ({
//     ...dic,
//     [FILIALES_LIST[current as keyof typeof FILIALES_LIST]]:
//       FILIALES_LIST[current as keyof typeof FILIALES_LIST]
//   }),
//   {} as typeof FILIALES_LIST
// );

type Dictionary = {
  [key: string]: any;
};

const correctValueInContainers = (containers: Dictionary) => (
  correctQ: boolean,
  fieldPath: string,
  newValue: string
) => {
  const correctIn = (targetContainer: any) => {
    const currentValue = getObjectValueFromString(fieldPath, targetContainer);
    if (currentValue !== newValue) {
      setObjectValueFromString(fieldPath, targetContainer, newValue);
      return { currentValue, newValue };
    }
    return undefined;
  };

  if (!correctQ) {
    return undefined as Dictionary | undefined;
  }

  const result = Object.keys(containers).reduce(
    (reduce: Dictionary, aKey) => ({ ...reduce, ...{ [aKey]: correctIn(containers[aKey]) } }),
    {} as Dictionary
  );

  return result;
};

const usePromoterFormColaborator = () => {
  const { capChoices } = useListProviders();

  const [activeFilial, setActiveFilial] = useState<string>(FILIALES_LIST['FILIAL METROPOLITANA']);
  const [capsOptions, setCapsOptions] = useState(capChoices(FILIALES_LIST['FILIAL METROPOLITANA']));
  const [alreadyInitializated, setAlreadyInitializated] = useState<boolean>(false);

  useEffect(() => {
    if (alreadyInitializated) {
      const caps = capChoices(activeFilial);
      setCapsOptions(caps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilial]);

  const calculateCapsOptions = (formData: PromoterForm | FilterAux, isEdit: boolean) => {
    const container = { formData };
    const correctValue = correctValueInContainers(container);
    // TO FIX: Rompe el componente cuando queremos modificar el estado desde afuera por lo que lo dejamos como deuda tecnica
    if (formData.filialCode && activeFilial !== String(formData.filialCode)) {
      setAlreadyInitializated(true);
      setActiveFilial(String(formData.filialCode));
      if (isEdit) {
        const capsList = CAP_LIST[String(formData.filialCode) as keyof typeof CAP_LIST];
        const capsValues = Object.values(capsList);
        formData.capCode = capsValues[0];
      } else {
        correctValue(true, 'capCode', '');
      }
    }
  };

  return { activeFilial, capsOptions, calculateCapsOptions };
};

export default usePromoterFormColaborator;
