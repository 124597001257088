import { cuit as argCuitValidator } from 'arg.js';
import { minMaxLength, numericalDigits, minLength } from '~utils/inputValidations';

import { DOCUMENT_TYPES, DOCUMENT_NUMBER_PROPS } from './constants';

function isNotAPrefix(documentNumberType: string, valueToCkeck: string) {
  return DOCUMENT_NUMBER_PROPS[documentNumberType].PREFIXES?.every(
    type => !valueToCkeck.startsWith(String(type))
  );
}

function cuitCuilValidation(documentNumberType: string, cuitCuil: any, errorMessage: string) {
  const cuitCuilStr = String(cuitCuil);
  return (
    (isNotAPrefix(documentNumberType, cuitCuilStr) || !argCuitValidator.isValid(cuitCuilStr)) && errorMessage
  );
}

const cuilValidation = (errorMessage: string) => (cuil: any) =>
  cuitCuilValidation(DOCUMENT_TYPES.CUIL.id, cuil, errorMessage);

const cuitValidation = (errorMessage: string) => (cuit: any) =>
  cuitCuilValidation(DOCUMENT_TYPES.CUIT.id, cuit, errorMessage);

export const getDocumentNumberValidation = ({ t, type }: { t: any; type: string }) => [
  type === DOCUMENT_TYPES.DNI.id &&
    numericalDigits(
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS[type].MIN,
        max: DOCUMENT_NUMBER_PROPS[type].MAX
      })
    ),
  (type === DOCUMENT_TYPES.Passport.id || type === DOCUMENT_TYPES.DNI.id) &&
    minMaxLength(
      DOCUMENT_NUMBER_PROPS[type].MIN,
      DOCUMENT_NUMBER_PROPS[type].MAX,
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS[type].MIN,
        max: DOCUMENT_NUMBER_PROPS[type].MAX
      })
    ),
  type === DOCUMENT_TYPES.CUIL.id &&
    minLength(
      DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIL.id].MIN,
      t('validation.exactDigits', { length: DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIL.id].MIN })
    ),
  type === DOCUMENT_TYPES.CUIT.id &&
    minLength(
      DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIT.id].MIN,
      t('validation.exactDigits', { length: DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIT.id].MIN })
    ),
  type === DOCUMENT_TYPES.CUIL.id && cuilValidation(t('validation.cuilNumber')),
  type === DOCUMENT_TYPES.CUIT.id && cuitValidation(t('validation.cuitNumber'))
];
