/* eslint-disable max-lines */
import { FILIALES_LIST } from '~constants/filiales';
import { CAP_LIST } from '~constants/filialesCaps';
import { ROLE_TYPES, PERMISO_OSDE_CHOICES, PERMISO_BINARIA_CHOICES } from '~constants/promoters';

function filCapTranslations(curFil: string): {} {
  const capsFromCurFil = CAP_LIST[curFil as keyof typeof CAP_LIST];

  return Object.keys(capsFromCurFil).reduce(
    (revThisFil, curCapNameThisFil) => ({
      ...revThisFil,
      [`${curFil}-${capsFromCurFil[curCapNameThisFil as keyof typeof capsFromCurFil]}`]: curCapNameThisFil
    }),
    {}
  );
}

function allFilsTransations() {
  return Object.values(FILIALES_LIST).reduce(
    (prev, curFil) => ({
      ...prev,
      ...filCapTranslations(curFil)
    }),
    {}
  );
}

function filFilialsTranslations() {
  return Object.keys(FILIALES_LIST).reduce(
    (revThisFil, curFilNameThisFIl) => ({
      ...revThisFil,
      [`${FILIALES_LIST[curFilNameThisFIl as keyof typeof FILIALES_LIST]}`]: curFilNameThisFIl
    }),
    {}
  );
}

const promotorsSpanish = {
  name: 'Usuarios',
  listName: 'Listado de usuarios',
  detailTitle: 'Detalle de usuario',
  fields: {
    promoter: 'Promotor',
    legajo: 'Legajo',
    filial: 'Filial',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    filialDescription: 'Nombre de filial',
    action: 'Acción',
    enabled: 'Habilitado',
    cap: 'Cap',
    permisoOSDE: 'Permiso OSDE',
    permisoOSDEChoices: {
      [PERMISO_OSDE_CHOICES.SI]: 'SI',
      [PERMISO_OSDE_CHOICES.NO]: 'NO'
    },
    permisoBinaria: 'Permiso Binaria',
    permisoBinariaChoices: {
      [PERMISO_BINARIA_CHOICES.SI]: 'SI',
      [PERMISO_BINARIA_CHOICES.NO]: 'NO'
    },
    phoneNumber: 'Teléfono',
    rol: 'Rol',
    rolTypes: {
      [ROLE_TYPES['Usuario promotor']]: 'Usuario promotor',
      [ROLE_TYPES.Adminstrador]: 'Administrador',
      [ROLE_TYPES.Analista]: 'Analista',
      [ROLE_TYPES.SuperAdmin]: 'Super Admin',
      [ROLE_TYPES.Asesor]: 'Asesor de Atención'
    },
    filialesList: filFilialsTranslations(),
    capsList: allFilsTransations()
  },
  modals: {
    togglePromoterState: {
      title: '¿Estás seguro?',
      activate: 'Estás por habilitar al promotor.',
      deactivate: 'Estás por deshabilitar al promotor.',
      success: 'Se ha actualizado el estado del promotor.'
    },
    create: {
      success: 'Se ha creado el promotor correctamente'
    },
    edit: {
      success: 'Se ha editado el promotor correctamente'
    }
  },
  editPromoter: 'Editar',
  editPromoterTitle: 'Editar'
};

export default promotorsSpanish;
