import React, { Children, ReactNode } from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { HideOnScroll, LoadingIndicator } from 'react-admin';
import LogoutButton from '~components/LogoutButton';
import { GenericObjectInterface } from '~utils/types';

import AppLogo from '../AppLogo';

import { useClasses, useStyles } from './styles';

interface Props {
  children?: ReactNode;
  classes?: object;
  className?: string;
  onToggleSidebar?: () => void;
  props?: object;
}

function AppBar({ children, classes: classesOverride, className, onToggleSidebar, ...props }: Props) {
  const classes = useClasses({ classes: classesOverride });
  const styles = useStyles();

  const isXSmall = useMediaQuery((theme: GenericObjectInterface<any>) => theme.breakpoints.down('xs'));

  return (
    <HideOnScroll>
      <MuiAppBar {...props} className={className} color="secondary">
        <Toolbar disableGutters className={classes.toolbar}>
          {isXSmall && (
            <IconButton
              onClick={onToggleSidebar}
              className={classes.icon}
              edge="end"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          {!isXSmall && Children.count(children) === 0 ? (
            <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
          ) : (
            children
          )}
          {isXSmall && <AppLogo showTitle={false} containerClassName={styles.logo} />}
          <LoadingIndicator />
          <LogoutButton />
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
}

export default AppBar;
