import React, { ReactNode } from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';
import Loading from '~components/Spinner/components/loading';
import CustomButton from '~components/CustomButton';

import { useStyles } from './styles';

interface Props {
  loading: boolean;
  children: ReactNode;
  error?: any;
  onRetry?: () => void;
}

function ModalInfoInnerContainer({ children, loading = false, error, onRetry }: Props) {
  const styles = useStyles();
  const t = useTranslate();

  return (
    <div className={cn('column', { 'full-height center middle': loading || !!error })}>
      {loading && <Loading />}
      {!loading && error && (
        <>
          <p className={cn(styles.error, { 'm-bottom-3': !!onRetry })}>{t('errors.default')}</p>
          {!!onRetry && (
            <CustomButton className={styles.retryButton} onClick={onRetry} label={t('general.retry')} />
          )}
        </>
      )}
      {!loading && !error && children}
    </div>
  );
}

export default ModalInfoInnerContainer;
