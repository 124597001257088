import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, PRIMARY_RED } from '~constants/colors';
import { FULL_WIDTH } from '~constants/sizes';

export const useStyles = makeStyles({
  container: {
    flexGrow: 1
  },
  row: {
    '&.MuiFormControl-root': {
      width: FULL_WIDTH,
      marginBottom: '4px'
    }
  },
  label: {
    marginTop: '20px',
    marginBottom: '20px',
    color: PRIMARY_BLUE
  },
  labelError: {
    color: PRIMARY_RED,
    fontSize: '12px'
  },
  input: {
    border: '1px solid #e6e6e6',
    padding: '10px',
    borderRadius: '4px'
  },
  inputError: {
    border: `1px solid ${PRIMARY_RED}`,
    padding: '10px'
  },
  inputCheckbox: {
    marginBottom: '20px',
    marginRight: '10px'
  },
  textArea: {
    marginBottom: '20px'
  },
  wrapperButton: {
    overflow: 'hidden'
  },
  button: {
    marginTop: '20px',
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  checkbox: {
    width: '110%',
    '& label': {
      textOverflow: 'ellipsis'
    }
  },
  simpleForm: {
    '& li': {
      border: 'none !important',
      '& p': {
        display: 'none !important'
      },
      '& label:nth-child(1)': {
        display: 'none !important'
      }
    },
    '& section': {
      '& div:nth-child(2)': {
        '& button': {
          justifyContent: 'center',
          display: 'none'
        }
      }
    },
    '& div:nth-child(2)': {
      borderRadius: '12px',
      background: 'white',
      '& div': {
        justifyContent: 'center',
        background: 'white',
        borderRadius: '12px',
        '& button': {
          display: 'inline',
          width: '50%'
        }
      }
    }
  },
  buttonFinished: {
    marginBottom: 10
  }
});
