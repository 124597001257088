import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import CustomButton from '~components/CustomButton';
import {
  getSendEmailTexts,
  getShowSendInitialEmail,
  isEvaluationPending,
  isAttorneyValidationPending
} from '~screens/SolicitudesF2/utils';
import { SolicitudF2Request } from '~utils/types';

import { useStyles } from './styles';

interface Props {
  solicitud: SolicitudF2Request;
  onReenvioMailValidacion: (solicitud: SolicitudF2Request) => void;
  onEnvioMailInicial: (solicitud: SolicitudF2Request) => void;
  onCheckDetail?: any;
  rl?: boolean;
}

export default function ActionField({
  solicitud,
  onEnvioMailInicial,
  onReenvioMailValidacion,
  onCheckDetail,
  rl = false
}: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const showSendInitialEmail = getShowSendInitialEmail(solicitud.state);
  const evaluationPending = isEvaluationPending(solicitud.state);
  const attorneyValidationPending = isAttorneyValidationPending(solicitud.state);
  const { labelShort, title } = useMemo(() => getSendEmailTexts(solicitud.state), [solicitud.state]);

  const handleClick = (event: SyntheticEvent<EventTarget>) => {
    if (showSendInitialEmail) {
      event.stopPropagation();
      onEnvioMailInicial(solicitud);
    }
    if (attorneyValidationPending) {
      event.stopPropagation();
      onReenvioMailValidacion(solicitud);
    }
    if (evaluationPending) {
      onCheckDetail();
    }
  };

  return (
    <>
      <div className={`${rl ? styles.buttonR : styles.button}`}>
        {(showSendInitialEmail || attorneyValidationPending) && (
          <CustomButton
            label={t(labelShort)}
            title={t(title)}
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
        {evaluationPending && (
          <CustomButton
            label={t('resources.solicitudF2.validateAffiliation')}
            title={t('resources.solicitudF2.validateAffiliationTitle')}
            onClick={handleClick}
            className={styles.buttonText}
          />
        )}
      </div>
    </>
  );
}
