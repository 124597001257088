/* eslint-disable react/forbid-dom-props */
import React from 'react';
import { useTranslate } from 'react-admin';
import cn from 'classnames';
import { useStyles } from '~components/ViewInput/styles';
import { AFFILIATION_TYPES, APPLICATION_TYPES } from '~constants/affiliations';
import { AGE_LIMIT_IN_MONTS, AGE_LIMIT_IN_MONTS_TWO } from '~screens/AffiliationRequests/constants';
import { FamilyMember, FamilyMembers } from '~utils/types';
import moment from 'moment';

import { useStyles as useStylesRisk } from './styles';

interface Props {
  familyData: FamilyMember[];
  isEdit?: boolean;
  holder?: any;
}

function FamilyGroup({ familyData, isEdit, holder = null }: Props) {
  const viewInputStyles = useStyles();
  const t = useTranslate();
  const stylesR = useStylesRisk();
  let childCount = 0;
  const existLimit =
    APPLICATION_TYPES.FULL === holder.applicationType ||
    APPLICATION_TYPES.BENEFICIARY === holder.applicationType;
  const limit =
    AFFILIATION_TYPES.DIRECT === holder.affiliationType ||
    AFFILIATION_TYPES.DIRECT_GROUP === holder.affiliationType
      ? AGE_LIMIT_IN_MONTS_TWO
      : AGE_LIMIT_IN_MONTS;
  const ageLimitInMoths = existLimit ? limit : null;

  return isEdit && familyData ? (
    <div className={`column ${viewInputStyles.container}`}>
      <span className={`full-width ${viewInputStyles.label}`}>
        {t('resources.affiliations.familyGroup.familyGroup')}
      </span>
      {familyData.map(item => {
        const isChild =
          item.member === FamilyMembers.CHILD ||
          item.member === FamilyMembers.STEPSON ||
          item.member === FamilyMembers.SON_UNDER_GUARD;
        let gender = null;
        if (isChild) {
          childCount++;
          gender = item.sex;
        }
        return (
          <div key={`${item.member}-${item.cuil}`} className="full-width row">
            <span className={`m-right-1 ${viewInputStyles.label}`}>
              {t(
                `resources.affiliations.familyGroup.${
                  gender && gender === 'Femenino' ? 'daughter' : item.member
                }`
              )}
              {isChild ? ` ${childCount}:` : ':'}
            </span>
            <span className={viewInputStyles.value}>
              {t('resources.affiliations.familyGroup.age', {
                age: item.age
              })}
              {isChild &&
                ageLimitInMoths &&
                moment().diff(moment(item.birthDate), 'months') >= ageLimitInMoths && (
                  <>
                    <div className={cn(stylesR.point)} style={{ display: 'inline-block' }} />
                    <p style={{ display: 'inline-block', color: 'red' }}>
                      {t('resources.affiliations.familyGroup.exceededAge')}
                    </p>
                  </>
                )}
            </span>
          </div>
        );
      })}
    </div>
  ) : null;
}

export default FamilyGroup;
