import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { AffiliationRequest, Nullable } from '~utils/types';
import { getCompleteName } from '~utils/general';
import StatusField from '~screens/AffiliationRequests/components/StatusField';
import ViewInput from '~components/ViewInput';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';
import { EMAIL_CONFIG } from '~screens/AffiliationRequests/constants';
import ActionField from '~screens/AffiliationRequests/components/ActionField';
import useModal from '~app/hooks/useModal';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import CustomModal from '~components/CustomModal';
import { getSendEmailTexts, isUserValidationPending } from '~screens/AffiliationRequests/utils';
import { useHistory } from 'react-router';
import { linkToRecord } from 'ra-core';

import StatusRPA from '../../StatusRPA';
import HistoricAffiliationButton from '../HistoricAffiliationButton';

interface RequestPayload {
  id?: number;
}

interface Props {
  record: AffiliationRequest;
  basePath: string;
  id: string;
}

function ResponsiveList({ record, basePath, id }: Props) {
  const styles = useResponsiveListStyles();
  const t = useTranslate();
  const history = useHistory();
  const resource = 'affiliations';
  const [affiliationState, setAffiliationState] = useState<Nullable<string> | undefined>(null);

  const handleRowClick = useCallback(() => history.push(linkToRecord(basePath, id, 'show')), [
    basePath,
    history,
    id
  ]);

  const handleOpen = useCallback((affiliation?: AffiliationRequest) => {
    setAffiliationState(affiliation?.state);
  }, []);

  const handleClose = useCallback(() => {
    setAffiliationState(null);
  }, []);

  const [, dispatchInitialRequest, loadingInitial, onOpenModalInitial] = useModal<
    AffiliationRequest,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING]
  });

  const [, dispatchValidationRequest, loadingValidation, onOpenModalValidation] = useModal<
    AffiliationRequest,
    RequestPayload
  >({
    onOpen: handleOpen,
    resource,
    ...EMAIL_CONFIG[AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION]
  });

  useEffect(() => {
    localStorage.removeItem('planValidityDate');
  }, []);
  useEffect(() => {
    localStorage.removeItem('planValidityDate');
  }, []);

  return (
    <>
      <ResponsiveListWrapper onClick={handleRowClick}>
        <div className={styles.row}>
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.affiliations.fields.firstName')}
            valueClassName={styles.value}
            value={getCompleteName(record.user)}
            className={styles.columnLarge}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('resources.affiliations.fields.id')}
            valueClassName={styles.value}
            value={`${record.id}`}
            className={styles.columnShort}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            className={styles.columnLarge}
            labelClassName={styles.label}
            label={t('resources.affiliations.fields.type')}
            value={t(`resources.affiliations.fields.affiliationTypes.${record.affiliationType}`)}
          />
          <ViewInput
            labelClassName={styles.label}
            label={t('general.document')}
            valueClassName={styles.value}
            value={`${record.user.documentNumber}`}
            className={styles.columnShort}
          />
        </div>
        <div className={styles.row}>
          <ViewInput
            className={styles.columnLarge}
            labelClassName={styles.label}
            valueClassName={styles.value}
            label={t('resources.affiliations.fields.status')}
            content={<StatusField labelClassName={styles.maxContent} state={record.state} rounded={false} />}
          />
          <HistoricAffiliationButton
            className={`${styles.columnShort} ${styles.middle}`}
            stateDatetime={record.stateDatetime}
            id={record.id}
          />
        </div>
        <ViewInput
          className={styles.columnLarge}
          labelClassName={styles.label}
          valueClassName={styles.value}
          label="Proceso RPA"
          content={<StatusRPA {...record} />}
        />
        <div className={`${styles.row} ${styles.centered}`}>
          <ActionField
            affiliation={record}
            onSendInitialEmailAction={onOpenModalInitial}
            onResendUserValidationEmail={onOpenModalValidation}
            onCheckDetail={handleRowClick}
          />
        </div>
      </ResponsiveListWrapper>
      {affiliationState && (
        <CustomModal
          title={t('resources.affiliations.modals.title')}
          modalName={EMAIL_CONFIG[affiliationState].modalName}
          onConfirm={
            isUserValidationPending(affiliationState) ? dispatchValidationRequest : dispatchInitialRequest
          }
          loading={isUserValidationPending(affiliationState) ? loadingValidation : loadingInitial}
          onClose={handleClose}
          showCloseIcon={false}
        >
          <p>{t(getSendEmailTexts(affiliationState).modalMessage)}</p>
        </CustomModal>
      )}
    </>
  );
}

export default ResponsiveList;
