import React, { useState } from 'react';
import { Create } from 'react-admin';
import { useCommonStyles } from '~app/styles';
import WithProps from '~components/WithProps';
import DetailActionButtons from '~components/DetailActionButtons';
import { Promoter } from '~utils/types';

import DatosPagoForm from '../DatosPagoForm';

function DatosPagoWrapper(props: { permissions: Promoter }) {
  const commonStyles = useCommonStyles();
  const [showResults, setShowResults] = useState<boolean>(false);

  return (
    <Create
      {...props}
      title={<span />}
      classes={{
        card: commonStyles.cardDatosPago,
        main: commonStyles.main,
        root: commonStyles.rootDatosPago
      }}
    >
      <WithProps>
        {(createProps: object) => (
          <>
            <DetailActionButtons fromDatosPago setShowResults={setShowResults} showBack={showResults} />
            <DatosPagoForm {...createProps} props={{ ...props, showResults, setShowResults }} />
          </>
        )}
      </WithProps>
    </Create>
  );
}

export default DatosPagoWrapper;
