import moment from 'moment';
import { cuit as argCuit } from 'arg.js';
import {
  CUIT_PREFIXES,
  DISCOUNT_RATE,
  DOCUMENT_NUMBER_PROPS,
  PROMOTER_ID_LENGTH
} from '~constants/validations';
import { DOCUMENT_TYPES } from '~constants/affiliations';

const emailRegex = /^[A-Za-z0-9._-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)+$/;

const numericalFourDigitsRegex = /^[0-9]{4}$/;

const numericalTenDigitsRegex = /^[0-9]{10}$/;

const numericalDigitsRegex = /^([0-9]*)$/;

const numericalDigitsWithSpacesRegex = /^[0-9\s]*$/;

const phoneDigitsRegex = /^(?=.{10})\d{10,15}$/;

// const discountRateRegex = /^(?=.{0,2})([1-9]\d|\d)$/;
const discountRateRegex = /^(?:[0-9]|[1-2][0-9]|30)$/;

export const required = value => (value ? undefined : 'validation.required');

export const requiredField = value => (value ? undefined : 'Los datos ingresados son inválidos');

export const validateJSON = value => {
  try {
    JSON.parse(value);
    return undefined;
  } catch (e) {
    return 'Ingrese un JSON válido';
  }
};

// const cuitValidation = errorMessage => cuit => !argCuit.isValid(cuit.toString()) && errorMessage;
// const cuilValidation = errorMessage => cuil =>
//   DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIL].TYPES.every(type => !`${cuil}`.startsWith(type)) && errorMessage;

export const minLength = (length, errorMessage) => val =>
  val && `${val}`.length >= length ? undefined : errorMessage;

export const maxLength = (length, errorMessage) => val =>
  val && `${val}`.length <= length ? undefined : errorMessage;

export const maxLengthOptional = (length, errorMessage) => val => {
  if (val) {
    return `${val}`.length <= length ? undefined : errorMessage;
  }
  return undefined;
};
export const minLengthOptional = (length, errorMessage) => val => {
  if (val) {
    return `${val}`.length >= length ? undefined : errorMessage;
  }
  return undefined;
};
export const validacionCUIL = errorMessage => cuil => {
  if (DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIL].TYPES.every(type => !`${cuil}`.startsWith(type))) {
    return errorMessage;
  }
  return !argCuit.isValid(cuil.toString()) && errorMessage;
};
export const validacionCUIT = errorMessage => cuit => {
  const cuitStr = cuit.toString();
  const hasValidPrefix = CUIT_PREFIXES.some(prefix => cuitStr.startsWith(prefix));
  const isValidCuit = argCuit.isValid(cuitStr);

  if (!hasValidPrefix || !isValidCuit) {
    return errorMessage;
  }
  return undefined;
};

export const pattern = (patternVal, errorMessage) => val =>
  val && (patternVal.test(val) ? undefined : errorMessage);

export const email = pattern(emailRegex, 'validation.email');
export const numericalFourDigits = errorMessage => pattern(numericalFourDigitsRegex, errorMessage);

export const match = (oldValue, errorMessage) => val => (val === oldValue ? undefined : errorMessage);

export const numericalTenDigits = errorMessage => pattern(numericalTenDigitsRegex, errorMessage);

export const minMaxLength = (min = 1, max, errorMessage) => val =>
  minLength(min, errorMessage)(val) || maxLength(max, errorMessage)(val);

export const numericalDigits = pattern(numericalDigitsRegex, 'validation.numerical');

export const phoneDigits = pattern(phoneDigitsRegex, 'validation.phone');

export const discountDigits = t =>
  pattern(
    discountRateRegex,
    t('validation.discountRate', {
      min: DISCOUNT_RATE.MIN,
      max: DISCOUNT_RATE.MAX
    })
  );

export const exactLength = (translate, length, errorMessage) => value =>
  value && `${value}`.length !== length && (translate('validation.exactLength', { length }) || errorMessage);

export const numericalDigitsWithSpaces = pattern(
  numericalDigitsWithSpacesRegex,
  'validation.numericalWithSpaces'
);

export const getDocumentNumberValidation = ({ t, type }) => [
  type === DOCUMENT_TYPES.DNI &&
    numericalDigits(
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS[type].MIN,
        max: DOCUMENT_NUMBER_PROPS[type].MAX
      })
    ),
  (type === DOCUMENT_TYPES.PASSPORT || type === DOCUMENT_TYPES.DNI) &&
    minMaxLength(
      DOCUMENT_NUMBER_PROPS[type].MIN,
      DOCUMENT_NUMBER_PROPS[type].MAX,
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS[type].MIN,
        max: DOCUMENT_NUMBER_PROPS[type].MAX
      })
    ),
  type === DOCUMENT_TYPES.CUIL &&
    minLength(
      DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIL].MIN,
      t('validation.exactDigits', { length: DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.CUIL].MIN })
    ),
  type === DOCUMENT_TYPES.CUIL && validacionCUIL(t('validation.cuilNumber'))
];

export const promoterId = t =>
  minMaxLength(
    PROMOTER_ID_LENGTH.MIN,
    PROMOTER_ID_LENGTH.MAX,
    t('validation.documentNumber', {
      min: PROMOTER_ID_LENGTH.MIN,
      max: PROMOTER_ID_LENGTH.MAX
    })
  );

export const validateFutureDate = value =>
  value && moment(value).isSameOrAfter(new Date(), 'd') ? undefined : 'validation.futureDate';

const DAYS_AGO = 60;

export const validate60DaysBefore = value =>
  value && moment(value).isSameOrAfter(moment(new Date()).subtract(DAYS_AGO, 'days'))
    ? undefined
    : 'validation.futureDate60';
