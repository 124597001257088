import React, { useState } from 'react';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import CustomButton from '~components/CustomButton';
import { editApplication, updateStateApplication } from '~services/OsdeService';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import { useRefresh, useNotify } from 'react-admin';
import { useDispatch } from '~contexts/ModalContext';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { WARNING_TYPE } from '~config/providers/constants';
import Loading from '~components/Spinner/components/loading';

import { useStyles } from './styles';
// import FileList from './components/FileList';

interface Props {
  applicationId: number;
}

const FileUploadModal = ({ applicationId }: Props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const [fileTypes, setFileTypes] = useState<{
    listaDeArchivosAnexados: any;
    unidadDeFacturacion: string;
    observaciones: string;
  }>({
    listaDeArchivosAnexados: {
      altaDeEmpresa: false,
      cartaCambioDePrestador: false,
      cartaCompromisoSinOpcion: false,
      cartaCondicionIva: false,
      cartaDeDiferentesFechas: false,
      cartaImc: false,
      cartaDeJerarquizacion: false,
      cartaDeTraspaso: false,
      certificadoDiscapacidad: false,
      descuentoMedicos: false,
      formulario53: false,
      formulario59: false,
      plan510: false,
      respuestaAuditoria: false,
      senior: false,
      subeMasDeDosPlanes: false,
      subsidioFallecimiento: false,
      sumatoriaDeAportesPluriempleo: false
    },
    unidadDeFacturacion: '',
    observaciones: ''
  });

  const [errorUF, setErrorUF] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const styles = useStyles();

  // const handleCheckboxChange = (fileName: string, status: boolean) => {
  //   setFileTypes(prevState => ({
  //     ...prevState,
  //     listaDeArchivosAnexados: {
  //       ...prevState.listaDeArchivosAnexados,
  //       [fileName]: status
  //     }
  //   }));
  // };

  const handleUFChange = (e: any) => {
    const UF_MAX_LENGHT = 12;
    if (e.target.value.length === 0) {
      setErrorUF(false);
      return;
    }
    if (e.target.value && e.target.value.length === UF_MAX_LENGHT) {
      setFileTypes({
        ...fileTypes,
        unidadDeFacturacion: e.target.value
      });
      setErrorUF(false);
    } else {
      setErrorUF(true);
    }
  };

  const handleObservacionesChange = (e: any) => {
    setFileTypes({
      ...fileTypes,
      observaciones: e.target.value
    });
  };

  const handleClick = () => {
    if (!errorUF) {
      setLoading(true);
      let obs = null;
      let uf = null;
      const { unidadDeFacturacion, observaciones } = fileTypes;
      if (unidadDeFacturacion.length > 0) {
        uf = `E${unidadDeFacturacion}`;
      }
      if (unidadDeFacturacion.length > 0 && observaciones.length > 0) {
        obs = `${observaciones} E${unidadDeFacturacion}`;
      }
      if (unidadDeFacturacion.length === 0 && observaciones.length > 0) {
        obs = observaciones;
      }
      const data = { ...fileTypes, unidadDeFacturacion: uf, observaciones: obs };
      editApplication(applicationId, { infoAfiliacion: { ...data } })
        .then(() =>
          updateStateApplication(applicationId, AFFILIATION_REQUEST_STATUS.FINISHED)
            .then(response => {
              if (!response.ok) {
                throw new Error(response.data?.errors[0].message);
              }
              setLoading(false);
              refresh();
              dispatch?.(modalActions.closeModal(MODAL_NAMES.FILES));
              notify('Se ha dado de alta el proceso de afiliación correctamente');
            })
            .catch((error: Error) => {
              setLoading(false);
              dispatch?.(modalActions.closeModal(MODAL_NAMES.FILES));
              notify(error.message, WARNING_TYPE);
            })
        )
        .catch((error: Error) => {
          setLoading(false);
          notify(error.message, WARNING_TYPE);
        });
    }
  };

  return (
    <ModalInfoInnerContainer loading={false}>
      {/* Lista de archivos */}
      {/* <FileList attachedFiles={fileTypes.listaDeArchivosAnexados} onCheckFile={handleCheckboxChange} /> */}
      {/* Unidad de facturacion */}
      <label className={styles.label}>Unidad de facturaci&oacute;n</label>
      <input
        autoComplete="off"
        className={errorUF ? styles.inputError : styles.input}
        name="unidadDeFacturacion"
        onChange={handleUFChange}
        type="number"
      />
      {errorUF && <label className={styles.labelError}>El campo debe tener 12 n&uacute;meros</label>}
      {/* Observaciones */}
      <label className={styles.label}>Observaciones</label>
      <textarea
        className={styles.input}
        id="observaciones"
        name="observaciones"
        onChange={handleObservacionesChange}
        maxLength={256}
        cols={30}
        rows={10}
      />
      <div className={styles.wrapperButton}>
        <CustomButton
          disabled={loading}
          className={`${styles.button}`}
          onClick={handleClick}
          label={loading ? '' : 'Confirmar'}
        >
          {loading && <Loading />}
        </CustomButton>
      </div>
    </ModalInfoInnerContainer>
  );
};

export default FileUploadModal;
