import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router';
import { linkToRecord } from 'ra-core';
import ViewInput from '~components/ViewInput';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
// import { MassiveUploadRequest } from '~utils/types';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';

import StatusField from './StatusField';

function ResponsiveList({ record, basePath, id }: any) {
  const history = useHistory();
  const styles = useResponsiveListStyles();
  const t = useTranslate();

  const handleRowClick = useCallback(() => history.push(linkToRecord(basePath, id, 'show')), [
    basePath,
    history,
    id
  ]);

  return (
    <ResponsiveListWrapper onClick={handleRowClick}>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.fields.id')}
          valueClassName={styles.value}
          className={styles.columnShort}
          value={record.id}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.fields.promotor')}
          className={styles.columnShort}
          value={record.idPromotor}
          tooltip={`${record.promoterFirstName} ${record.promoterLastName}`}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.fields.name')}
          valueClassName={styles.value}
          className={styles.columnShort}
          value={record.nombreEmpresa}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          className={styles.columnLarge}
          labelClassName={styles.label}
          valueClassName={styles.value}
          label={t('resources.altasmasivas.fields.estado')}
          content={<StatusField labelClassName={styles.maxContent} estado={record.estado} rounded={false} />}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.fields.cantAltas')}
          className={styles.columnShort}
          value={record.cantAltas}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.fields.cantOk')}
          valueClassName={styles.value}
          className={styles.columnShort}
          value={record.cantProcesadosOk}
        />
        <ViewInput
          labelClassName={styles.label}
          label={t('resources.altasmasivas.fields.cantError')}
          className={styles.columnShort}
          value={record.cantProcesadosError}
        />
      </div>
    </ResponsiveListWrapper>
  );
}

export default ResponsiveList;
