import { makeStyles } from '@material-ui/core';

import { WHITE, DARK_GRAY, PRIMARY_BLUE, TRANSPARENCY } from '../../../../../constants/colors';

export const useStyles = makeStyles(theme => ({
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '80%',
      padding: 10
    }
  },
  modalContainerSmall: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  commonModal: {
    backgroundColor: WHITE,
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    minWidth: 750,
    maxWidth: 1200,
    padding: '30px 50px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '90%',
      padding: '30px 20px'
    }
  },
  modalSmall: {
    minWidth: 390,
    maxWidth: 390,
    padding: '24px 9px'
  },
  closeIconContainer: {
    position: 'absolute',
    right: 12,
    top: 18,
    padding: 0,
    '&:hover': {
      backgroundColor: TRANSPARENCY
    },
    [theme.breakpoints.down('xs')]: {
      top: 12
    }
  },
  closeIcon: {
    color: DARK_GRAY,
    height: 24,
    width: 24
  },
  cancelButton: {
    backgroundColor: `${WHITE}!important`,
    padding: '14px 20px',
    '&:hover': {
      backgroundColor: WHITE,
      color: `${PRIMARY_BLUE}!important`,
      opacity: '0.9',
      transform: 'scale(1.03, 1.03)'
    },
    '& span': {
      color: PRIMARY_BLUE,
      fontWeight: 600,
      padding: 0
    }
  },
  actionsContainer: {
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  commonTitle: {
    color: DARK_GRAY,
    textAlign: 'center'
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '21px',
    marginBottom: 25
  },
  tableContainer: {
    marginTop: 15
  },
  rowTableSmall: {
    backgroundColor: '#f3f3f3',
    marginBottom: 5
  },
  cellRadioSmall: {
    width: '15%',
    borderRight: '1px solid #bababa'
  },
  cell2Small: {
    width: '55%'
  },
  divTableSmall: {
    display: 'grid'
  },
  margin20: {
    marginBottom: 20
  },
  titleDivTableSmall: {
    marginBottom: 5
  },
  colorText: {
    color: PRIMARY_BLUE
  },
  textBold: {
    fontWeight: 'bold'
  },
  alignVertically: {
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    flexGrow: 1,
    marginTop: '35px'
  },
  containerButtons: {
    flexDirection: 'column-reverse'
  },
  colWidth8: {
    width: '8%'
  },
  colWidthMail: {
    width: '250px'
  }
}));
