import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_RED, PRIMARY_BLUE, GRAY } from '~constants/colors';
import { FULL_WIDTH } from '~constants/sizes';

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'grid',
    gridAutoFlow: 'row',
    position: 'relative',
    width: FULL_WIDTH,
    marginBottom: 20
  },
  error: {
    color: PRIMARY_RED,
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 20
  },
  input: {
    margin: 0,
    '& > .MuiFormLabel-root, & > .MuiFormHelperText-root': {
      display: 'none'
    },
    '& > .MuiInputBase-root': {
      marginTop: 0
    }
  },
  label: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 12
  },
  iconContainer: {
    color: GRAY,
    position: 'absolute',
    right: 8,
    top: 34,
    '&.active': {
      color: PRIMARY_BLUE
    }
  },
  icon: {
    height: 24,
    width: 24
  },
  inputRed: {
    '& div': {
      border: `solid 1px ${PRIMARY_RED}`
    }
  }
});
