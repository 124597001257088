/* eslint-disable max-lines */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  SimpleForm,
  FormDataConsumer,
  useMutation,
  SelectArrayInput,
  maxLength,
  minLength
} from 'react-admin';
import InputLabel from '~components/InputLabel';
import { SolicitudBinariaRequest, SolicitudBinariaReqForm, Promoter, ErrorResponse } from '~utils/types';
import { getIsSuperAdmin } from '~utils/general';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import CustomModal from '~components/CustomModal';
import FeedbackModalContent from '~components/FeedbackModalContent';
import { useFormStyles, useCommonStyles } from '~app/styles';
import { required, numericalDigits, email, match } from '~utils/inputValidations';
import Toolbar from '~components/Toolbar';
import InputSelect from '~components/InputSelect';
import { getObjectValueFromString } from '~utils/object';
import { useHistory } from 'react-router';
import { useDispatch } from '~contexts/ModalContext';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import { DOCUMENT_NUMBER_PROPS } from '~constants/validations';
import { DOCUMENT_TYPES } from '~constants/affiliations';
import { getApi as api } from '~config/api';

import {
  productNameDescription,
  productTypeDescription,
  formNameDescription,
  linkFueAbierto
} from '../helperFunctions';

interface Props {
  record?: SolicitudBinariaRequest;
  title?: string;
  esEdicion?: boolean;
  redirect?: string;
  toolbar?: ReactNode;
  resource?: string;
  onSetEditing?: () => void;
  enEdicion?: boolean;
  permissions: Promoter;
}

interface FormDataProps {
  formData: SolicitudBinariaReqForm;
}

export default function SolicitudBinariaForm(props: Props) {
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuperAdmin = getIsSuperAdmin(props.permissions?.role);
  const [productos, setProductos] = useState([]);
  const [tipoProductos, setTipoProductos] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [mutate, { data, error, loading }] = useMutation();

  const { esEdicion, onSetEditing, enEdicion, ...rest } = props;

  useEffect(() => {
    if (data || error) {
      dispatch?.(modalActions.openModal(MODAL_NAMES.BINARIA_DIALOG));
    }
  }, [data, dispatch, error]);

  useEffect(() => {
    if (!esEdicion) {
      const fetchProductos = async () => {
        const result = await api()
          .get<any, ErrorResponse>('binaria/listas', {
            codigoLista: 30
          })
          .then((response: any) => response.data);
        setProductos(result.map((producto: any) => ({ id: producto.codigo, name: producto.valor })));
      };
      fetchProductos();
    }
  }, [esEdicion]);

  useEffect(() => {
    if (!esEdicion) {
      const fetchTipoProductos = async () => {
        const result = await api()
          .get<any, ErrorResponse>('binaria/listas', {
            codigoLista: 31
          })
          .then((response: any) => response.data);
        setTipoProductos(
          result?.map((tipoProducto: any) => ({ id: tipoProducto.codigo, name: tipoProducto.valor }))
        );
      };
      fetchTipoProductos();
    }
  }, [esEdicion]);

  useEffect(() => {
    if (!esEdicion) {
      const fetchForms = async () => {
        const result = await api()
          .get<any, ErrorResponse>('binaria/listas', {
            codigoLista: 32
          })
          .then((response: any) => response.data);
        setFormularios(result?.map((forms: any) => ({ id: parseInt(forms.codigo), name: forms.valor })));
      };
      fetchForms();
    }
  }, [esEdicion]);
  const createSolicitud = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: 'CREAR_SOLICITUD_BINARIA',
        resource: props.resource,
        payload: values
      }),
    [mutate, props.resource]
  );
  const editarSolicitud = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: 'EDITAR_SOLICITUD_BINARIA',
        resource: props.resource,
        payload: { ...values, isSuperAdmin }
      }),
    [props.resource, mutate, isSuperAdmin]
  );
  const handleExit = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleSave = useCallback(
    (values: FormDataProps) => {
      if (esEdicion) {
        return editarSolicitud(values);
      }
      return createSolicitud(values);
    },
    [createSolicitud, editarSolicitud, esEdicion]
  );

  return (
    <>
      <SimpleForm
        {...rest}
        className={styles.form}
        save={handleSave}
        saving={loading}
        toolbar={enEdicion || !esEdicion ? <Toolbar {...props} /> : null}
        validateOnBlur
      >
        {esEdicion && <InputLabel label="ID" className={commonStyles.row} source="id" isEdit={esEdicion} />}
        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const estado = getObjectValueFromString('state', formData);
            let esEditable = false;
            if (linkFueAbierto(estado)) {
              esEditable = true;
            }
            return (
              <InputLabel
                {...formDataProps}
                label="Nombre"
                className={commonStyles.row}
                source="firstname"
                validate={required}
                isEdit={esEdicion}
                onSetEditing={onSetEditing}
                editEnabled={esEditable}
              />
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const estado = getObjectValueFromString('state', formData);
            let esEditable = false;
            if (linkFueAbierto(estado)) {
              esEditable = true;
            }
            return (
              <InputLabel
                {...formDataProps}
                label="Apellido"
                className={commonStyles.row}
                source="lastname"
                validate={required}
                isEdit={esEdicion}
                onSetEditing={onSetEditing}
                editEnabled={esEditable}
              />
            );
          }}
        </FormDataConsumer>
        <InputLabel
          label="Numero de Documento"
          className={commonStyles.row}
          source="dni"
          validate={[
            required,
            numericalDigits,
            minLength(DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.DNI].MIN),
            maxLength(DOCUMENT_NUMBER_PROPS[DOCUMENT_TYPES.DNI].MAX)
          ]}
          variant="standard"
          {...{ steps: 1, pattern: '[0-9]*' }}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
        />
        <InputLabel
          label="Email"
          className={commonStyles.row}
          type="email"
          source="mail"
          validate={[required, email]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled
        />
        {!esEdicion && (
          <FormDataConsumer>
            {({ formData, ...formDataProps }: FormDataProps) => {
              const emailData = getObjectValueFromString('mail', formData);
              return (
                <InputLabel
                  {...formDataProps}
                  label="Confirmación de correo"
                  className={commonStyles.row}
                  type="email"
                  source="mailConfirmation"
                  validate={[required, match(emailData, 'El correo de confirmación no coincide')]}
                  isEdit={esEdicion}
                  onSetEditing={onSetEditing}
                />
              );
            }}
          </FormDataConsumer>
        )}
        <InputLabel
          label="Número de Teléfono"
          className={commonStyles.row}
          source="phoneNumber"
          validate={numericalDigits}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled
        />
        <InputSelect
          className={commonStyles.row}
          source="productId"
          choices={productos}
          label="Producto"
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          specialFormatter={productNameDescription}
        />
        <InputSelect
          className={commonStyles.row}
          source="typeProductId"
          choices={tipoProductos}
          label="Tipo de Producto"
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          specialFormatter={productTypeDescription}
        />
        {!esEdicion && (
          <SelectArrayInput
            className={commonStyles.row}
            source="forms"
            choices={formularios}
            label="Tipo de Formulario"
            specialFormatter={formNameDescription}
          />
        )}
        <InputLabel
          label="Número de Cotización"
          className={commonStyles.row}
          source="cotizacionId"
          type="number"
          validate={[required, numericalDigits]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
        />
        <InputLabel
          label="Promotor"
          className={commonStyles.row}
          source="promoterId"
          type="number"
          validate={[required, numericalDigits]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          defaultValue={props?.permissions?.id}
        />
      </SimpleForm>
      {(data || error) && (
        <CustomModal modalName={MODAL_NAMES.BINARIA_DIALOG} {...(data ? { onClose: handleExit } : {})}>
          <FeedbackModalContent
            error={error}
            successMessage={
              esEdicion
                ? 'Se editó con éxito los datos de la afiliación.'
                : 'Se creó con éxito la nueva solicitud de afiliación.'
            }
          />
        </CustomModal>
      )}
    </>
  );
}
