import {
  convertCodDescrCollection,
  DOCUMENT_TYPES,
  PLAN_TYPES,
  SOLICITUDES_REQUEST_STATUS,
  DISCOUNT_TYPES
} from '~constants/solicitudesF2';

const solicitudesF2Spanish = {
  name: 'Solicitudes Empresas',
  history: 'Historial',
  listName: 'Listado de solicitudes de Empresas',
  detailTitle: 'Detalle de solicitud',
  initialEmail: {
    send: {
      complete: 'Enviar e-mail de inicio',
      short: 'Enviar',
      title: 'Click para enviar e-mail de inicio'
    },
    resend: {
      complete: 'Reenviar e-mail de inicio',
      short: 'Reenviar',
      title: 'Click para reenviar e-mail de inicio'
    }
  },
  resendUserValidationEmail: {
    complete: 'Reenviar e-mail de validación de datos',
    short: 'Reenviar',
    title: 'Click para reenviar e-mail de validación de datos'
  },
  validateAffiliation: 'Evaluar',
  validateAffiliationTitle: 'Click para ir al detalle y evaluar la solicitud de afiliación',
  createTitle: 'Nueva solicitud',
  modals: {
    title: '¿Estás seguro?',
    initialEmail: {
      send: 'Estás por enviar el e-mail para iniciar el proceso de afiliación.',
      resend: 'Estás por reenviar el e-mail para iniciar el proceso de afiliación.',
      success: 'Se ha enviado el e-mail correctamente'
    },
    userValidationEmail: {
      resend: 'Estás por reenviar el e-mail para que el afiliado valide sus datos.',
      success: 'Se ha enviado el e-mail correctamente'
    },
    form: {
      createSuccess: 'Se creó con éxito la nueva solicitud {applicationId} de afiliación de empresas.',
      editSuccess: 'Se editó con éxito los datos de la afiliación.'
    },
    titleHistorial: 'Histórico de pasos',
    titlePotentialRisk: 'Potencial de riesgo',
    modify: 'Modificar'
  },
  fields: {
    action: 'Acción',
    applicationId: 'ID',
    companyName: 'Razón social',
    companyNameList: 'Empresa/Razón social',
    cuit: 'CUIT',
    firstName: 'Nombre del responsable empresa',
    lastName: 'Apellido  del responsable empresa',
    documentType: 'Tipo de documento',
    documentNumber: 'Número de documento',
    email: 'E-mail del responsable empresa',
    emailConfirmation: 'Confirmación del e-mail',
    planType: 'Tipo de plan',
    applyDiscountAllPlans: 'Elija las opciones de descuentos',
    discountRate: 'Descuento',
    discountRate8260: 'Descuento 8-260',
    discountRate8360: 'Descuento 8-360',
    discountRate8430: 'Descuento 8-430',
    phoneNumber: 'Teléfono del responsable empresa',
    promoterId: 'ID promotor',

    planValidityDate: 'Fecha de gestión',

    status: 'Estado',

    statusTypes: convertCodDescrCollection(SOLICITUDES_REQUEST_STATUS, cd => ({ [cd.COD]: cd.DESCRIPCION })),
    discountsTypes: convertCodDescrCollection(DISCOUNT_TYPES, cd => ({ [cd.COD]: cd.DESCRIPCION })),
    documentTypes: convertCodDescrCollection(DOCUMENT_TYPES, cd => ({ [cd.COD]: cd.DESCRIPCION })),
    planTypes: convertCodDescrCollection(PLAN_TYPES, cd => ({ [cd.COD]: cd.DESCRIPCION }))
  },
  documents: {
    f2: { title: 'Formulario F2' },
    attachments: { title: 'Documentación' },
    termsAndConditions: { title: 'Términos y condiciones' },
    formData: { title: 'FormData' }
  },
  evaluate: {
    [SOLICITUDES_REQUEST_STATUS.FINISHED.COD]: 'Dar alta proceso',
    [SOLICITUDES_REQUEST_STATUS.REVISION.COD]: 'Llevar a revisión',
    [SOLICITUDES_REQUEST_STATUS.CANCELED.COD]: 'Cancelar solicitud',
    modal: {
      [SOLICITUDES_REQUEST_STATUS.FINISHED.COD]: {
        message: 'Estás por dar de alta el proceso de afiliación.',
        success: 'Se ha dado de alta el proceso de afiliación correctamente'
      },
      [SOLICITUDES_REQUEST_STATUS.REVISION.COD]: {
        message: 'Estás por llevar a revisión el proceso de afiliación.',
        success: 'Se ha llevado a revisión el proceso de afiliación correctamente'
      },
      [SOLICITUDES_REQUEST_STATUS.CANCELED.COD]: {
        message: 'Estás por llevar a cancelado el proceso de afiliación.',
        success: 'Se ha llevado a cancelado el proceso de afiliación correctamente'
      }
    }
  },
  tooltip: 'Modificado el '
};

export default solicitudesF2Spanish;
