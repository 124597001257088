/* eslint-disable no-magic-numbers */
export const DATOS_PAGO_FORM = {
  idPromotor: 'idPromotor',
  formulario: 'formulario',
  idSolicitud: 'idSolicitud',
  tipoDocumento: 'tipoDocumento',
  nroDocumentoTitular: 'nroDocumentoTitular'
};

export const IDEM = 'Idem';
export const NOT_MODIFY = 'No modificar';

export const DOCUMENT_TYPES = {
  DNI: { id: 'DNI', name: 'DNI' },
  Passport: { id: 'Pasaporte', name: 'Pasaporte' },
  CUIL: { id: 'CUIL', name: 'CUIL' },
  CUIT: { id: 'CUIT', name: 'CUIT' }
};

export const FORMS_DOCUMENT_TYPES: { [key: string]: { id: string; name: string }[] } = {
  OSDE: [DOCUMENT_TYPES.DNI, DOCUMENT_TYPES.Passport, DOCUMENT_TYPES.CUIL],
  F2: [DOCUMENT_TYPES.CUIT]
};

const CUIL_PREFIXES = [20, 23, 24, 25, 26, 27];
const CUIT_PREFIXES = [20, 23, 24, 25, 26, 27, 30, 33, 34];

export const DOCUMENT_NUMBER_PROPS = {
  [DOCUMENT_TYPES.DNI.id]: {
    MIN: 7,
    MAX: 8
  },
  [DOCUMENT_TYPES.CUIL.id]: {
    MIN: 11,
    PREFIXES: CUIL_PREFIXES
  },
  [DOCUMENT_TYPES.Passport.id]: {
    MIN: 7,
    MAX: 14
  },
  [DOCUMENT_TYPES.CUIT.id]: {
    MIN: 11,
    PREFIXES: CUIT_PREFIXES
  }
};
