import React, { ReactElement, FormEvent, useCallback } from 'react';
import cn from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import { Table } from '~utils/types';

import { useStyles, useRowTableRowStyles, useCheckboxStyles } from './styles';

interface Props extends Table {
  record?: object;
  resource?: string;
  basePath?: string;
  selected?: boolean;
  onToggleItem?: Function;
  id?: number;
  children?: ReactElement[] | ReactElement;
}

function DatagridRow({
  record,
  resource,
  id,
  onToggleItem,
  selected,
  basePath,
  selectable,
  showDetail,
  children
}: Props) {
  const history = useHistory();
  const styles = useStyles();
  const cellStyles = useRowTableRowStyles();
  const checkboxStyles = useCheckboxStyles();

  const handleSelectItem = useCallback(
    (event: FormEvent<EventTarget>) => {
      if (onToggleItem) {
        event.stopPropagation();
        onToggleItem(id);
      }
    },
    [id, onToggleItem]
  );

  const handleRowClick = useCallback(() => history.push(linkToRecord(basePath, id, 'show')), [
    basePath,
    history,
    id
  ]);
  return (
    <TableRow key={id} hover {...(showDetail && { onClick: handleRowClick, classes: { root: styles.root } })}>
      {selectable && (
        <TableCell className={styles.rowCell}>
          <Checkbox classes={checkboxStyles} checked={selected} onClick={handleSelectItem} color="primary" />
        </TableCell>
      )}
      {children &&
        React.Children.map(children, (field: React.ReactElement<any>) => (
          <TableCell className={styles.rowCell} key={`${id}-${field.props.source}`}>
            {React.cloneElement(field, {
              record,
              basePath,
              resource,
              className: cn(cellStyles.rowField, { [cellStyles.selected]: selected })
            })}
          </TableCell>
        ))}
    </TableRow>
  );
}

export default DatagridRow;
