import { useState, useMemo } from 'react';
import { useMutation, useRefresh, useNotify } from 'react-admin';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { WARNING_TYPE } from '~config/providers/constants';
import { useDispatch, useSelector } from '~contexts/ModalContext';

interface Props<T, K> {
  resource: string;
  requestType: string;
  requestPayload: (item?: T) => K;
  modalName: string;
  onOpen?: (item?: T) => void;
  successMessageNotify?: string;
}

function useModal<T, K>({
  resource,
  requestType,
  requestPayload,
  modalName,
  onOpen,
  successMessageNotify
}: Props<T, K>) {
  const [itemOpen, setItem] = useState<T>();
  const dispatch = useDispatch();

  const data = useSelector(state => state.data);

  const refresh = useRefresh();
  const notify = useNotify();
  const onOpenModal = (modalData: T) => {
    onOpen?.(modalData);
    setItem(modalData);
    dispatch(modalActions.openModal(modalName));
  };

  const item = useMemo(() => itemOpen || data, [data, itemOpen]);

  const [dispatchRequest, { loading }] = useMutation(
    {
      type: requestType,
      resource,
      payload: requestPayload(item)
    },
    {
      onSuccess: () => {
        refresh();
        dispatch?.(modalActions.closeModal(modalName));
        if (successMessageNotify) {
          notify(successMessageNotify);
        }
      },
      onFailure: (error: Error) => notify(error.message, WARNING_TYPE)
    }
  );

  return [item, dispatchRequest, loading, onOpenModal];
}

export default useModal;
