export const ALTAS_MASIVAS_FIELDS = {
  id: 'id',
  nombre: 'nombreEmpresa',
  idPromotor: 'idPromotor',
  fecha: 'fechaAlta',
  altas: 'cantAltas',
  procesadosOk: 'cantProcesadosOk',
  procesadosError: 'cantProcesadosError',
  estado: 'estado',
  promoterFirstName: 'promoterFirstName',
  promoterLastName: 'promoterLastName'
};

export const ALTAS_LIST_FILTER = {
  id: ALTAS_MASIVAS_FIELDS.id,
  promoterId: ALTAS_MASIVAS_FIELDS.idPromotor,
  nombreEmpresa: ALTAS_MASIVAS_FIELDS.nombre
};

export const ALTAS_DETALLE_FIELDS = {
  id: 'id',
  nombre: 'nombre',
  apellido: 'apellido',
  emailAltas: 'altasEmail',
  tipoSocio: 'prospectTipo',
  tipoFormulario: 'prospectTipoFormulario',
  email: 'idAltasEmail',
  estado: 'estadoProceso',
  idSolicitud: 'idSolcitudCreada'
};

export const ALTAS_MASIVAS_FORM = {
  idPromotor: 'idPromotorAlta',
  nombre: ALTAS_MASIVAS_FIELDS.nombre,
  datos: 'datos'
};

export const ALTA_MASIVA_STATUS = {
  NO_PROCESADO: 'NO_PROCESADO',
  EN_PROCESO: 'EN_PROCESO',
  PROCESADO: 'PROCESADO',
  CARGANDO: 'CARGANDO'
};

export const ALTA_MASIVA_DETALLE_STATUS = {
  OK: 'OK',
  ERROR: 'ERROR',
  SIN_PROCESAR: 'SIN_PROCESAR'
};
