import React from 'react';
import { useTranslate, TextInput, SelectInput } from 'react-admin';
import CustomFilter from '~components/CustomFilter';
import { SOLICITUDES_ON_REQUEST_STATUS } from '~constants/solicitudesON';
import { SOLICITUD_ON_FILTER } from '~screens/SolicitudesON/constants';

export default function SolicitudONFilter(props: object) {
  const t = useTranslate();

  const solicitudesStatusTypes = Object.values(SOLICITUDES_ON_REQUEST_STATUS).map(status => ({
    id: status,
    name: t(`resources.on.fields.statusTypes.${status}`)
  }));
  return (
    <CustomFilter {...props}>
      <TextInput label="Solicitud" source={SOLICITUD_ON_FILTER.id} />
      <TextInput label="Promotor" source={SOLICITUD_ON_FILTER.promoterId} />
      <TextInput label="Nombre" source={SOLICITUD_ON_FILTER.firstName} />
      <TextInput label="Apellido" source={SOLICITUD_ON_FILTER.lastName} />
      <TextInput label="Documento" source={SOLICITUD_ON_FILTER.documentNumber} />
      <TextInput label="Cuit Empresa" source={SOLICITUD_ON_FILTER.cuitEmpresa} />
      <SelectInput label="Estado" source="state" choices={solicitudesStatusTypes} />
    </CustomFilter>
  );
}
