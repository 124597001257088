export const MODAL_NAMES = {
  DIALOG: 'dialog',
  INITIAL_EMAIL: 'InitialEmail',
  USER_VALIDATION: 'UserValidation',
  EVALUATE: 'evaluate',
  HISTORIC_STEPS: 'historicSteps',
  POTENTIAL_RISK: 'potentialRisk',
  TOGGLE_PROMOTER_STATUS: 'togglePromoterStatus',
  BINARIA_DIALOG: 'binariaDialog',
  SAP: 'sap',
  VB: 'vb',
  FILES: 'files',
  FILES_PROMOTER: 'files_promoter',
  FILES_ZCAR: 'files_zcar',
  QUESTIONS: 'questions'
};
