import React, { useState } from 'react';
import { SimpleForm } from 'react-admin';
import InputLabel from '~components/InputLabel';
import { useFormStyles, useCommonStyles } from '~app/styles';
import { requiredField as requiredValidator } from '~utils/inputValidations';
import Toolbar from '~components/Toolbar';
import { cancelApplication } from '~services/OsdeService';
import { useNotify } from 'ra-core';

import { CANCELACIONES_FORM } from '../../constants';

const STATUS_OK = 200;

function CancelacionesForm({ props }: any) {
  const { ...rest } = props;
  const idPromoter = props.permissions.id;
  const notify = useNotify();
  const styles = useFormStyles();
  const commonStyles = useCommonStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const saving = ({ idSolicitud }: { idSolicitud: number }) => {
    setError('');
    setLoading(true);
    cancelApplication(idSolicitud, idPromoter)
      .then((response: any) => {
        if (response.status === STATUS_OK) {
          notify('Solicitud cancelada correctamente');
        } else if (response.data && response.data.errors) {
          setError(response.data.errors[0].message || 'Ocurrio un error');
        } else {
          setError('Ocurrio un error');
        }
        setLoading(false);
      })
      .catch(() => {
        setError('Ocurrio un error');
        setLoading(false);
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ASCII_DIGIT_ZERO = 48;
    const ASCII_DIGIT_NINE = 57;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < ASCII_DIGIT_ZERO || charCode > ASCII_DIGIT_NINE) {
      event.preventDefault();
    }
  };
  return (
    <div className={commonStyles.flex}>
      <div>
        <h1 className={commonStyles.titleDatosPago}>Cancelaciones</h1>
        <SimpleForm
          {...rest}
          className={styles.form}
          save={saving}
          saving={loading}
          toolbar={<Toolbar label="Cancelar solicitud" {...props} />}
          validateOnBlur
        >
          <InputLabel
            label="ID Solicitud"
            className={commonStyles.row}
            source={CANCELACIONES_FORM.idSolicitud}
            validate={[requiredValidator]}
            editEnabled
            type="text"
            onKeyPress={handleKeyPress}
          />
          <span className={commonStyles.errorMessage}>{error}</span>
        </SimpleForm>
      </div>
    </div>
  );
}

export default CancelacionesForm;
