import { makeStyles } from '@material-ui/core/styles';
import {
  GRAY,
  PRIMARY_RED,
  PRIMARY_RED_OPACITY,
  WHITE,
  PRIMARY_BLUE,
  PRIMARY_BLUE_OPACITY
} from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  buttonDownloadZip: {
    width: 'max-content',
    backgroundColor: PRIMARY_BLUE,
    color: WHITE,
    borderRadius: '4px',
    padding: '20px',
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_BLUE_OPACITY,
      color: WHITE
    }
  },
  modalContent: {
    color: GRAY,
    fontSize: 14,
    lineHeight: '16px',
    marginBottom: '18px',
    textAlign: 'center'
  },
  button: {
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  buttonCanceled: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: PRIMARY_RED,
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: WHITE
      }
    },
    '& span': {
      color: WHITE
    }
  },
  sendEmailButton: {
    display: 'flex',
    '& span': {
      fontSize: 16
    }
  },
  spacing: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px'
    }
  }
}));
