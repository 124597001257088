import React from 'react';
import { Create } from 'react-admin';
import { useCommonStyles } from '~app/styles';
import WithProps from '~components/WithProps';
import DetailActionButtons from '~components/DetailActionButtons';
import { Promoter } from '~utils/types';

import AltaMasivaCreate from '../componets/AltaMasivaCreate';

function MassiveUploadRequest(props: { permissions: Promoter }) {
  const commonStyles = useCommonStyles();
  return (
    <Create
      {...props}
      title={<span />}
      classes={{ card: commonStyles.card, main: commonStyles.main, root: commonStyles.root }}
    >
      <WithProps>
        {(createProps: object) => (
          <>
            <DetailActionButtons />
            <AltaMasivaCreate {...createProps} props={props} />
          </>
        )}
      </WithProps>
    </Create>
  );
}

export default MassiveUploadRequest;
