import React, { cloneElement, ReactElement } from 'react';
import { TopToolbar, CreateButton, ExportButton, sanitizeListRestProps, Button } from 'react-admin';
import { Add, GetApp as Download } from '@material-ui/icons';
import { CUSTOM_ICON_STYLES } from '~app/styles';
import { Tooltip } from '@material-ui/core';
import { affiliationScreen } from '~utils/general';
import { ReactComponent as Excel } from '~assets/excel.svg';
import GroupIcon from '@material-ui/icons/Group';
import AltaMasivaCreate from '~screens/AltasMasivas/componets/AltaMasivaCreate';

import MasiveUploadPopUp from '../BulkUploadPopUp/index';
import './ListActions.scss';

interface Props {
  currentSort?: object;
  className?: string;
  resource?: string;
  filters?: ReactElement<{
    resource: string | undefined;
    showFilter: any;
    displayedFilters: any;
    filterValues: any;
    context: string;
  }>;
  displayedFilters?: object;
  showExport?: boolean;
  exporter?: object | Function;
  filterValues?: object;
  permanentFilter?: object;
  hasCreate?: boolean;
  basePath?: string;
  showFilter?: Function;
  maxResults?: number;
  total?: number;
  permissions?: object;
  props?: any;
}

function ListActions({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  showExport,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  showFilter,
  maxResults,
  total,
  props,
  ...rest
}: Props) {
  const [click, setClick] = React.useState<boolean>(false);
  const [showClose, setShowClose] = React.useState<boolean>(true);
  // const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isAltaMasivas = window.config.REACT_APP_SHOW_ALTAS_MASIVAS;

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    setClick(true);
  };

  const handleClose = (state: boolean) => {
    setClick(state);
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button'
        })}
      {affiliationScreen(basePath, isAltaMasivas) && (
        <>
          <Tooltip title="Carga Masiva">
            <div>
              <CreateButton
                onClick={(e: MouseEvent) => {
                  handleClick(e);
                }}
                icon={<GroupIcon style={CUSTOM_ICON_STYLES} />}
                label=""
              />
            </div>
          </Tooltip>
          <Tooltip title="Descargar Excel">
            <div>
              <Button>
                <a href="/modeloAltasMasivas.xls" target="_self" download>
                  <Excel style={CUSTOM_ICON_STYLES} className="excel-icon" />
                </a>
              </Button>
            </div>
          </Tooltip>
        </>
      )}
      {hasCreate && basePath !== '/altasmasivas' && (
        <Tooltip title="Crear">
          <div>
            <CreateButton
              icon={<Add style={CUSTOM_ICON_STYLES} />}
              label=""
              basePath={basePath}
              data-cy="createOsdeApplication"
            />
          </div>
        </Tooltip>
      )}
      {showExport && (
        <Tooltip title="Descargar">
          <div>
            <ExportButton
              disabled={!total}
              icon={<Download style={CUSTOM_ICON_STYLES} />}
              label=""
              resource={resource}
              sort={currentSort}
              filter={{ ...filterValues, ...permanentFilter }}
              exporter={exporter}
              maxResults={maxResults}
            />
          </div>
        </Tooltip>
      )}
      <MasiveUploadPopUp touched={click} callback={handleClose} showCloseIcon={showClose}>
        <AltaMasivaCreate props={props} callback={handleClose} setLoading={setShowClose} />
      </MasiveUploadPopUp>
    </TopToolbar>
  );
}

export default ListActions;
