/* eslint-disable quote-props */
export const ROLE_TYPES = {
  'Usuario promotor': 'regular',
  Adminstrador: 'admin',
  Analista: 'analista',
  SuperAdmin: 'superadmin',
  Asesor: 'asesor'
};

export const PERMISO_OSDE_CHOICES = {
  SI: 'true',
  NO: 'false'
};

export const PERMISO_BINARIA_CHOICES = {
  SI: 'true',
  NO: 'false'
};
