export const camelToSnake = (value: string) => value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const trimTextFilter = (filter: { [key: string]: string }) =>
  Object.keys(filter).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: filter[curr].trim()
    }),
    {}
  );
