import React, { useEffect } from 'react';
import { useInput, useTranslate } from 'ra-core';
import { useCommonStyles } from '~app/styles';
import InputLabel from '~components/InputLabel';
import { DOCUMENT_TYPES } from '~constants/solicitudesF2';
import { INPUT_NUMBER_PROPS } from '~constants/validations';
import { SOLICITUDES_F2_REQUESTS_FIELDS } from '~screens/SolicitudesF2/constants';
import { required } from '~utils/inputValidations';
import { getDocumentNumberValidationF2 } from '~utils/inputValidationsF2';
import { SolicitudF2RequestForm } from '~utils/types';

interface FormDataProps {
  formData: SolicitudF2RequestForm;
  esEdicion?: boolean;
  onSetEditing?: any;
  extraFieldsEdit: any;
}

const DocumentType = ({
  formData,
  esEdicion,
  onSetEditing,
  extraFieldsEdit,
  ...formDataProps
}: FormDataProps) => {
  const t = useTranslate();
  const regexAlpha = /[^a-zA-Z0-9]+/g;
  const regexNumero = /[^0-9]+/g;
  const type = formData.attorney.documentType;
  const isPassport = type === DOCUMENT_TYPES.PASSPORT.COD;
  const commonStyles = useCommonStyles();
  const fieldTypeDoc = useInput({ source: SOLICITUDES_F2_REQUESTS_FIELDS.documentType });

  useEffect(() => {
    const inputTypeDoc = document.getElementsByName('attorney.documentNumber')[0];
    if (inputTypeDoc) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      document.getElementsByName('attorney.documentNumber')[0].value = ' ';
      formData.attorney.documentNumber = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldTypeDoc.input.value]);

  if (!isPassport) {
    return (
      <InputLabel
        {...formDataProps}
        className={commonStyles.row}
        source={SOLICITUDES_F2_REQUESTS_FIELDS.documentNumber}
        label={t('resources.solicitudF2.fields.documentNumber')}
        validate={[required, ...(getDocumentNumberValidationF2({ t, type }) as any[])]}
        {...(!isPassport && { ...INPUT_NUMBER_PROPS, type: 'number' })}
        variant="standard"
        isEdit={esEdicion}
        parse={(value: string) => value.replace(regexNumero, '')}
        onSetEditing={onSetEditing}
        type="text"
        // editEnabled={extraFieldsEdit}
      />
    );
  }
  return (
    <InputLabel
      {...formDataProps}
      className={commonStyles.row}
      source={SOLICITUDES_F2_REQUESTS_FIELDS.documentNumber}
      label={t('resources.affiliations.fields.documentNumber')}
      validate={[required, ...(getDocumentNumberValidationF2({ t, type }) as any[])]}
      variant="standard"
      isEdit={esEdicion}
      onSetEditing={onSetEditing}
      parse={(value: string) => value.replace(regexAlpha, '')}
      type="text"
      // editEnabled
    />
  );
};

export default DocumentType;
