/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';
import axios from 'axios';
import { AuthProvider } from 'react-oidc-context';
import '~config';
import { oidcConfig } from '~config/oauth';

import App from './app';

import './scss/application.scss';

const container: any = document.getElementById('root');
const root = createRoot(container);

function onSigninCallback() {
  // window.location.href = "/";
  window.history.replaceState({}, document.title, window.location.pathname);
}

const render = () => {
  root.render(
    <AuthProvider {...oidcConfig()} onSigninCallback={onSigninCallback}>
      <AppContainer>
        <App />
      </AppContainer>
    </AuthProvider>
  );
};

(async () => {
  await axios(`${process.env.PUBLIC_URL}/config.json`).then(res => {
    window.config = res.data;
    render();
  });
})();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./app', () => {
    render();
  });
}
