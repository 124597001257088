import { useState, useCallback } from 'react';

export const useEditInput = (onClick?: () => void): [boolean, () => void] => {
  const [enableEdit, setEnableEdit] = useState(false);

  const handleClick = useCallback(() => {
    setEnableEdit(!enableEdit);
    if (onClick) {
      onClick();
    }
  }, [enableEdit, onClick]);

  return [enableEdit, handleClick];
};
