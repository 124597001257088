import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import CustomButton from '~components/CustomButton';

import { useStyles } from './styles';

interface Props {
  enableButton: boolean;
  handleSubmit?: () => void;
  invalid?: boolean;
  label?: string;
  saving?: boolean;
  loading?: boolean;
  producto?: string;
  invalidPlanValidityDate?: any;
}

function Toolbar({
  enableButton,
  handleSubmit,
  invalid,
  saving,
  loading,
  label,
  producto,
  invalidPlanValidityDate
}: Props) {
  const onSubmit = useCallback(() => {
    if (handleSubmit) {
      handleSubmit();
    }
  }, [handleSubmit]);
  const styles = useStyles();
  const t = useTranslate();
  return (
    <div className={styles.container}>
      {enableButton &&
        (producto && producto === 'OSDE' ? (
          <CustomButton
            disabled={invalid || saving || loading}
            label={label || t('ra.action.save')}
            onClick={onSubmit}
            producto={producto}
            invalidPlanValidityDate={invalidPlanValidityDate}
          />
        ) : (
          <CustomButton
            disabled={invalid || saving || loading || invalidPlanValidityDate}
            label={label || t('ra.action.save')}
            onClick={onSubmit}
          />
        ))}
    </div>
  );
}

Toolbar.defaultProps = {
  enableButton: true,
  loading: false
};

export default Toolbar;
