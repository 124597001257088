import React from 'react';
// import cn from 'classnames';

import { useStyles } from './styles';

const MessageError = () => {
  const styles = useStyles();

  return (
    <div>
      <p className={styles.colorText}>
        Hemos encontrado el socio en la base de datos pero faltan datos para asociarlo al proceso de alta de
        beneficiario. Por favor continua en modo manual.
      </p>
    </div>
  );
};

export default MessageError;
