import React from 'react';
import { SolicitudBinariaRequest, Promoter } from '~utils/types';
import { useCommonStyles } from '~app/styles';
import CustomShow from '~components/CustomShow';

import SolicitudBinariaDetail from './SolicitudBinariaDetail';

interface Props {
  record: SolicitudBinariaRequest;
  resource: string;
  permissions: Promoter;
}

export default function SolicitudBinariaShow(props: Props) {
  const commonStyles = useCommonStyles();
  return (
    <CustomShow
      {...props}
      actions={null}
      title={<span />}
      classes={{ card: commonStyles.card, main: commonStyles.main, root: commonStyles.root }}
    >
      <SolicitudBinariaDetail {...props} />
    </CustomShow>
  );
}
