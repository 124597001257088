import React, { useCallback } from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import CustomButton from '~components/CustomButton';
import { useDispatch, useSelector } from '~contexts/ModalContext';
import { Affiliation } from '~utils/types';
import Tooltip from '@material-ui/core/Tooltip';
import { MedicineBoxTwoTone } from '@ant-design/icons';

import { useStyles } from './styles';
import PotentialRiskModal from './components/PotentialRiskModal';

interface Props {
  application: Affiliation;
}

function PotentialRiskField({ application }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { potentialRisk: risk } = application;
  const modal = useSelector(state => state);
  const isOpen = modal[MODAL_NAMES.POTENTIAL_RISK];
  const handleOpenPotentialRisk = useCallback(() => {
    dispatch?.(modalActions.openModal(MODAL_NAMES.POTENTIAL_RISK));
  }, [dispatch]);
  const handleClose = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.POTENTIAL_RISK));
  }, [dispatch]);
  const PATAOLOGIAS_URL = window.config.REACT_APP_PATOLOGIAS_URL;
  const TOOLTIP_PATOLOGIAS = window.config.REACT_APP_SHOW_TOOLTIP_PATOLOGIAS;
  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.point, { risk })} />
        <p className={styles.label}>{t(`general.${risk ? 'yes' : 'no'}`)}</p>

        {!!risk && (
          <>
            <>
              <CustomButton
                onClick={handleOpenPotentialRisk}
                label={t('resources.affiliations.potentialRisk.detail')}
                isLinkButton
              />
            </>
            {TOOLTIP_PATOLOGIAS && (
              <Tooltip title="Buscador de Patologías" arrow>
                <a href={`${PATAOLOGIAS_URL}`} target="_blank">
                  <MedicineBoxTwoTone className={cn(styles.medicalIcon, { risk })} />
                </a>
              </Tooltip>
            )}
          </>
        )}
      </div>
      {!!risk && (
        <CustomModal
          modalName={MODAL_NAMES.POTENTIAL_RISK}
          onClose={handleClose}
          isInfo
          title={t('resources.affiliations.modals.titlePotentialRisk')}
        >
          {isOpen && <PotentialRiskModal application={application} />}
        </CustomModal>
      )}
    </>
  );
}

export default PotentialRiskField;
