import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { linkToRecord } from 'ra-core';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper/index';
import ViewInput from '~components/ViewInput/index';
import { SolicitudBinariaRequest } from '~utils/types';
import HistoricAffiliationButton from '~screens/AffiliationRequests/screens/AffiliationRequestsList/components/HistoricAffiliationButton';

import StatusField from './StatusField';

type Props = {
  record: SolicitudBinariaRequest;
  basePath: string;
  id: number;
};

function ResponsiveList({ record, basePath, id }: Props) {
  const history = useHistory();
  const styles = useResponsiveListStyles();

  const handleRowClick = useCallback(() => history.push(linkToRecord(basePath, id, 'show')), [
    basePath,
    history,
    id
  ]);

  return (
    <ResponsiveListWrapper onClick={handleRowClick}>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label="Nombre"
          valueClassName={styles.value}
          value={`${record.firstname} ${record.lastname}`}
          className={styles.columnLarge}
        />
        <ViewInput
          labelClassName={styles.label}
          label="ID"
          valueClassName={styles.value}
          value={`${record.id}`}
          className={styles.columnShort}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          className={styles.columnLarge}
          labelClassName={styles.label}
          label="Producto"
          value={record.typeProductName}
        />
        <ViewInput
          labelClassName={styles.label}
          label="Documento"
          valueClassName={styles.value}
          value={`${record.dni}`}
          className={styles.columnShort}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          className={styles.columnLarge}
          labelClassName={styles.label}
          valueClassName={styles.value}
          label="Estado"
          content={<StatusField labelClassName={styles.maxContent} state={record.state} rounded={false} />}
        />
        <HistoricAffiliationButton
          className={`${styles.columnShort} ${styles.middle}`}
          stateDatetime={record.stateDatetime}
          id={record.id}
          typeApplication="binaria"
        />
      </div>
    </ResponsiveListWrapper>
  );
}

export default ResponsiveList;
