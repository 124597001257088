import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 20px 0',
    width: '100%'
  }
});
