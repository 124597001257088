import { makeStyles } from '@material-ui/core/styles';
import { BLACK } from '~constants/colors';

export const useClasses = makeStyles({
  content: {
    boxShadow: 'none',
    overflowX: 'auto'
  }
});

export const useStyles = makeStyles(theme => ({
  spacing: {
    [theme.breakpoints.only('xs')]: {
      marginTop: 25,
      padding: '0 10px 10px',
      '& .MuiToolbar-root': {
        padding: 0,
        '& .MuiToolbar-root': {
          flexWrap: 'wrap'
        }
      }
    }
  },
  tableTitleMobile: {
    color: BLACK,
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
    padding: '0 10px 10px'
  },
  logoOsde: {
    height: '51px',
    width: '134px'
  },
  logoBinaria: {
    height: '49px',
    width: '180px'
  },
  logoON: {
    height: '49px',
    width: '86px'
  }
}));
