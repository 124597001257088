/* eslint-disable complexity */
import React, { useState, useMemo, useCallback, SyntheticEvent } from 'react';
import { useTranslate, SimpleShowLayout } from 'react-admin';
import { SolicitudF2Request, Promoter, Nullable } from '~utils/types';
import { useCommonStyles } from '~app/styles';
import useModal from '~app/hooks/useModal';
import DetailActionButtons from '~components/DetailActionButtons';
import ViewInput from '~components/ViewInput';
import CustomButton from '~components/CustomButton';
import CustomModal from '~components/CustomModal';
import { EVALUATE_APPLICATION_F2, RESOURCES_PATH } from '~config/providers/constants';
import {
  getSendEmailTexts,
  getShowSendInitialEmail,
  isSolicitudFilled,
  isAttorneyValidationPending
} from '~screens/SolicitudesF2/utils';
import { EMAIL_CONFIG } from '~screens/SolicitudesF2/constants';
import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';
import StatusField from '~screens/SolicitudesF2/components/StatusField';
import SolicitudF2Form from '~screens/SolicitudesF2/components/SolicitudF2Form';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import { VIGENCIA_ZIP_GAW, hasPassedXDaysFromDate } from '~utils/date';
import DownloadZipButton from '~components/DownloadZipButton';

import SolicitudF2Filled from '../SolicitudF2Filled';
import { getEvaluatePayload } from '../SolicitudF2Filled/utils';

import { useStyles } from './styles';

interface Props {
  record: SolicitudF2Request;
  resource: string;
  permissions: Promoter;
}

interface RequestPayload {
  id?: number;
}

export default function SolicitudF2Detail(props: Props) {
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const t = useTranslate();
  const estado = useMemo(() => props.record.state, [props.record.state]);
  const [statusToChange, setStatusToChange] = useState<Nullable<string>>(null);
  const finished = props?.record?.state === AFFILIATION_REQUEST_STATUS.FINISHED;
  const sap =
    props?.record?.state === AFFILIATION_REQUEST_STATUS.SAP ||
    props?.record?.state === AFFILIATION_REQUEST_STATUS.SAP_RPA;
  const lastAudit = props?.record?.lastAudit;
  const zipGawUrl = props?.record?.zipGawUrl;

  const handleSetIsEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const canContinue = isSolicitudFilled(estado);
  const showSendInitialEmail = getShowSendInitialEmail(estado);
  const attorneyValidationPending = isAttorneyValidationPending(estado);
  const { label, title, modalMessage } = useMemo(() => getSendEmailTexts(estado), [estado]);

  const [solicitudF2Abierta, dispatchRequest, loading, onOpenModal] = useModal<
    SolicitudF2Request,
    RequestPayload
  >({
    resource: props.resource,
    ...(EMAIL_CONFIG[estado] || EMAIL_CONFIG[SOLICITUDES_REQUEST_STATUS.INITIAL_EMAIL_PENDING.COD])
  });
  const [modalData, dispatchRequestCancel, loadingCancel, onOpenModalCancel] = useModal<
    RequestPayload,
    RequestPayload
  >({
    resource: props.resource,
    requestType: EVALUATE_APPLICATION_F2,
    requestPayload: getEvaluatePayload,
    modalName: MODAL_NAMES.EVALUATE,
    successMessageNotify: `resources.affiliations.evaluate.modal.${statusToChange}.success`
  });
  const handleOpenModal = useCallback(
    (action: string) => {
      setStatusToChange(action);
      onOpenModalCancel({ id: props.record.id, newState: action });
    },
    [props.record.id, onOpenModalCancel]
  );

  const handleClickSendEmail = (event: SyntheticEvent<EventTarget>) => {
    event.stopPropagation();
    onOpenModal(props.record);
  };

  return (
    <>
      <DetailActionButtons />
      <SimpleShowLayout {...props}>
        <>
          <h1 className={commonStyles.title}>Detalles de solicitud</h1>
        </>
        <SolicitudF2Form {...props} esEdicion enEdicion={isEditing} onSetEditing={handleSetIsEditing} />
        <ViewInput
          label="Estado"
          content={<StatusField state={estado} rounded={false} rpaStatus={props.record.rpaStatus} />}
          innerContainerClassName={styles.spacing}
        />
        <>
          {showSendInitialEmail && (
            <>
              <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
                <CustomButton label={t(label)} title={t(title)} onClick={handleClickSendEmail} />
              </div>
              <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
                <CustomButton
                  className={`${styles.button} ${styles.buttonCanceled}`}
                  label={t(`resources.affiliations.evaluate.${AFFILIATION_REQUEST_STATUS.CANCELED}`)}
                  title={t(title)}
                  onClick={() => handleOpenModal(AFFILIATION_REQUEST_STATUS.CANCELED)}
                />
              </div>
            </>
          )}
          {attorneyValidationPending && (
            <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
              <CustomButton label={t(label)} title={t(title)} onClick={() => handleOpenModal(estado)} />
            </div>
          )}
          {canContinue && <SolicitudF2Filled record={props.record} resource={RESOURCES_PATH.on} />}
          {attorneyValidationPending && (
            <div className={`${commonStyles.row} ${styles.sendEmailButton} ${styles.spacing}`}>
              <CustomButton label={t(label)} title={t(title)} onClick={handleClickSendEmail} />
            </div>
          )}
        </>
        <>
          {(finished || sap) &&
            lastAudit &&
            zipGawUrl &&
            zipGawUrl !== '' &&
            !hasPassedXDaysFromDate(VIGENCIA_ZIP_GAW, lastAudit) && <DownloadZipButton fileUrl={zipGawUrl} />}
        </>
      </SimpleShowLayout>
      {solicitudF2Abierta?.id && (
        <CustomModal
          title="¿Estás seguro?"
          modalName={EMAIL_CONFIG[estado].modalName}
          onConfirm={dispatchRequest}
          loading={loading}
          showCloseIcon={false}
        >
          <p className={styles.modalContent}>{t(modalMessage)}</p>
        </CustomModal>
      )}
      {modalData?.id && (
        <CustomModal
          title={t('¿Estás seguro?')}
          modalName={MODAL_NAMES.EVALUATE}
          onConfirm={dispatchRequestCancel}
          loading={loadingCancel}
        >
          {showSendInitialEmail && (
            <p className={styles.modalContent}>Estás por llevar a cancelado el proceso de afiliación.</p>
          )}
        </CustomModal>
      )}
    </>
  );
}
