import React, { useEffect, useState } from 'react';
// import cn from 'classnames';
import { useTranslate } from 'react-admin';
import { ReactComponent as OkIcon } from '~assets/Ok_green.svg';
import { useCommonStyles } from '~app/styles';
import { AffiliationRequest } from '~utils/types';

const StatusRPA = (record: AffiliationRequest) => {
  const [showIconOk, setShowIconOk] = useState(false);
  const t = useTranslate();
  const commonStyles = useCommonStyles();
  useEffect(() => {
    const mostrarIconRPA = () => {
      if (record.state === 'sap') {
        setShowIconOk(true);
      }
    };
    mostrarIconRPA();
  }, [commonStyles.icon, record, t]);
  return <div>{showIconOk && <OkIcon className={commonStyles.icon} title={record.rpaPath} />}</div>;
};

export default StatusRPA;
