import { SolicitudONRequest } from '~utils/types';
import { SOLICITUDES_ON_REQUEST_STATUS } from '~constants/solicitudesON';

export const getSendEmailRequestPayload = (solicitudON?: SolicitudONRequest) => ({ id: solicitudON?.id });

export const getShowSendInitialEmail = (status: string) =>
  status === SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING ||
  status === SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_SENT ||
  status === SOLICITUDES_ON_REQUEST_STATUS.FORM_LINK_OPENED;

export const isSolicitudONFilled = (status: string) =>
  status === SOLICITUDES_ON_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === SOLICITUDES_ON_REQUEST_STATUS.PENDING_USER_VALIDATION ||
  status === SOLICITUDES_ON_REQUEST_STATUS.FINISHED ||
  status === SOLICITUDES_ON_REQUEST_STATUS.REVISION ||
  status === SOLICITUDES_ON_REQUEST_STATUS.USER_REJECTED ||
  status === SOLICITUDES_ON_REQUEST_STATUS.EXPIRED;

export const isUserValidationPending = (status: string) =>
  status === SOLICITUDES_ON_REQUEST_STATUS.PENDING_USER_VALIDATION;

export const getSendEmailTexts = (status: string) => {
  let base = '';
  const modalBase = 'resources.on.modals';
  if (isUserValidationPending(status)) {
    base = 'resources.on.resendUserValidationEmail';
    return {
      label: `${base}.complete`,
      labelShort: `${base}.short`,
      title: `${base}.title`,
      modalMessage: `${modalBase}.userValidationEmail.resend`
    };
  }
  base = 'resources.on.initialEmail';
  const type = status === SOLICITUDES_ON_REQUEST_STATUS.INITIAL_EMAIL_PENDING ? 'send' : 'resend';
  return {
    label: `${base}.${type}.complete`,
    labelShort: `${base}.${type}.short`,
    title: `${base}.${type}.title`,
    modalMessage: `${modalBase}.initialEmail.${type}`
  };
};

export const isEvaluationPending = (status: string) =>
  status === SOLICITUDES_ON_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === SOLICITUDES_ON_REQUEST_STATUS.REVISION;
