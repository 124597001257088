import React, { useState, useCallback } from 'react';
import { TextField, FunctionField } from 'react-admin';
import WithProps from '~components/WithProps';
import CustomTable from '~components/CustomTable';
import { SolicitudBinariaRequest, Nullable } from '~utils/types';
import CustomModal from '~components/CustomModal';
import useModal from '~app/hooks/useModal';
import HistoricAffiliationButton from '~screens/AffiliationRequests/screens/AffiliationRequestsList/components/HistoricAffiliationButton';
import HistoricAffiliationModal from '~screens/AffiliationRequests/screens/AffiliationRequestsList/components/HistoricAffiliationModal';

import { useStyles } from '../binaria-styles';
import { getMessageModal, evaluatePayload, productNameDescription } from '../helperFunctions';

import ResponsiveList from './ResponsiveList';
import StatusField from './StatusField';
import SolicitudBinariaFilter from './SolicitudBinariaFilter';
import ActionField from './ActionField';

interface Props {
  resource: string;
}

interface RequestPayload {
  id?: number;
}

export default function SolicitudesBinariaList({ resource, ...props }: Props) {
  const styles = useStyles();
  const [estado, setEstado] = useState<Nullable<string> | undefined>(null);

  const handleClose = useCallback(() => {
    setEstado(null);
  }, []);

  const [, dispatchRequest, loading, onOpenModal] = useModal<RequestPayload, RequestPayload>({
    resource,
    requestType: 'UPDATE_ESTADO_SOLICITUD_BINARIA',
    requestPayload: evaluatePayload,
    modalName: 'evaluate',
    successMessageNotify: 'Se ha enviado el email correctamente'
  });

  const handleOpenModal = useCallback(
    (nextState: string, actualState: string, id: number) => {
      setEstado(actualState);
      onOpenModal({ id, newState: nextState });
    },
    [onOpenModal]
  );

  return (
    <WithProps {...{ resource, ...props }}>
      {(withProps: Props) => (
        <>
          <CustomTable
            {...withProps}
            title="Listado de solicitudes"
            filters={<SolicitudBinariaFilter />}
            showDetail
            listComponent={ResponsiveList}
          >
            <TextField label="ID" source="id" sortable={false} />
            <TextField label="Promotor" source="promoterId" sortable={false} />
            <TextField label="Nombre Asegurado" source="firstname" sortable={false} />
            <TextField label="Apellido Asegurado" source="lastname" sortable={false} />
            <TextField label="Documento" source="dni" sortable={false} />
            <TextField
              label="Producto"
              source="productName"
              sortable={false}
              specialFormatter={productNameDescription}
            />
            <FunctionField label="Estado" render={StatusField} />
            <FunctionField
              label="Acción"
              render={(sol: SolicitudBinariaRequest) => (
                <div className={styles.actions}>
                  <HistoricAffiliationButton
                    id={sol.id}
                    stateDatetime={sol.stateDatetime}
                    typeApplication="binaria"
                  />
                  <ActionField solicitud={sol} onEnvioMailInicial={handleOpenModal} />
                </div>
              )}
            />
          </CustomTable>
          {estado && (
            <CustomModal
              title="¿Estás seguro?"
              modalName="evaluate"
              onConfirm={dispatchRequest}
              loading={loading}
              onClose={handleClose}
              showCloseIcon={false}
            >
              <p className={styles.content}>{getMessageModal(estado)}</p>
            </CustomModal>
          )}
          <HistoricAffiliationModal typeApplication="binaria" />
        </>
      )}
    </WithProps>
  );
}
