import React from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';

import { useStyles } from './styles';

interface Props {
  estado: string;
  rounded?: boolean;
  className?: string;
  labelClassName?: string;
}
function StatusDetail({ estado, rounded = true, className = '', labelClassName = '' }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const state = estado ? estado : 'SIN_PROCESAR';
  return (
    <>
      <div className={`full-width ${className}`}>
        <div
          className={cn(styles.label, styles[state], labelClassName, {
            rounded
          })}
        >
          <span>{t(`resources.altasmasivas.statusDetail.${state}`)}</span>
        </div>
      </div>
    </>
  );
}

export default StatusDetail;
