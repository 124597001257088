import { makeStyles } from '@material-ui/core/styles';
import { DARK_GRAY, MERCURY_TRANSPARENCY, GRAY, PRIMARY_BLUE } from '~constants/colors';

export const useStyles = makeStyles({
  rowCell: {
    borderBottom: `1px solid ${MERCURY_TRANSPARENCY}`,
    padding: '10px 10px 10px 5px'
  },
  root: {
    cursor: 'pointer'
  }
});

export const useCheckboxStyles = makeStyles({
  root: {
    padding: '0'
  },
  colorPrimary: {
    color: GRAY
  }
});

export const useRowTableRowStyles = makeStyles({
  rowField: {
    padding: '12px 0',
    color: DARK_GRAY,
    fontWeight: 600,
    lineHeight: '16px'
  },
  selected: {
    color: PRIMARY_BLUE
  }
});
