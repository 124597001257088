import { useAuth } from 'react-oidc-context';
import React, { useCallback } from 'react';
import { ExitToApp } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DARK_GRAY } from '~constants/colors';
import paths from '~components/Routes/paths';
import authProvider from '~config/providers/authProvider';

function LogoutButton() {
  const history = useHistory();
  const auth = useAuth();

  const handleLogout = useCallback(async () => {
    await auth.removeUser();
    authProvider.logout();
    sessionStorage.removeItem('url');
    history.push(paths.login);
  }, [auth, history]);

  return (
    <Tooltip title="Cerrar sesion" data-cy="logout">
      <IconButton onClick={handleLogout}>
        <ExitToApp style={{ color: DARK_GRAY }} />
      </IconButton>
    </Tooltip>
  );
}

export default LogoutButton;
