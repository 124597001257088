/* eslint-disable max-lines */
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {
  PRIMARY_BLUE,
  DARK_GRAY,
  LIGHT_GRAY,
  SECONDARY_BLUE,
  TERTIARY_BLUE,
  PRIMARY_RED,
  GRAY,
  TRANSPARENCY
} from '~constants/colors';
import { white } from '~utils/colors';
import { FULL_WIDTH, WIDTH_ROW } from '~constants/sizes';

const textStyles = {
  h1: {
    fontSize: '32px',
    lineHeight: '38px'
  },
  h2: {
    fontSize: '24px',
    lineHeight: '29px'
  },
  h3: {
    fontSize: '20px',
    lineHeight: '24px'
  },
  h4: {
    fontSize: '16px',
    lineHeight: '19px'
  },
  h5: {
    fontSize: '14px',
    lineHeight: '17px'
  },
  h6: {
    fontSize: '12px',
    lineHeight: '14px'
  },
  p: {
    fontSize: '14px',
    lineHeight: '17px'
  }
};
const defaultTheme = createMuiTheme();

export const appTheme = createMuiTheme({
  palette: {
    text: {
      primary: PRIMARY_BLUE,
      secondary: DARK_GRAY,
      disabled: LIGHT_GRAY
    },
    primary: {
      main: PRIMARY_BLUE
    },
    secondary: {
      main: TERTIARY_BLUE
    }
  },
  typography: {
    fontWeightBold: 500,
    fontWeightMedium: 300,
    fontWeightRegular: 300,
    fontWeightLight: 300,
    fontFamily: ['museo-sans', 'sans-serif', 'Segoe UI', 'Roboto', 'Helvetica Neue'].join(',')
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        maxWidth: 230
      }
    },
    MuiButton: {
      textPrimary: {
        color: DARK_GRAY
      },
      containedPrimary: {
        borderRadius: '4px',
        padding: '14px 20px',
        backgroundColor: PRIMARY_BLUE,
        '& span': {
          color: white,
          fontWeight: 500,
          padding: 0
        },
        '&:hover': {
          backgroundColor: TERTIARY_BLUE,
          transform: 'scale(1.03, 1.03)'
        },
        '&:focus': {
          backgroundColor: PRIMARY_BLUE,
          boxShadow: `0 0 4px 0 ${SECONDARY_BLUE}`
        },
        '&:disabled': {
          backgroundColor: GRAY,
          pointerEvents: 'none'
        }
      }
    },
    MuiMenuItem: {
      root: { fontWeight: 500 }
    },
    MuiInputLabel: {
      marginDense: {
        transform: 'scale(1)'
      },
      shrink: {
        transform: 'scale(1)'
      },
      root: {
        '& span': {
          color: PRIMARY_BLUE,
          fontSize: '16px',
          fontWeight: 'bold'
        }
      }
    },
    MuiInput: {
      marginDense: {
        padding: 0
      },
      formControl: {
        'label + &': {
          marginTop: '26px'
        }
      },
      input: {
        padding: '10px',
        '&.Mui-disabled': { color: GRAY }
      },
      underline: {
        border: `1px solid ${GRAY}`,
        borderRadius: '2px',
        '&:before, &:after': { borderBottom: 'none' },
        '&.Mui-disabled:before': { borderBottom: 'none' },
        '&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)': {
          '&:not(.Mui-error)': {
            borderColor: SECONDARY_BLUE,
            boxShadow: `0 0 4px 0 ${SECONDARY_BLUE}`
          },
          '&:before': {
            borderBottom: 'none'
          }
        },
        '&.Mui-error': {
          borderColor: PRIMARY_RED,
          boxShadow: 'none'
        }
      }
    },
    MuiCardContent: {
      root: {
        padding: 0
      }
    },
    MuiFormControl: {
      root: {
        '& > .MuiFormHelperText-root.Mui-error': {
          bottom: -0,
          color: PRIMARY_RED,
          fontWeight: 500
        }
      },
      marginDense: {
        marginTop: 0
      }
    },
    MuiIconButton: {
      label: {
        color: 'inherit'
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: SECONDARY_BLUE
      }
    },
    MuiDrawer: {
      docked: {
        flexBasis: '250px',
        [defaultTheme.breakpoints.only('sm')]: {
          flexBasis: '225px'
        }
      }
    },
    MuiList: {
      root: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    MuiToolbar: {
      root: {
        margin: 0,
        padding: 0
      }
    },
    MuiFab: {
      label: {
        color: 'inherit'
      }
    }
  }
});

export const useGlobalStyles = makeStyles(textStyles);

export const useCommonStyles = makeStyles(theme => ({
  errorMessage: {
    color: 'red'
  },
  successMessage: {
    color: 'green'
  },
  flex: {
    display: 'flex'
  },
  width50: {
    width: '50%'
  },
  column: {
    marginLeft: '50px'
  },
  row: {
    '&.MuiFormControl-root': {
      width: FULL_WIDTH,
      marginBottom: '4px'
    }
  },
  field: {
    marginBottom: '10px'
  },
  card: {
    alignItems: 'center',
    boxShadow: `0 10px 30px ${TRANSPARENCY}`,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    justifyContent: 'center',
    maxWidth: 580,
    padding: '10px',
    width: FULL_WIDTH,
    [theme.breakpoints.only('xs')]: {
      padding: 0
    }
  },
  cardDatosPago: {
    alignItems: 'center',
    boxShadow: `0 10px 30px ${TRANSPARENCY}`,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    width: FULL_WIDTH,
    [theme.breakpoints.only('xs')]: {
      padding: 0
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  root: {
    padding: '20px 40px 0 100px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  rootDatosPago: {
    // padding: '0 40px 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  title: {
    color: DARK_GRAY,
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '28px',
    marginBottom: '36px',
    textAlign: 'center',
    width: FULL_WIDTH
  },
  titleDatosPago: {
    color: DARK_GRAY,
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '28px',
    marginBottom: '36px',
    width: FULL_WIDTH
  },
  icon: {
    color: PRIMARY_BLUE,
    cursor: 'pointer',
    fontSize: 18
  },
  noMarginBottom: {
    marginBottom: 0
  },
  buttonsGroupFlexDirectionXs: {
    flexDirection: 'column'
  }
}));

export const useFormStyles = makeStyles(theme => ({
  form: {
    '& .ra-input': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .MuiCardContent-root:first-child': {
      marginTop: 0,
      padding: 0
    },
    marginTop: 0,
    minWidth: WIDTH_ROW,
    paddingTop: 0,
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px'
    },
    '& .picker': {
      marginBottom: '4px'
    }
  },
  minWidthSelect: {
    minWidth: 'unset'
  }
}));

export const CUSTOM_ICON_STYLES = {
  height: 22,
  width: 22
};
