/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable dot-notation */
/* eslint-disable complexity */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useCallback, useState, useRef } from 'react';
import cn from 'classnames';
import { useDispatch } from '~contexts/ModalContext';
import { useHistory } from 'react-router';
import { ReactComponent as VBLogo } from '~assets/logo_sid.svg';
import { MODAL_NAMES } from '~app/contexts/ModalContext/constants';
import { getEmailValidation } from '~services/OsdeService';
import { useFormStyles, useCommonStyles } from '~app/styles';
import {
  convertCodDescrCollAsArray,
  DISCOUNT_TYPES,
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_CUIT,
  PLAN_TYPES
} from '~constants/solicitudesF2';
import { SOLICITUDES_F2_REQUESTS_FIELDS } from '~screens/SolicitudesF2/constants';
import { getIsAutogestion, getIsSuperAdmin } from '~utils/general';
import { getObjectValueFromString } from '~utils/object';
import {
  required,
  numericalDigits,
  email,
  match,
  phoneDigits,
  discountDigits
} from '~utils/inputValidations';
import { getDocumentNumberValidationF2, promoterId } from '~utils/inputValidationsF2';
import { SolicitudF2Request, Promoter, SolicitudF2RequestForm, Nullable } from '~utils/types';
import {
  SimpleForm,
  FormDataConsumer,
  useMutation,
  useTranslate,
  regex,
  useNotify,
  Labeled,
  TextInput
} from 'react-admin';
import AutoCompleteDate from '~components/AutoCompleteDate';
import InputLabel from '~components/InputLabel';
import InputSelect from '~components/InputSelect';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import FeedbackModalContent from '~components/FeedbackModalContent';
import CustomModal from '~components/CustomModal';
import Toolbar from '~components/Toolbar';
import { INPUT_NUMBER_PROPS } from '~constants/validations';
import dataProvider from '~config/providers/dataProvider';
import { GET_ONE_PROMOTER_LIST, RESOURCES_PATH, UPDATE_SOLICITUD_F2 } from '~config/providers/constants';
import { isEditable, isInitialMailPending } from '~screens/SolicitudesF2/utils';
import moment from 'moment';
import VBField from '~screens/AffiliationRequests/components/VBField';
import { IVBSteps } from '~utils/interfaces';
import ViewInput from '~components/ViewInput';
import SummaryDiscountModal from '~components/BulkUploadPopUp';
import { Modal, DialogActions, IconButton } from '@material-ui/core';
import CustomButton from '~components/CustomButton';

import DocumentType from './DocumentType';
import { useF2FormStyles } from './styles';
import { getDiscountType, getPlanType } from './utils';

interface Props {
  record?: SolicitudF2Request;
  title?: string;
  esEdicion?: boolean;
  redirect?: string;
  toolbar?: ReactNode;
  resource?: string;
  onSetEditing?: () => void;
  enEdicion?: boolean;
  permissions: Promoter;
}

interface FormDataProps {
  formData: SolicitudF2RequestForm;
}

/* eslint complexity: ["error", 12] */
export default function SolicitudF2Form(props: Props) {
  const styles = useFormStyles();
  const f2Styles = useF2FormStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuperAdmin = getIsSuperAdmin(props.permissions?.role);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [formattedPromoter, setFormattedPromoter] = useState<Nullable<string>>(null);
  const [invalidPlanValidityDate, setInvalidPlanValidityDate] = useState(false);
  const [mutate, { data, error, loading }] = useMutation();
  const regexBlockSpecialCharacters = /[^A-Za-z_ÑñÁáÉéÍíÓóÚú\s_]+/g;
  const regexCharacters = /^[a-zA-Z\u00C0-\u024F'\s]+$/;
  const regexNumero = /[^0-9]+/g;
  const { esEdicion, onSetEditing, enEdicion, ...rest } = props;
  const t = useTranslate();
  const notify = useNotify();
  const [click, setClick] = React.useState<boolean>(false);
  const [showClose, setShowClose] = React.useState<boolean>(true);
  // const [formValues, setFormValues] = useState<FormDataProps>();
  const formValuesRef = useRef<FormDataProps>();
  const [discountTypesChoicesFlag, setDiscountTypesChoicesFlag] = React.useState(true);

  const handleClose = (state: boolean) => {
    setClick(state);
  };
  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClick(false);
  };
  const documentTypesChoices = convertCodDescrCollAsArray(DOCUMENT_TYPES, cd => ({
    id: cd.COD,
    name: cd.DESCRIPCION
  }));
  const planTypesChoices = convertCodDescrCollAsArray(PLAN_TYPES, cd => ({
    id: cd.COD,
    name: cd.DESCRIPCION
  }));
  const discountTypesChoices = convertCodDescrCollAsArray(DISCOUNT_TYPES, cd => ({
    id: cd.COD,
    name: cd.DESCRIPCION
  }));
  const DAYS_BEFORE = 90;
  const extraFieldsEdit = isInitialMailPending(props.record?.state);

  const isValidityDateEditable = isEditable(props.record?.state);

  const promoter = `${props.record?.promoter?.firstName} ${props.record?.promoter?.lastName}`;
  const vbSteps: IVBSteps | undefined = props.record?.vbSteps;

  useEffect(() => {
    if (data || error) {
      dispatch?.(modalActions.openModal(MODAL_NAMES.DIALOG));
    }
  }, [data, dispatch, error]);

  const createSolicitud = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: 'CREAR_SOLICITUD_F2',
        resource: props.resource,
        payload: values
      }),
    [mutate, props.resource]
  );
  const editarSolicitud = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: UPDATE_SOLICITUD_F2,
        resource: props.resource,
        payload: { ...values, isSuperAdmin }
      }),
    [props.resource, mutate, isSuperAdmin]
  );

  const handleExit = useCallback(() => {
    history.goBack();
  }, [history]);

  const isAutogestion = getIsAutogestion(props.record?.entryChannel);

  const formatValuesToSubmit = useCallback((values: any) => {
    if (values.planType === PLAN_TYPES.BINARIO.COD) {
      delete values['discountRate8260'];
      delete values['discountRate8360'];
      delete values['discountRate8430'];
    } else if (values.applyDiscountAllPlans === undefined || values.applyDiscountAllPlans === 'true') {
      values['discountRate8260'] = values['discountRate'] || '0';
      values['discountRate8360'] = values['discountRate'] || '0';
      values['discountRate8430'] = values['discountRate'] || '0';
    } else if (values.applyDiscountAllPlans === 'null') {
      values['applyDiscountAllPlans'] = 'true';
      values['discountRate8260'] = '0';
      values['discountRate8360'] = '0';
      values['discountRate8430'] = '0';
    } else if (values.applyDiscountAllPlans === 'false') {
      if (values.discountRate8260 === null) {
        values['discountRate8260'] = '0';
      }
      if (values.discountRate8360 === null) {
        values['discountRate8360'] = '0';
      }
      if (values.discountRate8430 === null) {
        values['discountRate8430'] = '0';
      }
    }
    delete values['discountRate'];
  }, []);

  const confirmSave = useCallback(() => {
    const currentFormValues = formValuesRef.current;
    if (!currentFormValues) {
      return undefined;
    }
    const planValidityDate =
      localStorage.getItem('planValidityDate') || currentFormValues.formData.planValidityDate;
    const newFormData = {
      ...currentFormValues,
      planValidityDate
    };
    if (esEdicion) {
      return editarSolicitud(newFormData);
    }
    // setClick(false);
    return createSolicitud(newFormData);
  }, [createSolicitud, editarSolicitud, esEdicion]);

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    confirmSave();
    // setClick(false);
  };

  const handleSave = useCallback(
    (values: any) => {
      formatValuesToSubmit(values);
      // setFormValues(values);
      formValuesRef.current = values;
      if (esEdicion && values.applyDiscountAllPlans === 'false') {
        setDiscountTypesChoicesFlag(false);
      }
      if (values.planType === 'binario') {
        confirmSave();
      } else {
        setClick(true);
      }
    },
    [confirmSave, esEdicion, formatValuesToSubmit]
  );

  const validatePromoter = useCallback(
    async (
      currentPromoterId: number,
      _: SolicitudF2Request,
      fieldProps: { pristine: boolean; initial: any; modified: boolean }
    ) => {
      if (!fieldProps.pristine || (fieldProps.initial === currentPromoterId && fieldProps.modified)) {
        setIsValidationLoading(true);
        setFormattedPromoter(t('validation.loadingPromoter'));
        const promoterData = await dataProvider(GET_ONE_PROMOTER_LIST, RESOURCES_PATH.promoters, {
          id: currentPromoterId
        });
        setIsValidationLoading(false);
        if (!promoterData || promoterData.error) {
          setFormattedPromoter(null);
          return promoterData?.error || 'errors.default';
        }
        const { id, firstName, lastName } = promoterData.page?.[0] || {};
        setFormattedPromoter(`${id} - ${firstName} ${lastName}`);
      }
      return undefined;
    },
    [t]
  );

  return (
    <>
      <SimpleForm
        {...rest}
        className={cn(styles.form, f2Styles.form)}
        save={handleSave}
        saving={loading || isValidationLoading}
        toolbar={
          enEdicion || !esEdicion ? (
            <Toolbar {...props} invalidPlanValidityDate={invalidPlanValidityDate} />
          ) : null
        }
        validateOnBlur
      >
        {esEdicion && (
          <InputLabel
            label={t('resources.solicitudF2.fields.applicationId')}
            className={commonStyles.row}
            source={SOLICITUDES_F2_REQUESTS_FIELDS.id}
            isEdit={esEdicion}
          />
        )}

        <InputLabel
          label={t('resources.solicitudF2.fields.companyName')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.companyName}
          isEdit={esEdicion}
          validate={required}
          onSetEditing={onSetEditing}
          editEnabled={extraFieldsEdit}
        />

        <InputLabel
          label={t('resources.solicitudF2.fields.cuit')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.cuit}
          validate={[
            required,
            ...(getDocumentNumberValidationF2({ t, type: DOCUMENT_TYPES_CUIT.COD }) as any[])
          ]}
          {...{ ...INPUT_NUMBER_PROPS, type: 'number' }}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          type="number"
        />

        <InputLabel
          label={t('resources.solicitudF2.fields.firstName')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.firstName}
          validate={[required, regex(regexCharacters, 'No se permiten caracteres especiales.')]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled={!isAutogestion}
          parse={(value: string) => value.replace(regexBlockSpecialCharacters, '')}
        />

        <InputLabel
          label={t('resources.solicitudF2.fields.lastName')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.lastName}
          validate={[required, regex(regexCharacters, 'No se permiten caracteres especiales.')]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled={!isAutogestion}
          parse={(value: string) => value.replace(regexBlockSpecialCharacters, '')}
        />

        <InputSelect
          label={t('resources.solicitudF2.fields.documentType')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.documentType}
          choices={documentTypesChoices}
          defaultValue={DOCUMENT_TYPES.DNI.COD}
          validate={required}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          // editEnabled={extraFieldsEdit}
        />

        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => (
            <DocumentType
              formData={formData}
              onSetEditing={onSetEditing}
              extraFieldsEdit={extraFieldsEdit}
              esEdicion={esEdicion}
              {...formDataProps}
            />
          )}
        </FormDataConsumer>

        <InputLabel
          label={t('resources.solicitudF2.fields.phoneNumber')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.phoneNumber}
          type="text"
          parse={(value: string) => value.replace(regexNumero, '')}
          validate={[phoneDigits]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled
        />

        <InputLabel
          label={t('resources.solicitudF2.fields.email')}
          className={commonStyles.row}
          type="email"
          source={SOLICITUDES_F2_REQUESTS_FIELDS.attorneyEmail}
          validate={[required, email]}
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          editEnabled
        />

        {!esEdicion && (
          <FormDataConsumer>
            {({ formData, ...formDataProps }: any) => {
              const emailData = getObjectValueFromString(
                SOLICITUDES_F2_REQUESTS_FIELDS.attorneyEmail,
                formData
              );
              return (
                <div className={cn(styles.form, f2Styles.form)}>
                  <InputLabel
                    {...formDataProps}
                    className={commonStyles.row}
                    label={t('resources.solicitudF2.fields.emailConfirmation')}
                    type="email"
                    source={SOLICITUDES_F2_REQUESTS_FIELDS.emailConfirmation}
                    validate={[required, match(emailData, t('validation.emailMatching'))]}
                    isEdit={esEdicion}
                    onSetEditing={onSetEditing}
                    editEnabled
                  />
                </div>
              );
            }}
          </FormDataConsumer>
        )}

        <FormDataConsumer>
          {({ formData, ...formDataProps }: any) => {
            const esPlanCorporativo = formData.planType === PLAN_TYPES.CORPORATIVO.COD;
            const discountApplyAllPlans =
              formData.applyDiscountAllPlans === undefined ||
              formData.applyDiscountAllPlans === null ||
              formData.applyDiscountAllPlans === 'null'
                ? true
                : formData.applyDiscountAllPlans === 'true';

            const handleInputChange = (newValue: any) => {
              setDiscountTypesChoicesFlag(newValue === 'true');
            };

            return (
              <div className={cn(styles.form, f2Styles.form)}>
                <InputSelect
                  {...props}
                  label={t('resources.solicitudF2.fields.planType')}
                  className={commonStyles.row}
                  source={SOLICITUDES_F2_REQUESTS_FIELDS.planType}
                  choices={planTypesChoices}
                  validate={required}
                  isEdit={esEdicion}
                  onSetEditing={onSetEditing}
                  editEnabled={extraFieldsEdit}
                  specialFormatter={getPlanType}
                />
                {esPlanCorporativo && (
                  <div className={cn(f2Styles.marginBottom15)}>
                    <InputSelect
                      {...props}
                      label={t('resources.solicitudF2.fields.discountRate')}
                      className={commonStyles.row}
                      source={SOLICITUDES_F2_REQUESTS_FIELDS.applyDiscountAllPlans}
                      choices={discountTypesChoices}
                      defaultValue={DISCOUNT_TYPES.APLICA_A_TODOS.COD}
                      validate={required}
                      isEdit={esEdicion}
                      onSetEditing={onSetEditing}
                      editEnabled={extraFieldsEdit}
                      specialFormatter={getDiscountType}
                      onSelectedValueChange={handleInputChange}
                    />
                  </div>
                )}

                {esPlanCorporativo && discountApplyAllPlans && (
                  <div className={commonStyles.flex}>
                    <InputLabel
                      {...formDataProps}
                      label=""
                      field="discountRate"
                      className={commonStyles.row}
                      source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate}
                      type="text"
                      parse={(value: string) => value.replace(regexNumero, '')}
                      validate={[discountDigits(t)]}
                      isEdit={esEdicion}
                      onSetEditing={onSetEditing}
                      editEnabled={extraFieldsEdit}
                    />
                    <p className={cn(f2Styles.percentageDiscounts, f2Styles.percentageOneDiscountsPadding)}>
                      %
                    </p>
                  </div>
                )}

                {esPlanCorporativo && !discountApplyAllPlans && (
                  <>
                    <div className={commonStyles.flex}>
                      <InputLabel
                        {...formDataProps}
                        label={t('resources.solicitudF2.fields.discountRate8260')}
                        field="discountRate_8260"
                        className={commonStyles.row}
                        source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate8260}
                        type="text"
                        parse={(value: string) => value.replace(regexNumero, '')}
                        validate={[discountDigits(t)]}
                        isEdit={esEdicion}
                        onSetEditing={onSetEditing}
                        editEnabled={extraFieldsEdit}
                      />
                      <p className={cn(f2Styles.percentageDiscounts, f2Styles.percentageAllDiscountsPadding)}>
                        %
                      </p>
                    </div>
                    <div className={commonStyles.flex}>
                      <InputLabel
                        {...formDataProps}
                        label={t('resources.solicitudF2.fields.discountRate8360')}
                        field="discountRate_8360"
                        className={commonStyles.row}
                        source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate8360}
                        type="text"
                        parse={(value: string) => value.replace(regexNumero, '')}
                        validate={[discountDigits(t)]}
                        isEdit={esEdicion}
                        onSetEditing={onSetEditing}
                        editEnabled={extraFieldsEdit}
                      />
                      <p className={cn(f2Styles.percentageDiscounts, f2Styles.percentageAllDiscountsPadding)}>
                        %
                      </p>
                    </div>
                    <div className={commonStyles.flex}>
                      <InputLabel
                        {...formDataProps}
                        label={t('resources.solicitudF2.fields.discountRate8430')}
                        field="discountRate_8430"
                        className={commonStyles.row}
                        source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate8430}
                        type="text"
                        parse={(value: string) => value.replace(regexNumero, '')}
                        validate={[discountDigits(t)]}
                        isEdit={esEdicion}
                        onSetEditing={onSetEditing}
                        editEnabled={extraFieldsEdit}
                      />
                      <p className={cn(f2Styles.percentageDiscounts, f2Styles.percentageAllDiscountsPadding)}>
                        %
                      </p>
                    </div>
                  </>
                )}
                {esPlanCorporativo && (
                  <SummaryDiscountModal touched={click} callback={handleClose} showCloseIcon={showClose}>
                    {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
                    <InputLabel
                      label={t('resources.affiliations.fields.planType')}
                      className={commonStyles.flex}
                      source={SOLICITUDES_F2_REQUESTS_FIELDS.planType}
                      type="text"
                      disabled
                    />
                    {discountTypesChoicesFlag === true && (
                      <>
                        <div className={commonStyles.flex}>
                          <InputLabel
                            label={`${t('resources.solicitudF2.fields.discountRate')}`}
                            className={commonStyles.row}
                            source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate}
                            type="text"
                            disabled
                          />
                          <p
                            className={cn(
                              f2Styles.percentageDiscounts,
                              f2Styles.percentageAllDiscountsPadding
                            )}
                          >
                            %
                          </p>
                        </div>
                        <div className={f2Styles.label}>
                          <p> {`${DISCOUNT_TYPES.APLICA_A_TODOS.DESCRIPCION}`}</p>
                        </div>
                      </>
                    )}
                    {formData.discountRate8260 && discountTypesChoicesFlag === false && (
                      <div className={commonStyles.flex}>
                        <InputLabel
                          label={t('resources.solicitudF2.fields.discountRate8260')}
                          className={commonStyles.row}
                          source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate8260}
                          type="text"
                          disabled
                        />
                        <p
                          className={cn(f2Styles.percentageDiscounts, f2Styles.percentageAllDiscountsPadding)}
                        >
                          %
                        </p>
                      </div>
                    )}
                    {formData.discountRate8360 && discountTypesChoicesFlag === false && (
                      <div className={commonStyles.flex}>
                        <InputLabel
                          label={t('resources.solicitudF2.fields.discountRate8360')}
                          className={commonStyles.row}
                          source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate8360}
                          type="text"
                          disabled
                        />
                        <p
                          className={cn(f2Styles.percentageDiscounts, f2Styles.percentageAllDiscountsPadding)}
                        >
                          %
                        </p>
                      </div>
                    )}
                    {formData.discountRate8430 && discountTypesChoicesFlag === false && (
                      <div className={commonStyles.flex}>
                        <InputLabel
                          label={t('resources.solicitudF2.fields.discountRate8430')}
                          className={commonStyles.row}
                          source={SOLICITUDES_F2_REQUESTS_FIELDS.discountRate8430}
                          type="text"
                          disabled
                        />
                        <p
                          className={cn(f2Styles.percentageDiscounts, f2Styles.percentageAllDiscountsPadding)}
                        >
                          %
                        </p>
                      </div>
                    )}
                    <DialogActions className={f2Styles.actionsContainer}>
                      <CustomButton
                        disabled={loading}
                        onClick={handleSaveClick}
                        autoFocus
                        label={t('general.confirm')}
                      />
                      <CustomButton
                        className={f2Styles.cancelButton}
                        disabled={false}
                        onClick={handleCloseClick}
                        label={t('resources.solicitudF2.modals.modify')}
                      />
                    </DialogActions>
                  </SummaryDiscountModal>
                )}
              </div>
            );
          }}
        </FormDataConsumer>

        {/* Si corresponde VB e inicie algun paso, dispongo del modal */}
        {esEdicion &&
          vbSteps?.correspondeProceso &&
          (vbSteps?.capturaDocumento.iniciado ||
            vbSteps?.capturaManual.iniciado ||
            vbSteps?.pruebaDeVida.iniciado ||
            vbSteps?.validacionIdentidad.iniciado) && (
            <ViewInput label="Validaci&oacute;n biom&eacute;trica" content={<VBField vbSteps={vbSteps} />} />
          )}

        {/* Si corresponde VB y no inicie ningun paso, muestro el logo VB */}
        {esEdicion &&
          vbSteps?.correspondeProceso &&
          !vbSteps?.capturaDocumento.iniciado &&
          !vbSteps?.capturaManual.iniciado &&
          !vbSteps?.pruebaDeVida.iniciado &&
          !vbSteps?.validacionIdentidad.iniciado && (
            <ViewInput
              label="Validaci&oacute;n biom&eacute;trica"
              content={<VBLogo height={100} width={100} />}
            />
          )}

        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => (
            <AutoCompleteDate
              {...formDataProps}
              label={t('resources.affiliations.fields.planValidityDate')}
              className={commonStyles.row}
              source={SOLICITUDES_F2_REQUESTS_FIELDS.planValidityDate}
              validate={[required]}
              minDate={moment(new Date())
                .subtract(DAYS_BEFORE, 'days')
                .toString()}
              isEdit={esEdicion}
              onSetEditing={onSetEditing}
              showErrorOnEdit
              editEnabled={isValidityDateEditable}
              setInvalidPlanValidityDate={setInvalidPlanValidityDate}
              retroactivityDays={DAYS_BEFORE}
            />
          )}
        </FormDataConsumer>

        <InputLabel
          label={t('resources.solicitudF2.fields.promoterId')}
          className={commonStyles.row}
          source={SOLICITUDES_F2_REQUESTS_FIELDS.promoterId}
          validate={[required, numericalDigits, promoterId(t), ...(esEdicion ? [validatePromoter] : [])]}
          type="number"
          isEdit={esEdicion}
          onSetEditing={onSetEditing}
          fieldMessage={formattedPromoter}
          defaultValue={props?.permissions?.id}
          editEnabled={isSuperAdmin}
          tooltipOnView={promoter}
        />
      </SimpleForm>
      {(data || error) && (
        <CustomModal modalName={MODAL_NAMES.DIALOG} {...(data ? { onClose: handleExit } : {})}>
          <FeedbackModalContent
            error={error}
            successMessage={
              esEdicion
                ? t('resources.solicitudF2.modals.form.editSuccess')
                : t('resources.solicitudF2.modals.form.createSuccess').replaceAll(
                    '{applicationId}',
                    data.applicationId
                  )
            }
          />
        </CustomModal>
      )}
    </>
  );
}