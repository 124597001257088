import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'start',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: 100,
    marginBottom: 28,
    maxWidth: '250px',
    width: '100%',
    '& img': {
      display: 'block',
      height: 65,
      marginBottom: 15,
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      width: 120
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '225px'
    }
  },
  title: {
    height: '54px',
    width: '149px',
    color: '#19317E',
    fontSize: '28px',
    letterSpacing: 0,
    lineHeight: '27px'
  },
  titlePaddingTop: {
    paddingTop: '18px'
  }
}));
