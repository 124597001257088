import { makeStyles } from '@material-ui/core';

export const useFilterStyles = makeStyles({
  input: {
    maxWidth: 180,
    marginTop: 25
  }
});

export const useFilterClasses = makeStyles(theme => ({
  form: {
    [theme.breakpoints.only('xs')]: {
      flex: 2,
      flexBasis: 200,
      marginTop: 0,
      minHeight: 0
    }
  }
}));
