/* eslint-disable no-negated-condition */
import React from 'react';
import cn from 'classnames';
import { useTranslate } from 'react-admin';
import { Affiliation } from '~utils/types';

import { useStyles } from './styles';

interface Props {
  application: Affiliation;
}

function InsuranceField({ application }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  const { lifeInsurance: hasInsurance } = application;

  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.point, { hasInsurance })} />
        <p className={styles.label}>{t(`general.${hasInsurance ? 'yes' : 'no'}`)}</p>
      </div>
    </>
  );
}

export default InsuranceField;
