import { getApi as api } from '~config/api';
import { getApiNoSerialization } from '~config/api/indexNoSerialization';
import { returnApiError } from '~config/providers/utils';
import { STATUS_400, STATUS_404, STATUS_503 } from '~constants/affiliations';

export const stateRevision = (id, newState) => api().patch(`/applications/${id}/evaluate`, { newState });

export const editApplication = (id, data) => api().put(`/applications/${id}`, data);

export const updateStateApplication = (id, newState) =>
  api().patch(`/applications/${id}/evaluate`, { newState });

export const getPaymentData = ({ documentNumber, applicationId, promoterId, producto, documentType }) =>
  api().get(
    `/pci/datospago?document_number=${documentNumber}&application_id=${applicationId}&promoter_id=${promoterId}&producto=${producto}&document_type=${documentType}`
  );
export const getPrevious = userDocument =>
  api()
    .get(`/applications/previous/${userDocument}`)
    .then(response => {
      if (response.status === STATUS_400 || response.status === STATUS_503) {
        return { error: 'errors.errorRepeatability' };
      }
      if (response.status === STATUS_404) {
        return { error: 'errors.notFoundRepeatability' };
      }
      return response;
    });

export const questionsApplication = (id, questions) =>
  api()
    .post(`/applications/${id}/questions`, questions)
    .then(response => {
      if (response.ok) {
        return { status: response.status };
      }
      return returnApiError(response);
    });

export const getEmailValidation = email =>
  api()
    .get(`/emailvalidation/${email}`)
    .then(response => {
      if (response.ok) {
        return { status: response.status, ...response };
      }
      return returnApiError(response);
    });

export const cancelApplication = (applicationId, promoterId) =>
  api()
    .put(`/applications/${applicationId}/cancel`, { promoterId })
    .then(response => {
      if (response.ok) {
        return { status: response.status };
      }
      return response;
    });

export const getPartialDataService = applicationId =>
  getApiNoSerialization().get(`/applications/partial/${applicationId}`);

export const savePartialDataService = (applicationId, partialData) =>
  getApiNoSerialization()
    // eslint-disable-next-line @typescript-eslint/camelcase
    .put(`/applications/partial/${applicationId}`, { partial_data: partialData })
    .then(response => {
      if (response.ok) {
        return { status: response.status };
      }
      return response;
    });
