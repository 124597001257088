import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  text: {
    color: PRIMARY_BLUE,
    fontSize: 16,
    marginTop: '10px',
    lineHeight: '18px',
    textAlign: 'center'
  }
});
