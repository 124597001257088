import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_RED } from '~constants/colors';

export const useStyles = makeStyles({
  error: {
    color: PRIMARY_RED,
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'center'
  },
  retryButton: {
    padding: '10px',
    width: '100px'
  }
});
