export const PROMOTER_FIELDS = {
  id: 'id',
  legajo: 'legajo',
  firstName: 'firstName',
  lastName: 'lastName',
  filialCode: 'filialCode',
  filialDescription: 'filialDescription',
  capCode: 'capCode',
  capDescription: 'capDescription',
  role: 'role',
  email: 'email',
  active: 'active',
  subroles: 'subroles',
  permisoOSDE: 'permisoOSDE',
  permisoBinaria: 'permisoBinaria',
  promoterEmail: 'promoter_email',
  phoneNumber: 'phoneNumber'
};

export const PROMOTER_FILTERS = {
  id: 'promoter_id',
  firstName: PROMOTER_FIELDS.firstName,
  lastName: PROMOTER_FIELDS.lastName
};
