import { makeStyles } from '@material-ui/core/styles';
import { WHITE, GRAY, TERTIARY_BLUE, PRIMARY_BLUE, SECONDARY_BLUE, TRANSPARENT } from '~constants/colors';

export const useStyles = makeStyles({
  button: {
    backgroundColor: PRIMARY_BLUE,
    borderRadius: '4px',
    margin: 1,
    padding: '14px 20px',
    textTransform: 'none',
    width: '100%',
    '& span': {
      color: WHITE,
      fontWeight: 500,
      lineHeight: '24px',
      padding: 0
    },
    '&:hover': {
      backgroundColor: TERTIARY_BLUE,
      transform: 'scale(1.03, 1.03)'
    },
    '&:focus': {
      backgroundColor: PRIMARY_BLUE,
      boxShadow: `0 0 4px 0 ${SECONDARY_BLUE}`
    },
    '&:disabled': {
      backgroundColor: GRAY,
      pointerEvents: 'none'
    }
  },
  linkButton: {
    backgroundColor: TRANSPARENT,
    borderRadius: 0,
    margin: 0,
    padding: 0,
    textTransform: 'none',
    width: 'auto',
    '& span': {
      color: PRIMARY_BLUE,
      fontSize: 16
    },
    '&:hover': {
      backgroundColor: TRANSPARENT,
      '& span': {
        textDecoration: 'underline'
      }
    }
  }
});
