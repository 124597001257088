import moment from 'moment';
import { cuit as argCuit } from 'arg.js';

import { DOCUMENT_TYPES, DOCUMENT_TYPES_CUIT } from '../constants/solicitudesON';
import { DOCUMENT_NUMBER_PROPS_ON } from '../constants/validationsOn';

const emailRegex = /^[A-Za-z0-9._-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)+$/;
export const regex = /[^A-Za-z_ÑñÁáÉéÍíÓóÚú\s]+/g;
export const regexAlpha = /[^a-zA-Z0-9]+/g;
export const numericalDigitsRegex = /^([A-Za-z0-9]*)$/;
const phoneRegexOn = /^(?=.{10})\d{10,15}$/;

export const required = value => (value ? undefined : 'validation.required');

export const pattern = (patternVal, errorMessage) => val =>
  val && (patternVal.test(val) ? undefined : errorMessage);

export const numericalDigits = pattern(numericalDigitsRegex, 'validation.numerical');

export const minLength = (length, errorMessage) => val =>
  val && `${val}`.length >= length ? undefined : errorMessage;

export const maxLength = (length, errorMessage) => val =>
  val && `${val}`.length <= length ? undefined : errorMessage;

export const minMaxLength = (min = 1, max, errorMessage) => val =>
  minLength(min, errorMessage)(val) || maxLength(max, errorMessage)(val);

export const email = pattern(emailRegex, 'validation.email');

export const cuilValidation = errorMessage => cuil =>
  DOCUMENT_NUMBER_PROPS_ON[DOCUMENT_TYPES.CUIL].TYPES.every(type => !`${cuil}`.startsWith(type)) &&
  errorMessage;

// const cuitValidation = errorMessage => cuit =>
//   DOCUMENT_NUMBER_PROPS_ON[DOCUMENT_TYPES_CUIT].TYPES.every(type => !`${cuit}`.startsWith(type)) &&
//   errorMessage;

const cuitValidation = errorMessage => cuit => !argCuit.isValid(cuit.toString()) && errorMessage;

export const getDocumentNumberValidationON = ({ t, type }) => [
  type === DOCUMENT_TYPES.DNI &&
    numericalDigits(
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS_ON[type].MIN,
        max: DOCUMENT_NUMBER_PROPS_ON[type].MAX
      })
    ),
  (type === DOCUMENT_TYPES.PASSPORT || type === DOCUMENT_TYPES.DNI) &&
    minMaxLength(
      DOCUMENT_NUMBER_PROPS_ON[type].MIN,
      DOCUMENT_NUMBER_PROPS_ON[type].MAX,
      t('validation.documentNumber', {
        min: DOCUMENT_NUMBER_PROPS_ON[type].MIN,
        max: DOCUMENT_NUMBER_PROPS_ON[type].MAX
      })
    ),
  type === DOCUMENT_TYPES.CUIL &&
    minLength(
      DOCUMENT_NUMBER_PROPS_ON[DOCUMENT_TYPES.CUIL].MIN,
      t('validation.exactDigits', { length: DOCUMENT_NUMBER_PROPS_ON[DOCUMENT_TYPES_CUIT].MIN })
    ),
  type === DOCUMENT_TYPES.CUIL && cuilValidation(t('validation.cuilNumber'))
];
export const getCuitValidationON = ({ t }) => [
  minLength(
    DOCUMENT_NUMBER_PROPS_ON[DOCUMENT_TYPES_CUIT].MIN,
    t('validation.exactLength', { length: DOCUMENT_NUMBER_PROPS_ON[DOCUMENT_TYPES_CUIT].MIN })
  ),
  cuitValidation(t('validation.cuitNumber'))
];
export const phoneValidationOn = pattern(phoneRegexOn, 'validation.phone');

export const validateFutureDate = value =>
  value && moment(value).isSameOrAfter(new Date(), 'd') ? undefined : 'validation.futureDate';
