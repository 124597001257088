import React, { useCallback } from 'react';
import { Button, useTranslate } from 'react-admin';
import { HistoryOutlined } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, useMediaQuery } from '@material-ui/core';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import { momentDateTimeToString } from '~utils/date';
import { DATE_TIME_FORMAT_SHORT } from '~constants/datetime';
import { useDispatch } from '~contexts/ModalContext';

import { useStyles, CUSTOM_ICON_STYLES, CUSTOM_ICON_STYLES_RESPONSIVE } from './styles';

interface Props {
  stateDatetime?: string;
  id: number;
  className?: string;
}

function HistoricSolicitudF2Button({ id, stateDatetime, className }: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleOpenHistoric = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch?.(modalActions.openModal(MODAL_NAMES.HISTORIC_STEPS, { id }));
    },
    [dispatch, id]
  );

  return (
    <div className={className}>
      <Tooltip
        title={`${t('resources.solicitudF2.tooltip')} ${momentDateTimeToString(
          stateDatetime || '',
          DATE_TIME_FORMAT_SHORT
        )}`}
        arrow
      >
        <div>
          <Button onClick={handleOpenHistoric} className={styles.button}>
            <>
              <HistoryOutlined style={isSmall ? CUSTOM_ICON_STYLES_RESPONSIVE : CUSTOM_ICON_STYLES} />
              {isSmall && <span className={styles.historyText}>{t('resources.solicitudF2.history')}</span>}
            </>
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}

export default HistoricSolicitudF2Button;
