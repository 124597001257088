import React, { useCallback, useEffect } from 'react';
import { useTranslate, useMutation } from 'react-admin';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import { GET_HISTORIC_APPLICATION, GET_HISTORIC_APPLICATION_BINARIA } from '~config/providers/constants';
import { momentDateTimeToString } from '~utils/date';
import { DATE_TIME_FORMAT_SHORT } from '~constants/datetime';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import { useDispatch, useSelector } from '~contexts/ModalContext';

import { useStyles } from './styles';

interface HistoricEventOsde {
  datetimeStatus: string;
  id: number;
  responsible: number;
  status: string;
}

interface HistoricEventBinaria {
  usuario: string;
  estado: string;
  fecha: string;
}

interface Props {
  typeApplication?: 'osde' | 'binaria';
}

function HistoricAffiliationModal({ typeApplication = 'osde' }: Props) {
  const t = useTranslate();
  const dispatch = useDispatch();
  const currentAffiliationId = useSelector(state => state.data?.id);
  const styles = useStyles();

  const handleClose = useCallback(() => {
    dispatch?.(modalActions.closeModal(MODAL_NAMES.HISTORIC_STEPS));
  }, [dispatch]);

  const [getHistoric, { data, loading, error }] = useMutation({
    type: typeApplication === 'osde' ? GET_HISTORIC_APPLICATION : GET_HISTORIC_APPLICATION_BINARIA,
    resource: 'get',
    payload: { id: currentAffiliationId }
  });

  useEffect(() => {
    if (currentAffiliationId) {
      getHistoric();
    }
  }, [currentAffiliationId, getHistoric]);

  return (
    <CustomModal
      title={t('resources.affiliations.modals.titleHistorial')}
      modalName={MODAL_NAMES.HISTORIC_STEPS}
      onClose={handleClose}
      isInfo
    >
      <ModalInfoInnerContainer loading={loading} error={error} onRetry={getHistoric}>
        <>
          {typeApplication === 'osde' &&
            data?.map(({ id, responsible, status, datetimeStatus }: HistoricEventOsde) => (
              <div className={`full-width ${styles.infoContainer}`} key={id}>
                <p className={styles.textProspect}>
                  {Number.isInteger(Number(responsible))
                    ? `${t('resources.affiliations.fields.id')}: ${responsible}`
                    : responsible}
                </p>
                <p className={styles.textStatus}>
                  {t(`resources.affiliations.fields.statusTypes.${status}`)}
                </p>
                <p className={styles.textTime}>
                  {momentDateTimeToString(datetimeStatus, DATE_TIME_FORMAT_SHORT)}
                </p>
              </div>
            ))}
          {typeApplication === 'binaria' &&
            data?.map(({ usuario, estado, fecha }: HistoricEventBinaria) => (
              <div className={`full-width ${styles.infoContainer}`} key={fecha}>
                <p className={styles.textProspect}>{usuario}</p>
                <p className={styles.textStatus}>{estado}</p>
                <p className={styles.textTime}>{fecha}</p>
              </div>
            ))}
        </>
      </ModalInfoInnerContainer>
    </CustomModal>
  );
}

export default HistoricAffiliationModal;
