import { SOLICITUDES_REQUEST_STATUS } from '~constants/solicitudesF2';
import { sortAlphabetically } from '~utils/general';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';

import { FILES_UPLOADED, FILES_MIMETYPE } from './constants';

export const getEvaluatePayload = (data?: { id?: number; newState?: string }) => ({
  id: data?.id,
  newState: data?.newState
});

export const getFileName = (url: string, t: Function, index?: number) => {
  const cleanUrl = url.split(/[?#]/)[0];
  const result = cleanUrl.substring(cleanUrl.lastIndexOf('/')).replace('/', '');
  if (index !== undefined) {
    return result.split('-')[0];
  }
  return result;
};

type AttachmentType = Array<{ url: string; name: string }>;

const getSonIndex = (who: string, sons: AttachmentType) =>
  sons.filter(attachment => attachment.name.includes(who)).length + 1;

export const getCleanFileName = (url: string) => {
  const cleanUrl = url.split(/[?#]/)[0];
  return decodeURI(cleanUrl.substring(cleanUrl.lastIndexOf('/')).replace('/', ''));
};

export const setFileName = (fileUrl: string) => {
  let name = '';

  if (fileUrl.includes(FILES_UPLOADED.F2)) {
    name = FILES_MIMETYPE.F2;
  }
  if (fileUrl.includes(FILES_UPLOADED.AFIP)) {
    name = FILES_MIMETYPE.AFIP;
  }
  if (fileUrl.includes(FILES_UPLOADED.DNI)) {
    name = FILES_MIMETYPE.DNI;
  }
  if (fileUrl.includes(FILES_UPLOADED.ESTATUTO)) {
    name = FILES_MIMETYPE.ESTATUTO;
  }
  if (fileUrl.includes(FILES_UPLOADED.TITULAR)) {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1, fileUrl.indexOf('?'));
    name = fileName.replace('titular', 'responsable');
  }
  if (fileUrl.includes(FILES_UPLOADED.ROSTRO)) {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1, fileUrl.indexOf('?'));
    name = fileName.replace('titular', 'responsable');
  }
  if (fileUrl.includes(FILES_UPLOADED.FRENTE)) {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1, fileUrl.indexOf('?'));
    name = fileName.replace('titular', 'responsable');
  }
  if (fileUrl.includes(FILES_UPLOADED.DORSO)) {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1, fileUrl.indexOf('?'));
    name = fileName.replace('titular', 'responsable');
  }
  return name;
};

export const getDocumentsF2Form = (urls: any) =>
  urls
    ?.filter(
      (c: any) =>
        !c.includes(FILES_UPLOADED.TyC) &&
        !c.includes(FILES_UPLOADED.APODERADO) &&
        !c.includes(FILES_UPLOADED.FORM) &&
        !c.includes(FILES_UPLOADED.TITULAR) &&
        !c.includes(FILES_UPLOADED.ROSTRO) &&
        !c.includes(FILES_UPLOADED.FRENTE) &&
        !c.includes(FILES_UPLOADED.DORSO)
    )
    .map((element: any) => ({ url: element, name: setFileName(element) }));

export const getDocumentsF2FormVB = (urls: any) =>
  urls
    ?.filter(
      (c: any) =>
        !c.includes(FILES_UPLOADED.TyC) &&
        !c.includes(FILES_UPLOADED.APODERADO) &&
        !c.includes(FILES_UPLOADED.FORM) &&
        !c.includes(FILES_UPLOADED.ESTATUTO) &&
        !c.includes(FILES_UPLOADED.AFIP) &&
        !c.includes(FILES_UPLOADED.F2)
    )
    .map((element: any) => ({ url: element, name: setFileName(element) }));

export const getDocumentsUrlOrder = (key: string, type?: string, urls?: Array<any>) =>
  urls?.filter((c: any) => c.includes(key)).map((element: any) => ({ url: element, name: type }));
// TODO : borrar
export const getOrderedAttachments = (attachments: string[], t: Function): AttachmentType => {
  const holder: AttachmentType = [];
  const couple: AttachmentType = [];
  const sons: AttachmentType = [];
  const holderText = t('general.holder').toLowerCase();
  const coupleText = t('general.couple').toLowerCase();
  attachments.forEach((item: string) => {
    const cleanName = getCleanFileName(item);
    const who = cleanName.split('-')[0];
    const extension = cleanName.substring(cleanName.lastIndexOf('.'));
    const result = { url: item };
    if (who === holderText) {
      holder.push({
        ...result,
        name: `${holderText}-${t('general.document').toLowerCase()}-${holder.length + 1}${extension}`
      });
    } else if (who === coupleText) {
      couple.push({
        ...result,
        name: `${coupleText}-${t('general.document').toLowerCase()}-${couple.length + 1}${extension}`
      });
    } else {
      sons.push({
        ...result,
        name: `${who}-${t('general.document').toLowerCase()}-${getSonIndex(who, sons)}${extension}`
      });
    }
  });

  return [...holder, ...couple, ...sons.sort(sortAlphabetically('name'))];
};

export const shouldShowPotentialRiskField = (status: string) =>
  status === SOLICITUDES_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION.COD ||
  status === SOLICITUDES_REQUEST_STATUS.PENDING_USER_VALIDATION.COD;

export const isAffilliationFilled = (status: string) =>
  status === AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION ||
  status === AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION ||
  status === AFFILIATION_REQUEST_STATUS.FINISHED ||
  status === AFFILIATION_REQUEST_STATUS.REVISION ||
  status === AFFILIATION_REQUEST_STATUS.USER_REJECTED ||
  status === AFFILIATION_REQUEST_STATUS.EXPIRED ||
  status === AFFILIATION_REQUEST_STATUS.SAP ||
  status === AFFILIATION_REQUEST_STATUS.SAP_RPA ||
  status === AFFILIATION_REQUEST_STATUS.CANCELED;

export const showFiles = (state: string) =>
  state === AFFILIATION_REQUEST_STATUS.CANCELED ||
  state === AFFILIATION_REQUEST_STATUS.FINISHED ||
  state === AFFILIATION_REQUEST_STATUS.EXPIRED;
