import React, { useEffect, useState } from 'react';
import { TextInput, SelectInput } from 'react-admin';
import { descripcionStates } from '~constants/binaria';
import CustomFilter from '~components/CustomFilter';
import { ErrorResponse } from '~utils/types';
import { getApi as api } from '~config/api';
import { useLocation } from 'react-router-dom';

export default function SolicitudBinariaFilter(props: object) {
  const location = useLocation();
  const isBinaria = location.pathname === '/binaria';
  const statusTypes = Array.from(descripcionStates, ([status, descripcion]) => ({
    id: status,
    name: descripcion
  }));
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    let isSubscribe = true;
    if (isBinaria && isSubscribe) {
      const fetchProductos = async () => {
        const result = await api()
          .get<any, ErrorResponse>('binaria/listas', {
            codigoLista: 30
          })
          .then((response: any) => response.data);
        setProductos(result?.map((producto: any) => ({ id: producto.codigo, name: producto.valor })));
      };
      fetchProductos();
    }
    return () => {
      isSubscribe = false;
    };
  }, [isBinaria]);

  return (
    <CustomFilter {...props}>
      <TextInput label="Promotor" source="promoterId" />
      <TextInput label="Solicitud" source="id" />
      <TextInput label="Nombre" source="firstname" />
      <TextInput label="Apellido" source="lastname" />
      <TextInput label="Documento" source="dni" />
      <SelectInput label="Estado" source="state" choices={statusTypes} />
      <SelectInput label="Producto" source="productId" choices={productos} />
    </CustomFilter>
  );
}
