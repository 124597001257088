import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, WHITE, PRIMARY_RED, PRIMARY_RED_OPACITY } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  button: {
    height: '48px',
    '& span': {
      fontSize: 17,
      lineHeight: '24px'
    }
  },
  buttonFinished: {
    marginBottom: 10
  },
  buttonRevision: {
    border: `1px solid ${PRIMARY_BLUE}`,
    backgroundColor: WHITE,
    '&:hover, &:focus': {
      backgroundColor: WHITE
    },
    '& span': {
      color: PRIMARY_BLUE
    },
    marginBottom: 10
  },
  buttonCanceled: {
    border: `1px solid ${PRIMARY_RED}`,
    backgroundColor: PRIMARY_RED,
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_RED_OPACITY,
      '& span': {
        color: WHITE
      }
    },
    '& span': {
      color: WHITE
    }
  },
  separation: {
    marginBottom: '40px'
  },
  spacing: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px'
    }
  }
}));
