import React from 'react';
import { AffiliationRequest } from '~utils/types';
import { Tooltip } from '@material-ui/core';

import { useStyles } from './styles';

const TooltipPromoterName = (affiliation: AffiliationRequest) => {
  const { promoter, promoterId } = affiliation || {};
  const { firstName, lastName } = promoter || {};
  const styles = useStyles();

  return (
    <Tooltip title={`${firstName} ${lastName}`}>
      <span className={styles.promoterContent}>{promoterId}</span>
    </Tooltip>
  );
};

export default TooltipPromoterName;
