import { makeStyles } from '@material-ui/core/styles';
import { LIGHT_GRAY, GRAY, DARK_GRAY } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  container: {
    borderBottom: `1px solid ${LIGHT_GRAY}`
  },
  text: {
    color: GRAY
  },
  modalTitle: {
    color: DARK_GRAY,
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '21px'
  },
  modalSubtitle: {
    color: DARK_GRAY,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center'
  },
  errorDisplay: {
    margin: '5px',
    padding: '5px',
    width: '400px',
    height: '200px',
    overflowY: 'scroll',
    textAlign: 'justify',
    [theme.breakpoints.only('xs')]: {
      width: '200px'
    }
  }
}));
