import React from 'react';

interface Props {
  checked: boolean;
  columnClassName?: string;
  disabled?: boolean;
  error?: string;
  errorClassName?: string;
  inputClassName?: string;
  label?: string;
  value?: any;
  labelClassName?: string;
  name: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onLabelClick?: (event: React.MouseEvent<HTMLElement>) => void;
  required?: boolean;
}

function Checkbox({
  checked,
  columnClassName = '',
  disabled = false,
  error = '',
  errorClassName = '',
  inputClassName = '',
  label = '',
  value = null,
  labelClassName = '',
  name,
  onChange,
  onLabelClick,
  required = false
}: Props) {
  // const onLabelClick = (e: React.MouseEvent<HTMLElement>) => e.preventDefault();
  return (
    <div className={`column start ${columnClassName}`}>
      <div>
        <input
          className={inputClassName}
          type="checkbox"
          id={label}
          name={name}
          value={value || label}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
        {label && (
          <label onClick={onLabelClick} className={labelClassName} htmlFor={label}>
            {label}
          </label>
        )}
      </div>
      {error && <label className={`text-error ${errorClassName}`}>{error}</label>}
    </div>
  );
}

export default Checkbox;
