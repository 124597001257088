import { makeStyles } from '@material-ui/core/styles';
import { AFFILIATION_REQUEST_STATUS } from '~constants/affiliations';
import {
  PRIMARY_BLUE,
  SECONDARY_BLUE,
  TERTIARY_BLUE,
  QUATERNARY_BLUE,
  PRIMARY_GREEN,
  SECONDARY_GREEN,
  PRIMARY_ORANGE,
  SECONDARY_ORANGE,
  GRAY,
  LIGHT_GRAY,
  SECONDARY_YELLOW,
  PRIMARY_YELLOW,
  PRIMARY_RED,
  SECONDARY_RED,
  BLACK,
  WHITE
} from '~constants/colors';

export const useStyles = makeStyles({
  label: {
    borderRadius: '2px',
    maxWidth: '250px',
    textAlign: 'center',
    padding: '12px 20px',
    '& span': {
      color: PRIMARY_BLUE,
      fontSize: 14,
      lineHeight: '19px'
    },
    '&.rounded': {
      borderRadius: '20px',
      padding: '10px 12px'
    }
  },
  [AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING]: {
    backgroundColor: LIGHT_GRAY,
    border: `1px solid ${GRAY}`,
    '& span': {
      color: GRAY
    }
  },
  [AFFILIATION_REQUEST_STATUS.FORM_LINK_OPENED]: {
    backgroundColor: SECONDARY_BLUE,
    border: `1px solid ${PRIMARY_BLUE}`,
    '& span': {
      color: PRIMARY_BLUE
    }
  },
  [AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT]: {
    backgroundColor: QUATERNARY_BLUE,
    border: `1px solid ${TERTIARY_BLUE}`,
    '& span': {
      color: TERTIARY_BLUE
    }
  },
  [AFFILIATION_REQUEST_STATUS.PENDING_USER_VALIDATION]: {
    backgroundColor: SECONDARY_ORANGE,
    border: `1px solid ${PRIMARY_ORANGE}`,
    '& span': {
      color: PRIMARY_ORANGE
    }
  },
  [AFFILIATION_REQUEST_STATUS.PENDING_PROMOTER_VALIDATION]: {
    backgroundColor: SECONDARY_YELLOW,
    border: `1px solid ${PRIMARY_YELLOW}`,
    '& span': {
      color: PRIMARY_YELLOW
    }
  },
  [AFFILIATION_REQUEST_STATUS.FINISHED]: {
    backgroundColor: SECONDARY_GREEN,
    border: `1px solid ${PRIMARY_GREEN}`,
    '& span': {
      color: PRIMARY_GREEN
    }
  },
  [AFFILIATION_REQUEST_STATUS.SAP]: {
    backgroundColor: SECONDARY_GREEN,
    border: `1px solid ${PRIMARY_GREEN}`,
    '& span': {
      color: PRIMARY_GREEN
    }
  },
  [AFFILIATION_REQUEST_STATUS.SAP_RPA]: {
    backgroundColor: SECONDARY_ORANGE,
    border: `1px solid ${PRIMARY_ORANGE}`,
    '& span': {
      color: PRIMARY_ORANGE
    }
  },
  [AFFILIATION_REQUEST_STATUS.REVISION]: {
    backgroundColor: SECONDARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: PRIMARY_RED
    }
  },
  [AFFILIATION_REQUEST_STATUS.USER_REJECTED]: {
    backgroundColor: SECONDARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: PRIMARY_RED
    }
  },
  [AFFILIATION_REQUEST_STATUS.EXPIRED]: {
    backgroundColor: LIGHT_GRAY,
    border: `1px solid ${BLACK}`,
    '& span': {
      color: BLACK
    }
  },
  [AFFILIATION_REQUEST_STATUS.CANCELED]: {
    backgroundColor: PRIMARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: WHITE
    }
  },
  [AFFILIATION_REQUEST_STATUS.ZCAR]: {
    backgroundColor: '#F5E4E4',
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: PRIMARY_BLUE
    }
  },
  [AFFILIATION_REQUEST_STATUS.ENABLED]: {
    backgroundColor: SECONDARY_BLUE,
    border: `1px solid ${PRIMARY_BLUE}`,
    '& span': {
      color: PRIMARY_BLUE
    }
  }
});
