import React, { useCallback, useEffect } from 'react';
import { useTranslate, useMutation } from 'react-admin';
import { GET_DETALLE_MASSIVE_UPLOAD } from '~config/providers/constants';
import { useCommonStyles } from '~app/styles';
import DetailActionButtons from '~components/DetailActionButtons';

import TablaDetalles from '../componets/TableDetalle';

function AltaMasivasDetail(props: any) {
  const commonStyles = useCommonStyles();
  const t = useTranslate();
  const { id } = props;
  const [mutate, { data }] = useMutation();

  const getDetalle = useCallback(() => {
    mutate({
      type: GET_DETALLE_MASSIVE_UPLOAD,
      resource: 'get',
      payload: id
    });
  }, [id, mutate]);

  useEffect(() => {
    getDetalle();
  }, [id, getDetalle]);

  return (
    <>
      <DetailActionButtons />
      <h1 className={commonStyles.title}>{t('resources.altasmasivas.details')}</h1>
      {data && <TablaDetalles data={data} />}
    </>
  );
}

export default AltaMasivasDetail;
