import { STATES, descripcionForms, descripcionProductos, descripcionTipoProductos } from '~constants/binaria';

const estadosFinalizados = [
  STATES.PENDING_PROMOTER_VALIDATION,
  STATES.FINISHED,
  STATES.REVISION,
  STATES.EXPIRED
];

const initialEmailStates = [STATES.INITIAL_EMAIL_SENT, STATES.INITIAL_EMAIL_PENDING, STATES.FORM_LINK_OPENED];
const beforeOpenLinkStates = [STATES.INITIAL_EMAIL_SENT, STATES.INITIAL_EMAIL_PENDING];

export const evaluatePayload = (data?: { id?: number; newState?: string }) => ({
  id: data?.id,
  newState: data?.newState
});

export function isPendienteProm(estado: string) {
  return estado === STATES.PENDING_PROMOTER_VALIDATION || estado === STATES.REVISION;
}

export function isPendienteProspect(estado: string) {
  return estado === STATES.PENDING_PROSPECT_VALIDATION;
}

export function isCompleta(estado: string) {
  return estadosFinalizados.includes(estado);
}

export function canSendInitialEmail(estado: string) {
  return initialEmailStates.includes(estado);
}

export function linkFueAbierto(estado: any) {
  return beforeOpenLinkStates.includes(estado);
}

export function getEtiquetas(estado: string) {
  if (estado === STATES.INITIAL_EMAIL_PENDING) {
    return {
      label: 'Enviar e-mail de inicio',
      labelShort: 'Enviar',
      title: 'Click para enviar e-mail de Inicio',
      modalMsg: 'Estás por enviar el e-mail para iniciar el proceso de afiliación.'
    };
  }
  return {
    label: 'Reenviar e-mail de inicio',
    labelShort: 'Reenviar',
    title: 'Click para reenviar e-mail de inicio',
    modalMsg: 'Estás por reenviar el e-mail para iniciar el proceso de afiliación.'
  };
}

export function getMessageModal(estado: string) {
  if (estado === STATES.INITIAL_EMAIL_PENDING) {
    return 'Estás por enviar el e-mail para iniciar el proceso de afiliación.';
  }
  return 'Estás por reenviar el e-mail para iniciar el proceso de afiliación.';
}

export const productNameDescription = (key: string) => descripcionProductos.get(key);

export const productTypeDescription = (key: string) => descripcionTipoProductos.get(key);

export const formNameDescription = (key: string) => descripcionForms.get(key);
