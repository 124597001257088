import React from 'react';
import { useTranslate } from 'react-admin';
import { useStyles } from '~components/ViewInput/styles';
import FamillyMemberField from '~screens/SolicitudesON/components/FamillyMemberField';
import { FamilyMember } from '~utils/types';

interface Props {
  familyData: FamilyMember[];
  isEdit?: boolean;
}

function FamilyGroup({ familyData, isEdit }: Props) {
  const viewInputStyles = useStyles();
  const t = useTranslate();
  let childCount = 0;

  return isEdit && familyData ? (
    <div className={`column ${viewInputStyles.container}`}>
      <span className={`full-width ${viewInputStyles.label}`}>
        {t('resources.on.familyGroup.familyGroup')}
      </span>
      {familyData.map(fMember => (
        <FamillyMemberField
          key={`${fMember.member}-${fMember.cuil}`}
          member={fMember}
          indexForExtraChild={() => ++childCount}
        />
      ))}
    </div>
  ) : null;
}

export default FamilyGroup;
