import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_RED } from '~constants/colors';

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  point: {
    backgroundColor: PRIMARY_RED,
    borderRadius: '6px',
    height: '12px',
    marginRight: '8px',
    marginLeft: '8px',
    width: '12px'
  },
});
