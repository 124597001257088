import React from 'react';
import { useTranslate, TextField, FunctionField } from 'react-admin';
import CustomTable from '~components/CustomTable';
import WithProps from '~components/WithProps';
import TooltipPromoterName from '~screens/AffiliationRequests/screens/AffiliationRequestsList/components/TooltipPromoterName';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import AltasMasivasFilters from '../componets/AltasListFilter';
import ResponsiveList from '../componets/ResponsiveList';
import { ALTAS_MASIVAS_FIELDS } from '../constants';
import StatusField from '../componets/StatusField';

import { useStyles } from './styles';

interface Props {
  resource: string;
}

function AltaMasivasList({ resource, ...props }: Props) {
  const t = useTranslate();
  const styles = useStyles();
  return (
    <WithProps {...{ resource, ...props }}>
      {(withProps: Props) => (
        <>
          <CustomTable
            {...withProps}
            title={t('resources.altasmasivas.listName')}
            showExport
            showDetail
            filters={<AltasMasivasFilters />}
            listComponent={ResponsiveList}
          >
            <TextField label={t('resources.altasmasivas.fields.id')} source={ALTAS_MASIVAS_FIELDS.id} />
            <FunctionField
              label={t('resources.altasmasivas.fields.promotor')}
              source={ALTAS_MASIVAS_FIELDS.idPromotor}
              render={(record: any) => (
                <Tooltip title={`${record.promoterFirstName} ${record.promoterLastName}`}>
                  <span className={styles.promoterContent}>{`${record.idPromotor}`}</span>
                </Tooltip>
              )}
            />
            <TextField label={t('resources.altasmasivas.fields.name')} source={ALTAS_MASIVAS_FIELDS.nombre} />
            <FunctionField label={t('resources.altasmasivas.fields.estado')} render={StatusField} />
            <TextField
              label={t('resources.altasmasivas.fields.cantAltas')}
              source={ALTAS_MASIVAS_FIELDS.altas}
            />
            <TextField
              label={t('resources.altasmasivas.fields.cantOk')}
              source={ALTAS_MASIVAS_FIELDS.procesadosOk}
            />
            <TextField
              label={t('resources.altasmasivas.fields.cantError')}
              source={ALTAS_MASIVAS_FIELDS.procesadosError}
            />
          </CustomTable>
        </>
      )}
    </WithProps>
  );
}

export default AltaMasivasList;
