/* eslint-disable @typescript-eslint/camelcase */
import moment from 'moment';
import { isInitialMailPending, isInitialMailSent } from '~screens/SolicitudesF2/utils';

export const denormalizeSolicitudF2 = (values: any, isEdit = false) => {
  const {
    promoterId,
    isSuperAdmin,
    state,
    companyName,
    cuit,
    planValidityDate,
    planType,
    applyDiscountAllPlans,
    discountRate8260,
    discountRate8360,
    discountRate8430
  } = values;

  const { firstName, lastName, documentNumber, documentType, phoneNumber, email } = values.attorney;

  function notEditReturn() {
    return {
      application: {
        companyName,
        cuit,
        planValidityDate: moment(planValidityDate).toISOString(),
        planType,
        applyDiscountAllPlans:
          applyDiscountAllPlans === 'undefined' ? true : applyDiscountAllPlans === 'true',
        discountRate_8260: discountRate8260,
        discountRate_8360: discountRate8360,
        discountRate_8430: discountRate8430,
        ...(isSuperAdmin && { promoterId })
      },
      attorney: {
        firstName,
        lastName,
        email,
        ...(phoneNumber && { phoneNumber: `${phoneNumber}` }),
        documentType,
        documentNumber: `${documentNumber}`
      }
    };
  }
  function isEditReturn() {
    return {
      application: {
        ...(isInitialMailPending(state) && {
          companyName,
          cuit,
          planValidityDate: moment(planValidityDate).toISOString(),
          planType,
          applyDiscountAllPlans:
            applyDiscountAllPlans === 'undefined' ? true : applyDiscountAllPlans === 'true',
          discountRate_8260: discountRate8260,
          discountRate_8360: discountRate8360,
          discountRate_8430: discountRate8430
        }),
        ...(isInitialMailSent(state) && {
          planValidityDate: moment(planValidityDate).toISOString()
        }),
        ...(isSuperAdmin && { promoterId })
      },
      attorney: {
        firstName,
        lastName,
        email,
        ...(phoneNumber ? { phoneNumber: `${phoneNumber}` } : { phoneNumber: '' }),
        documentType,
        documentNumber: `${documentNumber}`
      }
    };
  }
  if (isEdit) {
    return isEditReturn();
  }
  return notEditReturn();
};
