import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_BLUE, EXTRA_LIGHT_GRAY } from '~constants/colors';

export const useStyles = makeStyles(theme => ({
  riskContainer: {
    '& *': { color: PRIMARY_BLUE }
  },
  textType: {
    marginBottom: '30px',
    '&, & span': {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '28px'
    },
    '& span': { fontWeight: 500 },
    [theme.breakpoints.only('xs')]: {
      marginBottom: '15px',
      '&, & span': {
        fontSize: 16,
        lineHeight: '20px'
      }
    }
  },
  reasonTextContainer: {
    borderColor: EXTRA_LIGHT_GRAY,
    borderStyle: 'solid',
    borderWidth: '1px 0 1px 0',
    marginBottom: '40px',
    padding: '28px 0',
    [theme.breakpoints.only('xs')]: {
      marginBottom: '20px',
      padding: '18px 0'
    }
  },
  textReasons: {
    fontSize: 16,
    lineHeight: '19px',
    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  }
}));
