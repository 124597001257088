import React from 'react';
import { CreateButton, useTranslate } from 'react-admin';
import { useCommonStyles } from '~app/styles';

import { useStyles } from './styles';

export interface EmptyTableProps {
  basePath: string;
  resource: string;
}

function EmptyTable({ basePath, resource }: EmptyTableProps) {
  const t = useTranslate();
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h1 className={`${commonStyles.title} ${styles.title}`}>
        {t('resources.emptyList.title', { resource: t(`resources.${resource}.name`).toLowerCase() })}
      </h1>
      <CreateButton className={styles.button} basePath={basePath} />
    </div>
  );
}

export default EmptyTable;
