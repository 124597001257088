import { GenericObjectInterface } from './types';

export const getObjectValueFromString = (str: string, obj: GenericObjectInterface<any>): object | string =>
  str.split('.').reduce((o, key) => (o ? o[key] : null), obj);

export const setObjectValueFromString = (
  str: string,
  obj: GenericObjectInterface<any>,
  newValue?: string
) => {
  const fieldPath = str.split('.');
  // obtener el penúltimo objeto
  const propertyContainer = fieldPath.slice(0, -1).reduce((o, key) => (o ? o[key] : null), obj);
  propertyContainer[fieldPath[fieldPath.length - 1]] = newValue;
};
