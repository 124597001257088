import React from 'react';
import { TablePassing } from '~utils/types';

type Props = Omit<TablePassing, 'selectable'>;

function DataList({ listComponent: ListComponent, data = {}, basePath, ids, showDetail }: Props) {
  return (
    <div className="column">
      {!!ids?.length &&
        ids.map(id =>
          showDetail ? (
            <ListComponent record={data[id]} {...data[id]} basePath={basePath} id={id}/>
          ) : (
            <ListComponent {...data[id]} />
          )
        )}
    </div>
  );  
}

export default DataList;
