import React from 'react';
import { useTranslate, TextInput, SelectInput } from 'react-admin';
import { ALTAS_LIST_FILTER, ALTAS_MASIVAS_FIELDS, ALTA_MASIVA_STATUS } from '~screens/AltasMasivas/constants';
import CustomFilter from '~components/CustomFilter';

function AltasMasivasFilters(props: object) {
  const t = useTranslate();
  const affiliationStatusTypes = Object.values(ALTA_MASIVA_STATUS).map(type => ({
    id: type,
    name: t(`resources.altasmasivas.statusTypes.${type}`)
  }));

  return (
    <CustomFilter {...props}>
      <TextInput label={t('resources.altasmasivas.fields.id')} source={ALTAS_LIST_FILTER.id} />
      <TextInput label={t('resources.altasmasivas.fields.promotor')} source={ALTAS_LIST_FILTER.promoterId} />
      <TextInput label={t('resources.altasmasivas.fields.name')} source={ALTAS_LIST_FILTER.nombreEmpresa} />
      <SelectInput
        source={ALTAS_MASIVAS_FIELDS.estado}
        choices={affiliationStatusTypes}
        label={t('resources.affiliations.fields.status')}
      />
    </CustomFilter>
  );
}

export default AltasMasivasFilters;
