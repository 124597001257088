import React, { useEffect } from 'react';
import { useTranslate, useMutation } from 'react-admin';
import { RejectedReason, Affiliation } from '~utils/types';
import ModalInfoInnerContainer from '~components/ModalInfoInnerContainer';
import { GET_REJECTED_REASONS } from '~config/providers/constants';

import { orderRiskData } from './utils';
import { useStyles } from './styles';

interface Props {
  application: Affiliation;
}

function PotentialRiskModal({ application }: Props) {
  const styles = useStyles();
  const t = useTranslate();
  const { id, familyGroupDetails } = application;
  const [getRejectedReasons, { data, loading, error }] = useMutation({
    type: GET_REJECTED_REASONS,
    resource: 'get',
    payload: { id }
  });

  useEffect(() => {
    getRejectedReasons();
  }, [getRejectedReasons]);

  const riskData = !loading && !error && data ? orderRiskData(data, familyGroupDetails || [], t) : [];

  return (
    <ModalInfoInnerContainer loading={loading} error={error} onRetry={getRejectedReasons}>
      {riskData?.map(({ reasons, type, age }: RejectedReason) =>
        reasons.length ? (
          <div className={styles.riskContainer} key={type}>
            <p className={styles.textType}>
              {type}: <span>{t('resources.affiliations.familyGroup.age', { age })}</span>
            </p>
            <div className={`full-width ${styles.reasonTextContainer}`}>
              <p className={styles.textReasons}>
                {t('resources.affiliations.potentialRisk.riskText', {
                  reasons: reasons.join(', ')
                })}
              </p>
            </div>
          </div>
        ) : null
      )}
    </ModalInfoInnerContainer>
  );
}

export default PotentialRiskModal;
