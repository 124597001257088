import { makeStyles } from '@material-ui/core/styles';
import {
  PRIMARY_BLUE,
  SECONDARY_BLUE,
  GRAY,
  LIGHT_GRAY,
  SECONDARY_RED,
  PRIMARY_RED
} from '~constants/colors';

import { ALTA_MASIVA_DETALLE_STATUS } from '../../constants';

export const useStyles = makeStyles({
  label: {
    borderRadius: '2px',
    maxWidth: '120px',
    textAlign: 'center',
    padding: '12px 20px',
    '& span': {
      color: PRIMARY_BLUE,
      fontSize: 14,
      lineHeight: '19px'
    },
    '&.rounded': {
      borderRadius: '20px',
      padding: '10px 12px'
    }
  },
  [ALTA_MASIVA_DETALLE_STATUS.ERROR]: {
    backgroundColor: SECONDARY_RED,
    border: `1px solid ${PRIMARY_RED}`,
    '& span': {
      color: PRIMARY_RED
    }
  },
  [ALTA_MASIVA_DETALLE_STATUS.OK]: {
    backgroundColor: SECONDARY_BLUE,
    border: `1px solid ${PRIMARY_BLUE}`,
    '& span': {
      color: PRIMARY_BLUE
    }
  },
  [ALTA_MASIVA_DETALLE_STATUS.SIN_PROCESAR]: {
    backgroundColor: LIGHT_GRAY,
    border: `1px solid ${GRAY}`,
    '& span': {
      color: GRAY
    }
  }
});
