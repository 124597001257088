import React from 'react';
import ResponsiveListWrapper from '~components/ResponsiveListWrapper';
import { useResponsiveListStyles } from '~components/ResponsiveListWrapper/styles';
import ViewInput from '~components/ViewInput';
import { useTranslate } from 'react-admin';
import StatusField from '~screens/SolicitudesON/components/StatusField';
import { EntryChannel, SolicitudONRequest } from '~utils/types';

import HistoricSolicitudONButton from '../HistoricSolicitudONButton';

function ResponsiveList(record: SolicitudONRequest) {
  const styles = useResponsiveListStyles();
  const t = useTranslate();
  const isAutogestion = record.entryChannel === EntryChannel.AUTOGESTION;
  return (
    <ResponsiveListWrapper>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label="Nombre"
          valueClassName={styles.value}
          value={`${record.user.firstName} ${record.user.lastName}`}
          className={styles.columnLarge}
        />
        <ViewInput
          labelClassName={styles.label}
          label="ID"
          valueClassName={styles.value}
          value={`${record.id}`}
          className={styles.columnShort}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          labelClassName={styles.label}
          label="Tipo de Socio"
          valueClassName={styles.value}
          value={
            isAutogestion
              ? t(`resources.on.fields.affiliationTypesAutogestion.${record.affiliationType}`)
              : t(`resources.on.fields.affiliationTypes.${record.affiliationType}`)
          }
          className={styles.columnLarge}
        />
        <ViewInput
          labelClassName={styles.label}
          label="Documento"
          valueClassName={styles.value}
          value={record.user.documentNumber}
          className={styles.columnShort}
        />
      </div>
      <div className={styles.row}>
        <ViewInput
          className={styles.columnLarge}
          labelClassName={styles.label}
          valueClassName={styles.value}
          label="Estado"
          content={<StatusField labelClassName={styles.maxContent} state={record.state} rounded={false} />}
        />
        <HistoricSolicitudONButton
          className={`${styles.columnShort} ${styles.middle}`}
          stateDatetime={record.stateDatetime}
          id={record.id}
        />
      </div>
    </ResponsiveListWrapper>
  );
}

export default ResponsiveList;
