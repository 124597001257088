/* eslint-disable max-lines */
/* eslint-disable import/named */
/* eslint-disable id-length */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-negated-condition */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { SimpleForm, useTranslate, FormDataConsumer, useMutation, regex, useNotify } from 'react-admin';
import { useHistory } from 'react-router';
import InputLabel from '~components/InputLabel';
import { useFormStyles, useCommonStyles } from '~app/styles';
import { AFFILIATION_REQUESTS_FIELDS } from '~screens/AffiliationRequests/constants';
import {
  DOCUMENT_TYPES,
  AFFILIATION_TYPES,
  APPLICATION_TYPES,
  AFFILIATION_REQUEST_STATUS,
  COMPANY_PLAN_TYPES,
  HIRING_MODE_TYPES,
  INCREASE_PLAN_ALLOWED_TYPES,
  IS_PAYMENT_RESPONSIBLE_TYPES,
  PLAN_TYPES,
  APPLICATION_SUBTYPES,
  // DNI_OF_CUIL,
  // DNI_OF_CUIL_LENGTH,
  STATUS_200,
  CORPORATIVE_PLAN_TYPES
} from '~constants/affiliations';
import { CUIT_MIN_LENGTH, INPUT_NUMBER_PROPS } from '~constants/validations';
import { actionCreators as modalActions } from '~contexts/ModalContext/reducer';
import Toolbar from '~components/Toolbar';
import { MODAL_NAMES } from '~contexts/ModalContext/constants';
import CustomModal from '~components/CustomModal';
import FeedbackModalContent from '~components/FeedbackModalContent';
import {
  required as requiredValidator,
  getDocumentNumberValidation,
  numericalDigits,
  match,
  promoterId,
  validateFutureDate,
  validate60DaysBefore,
  maxLengthOptional,
  phoneDigits,
  email,
  validacionCUIT,
  minLength
} from '~utils/inputValidations';
// eslint-disable-next-line import/namespace
import { AffiliationRequest, Nullable } from '~utils/types';
import { IVBSteps } from '~utils/interfaces';
import {
  RESOURCES_PATH,
  CREATE_APPLICATION,
  EDIT_APPLICATION,
  GET_ONE_PROMOTER_LIST,
  GET_DATOS_BENEFICIARIO
} from '~config/providers/constants';
import InputSelect from '~components/InputSelect';
import { getObjectValueFromString } from '~utils/object';
import { useDispatch, useSelector } from '~contexts/ModalContext';
import { getIsSuperAdmin } from '~utils/general';
import dataProvider from '~config/providers/dataProvider';
import moment from 'moment';
import { ReactComponent as Sap } from '~assets/logo_sap.svg';
import { ReactComponent as VBLogo } from '~assets/logo_sid.svg';
import ViewInput from '~components/ViewInput';
import useListProviders from '~config/hooks/useListProviders';
import AutoCompleteDate from '~components/AutoCompleteDate';
import WithProps from '~components/WithProps';
import { getOrderedAttachments } from '~utils/attachmentFiles';
import { getPrevious, getEmailValidation } from '~services/OsdeService';
import { ROLE_TYPES } from '~constants/promoters';

import CreateBeneficiaryModal from '../CreateBeneficiaryModal/CreateBeneficiaryModal';
import VBField from '../VBField';
import AffiliationUploadFileBc from '../../screens/AffiliationRequestShow/components/AffiliationUploadFileBc';
import RepeatabilityModal from '../RepeatabilityModal';

import {
  getAffiliationType,
  getDocumentType,
  getApplicationType,
  getHiringModeTypes,
  getPlanType,
  getIncreasePlanAllowedType,
  getIsPaymentResponsibleType,
  getCompanyPlanType,
  getCorporativePlanType,
  getApplicationSubtype
} from './utils';
import FamilyGroup from './components/FamilyGroup';
import { useSapLogoStyles } from './styles';
import { Props, FormDataProps, RESPONSE_OK } from './affiliationFormDefinitions';
// eslint-disable-next-line id-length
import useAfiliationOptionsColaborator from './hooks/useAfiliationOptionsColaborator';

// eslint-disable-next-line max-statements
function AffiliationForm(props: Props) {
  const { ENABLED_REPEATABILITY } = window.config;
  /* eslint-disable id-length */
  const { isEdit, onSetEditing, isEditing, ...rest } = props;

  const {
    isDirectGroup,
    showPlanType,
    showHiringMode,
    isCorporative,
    isComplementacion,
    isShowPlanEnable,
    showIncreasePlanAllowed,
    showIsPaymentResponsible,
    showCompanyPlanSelector,
    showCompanyCuit,
    companyPlanSelectorValidatorType,
    companyPlanSelectorChoicesTypes,
    companyPlanSelectorValidator,
    checkAndCorrectAfiliationOptions
  } = useAfiliationOptionsColaborator(isEdit!);
  /* eslint-enable id-length */

  const {
    documentChoices,
    affiliationTypeChoices,
    applicationTypeChoices,
    planTypeChoices,
    hiringModeChoices,
    increasePlanAllowedChoices,
    isPaymentResponsibleChoices,
    companyPlanChoices,
    applicationTypesChoicesNoDDJJ,
    // eslint-disable-next-line id-length
    applicationTypesChoicesNoOTHERS,
    getApplicationTypesChoices,
    // eslint-disable-next-line id-length
    applicationSubTypesChoicesTurnnedOff
  } = useListProviders();
  const t = useTranslate();
  const notify = useNotify();

  const styles = useFormStyles();
  const stylesSap = useSapLogoStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuperAdmin = getIsSuperAdmin(props.permissions?.role);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [formattedPromoter, setFormattedPromoter] = useState<Nullable<string>>(null);
  const MAX_LENGTH_DETAILS = 1024;
  const regexCharacters = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
  const regexAlpha = /[^a-zA-Z0-9]+/g;
  const regexNumero = /[^0-9]+/g;
  const [mutate, { data, error, loading }] = useMutation();
  const [isBeneficiary, setIsBeneficiary] = useState<boolean>(false);
  const [partialFormData, setPartialFormData] = useState<any>(null);
  const [beneficiaryData, setBeneficiaryData] = useState<any>(null);
  const [benficiaryDataError, setBeneficiaryDataError] = useState<any>(null);
  const [showModalBeneficiary, setShowModalBeneficiary] = useState<boolean>(false);
  const [repeatabilityData, setRepeatabilityData] = useState<any>(null);
  const [showModalRepeatability, setShowModalRepeatability] = useState<boolean>(false);
  const [valuesForm, setValuesForm] = useState<any>(null);
  const [wasCalled, setWasCalled] = useState<boolean>(false);
  const [hasAffiliation, sethasAffiliation] = useState<boolean>(false);
  const [invalidPlanValidityDate, setInvalidPlanValidityDate] = useState(false);
  const vbSteps: IVBSteps | undefined = props.record?.vbSteps;

  const [isEditPreDdjj, setIsEditPreDdjj] = useState(isEdit);
  const [uploadFileOnCancel, setUploadFileOnCancel] = useState<boolean>(false);
  const { attachments = [] }: any = props.record;
  const attachmentsResult = getOrderedAttachments(attachments || [], t);
  const extraFieldsEdit = props.record?.state === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING;

  const canEditDetails =
    props.record?.state === AFFILIATION_REQUEST_STATUS.USER_REJECTED ||
    props.record?.state === AFFILIATION_REQUEST_STATUS.FINISHED ||
    props.record?.state === AFFILIATION_REQUEST_STATUS.CANCELED;

  const validateDetails = maxLengthOptional(MAX_LENGTH_DETAILS, 'Máximo 1024 caracteres');
  const files = useSelector((state: any) => state.file);

  const promoter = `${props.record?.promoterFirstName} ${props.record?.promoterLastName}`;
  const uploadFiles = props.record?.state;

  useEffect(() => {
    if ((data || error) && !isBeneficiary) {
      dispatch?.(modalActions.openModal(MODAL_NAMES.DIALOG));
    }
  }, [data, dispatch, error, isBeneficiary]);

  const createAffiliation = useCallback(
    (values: FormDataProps) =>
      mutate({
        type: CREATE_APPLICATION,
        resource: props.resource,
        payload: values
      }),
    [mutate, props.resource]
  );

  const handleGetDataBeneficiario = useCallback((values: any) => {
    dataProvider(GET_DATOS_BENEFICIARIO, '', {
      values
    })
      .then((result: any) => {
        if (result.response.status === RESPONSE_OK) {
          setBeneficiaryData(result.response.data);
          setBeneficiaryDataError(null);
          sethasAffiliation(true);
        } else {
          sethasAffiliation(false);
          setBeneficiaryData(null);
          setBeneficiaryDataError(result.response.status);
        }
      })
      .catch((err: any) => {
        setBeneficiaryData(null);
        setBeneficiaryDataError(err);
      });
  }, []);

  const handleCreateAffliliation = useCallback(
    (values: any) => {
      localStorage.removeItem('planValidityDate');
      createAffiliation(values);
    },
    [createAffiliation, handleGetDataBeneficiario]
  );

  const getBeneficiaryData = useCallback(
    (values: any) => {
      localStorage.removeItem('planValidityDate');
      setIsValidationLoading(true);
      setPartialFormData(values);
      handleGetDataBeneficiario(values);
      // eslint-disable-next-line babel/no-unused-expressions
      values.callback && values.callback();
    },
    [handleGetDataBeneficiario, handleCreateAffliliation, createAffiliation]
  );

  const openModalBeneficiary = useCallback(() => {
    setIsBeneficiary(true);
    setShowModalBeneficiary(true);
  }, [handleCreateAffliliation, createAffiliation, handleGetDataBeneficiario]);

  const handleCloseBeneficiaryModal = useCallback(() => {
    setShowModalBeneficiary(false);
    setIsBeneficiary(false);
    setPartialFormData(null);
    setBeneficiaryData(null);
    setBeneficiaryDataError(null);
    setIsValidationLoading(false);
    setWasCalled(false);
  }, []);

  const handleCloseRepeatabilityModal = useCallback(() => {
    setShowModalRepeatability(false);
    setRepeatabilityData(null);
    setValuesForm(null);
    setIsValidationLoading(false);
    setWasCalled(false);
  }, []);

  const handleCreateBeneficiary = useCallback(
    (values: any) => {
      createAffiliation(values);
      handleCloseBeneficiaryModal();
    },
    [createAffiliation, handleCloseBeneficiaryModal]
  );

  const editAffiliation = useCallback(
    (values: any) => {
      localStorage.removeItem('planValidityDate');
      return mutate({
        type: EDIT_APPLICATION,
        resource: props.resource,
        payload: { ...values, isSuperAdmin }
      });
    },
    [props.resource, mutate, isSuperAdmin]
  );

  const handleEditAffiliation = useCallback(
    (values: any) => {
      localStorage.removeItem('planValidityDate');
      const isBeneficiaryForm = values.applicationType === APPLICATION_TYPES.BENEFICIARY;
      const isOthersForm = values.applicationType === APPLICATION_TYPES.OTHERS;
      const isValidBeneficiaryEditState = values.state === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING;

      if ((isBeneficiaryForm || isOthersForm) && isValidBeneficiaryEditState) {
        setIsValidationLoading(true);
        setPartialFormData(values);
        handleGetDataBeneficiario(values);
        setIsBeneficiary(true);
        setShowModalBeneficiary(true);
      } else {
        editAffiliation(values);
      }
    },
    [editAffiliation, handleGetDataBeneficiario]
  );

  const handleEditBeneficiary = useCallback(
    (values: any) => {
      editAffiliation(values);
      handleCloseBeneficiaryModal();
    },
    [editAffiliation, handleCloseBeneficiaryModal]
  );

  const handleExit = useCallback(() => {
    history.goBack();
  }, [history]);

  // const getDniNumber = (documentNumber: string) => documentNumber.substring(DNI_OF_CUIL, DNI_OF_CUIL_LENGTH);

  // const handleSave = (values: any) => {
  const handleSave = async (values: any) => {
    let errMsg = '';
    let emailValidated = false;
    const emailToVerify = values.user.emailConfirmation ?? values.user.email;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const emailValidationService = await getEmailValidation(emailToVerify).then((res: any) => {
      if (res.ok) {
        const validEmail = res.data.status === 'VA';
        const invalidEmail = res.data.status !== 'VA';
        // NOTA: Originalmente se debía validar con el statusReason, y devolver el mensaje correspondiente al front,
        // pero el servicio casi siempre devuelve "statusReason": "ED", para los emails invalidos o inexistentes.
        // Por eso se valida únicamente con el status. Es decir:
        // data.status = 'VA' para email válidos
        // data.status = 'NV' para email inválidos
        // Se adjunta documentación en la tarjeta #2252 del trello
        if (validEmail) {
          errMsg = t('validation.validEmailService');
          emailValidated = true;
        } else if (invalidEmail) {
          errMsg = t('validation.invalidEmailService');
        }
      }
    });
    if (isEdit && emailValidated) {
      // if (isEdit) {
      return handleEditAffiliation({
        ...values,
        planValidityDate: localStorage.getItem('planValidityDate')
          ? localStorage.getItem('planValidityDate')
          : values.planValidityDate
      });
    }
    if (emailValidated) {
      setValuesForm({ ...values, attachment: files });
      const { documentNumber } = values.user;
      if (ENABLED_REPEATABILITY) {
        const getHistorial = () => getPrevious(documentNumber);
        const getBeneficiary = () =>
          getBeneficiaryData({
            ...values,
            attachment: files,
            planValidityDate: localStorage.getItem('planValidityDate')
              ? localStorage.getItem('planValidityDate')
              : moment().toISOString()
          });

        if (
          values.applicationType === APPLICATION_TYPES.BENEFICIARY ||
          values.applicationType === APPLICATION_TYPES.OTHERS
        ) {
          return Promise.all([getHistorial(), getBeneficiary()]).then((result: any) => {
            if (result[0].status === STATUS_200 && result[0].data.total) {
              setRepeatabilityData(result[0].data);
              setShowModalRepeatability(true);
            } else {
              if (result[0].error) {
                notify(result[0].error);
              }
              openModalBeneficiary();
            }
          });
        }

        return getHistorial().then(async (result: any) => {
          if (result.status === STATUS_200 && result.data.total) {
            setRepeatabilityData(result.data);
            setShowModalRepeatability(true);
          } else {
            if (result.error) {
              notify(result.error);
            }
            await handleCreateAffliliation({
              ...values,
              attachment: files,
              planValidityDate: localStorage.getItem('planValidityDate')
                ? localStorage.getItem('planValidityDate')
                : moment().toISOString()
            });
          }
        });
      }

      return handleCreateAffliliation({
        ...values,
        attachment: files,
        planValidityDate: localStorage.getItem('planValidityDate')
          ? localStorage.getItem('planValidityDate')
          : moment().toISOString(),
        callback: openModalBeneficiary
      });
    }
    notify(errMsg);
    return false;
  };

  const handleContinue = () => {
    if (!hasAffiliation) {
      setShowModalRepeatability(false);
      if (
        valuesForm.applicationType !== APPLICATION_TYPES.BENEFICIARY &&
        valuesForm.applicationType !== APPLICATION_TYPES.OTHERS
      ) {
        handleCreateAffliliation({
          ...valuesForm,
          planValidityDate: localStorage.getItem('planValidityDate')
            ? localStorage.getItem('planValidityDate')
            : moment().toISOString()
        });
      } else {
        openModalBeneficiary();
      }
      setWasCalled(false);

      return;
    }
    if (beneficiaryData) {
      setShowModalRepeatability(false);
      openModalBeneficiary();
      setWasCalled(false);
      return;
    }

    setWasCalled(true);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (wasCalled) {
      const timeout = setTimeout(() => {
        handleContinue();
        // eslint-disable-next-line no-magic-numbers
      }, 600);

      return () => {
        setWasCalled(false);
        clearTimeout(timeout);
      };
    }
  }, [beneficiaryData]);

  const handleCreation = async (values: any) => {
    await handleSave(values);
    dispatch(modalActions.cleanFile());
  };

  const validatePromoter = useCallback(
    async (
      currentPromoterId: number,
      _: AffiliationRequest,
      fieldProps: { pristine: boolean; initial: any; modified: boolean }
    ) => {
      if (!fieldProps.pristine || (fieldProps.initial === currentPromoterId && fieldProps.modified)) {
        setIsValidationLoading(true);
        setFormattedPromoter(t('validation.loadingPromoter'));
        const promoterData = await dataProvider(GET_ONE_PROMOTER_LIST, RESOURCES_PATH.promoters, {
          id: currentPromoterId,
          appId: props.record?.id
        });
        setIsValidationLoading(false);
        if (!promoterData || promoterData.error) {
          setFormattedPromoter(null);
          return promoterData?.error || 'errors.default';
        }
        const { id, firstName, lastName } = promoterData.page?.[0] || {};
        setFormattedPromoter(`${id} - ${firstName} ${lastName}`);
      }
      return undefined;
    },
    [t]
  );

  return (
    <>
      <SimpleForm
        {...rest}
        className={styles.form}
        save={handleCreation}
        saving={loading || isValidationLoading}
        toolbar={
          isEditing || !isEdit ? (
            <Toolbar producto="OSDE" invalidPlanValidityDate={invalidPlanValidityDate} {...props} />
          ) : null
        }
        validateOnBlur
      >
        <FormDataConsumer>{checkAndCorrectAfiliationOptions(props)}</FormDataConsumer>

        {isEdit && (
          <InputLabel
            label={t('resources.affiliations.fields.id')}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.id}
            isEdit={isEdit}
          />
        )}
        {isEdit && (
          <InputLabel
            label={t('resources.affiliations.fields.origin')}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.sourceApplication}
            isEdit={isEdit}
          />
        )}
        <InputLabel
          label={t('resources.affiliations.fields.firstName')}
          className={commonStyles.row}
          source={AFFILIATION_REQUESTS_FIELDS.firstName}
          validate={[requiredValidator, regex(regexCharacters, 'No se permiten caracteres especiales.')]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
        />
        <InputLabel
          label={t('resources.affiliations.fields.lastName')}
          className={commonStyles.row}
          source={AFFILIATION_REQUESTS_FIELDS.lastName}
          validate={[requiredValidator, regex(regexCharacters, 'No se permiten caracteres especiales.')]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
        />
        <InputSelect
          className={commonStyles.row}
          source={AFFILIATION_REQUESTS_FIELDS.documentType}
          choices={documentChoices}
          label={t('resources.affiliations.fields.documentType')}
          defaultValue={DOCUMENT_TYPES?.DNI}
          specialFormatter={getDocumentType}
          validate={requiredValidator}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
        />
        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const type = formData.user?.documentType;
            const isPassport = type === DOCUMENT_TYPES.PASSPORT;
            if (!isPassport) {
              return (
                <InputLabel
                  {...formDataProps}
                  className={commonStyles.row}
                  source={AFFILIATION_REQUESTS_FIELDS.documentNumber}
                  label={t('resources.affiliations.fields.documentNumber')}
                  validate={[requiredValidator, ...(getDocumentNumberValidation({ t, type }) as any[])]}
                  {...(!isPassport && { ...INPUT_NUMBER_PROPS, type: 'number' })}
                  variant="standard"
                  isEdit={isEdit}
                  parse={(value: string) => value.replace(regexNumero, '')}
                  onSetEditing={onSetEditing}
                  type="text"
                />
              );
            }
            return (
              <InputLabel
                {...formDataProps}
                className={commonStyles.row}
                source={AFFILIATION_REQUESTS_FIELDS.documentNumber}
                label={t('resources.affiliations.fields.documentNumber')}
                validate={[requiredValidator, ...(getDocumentNumberValidation({ t, type }) as any[])]}
                variant="standard"
                isEdit={isEdit}
                onSetEditing={onSetEditing}
                parse={(value: string) => value.replace(regexAlpha, '')}
                type="text"
              />
            );
          }}
        </FormDataConsumer>
        <InputLabel
          label={t('resources.affiliations.fields.email')}
          className={commonStyles.row}
          type="email"
          source={AFFILIATION_REQUESTS_FIELDS.userEmail}
          validate={[requiredValidator, email]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
        />
        {!isEdit && (
          <FormDataConsumer>
            {({ formData, ...formDataProps }: FormDataProps) => {
              const emailData = getObjectValueFromString(AFFILIATION_REQUESTS_FIELDS.userEmail, formData);
              return (
                <InputLabel
                  {...formDataProps}
                  className={commonStyles.row}
                  label={t('resources.affiliations.fields.emailConfirmation')}
                  type="email"
                  source={AFFILIATION_REQUESTS_FIELDS.emailConfirmation}
                  validate={[requiredValidator, match(emailData, t('validation.emailMatching'))]}
                  isEdit={isEdit}
                  onSetEditing={onSetEditing}
                />
              );
            }}
          </FormDataConsumer>
        )}
        <InputLabel
          label={t('resources.affiliations.fields.phoneNumber')}
          className={commonStyles.row}
          source={AFFILIATION_REQUESTS_FIELDS.phoneNumber}
          validate={[phoneDigits]}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled
          type="text"
          parse={(value: string) => value.replace(regexNumero, '')}
        />

        {/* Si corresponde VB e inicie algun paso, dispongo del modal */}
        {isEdit &&
          vbSteps?.correspondeProceso &&
          (vbSteps?.capturaDocumento.iniciado ||
            vbSteps?.capturaManual.iniciado ||
            vbSteps?.pruebaDeVida.iniciado ||
            vbSteps?.validacionIdentidad.iniciado) && (
            <ViewInput label="Validaci&oacute;n biom&eacute;trica" content={<VBField vbSteps={vbSteps} />} />
          )}

        {/* Si corresponde VB y no inicie ningun paso, muestro el logo VB */}
        {isEdit &&
          vbSteps?.correspondeProceso &&
          !vbSteps?.capturaDocumento.iniciado &&
          !vbSteps?.capturaManual.iniciado &&
          !vbSteps?.pruebaDeVida.iniciado &&
          !vbSteps?.validacionIdentidad.iniciado && (
            <ViewInput
              label="Validaci&oacute;n biom&eacute;trica"
              content={<VBLogo height={100} width={100} />}
            />
          )}
        <InputSelect
          className={commonStyles.row}
          source={AFFILIATION_REQUESTS_FIELDS?.affiliationType}
          choices={affiliationTypeChoices}
          label={t('resources.affiliations.fields.type')}
          defaultValue={AFFILIATION_TYPES.DIRECT}
          validate={requiredValidator}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          specialFormatter={getAffiliationType}
          editEnabled={extraFieldsEdit}
        />
        {showPlanType && (
          <InputSelect
            {...props}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.planTypes}
            choices={planTypeChoices}
            defaultValue={PLAN_TYPES.BINARIO}
            label={t('resources.affiliations.fields.planType')}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            specialFormatter={getPlanType}
            editEnabled={extraFieldsEdit}
          />
        )}
        {showHiringMode && (
          <InputSelect
            {...props}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.hiringMode}
            disabled={isDirectGroup || isComplementacion}
            choices={hiringModeChoices}
            label={t('resources.affiliations.fields.hiringMode')}
            defaultValue={HIRING_MODE_TYPES.APO}
            validate={showHiringMode && requiredValidator}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            specialFormatter={getHiringModeTypes}
            editEnabled={extraFieldsEdit}
          />
        )}
        {showCompanyPlanSelector && !isCorporative && isShowPlanEnable && (
          <InputSelect
            {...props}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.companyPlan}
            choices={companyPlanChoices(companyPlanSelectorChoicesTypes)}
            label={t('resources.affiliations.fields.companyPlan')}
            defaultValue={COMPANY_PLAN_TYPES.P210}
            validate={companyPlanSelectorValidator(companyPlanSelectorValidatorType)}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            specialFormatter={getCompanyPlanType}
            editEnabled={extraFieldsEdit}
          />
        )}
        {showCompanyPlanSelector && isCorporative && isShowPlanEnable && (
          <InputSelect
            {...props}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.companyPlan}
            choices={companyPlanChoices(companyPlanSelectorChoicesTypes)}
            label={t('resources.affiliations.fields.companyPlan')}
            defaultValue={CORPORATIVE_PLAN_TYPES.P8360}
            validate={companyPlanSelectorValidator(companyPlanSelectorValidatorType)}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            specialFormatter={getCorporativePlanType}
            editEnabled={extraFieldsEdit}
          />
        )}
        {showIncreasePlanAllowed && (
          <InputSelect
            {...props}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.increasePlanAllowed}
            choices={increasePlanAllowedChoices}
            label={t('resources.affiliations.fields.increasePlanAllowed')}
            defaultValue={INCREASE_PLAN_ALLOWED_TYPES.false}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            specialFormatter={getIncreasePlanAllowedType}
            editEnabled={extraFieldsEdit}
          />
        )}
        {showIsPaymentResponsible && (
          <InputSelect
            {...props}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.isPaymentResponsible}
            disabled={isDirectGroup}
            choices={isPaymentResponsibleChoices}
            label={t('resources.affiliations.fields.isPaymentResponsible')}
            defaultValue={IS_PAYMENT_RESPONSIBLE_TYPES.false}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            specialFormatter={getIsPaymentResponsibleType}
            editEnabled={extraFieldsEdit}
          />
        )}

        {showCompanyCuit && (
          <InputLabel
            label={t('resources.affiliations.fields.cuit')}
            className={commonStyles.row}
            source={AFFILIATION_REQUESTS_FIELDS.cuit}
            validate={[
              requiredValidator,
              minLength(CUIT_MIN_LENGTH, t('validation.exactDigits', { length: CUIT_MIN_LENGTH })),
              validacionCUIT(t('validation.cuitNumber'))
            ]}
            isEdit={isEdit}
            onSetEditing={onSetEditing}
            type="text"
            parse={(value: string) => value.replace(regexNumero, '')}
          />
        )}

        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const showOthers = window.config.ENABLED_OTHERS;
            const showddjj =
              formData.affiliationType === AFFILIATION_TYPES.DIRECT ||
              formData.affiliationType === AFFILIATION_TYPES.MONOTAX;
            let choices = showddjj ? applicationTypeChoices : applicationTypesChoicesNoDDJJ();
            if (!showddjj && formData.applicationType === APPLICATION_TYPES.DDJJ) {
              formData.applicationType = APPLICATION_TYPES.FULL;
              setIsEditPreDdjj(false);
            }
            if (!showOthers) {
              choices = applicationTypesChoicesNoOTHERS(choices);
            }
            if (props.permissions && props.permissions.subroles.includes(ROLE_TYPES.Asesor)) {
              choices = getApplicationTypesChoices([APPLICATION_TYPES.F1, APPLICATION_TYPES.OTHERS]);
            }
            return (
              <InputSelect
                {...formDataProps}
                className={commonStyles.row}
                source={AFFILIATION_REQUESTS_FIELDS.applicationType}
                choices={choices}
                label={t('resources.affiliations.fields.applicationType')}
                defaultValue={APPLICATION_TYPES.FULL}
                validate={requiredValidator}
                isEdit={isEditPreDdjj}
                onSetEditing={onSetEditing}
                specialFormatter={getApplicationType}
                editEnabled={extraFieldsEdit}
              />
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }: FormDataProps) => {
            if (
              formData.applicationType === APPLICATION_TYPES.BENEFICIARY &&
              window.location.href.includes('show') &&
              formData.datosTitularContrato
            ) {
              return (
                <div className={`${stylesSap.container}`}>
                  <div className={`${stylesSap.innerContainer}`}>
                    <h3 className={`${stylesSap.label}`}>
                      <Sap height={50} width={50} />
                    </h3>
                  </div>
                </div>
              );
            }
            return undefined;
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const {
              ENABLED_DOWN,
              ENABLED_CHANGE_PLAN,
              ENABLED_SPECIAL_PLAN,
              ENABLED_PLURIEMPLEO,
              ENABLED_SUMATORIA_APORTES
            } = window.config;

            const choices = applicationSubTypesChoicesTurnnedOff({
              ENABLED_DOWN,
              ENABLED_CHANGE_PLAN,
              ENABLED_SPECIAL_PLAN,
              ENABLED_PLURIEMPLEO,
              ENABLED_SUMATORIA_APORTES
            });

            return formData.applicationType === APPLICATION_TYPES.OTHERS ? (
              <InputSelect
                {...formDataProps}
                className={commonStyles.row}
                source={AFFILIATION_REQUESTS_FIELDS.applicationSubtype}
                choices={choices}
                label={t('resources.affiliations.fields.applicationSubtype')}
                defaultValue={APPLICATION_SUBTYPES.CAMBIO_DE_CONDICION_IVA}
                validate={requiredValidator}
                isEdit={isEdit}
                onSetEditing={onSetEditing}
                specialFormatter={getApplicationSubtype}
                editEnabled={extraFieldsEdit}
              />
            ) : (
              undefined
            );
          }}
        </FormDataConsumer>
        {props?.record?.familyGroupDetails && (
          <FamilyGroup familyData={props.record.familyGroupDetails} isEdit={isEdit} holder={props?.record} />
        )}
        <InputLabel
          className={commonStyles.row}
          label={t('resources.affiliations.fields.promoterId')}
          source={AFFILIATION_REQUESTS_FIELDS.promoterId}
          validate={[
            requiredValidator,
            numericalDigits,
            promoterId(t),
            ...(isEdit ? [validatePromoter] : [])
          ]}
          type="number"
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled={isSuperAdmin || props?.record?.sourceApplication === 'AUTOGESTION'}
          fieldMessage={formattedPromoter}
          defaultValue={props?.permissions?.id}
          tooltipOnView={promoter}
        />
        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            const { affiliationType, applicationType, planValidityDate } = formData;
            const isDirectOrMonotax =
              affiliationType === AFFILIATION_TYPES.DIRECT || affiliationType === AFFILIATION_TYPES.MONOTAX;
            const isFullApplication = applicationType === APPLICATION_TYPES.FULL;
            const shouldDisablePast = isDirectOrMonotax && isFullApplication;
            const DAYS_BEFORE = 59;
            const validationMethod = shouldDisablePast ? validateFutureDate : validate60DaysBefore;
            const isEditable =
              props.record?.state === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_PENDING ||
              props.record?.state === AFFILIATION_REQUEST_STATUS.INITIAL_EMAIL_SENT;

            if (isEdit && !planValidityDate) {
              return;
            }

            // eslint-disable-next-line consistent-return
            return (
              <AutoCompleteDate
                {...formDataProps}
                label={t('resources.affiliations.fields.planValidityDate')}
                className={commonStyles.row}
                source={AFFILIATION_REQUESTS_FIELDS.planValidityDate}
                validate={[requiredValidator, validationMethod]}
                disablePast={shouldDisablePast}
                minDate={moment(isEdit ? props.record?.createdAt : new Date())
                  .subtract(DAYS_BEFORE, 'days')
                  .toString()}
                isEdit={isEdit}
                onSetEditing={onSetEditing}
                showErrorOnEdit
                editEnabled={isEditable}
                setInvalidPlanValidityDate={setInvalidPlanValidityDate}
                fechaCreacion={isEdit ? props.record?.createdAt : null}
              />
            );
          }}
        </FormDataConsumer>
        <InputLabel
          label="Observaciones"
          className={commonStyles.row}
          source={AFFILIATION_REQUESTS_FIELDS.details}
          validate={validateDetails}
          isEdit={isEdit}
          onSetEditing={onSetEditing}
          editEnabled={!canEditDetails}
          multiline
          rows={3}
        />
        {!uploadFiles && (
          <WithProps>
            {(createProps: object) => (
              <AffiliationUploadFileBc
                {...createProps}
                openInput={uploadFileOnCancel}
                resetOpenInput={() => setUploadFileOnCancel(false)}
                attachments={attachmentsResult.fromPromoter}
              />
            )}
          </WithProps>
        )}
        <FormDataConsumer>
          {({ formData, ...formDataProps }: FormDataProps) => {
            if (formData.preddjjId) {
              return (
                isEdit && (
                  <InputLabel
                    {...formDataProps}
                    label="Pre DDJJ"
                    className={commonStyles.row}
                    source={AFFILIATION_REQUESTS_FIELDS.preddjjId}
                    defaultValue={formData.preddjjId}
                    isEdit={isEdit}
                    editEnabled={false}
                  />
                )
              );
            }
            return undefined;
          }}
        </FormDataConsumer>
      </SimpleForm>
      {/* {shouldUploadFile(record.state) && !isDDJJ && ( */}

      {/* )} */}
      {(beneficiaryData || benficiaryDataError) && isBeneficiary && showModalBeneficiary && (
        <CreateBeneficiaryModal
          {...(beneficiaryData || benficiaryDataError
            ? {
                onClose: handleCloseBeneficiaryModal,
                onCreate: isEdit ? handleEditBeneficiary : handleCreateBeneficiary,
                data: beneficiaryData,
                error: benficiaryDataError,
                beneficiaryData: partialFormData,
                isOpen: showModalBeneficiary
              }
            : {
                isOpen: showModalBeneficiary
              })}
        />
      )}
      {repeatabilityData && showModalRepeatability && (
        <RepeatabilityModal
          onClose={handleCloseRepeatabilityModal}
          onContinue={handleContinue}
          data={repeatabilityData}
          isOpen={showModalRepeatability}
          disableContinue={wasCalled}
        />
      )}

      {(data || error) && !isBeneficiary && !showModalBeneficiary && !partialFormData && (
        <CustomModal modalName={MODAL_NAMES.DIALOG} {...(data ? { onClose: handleExit } : {})}>
          <FeedbackModalContent
            error={error}
            successMessage={t(`resources.affiliations.modals.form.${isEdit ? 'edit' : 'create'}Success`)}
          />
        </CustomModal>
      )}
    </>
  );
}

export default AffiliationForm;
