import { create } from 'apisauce';

import { serializer } from '../serializer';
import { ClientStorageService } from '../../services/ClientStorageService';

import { TOKEN_HEADER, STATUS_CODES } from './constants';

let api = create({
  baseURL: 'localhost',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

let apiCreateOk = false;

export const setTokenHeader = token => {
  api.setHeader(TOKEN_HEADER, `Bearer ${token}`);
};

export const removeTokenHeader = () => {
  api.deleteHeader(TOKEN_HEADER);
};

export const createApi = apiURL => create({ baseURL: apiURL });

export const getApiNoSerialization = () => {
  if (apiCreateOk) {
    if (!api.headers.Authorization && ClientStorageService.getToken()) {
      setTokenHeader(ClientStorageService.getToken());
    }
    return api;
  }
  api = create({
    baseURL: window.config.REACT_APP_BASE_URL,
    timeout: 15000,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!api.headers.Authorization && ClientStorageService.getToken()) {
    setTokenHeader(ClientStorageService.getToken());
  }
  // eslint-disable-next-line no-alert
  api.addMonitor(response => {
    if (response.status === STATUS_CODES.unauthorized) {
      // TODO: Not used anymore.
    }
  });

  api.addMonitor(response => {
    if (response.status === STATUS_CODES.notUpdated) {
      window.location.href = `${window.location.origin}/deployInProgress.html`;
    }
  });

  api.addMonitor(response => {
    if (response.problem === 'NETWORK_ERROR') {
      // TODO: These callbacks should only be called if no other callback was asigned for the response.
    }
  });

  api.addRequestTransform(request => {
    if (request.params) {
      request.params = serializer.serialize(request?.params);
    }
  });

  apiCreateOk = true;

  return api;
};
