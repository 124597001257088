import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY_BLUE, GRAY } from '~constants/colors';

export const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px'
  },
  back: {
    alignSelf: 'start',
    justifySelf: 'start',
    '& span': {
      fontSize: '30px'
    },
    '&:hover': {
      backgroundColor: WHITE,
      transform: 'scale(1.2, 1.2)',
      color: PRIMARY_BLUE
    },
    '&:focus': {
      backgroundColor: WHITE
    }
  },
  arrowBack: {
    color: GRAY
  }
});
