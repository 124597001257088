import { AFFILIATION_TYPES, APPLICATION_TYPES } from '~constants/affiliations';

import { ALTA_MASIVA_STATUS, ALTA_MASIVA_DETALLE_STATUS } from './constants';

const altasMasivasSpanish = {
  name: 'Altas masivas',
  listName: 'Listado de altas masivas',
  details: 'Listado de altas masivas - detalle',
  createTitle: 'Nueva alta masiva',
  fields: {
    id: 'ID',
    promotor: 'Promotor',
    name: 'Empresa',
    cantAltas: 'Cantidad solicitudes',
    cantOk: 'Solicitudes ok',
    cantError: 'Solicitudes error',
    estado: 'Estado'
  },
  detail: {
    title: 'Detalle de la solicitud: ',
    nroRegistro: 'Nro',
    nombre: 'Nombre',
    apellido: 'Apellido',
    tipo: 'Tipo de socio',
    formulario: 'Formulario',
    idSolicitud: 'ID solicitud',
    estado: 'Creada',
    promotorEmail: 'Email Promotor',
    altasEmail: 'E-mail',
    prospectVigencia: 'Fecha de vigencia',
    prospectPlan: 'Plan',
    prospectTipoPlan: 'Tipo de plan',
    observaciones: 'Observaciones'
  },
  statusTypes: {
    [ALTA_MASIVA_STATUS.EN_PROCESO]: 'En proceso',
    [ALTA_MASIVA_STATUS.PROCESADO]: 'Procesado',
    [ALTA_MASIVA_STATUS.NO_PROCESADO]: 'No procesado',
    [ALTA_MASIVA_STATUS.CARGANDO]: 'Cargando'
  },
  statusDetail: {
    [ALTA_MASIVA_DETALLE_STATUS.OK]: 'Ok',
    [ALTA_MASIVA_DETALLE_STATUS.ERROR]: 'Error',
    [ALTA_MASIVA_DETALLE_STATUS.SIN_PROCESAR]: 'Sin procesar'
  },
  applicationTypes: {
    [APPLICATION_TYPES.FULL]: 'F1 + DDJJ',
    [APPLICATION_TYPES.F1]: 'F1',
    [APPLICATION_TYPES.BENEFICIARY]: 'Beneficiario',
    [APPLICATION_TYPES.DDJJ]: 'Pre-DDJJ'
  },
  affiliationTypes: {
    [AFFILIATION_TYPES.DIRECT]: 'Directo',
    [AFFILIATION_TYPES.DIRECT_GROUP]: 'Directo Por Empresa',
    [AFFILIATION_TYPES.MANDATORY]: 'Obligatorio',
    [AFFILIATION_TYPES.MONOTAX]: 'Monotributo',
    [AFFILIATION_TYPES.AGREEMENT]: 'Convenio',
    [AFFILIATION_TYPES.COMPLEMENT]: 'Complementación'
  },
  modals: {
    create: {
      success: 'Se han subido las altas correctamente'
    }
  }
};

export default altasMasivasSpanish;
